import {
  callApi,
  getDefaultEditActions,
  pushUrlToBrowserHistory,
  addExtraFilterToQuery
} from './helpers'
import {
  redirect
} from 'redux-first-router'
import {getDefaultTypeaheadOptionsFromKodifArray} from "../components/forms/FormHelpers";


export const pazemesUdensObjekti = (query) => (dispatch, getState) => {
  const mapSize = getState().gui['udeni_saraksts_karte_size']
  return dispatch(callApi({
    viewName: 'pazemes_udens_objekti_list',
    crudMethod: 'listPaging',
    crudParam: addExtraFilterToQuery(query, mapSize)
  }))
}

export const udensObjektsSelected = (kods) => {
  return {
    type: 'pazemes_udens_objekts_selected',
    payload: kods
  }
}

export const udensObjektsDoubleSelected = (kods) => (dispatch, getState) => {
  pushUrlToBrowserHistory(getState)
  dispatch(redirect({
    type: 'loc_pazemes_udens_objekts',
    payload: {
      kods: kods
    }
  }))
}

export const pazemesUdensObjektiActions = getDefaultEditActions({
  actionTypeName: 'pazemes_udens_objekts',
  stateName: 'pazemesUdensObjekts',
  apiViewName: 'pazemes_udens_objekts_view',
  afterSave: (dispatch, getState, result, savingNew) => {
    dispatch({
      type: 'pazemes_udens_objekts_clear'
    })
    dispatch(redirect({
      type: 'loc_pazemes_udens_objekts',
      payload: {
        kods: result.kods
      }
    }))
  },
  fetchArgsToCrudParams: ({kods}) => ['kods', kods]
})


export const pazemesUdensObjektsAtrasanasActions = getDefaultEditActions({
  actionTypeName: 'udens_objekts_atrasanas',
  stateName: 'pazemesUdensObjektsAtrasanas',
  apiViewName: 'pazemes_udens_objekts_atrasanas_view',
  fetchArgsToCrudParams: ({
    kods
  }) => ['kods', kods]
})

export const uoPazemesPasakumiActions = getDefaultEditActions({
  actionTypeName : 'uo_pazemes_pasakumi',
  stateName: 'uoPazemesPasakumi',
  combinedPage: true,
  apiViewName: 'uo_pasakumi_view',
  fetchArgsToCrudParams: ({ kods, periods }) => {
    if (periods != null) return ['kods', kods, 'periods', periods]
    else return ['kods', kods]
  }
})

export const pazemesUdensObjektiKvantitateActions = getDefaultEditActions({
  actionTypeName: 'pazemes_udens_objekts_kvantitate',
  stateName: 'pazemesUdensObjektsKvantitate',
  apiViewName: 'pazemes_udens_objekts_kvantitate_view',
  fetchArgsToCrudParams: ({
    kods
  }) => ['kods', kods]
})

const getQueryTab = (getState) => {
  const { query } = getState().location
  if(query && query.tab){
    return {tab: query.tab}
  }
  return undefined
}

export const pazemesUdensObjektsKimActions = getDefaultEditActions({
  actionTypeName: 'pazemes_udens_objekts_kim',
  stateName: 'pazemesUdensObjektsKim',
  apiViewName: 'pazemes_udens_objekts_kvalitate_kimiska',
  fetchArgsToCrudParams: ({ kods, gads }) => {
    let y = gads
    if (!y) {
      y = new Date().getFullYear() - 1
    }
    return ['kods', kods, 'gads', y]
  }
})

export const pazemesUdensObjektiRiskiUnIznemumiActions = getDefaultEditActions({
  actionTypeName: 'pazemes_udens_objekts_risks',
  stateName: 'pazemesUdensObjektsRiskiUnIznemumi',
  apiViewName: 'pazemes_udens_objekts_risks_view',
  fetchArgsToCrudParams: ({ kods, periods}, getState) => {
    let y = periods
    if (!y) {
      y = findPeriods(getState().kodif)
    }
    if (y == null) {
      return ['kods', kods]
    } else return ['kods', kods, 'periods', y]
  },
  afterSave: (dispatch, getState, result, savingNew) => {
    dispatch({
      type: 'pazemes_udens_objekts_clear'
    })
    dispatch(redirect({
      type: 'loc_pazemes_udens_objekts',
      payload: {
        kods: result.kods,
        gads: result.gads
      },
      query: getQueryTab(getState)
    }))
  }
})

const findPeriods = (kodif) => {
  if (kodif) {
    return getDefaultTypeaheadOptionsFromKodifArray(kodif, 'UO_PERIODS').find(val => {
          const values = val.tah_id.match(/\d+/g).map(num => parseInt(num))
          return new Date().getFullYear() >= values[0] && new Date().getFullYear() <= values[1]
        }
    ).tah_id
  } else return null
}

export const pazemesUdensObjektiSlodzesActions = getDefaultEditActions({
  actionTypeName: 'pazemes_udens_objekts_slodzes',
  stateName: 'pazemesUdensObjektsSlodzes',
  apiViewName: 'pazemes_udens_objekts_slodzes_view',
  fetchArgsToCrudParams: ({ kods, periods}, getState) => {
    let y = periods
    if (!y) {
      y = findPeriods(getState().kodif)
    }
    if (y == null) {
      return ['kods', kods]
    } else return ['kods', kods, 'periods', y]
  },
  afterSave: (dispatch, getState, result, savingNew) => {
    dispatch({
      type: 'pazemes_udens_objekts_clear'
    })
    dispatch(redirect({
      type: 'loc_pazemes_udens_objekts',
      payload: {
        kods: result.kods,
        gads: result.gads
      },
      query: getQueryTab(getState)
    }))
  }
})

export const pazemesUdensObjektiPapildinformacijaActions = getDefaultEditActions({
  actionTypeName: 'pazemes_udens_objekts_papildinformacija',
  stateName: 'pazemesUdensObjektsPapildinformacija',
  apiViewName: 'pazemes_udens_objekts_papildinformacija_view',
  fetchArgsToCrudParams: ({
                            kods
                          }) => ['kods', kods]
})

//
//
// export const uoPasakumiActions = getDefaultEditActions({
//   actionTypeName : 'uo_pasakumi',
//   stateName: 'uoPasakumi',
//   combinedPage: true,
//   apiViewName: 'uo_pasakumi_view',
//   fetchArgsToCrudParams: ({ kods }) => ['kods', kods]
// })
//
// export const udensObjektsEkoActions = getDefaultEditActions({
//   actionTypeName: 'udens_objekts_eko',
//   stateName: 'udensObjektsEko',
//   apiViewName: 'udens_objekts_kvalitate_ekologija',
//   fetchArgsToCrudParams: ({ kods, gads }) => {
//     let y = gads
//     if (!y) {
//       y = new Date().getFullYear()
//     }
//     return ['kods', kods, 'gads', y]
//   }
// })
//
// export const udensObjektsSlodzesActions = getDefaultEditActions({
//   actionTypeName: 'udens_objekts_slodzes',
//   stateName: 'udensObjektsSlodzes',
//   apiViewName: 'udens_objekts_slodzes',
//   fetchArgsToCrudParams: ({ kods, gads }) => {
//     let y = gads
//     if (!y) {
//       y = new Date().getFullYear()
//     }
//     return ['kods', kods, 'gads', y]
//   }
// })
