import {AppSidebarNav} from '@coreui/react';

import React from 'react';
import {NavItem, NavLink as RsNavLink} from 'reactstrap';
import {NavLink} from 'redux-first-router-link'

import {guiSet} from '../../actions/uiActions'

//overrided to use redux-first-router-link
class AppSidebarNavIzraktenis extends AppSidebarNav {

  onNavClick = (e) => {
    window.the_store.dispatch(guiSet('force_reload'))
    this.hideMobile(e)
  }

    navLink(item, key, classes) {
        const url = item.url ? item.url : '';
        const isExternal = typeof url !== 'object' && this.isExternal(url)
        const itemIcon = <i className={classes.icon} />
        const itemBadge = this.navBadge(item.badge)
        const attributes = item.attributes || {}
        return (
          <NavItem key={key} className={classes.item}>
            { attributes.disabled ?
                <RsNavLink href={""} className={classes.link} {...attributes}>
                  {itemIcon}{item.name}{itemBadge}
                </RsNavLink>
             :
             isExternal ?
                <RsNavLink href={url} className={classes.link} active {...attributes}>
                  {itemIcon}{item.name}{itemBadge}
                </RsNavLink> :
                <NavLink to={url} className={classes.link} activeClassName="active" onClick={this.onNavClick} {...attributes}>
                  {itemIcon}{item.name}{itemBadge}
                </NavLink>
            }
          </NavItem>
        );
      }
}

export default AppSidebarNavIzraktenis
