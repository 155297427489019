import * as helpers from './helpers'

import { startWs, stopWs } from "../ws"

export const user = (state = null, {
    type,
    payload
}) => {
    switch (type) {
        case 'lietotajs_info_list_success':
        case 'login_success':
            if (!payload || payload.length === 0 || !payload[0].id) return null
            const loggedUser = payload[0]
            const roles = loggedUser.lomas.map(l=>l.name)
            loggedUser.lomas = roles
            loggedUser.tiesibas = loggedUser.tiesibas_sep.split(";")
            startWs()
            return loggedUser
        case 'lietotajs_info_list_failure':
        case 'logout_success':
            stopWs()
            return null
        case 'update_user_uzstadijumi':
            const oldUzst = state.uzstadijumi || []
            const uzstadijumi = oldUzst.filter(u => u.nosaukums !== payload.nosaukums)
            uzstadijumi.push(payload)
            return {...state, uzstadijumi}
        default:
            return state
    }
}

const defaultListReducerForLietotaji = helpers.createListReducer({listViewName: 'lietotajs_edit', actionsName: 'lietotajs'})
const defaultEditReducerForLietotaji = helpers.createEditReducer({editViewName: 'lietotajs_edit', actionsName: 'lietotajs'})
export const lietotaji = (state = helpers.listEditGetInitialState(), {type, payload, reqId}) => {
    //overwrite or add custom actions catches here

    const stateAfterList = defaultListReducerForLietotaji({state, type, payload, reqId})
    return defaultEditReducerForLietotaji({state : stateAfterList, type, payload, reqId})
}
