import * as helpers from "./helpers";

const defaultListReducerForRaditaji = helpers.createListReducer({listViewName: 'raditajs_ekologija_edit', actionsName: 'raditajsEkologija'})
const defaultEditReducerForRaditaji = helpers.createEditReducer({editViewName: 'raditajs_ekologija_edit', actionsName: 'raditajsEkologija'})
export const raditajiEkologija = (state = helpers.listEditGetInitialState(), {type, payload, reqId}) => {
  //overwrite or add custom actions catches here

  const stateAfterList = defaultListReducerForRaditaji({state, type, payload, reqId})
  return defaultEditReducerForRaditaji({state: stateAfterList, type, payload, reqId})
}


const defaultListReducerForRaditajiKimiska = helpers.createListReducer({listViewName: 'raditajs_kimiska_edit', actionsName: 'raditajsKimiska'})
const defaultEditReducerForRaditajiKimiska = helpers.createEditReducer({editViewName: 'raditajs_kimiska_edit', actionsName: 'raditajsKimiska'})
export const raditajiKimiska = (state = helpers.listEditGetInitialState(), {type, payload, reqId}) => {
  //overwrite or add custom actions catches here

  const stateAfterList = defaultListReducerForRaditajiKimiska({state, type, payload, reqId})
  return defaultEditReducerForRaditajiKimiska({state: stateAfterList, type, payload, reqId})
}


const defaultListReducerForRaditajiQl = helpers.createListReducer({listViewName: 'raditajs_ql_edit', actionsName: 'raditajsQl'})
const defaultEditReducerForRaditajiQl = helpers.createEditReducer({editViewName: 'raditajs_ql_edit', actionsName: 'raditajsQl'})
export const raditajiQl = (state = helpers.listEditGetInitialState(), {type, payload, reqId}) => {
  //overwrite or add custom actions catches here

  const stateAfterList = defaultListReducerForRaditajiQl({state, type, payload, reqId})
  return defaultEditReducerForRaditajiQl({state: stateAfterList, type, payload, reqId})
}
