import * as helpers from './helpers'
import _ from 'lodash'

const defaultListReducerForAtradnes = helpers.createListReducerWithMapClicking({
    listViewName: 'atradne_list',
    actionsName: 'atradne',
    mapIdName: 'atradnes_saraksts',
    layerIdName: 'atradnes'
})
export const atradnes = (state = helpers.listGetInitialState(), { type, payload, reqId, mapId, layerId }) => {
    return defaultListReducerForAtradnes({ state, type, payload, reqId, mapId, layerId })
}

const defaultEditReducerForAtradne = helpers.createEditReducer({ editViewName: 'atradne_view', actionsName: 'atradne' })
export const atradne = (state = helpers.editGetInitialState(), { type, payload }) => {
    return defaultEditReducerForAtradne({ state, type, payload })
}

const defaultEditReducerForAtradnesDala = helpers.createEditReducer({ editViewName: 'atradnes_dala_view', actionsName: 'atradnes_dala' })
export const atradnesDala = (state = helpers.editGetInitialState(), { type, payload }) => {
    return defaultEditReducerForAtradnesDala({ state, type, payload })
}

const defaultEditReducerForAtrasanas = helpers.createEditReducerWithGeometryEditing({
    editViewName: 'atradne_atrasanas_view',
    actionsName: 'atradne_atrasanas',
    mapIdName: 'atradnes_kartina',
    layerIdName: 'atradne_atrasanas_koord',
})
export const atradneAtrasanas = (state = helpers.editWithGeometryInitialState(), { type, payload, mapId, layerId, featureGeom }) => {
    switch (type) {
        // clear atrasanas when atradne clears
        case 'atradne_clear':
            return helpers.editWithGeometryInitialState()
        default:
            return defaultEditReducerForAtrasanas({ state, type, payload, mapId, layerId, featureGeom })
    }
}

const defaultEditReducerForDalaAtrasanas = helpers.createEditReducerWithGeometryEditing({
    editViewName: 'atradnes_dala_atrasanas_view',
    actionsName: 'atradnes_dala_atrasanas',
    mapIdName: 'atradnes_dala_kartina',
    layerIdName: 'atradne_atrasanas_koord',
})
export const atradnesDalaAtrasanas = (state = helpers.editWithGeometryInitialState(), { type, payload, mapId, layerId, featureGeom }) => {
    switch (type) {
        // clear atrasanas when atradne clears
        case 'atradnes_dala_clear':
            return helpers.editWithGeometryInitialState()
        default:
            return defaultEditReducerForDalaAtrasanas({ state, type, payload, mapId, layerId, featureGeom })
    }
}

const defaultEditReducerForGeologija = helpers.createEditReducer({ editViewName: 'atradne_geologija_view', actionsName: 'atradne_geologija' })
export const atradneGeologija = (state = helpers.editGetInitialState(), { type, payload }) => {
    switch (type) {
        // clear atrasanas when atradne clears
        case 'atradne_clear':
            return helpers.editGetInitialState()
        default:
            return defaultEditReducerForGeologija({ state, type, payload })
    }
}

const defaultEditReducerForDalaGeologija = helpers.createEditReducer({ editViewName: 'atradnes_dala_geologija_view', actionsName: 'atradnes_dala_geologija' })
export const atradnesDalaGeologija = (state = helpers.editGetInitialState(), { type, payload }) => {
    switch (type) {
        // clear atrasanas when atradne clears
        case 'atradnes_dala_clear':
            return helpers.editGetInitialState()
        default:
            return defaultEditReducerForDalaGeologija({ state, type, payload })
    }
}

const defaultEditReducerForKvalitate = helpers.createEditReducer({ editViewName: 'atradne_kvalitate_view', actionsName: 'atradne_kvalitate' })
export const atradneKvalitate = (state = helpers.editGetInitialState(), { type, payload }) => {
    switch (type) {
        case 'atradne_clear':
            return helpers.editGetInitialState()
        default:
            return defaultEditReducerForKvalitate({ state, type, payload })
    }
}

const defaultEditReducerForDalaKvalitate = helpers.createEditReducer({ editViewName: 'atradnes_dala_kvalitate_view', actionsName: 'atradnes_dala_kvalitate' })
export const atradnesDalaKvalitate = (state = helpers.editGetInitialState(), { type, payload }) => {
    switch (type) {
        case 'atradnes_dala_clear':
            return helpers.editGetInitialState()
        default:
            return defaultEditReducerForDalaKvalitate({ state, type, payload })
    }
}

const getUzzinasInGridFromState = (state) => {
    if (state.selectedItem && state.selectedItem.uzzinas) {
        return [...state.selectedItem.uzzinas]
    } else {
        return []
    }
}

const defaultEditReducerForUzzinas = helpers.createEditReducer({ editViewName: 'atradne_uzzinas_view', actionsName: 'atradne_uzzinas' })
const getAtradnesUzzinasInitialState = () => ({ uzzinasInGrid: [], ...helpers.editGetInitialState() })
export const atradneUzzinas = (state = getAtradnesUzzinasInitialState(), { type, payload }) => {
    const newState = defaultEditReducerForUzzinas({ state, type, payload });
    // extra things for uzzinas
    switch (type) {
        // clear atrasanas when atradne clears
        case 'atradne_clear':
            return getAtradnesUzzinasInitialState()
        case `atradne_uzzinas_view_save_success`:
        case `atradne_uzzinas_view_get_success`:
        case `atradne_uzzinas_stop_edit`:
            return { ...newState, uzzinasInGrid: getUzzinasInGridFromState(newState) }
        case `atradne_uzzinas_delete_atradne_uzzina`:
            const newUzzinasInGridD = [...newState.uzzinasInGrid]
            _.remove(newUzzinasInGridD, (atrUzz) => atrUzz.uzzina_id === payload)
            return {
                ...newState,
                uzzinasInGrid: newUzzinasInGridD
            }
        case `atradne_uzzinas_add_uzzina`:
            const newUzzinasInGridA = [...newState.uzzinasInGrid]
            newUzzinasInGridA.push(payload)
            return {
                ...newState,
                uzzinasInGrid: newUzzinasInGridA
            }
        default:
            return newState
    }
}

const fillIeprNak = (array, item, uzIepr, uzNak) => {
    // ja ir iezīmēts, rekursīvi meklējam saistītos un iezīmējam
    if (item.selected || item.selectedSaist || item.selectedIeprNak) {
        if (item.ieprieksejais_id && uzIepr) {
            const ieprInd = array.findIndex((it) => it.id === item.ieprieksejais_id)
            if (ieprInd >= 0) {
                const iepr = { ...array[ieprInd], selectedIeprNak: true }
                array[ieprInd] = iepr
                fillIeprNak(array, iepr, true, false)
            }
        }
        if (uzNak) {
            const nakInd = array.findIndex((it) => it.ieprieksejais_id === item.id)
            if (nakInd >= 0) {
                const nak = { ...array[nakInd], selectedIeprNak: true }
                array[nakInd] = nak
                fillIeprNak(array, nak, false, true)
            }
        }
    }
}

const changeSelectedDok = (state, selectedDok) => {
    const { selectedItem } = state
    if (selectedItem) {
        const { pases, limiti, licences } = selectedItem
        if (pases && limiti && licences) {
            const dokType = selectedDok ? selectedDok.dokType : ''
            const id = selectedDok ? selectedDok.id : -1
            const newPases = pases.map(p => {
                let selected = false
                let selectedSaist = false
                if (dokType === 'pase' && p.id === id) {
                    selected = true
                } else if (dokType === 'licence') {
                    const lic = licences.find(l => l.id === id)
                    if (lic && lic.pase_id && lic.pase_id === p.id) {
                        selectedSaist = true
                    }
                } else if (dokType === 'limits') {
                    const lim = limiti.find(l => l.id === id)
                    if (lim && lim.pase_id && lim.pase_id === p.id) {
                        selectedSaist = true
                    }
                }
                if (selected !== p.selected || selectedSaist !== p.selectedSaist || p.selectedIeprNak === true) {
                    return { ...p, selected, selectedSaist, selectedIeprNak: false }
                }
                return p
            })
            const newLicences = licences.map(p => {
                let selected = false
                let selectedSaist = false
                if (dokType === 'licence' && p.id === id) {
                    selected = true
                } else if (dokType === 'pase' && p.pase_id === id) {
                    selectedSaist = true
                } else if (dokType === 'limits') {
                    const lim = limiti.find(l => l.id === id)
                    if (lim && lim.licence_id && lim.licence_id === p.id) {
                        selectedSaist = true
                    }
                }
                if (selected !== p.selected || selectedSaist !== p.selectedSaist || p.selectedIeprNak === true) {
                    return { ...p, selected, selectedSaist, selectedIeprNak: false }
                }
                return p
            })
            const newLimiti = limiti.map(p => {
                let selected = false
                let selectedSaist = false
                if (dokType === 'limits' && p.id === id) {
                    selected = true
                } else if (dokType === 'pase' && p.pase_id === id) {
                    selectedSaist = true
                } else if (dokType === 'licence' && p.licence_id === id) {
                    selectedSaist = true
                }
                if (selected !== p.selected || selectedSaist !== p.selectedSaist || p.selectedIeprNak === true) {
                    return { ...p, selected, selectedSaist, selectedIeprNak: false }
                }
                return p
            })
            newPases.forEach(p => fillIeprNak(newPases, p, true, true))
            newLicences.forEach(p => fillIeprNak(newLicences, p, true, true))
            newLimiti.forEach(p => fillIeprNak(newLimiti, p, true, true))
            return {
                ...state,
                selectedItem: {
                    ...selectedItem,
                    pases: newPases,
                    limiti: newLimiti,
                    licences: newLicences
                }
            }
        }
    }
    return state
}

const getAtradneDokSelectedReducers = (mapIdName) => ({ state, type, payload, mapId, layerId }) => {
    switch (type) {
        // clear doks when atradne clears
        case 'atradne_dokumentacija_dokselected':
            const selectedDok = payload.isSelect ? payload : null
            return changeSelectedDok(state, selectedDok)
        case 'MAP_clickedOutsideFeature':
            if (mapId === mapIdName) {
                return changeSelectedDok(state, null)
            } else {
                return state
            }
        case 'MAP_clickedFeature':
            if (mapId === mapIdName) {
                let dokType = null
                if (layerId === 'atradne_pase') {
                    dokType = 'pase'
                } else if (layerId === 'atradne_limits') {
                    dokType = 'limits'
                } else if (layerId === 'atradne_licence') {
                    dokType = 'licence'
                }
                if (dokType) {
                    return changeSelectedDok(state, { dokType, id: payload.id })
                }
            }
            return state
        default:
            return state
    }
}

const defaultEditReducerForDoks = helpers.createEditReducer({ editViewName: 'atradne_dokumentacija_view', actionsName: 'atradne_dokumentacija' })
const atradneDokSelectedReducers = getAtradneDokSelectedReducers('atradnes_kartina')
const izsniegtsSort = (a, b) => a.izsniegts_datums < b.izsniegts_datums ? -1 : a.izsniegts_datums > b.izsniegts_datums ? 1 : a.id < b.id ? -1 : 1
export const atradneDoks = (state = helpers.editGetInitialState(), { type, payload, mapId, layerId }) => {
    const newState = defaultEditReducerForDoks({ state, type, payload })
    switch (type) {
        // clear doks when atradne clears
        case 'atradne_clear':
            return helpers.editGetInitialState()
        case 'atradne_dokumentacija_view_get_success':
            //transform response to desired schema
            //add info from dalas
            const atradneDok = newState.selectedItem
            const pasesFromDalas = atradneDok.dalas.flatMap(dala => dala.pases.map(p => {
                p.dala = _.pick(dala, ['id', 'nosaukums', 'dalas_nosaukums'])
                return p
            }))
            const limitiFromDalas = atradneDok.dalas.flatMap(dala => dala.limiti.map(p => {
                p.dala = _.pick(dala, ['id', 'nosaukums', 'dalas_nosaukums'])
                return p
            }))
            const licencesFromDalas = atradneDok.dalas.flatMap(dala => dala.licences.map(p => {
                p.dala = _.pick(dala, ['id', 'nosaukums', 'dalas_nosaukums'])
                return p
            }))
            atradneDok.pases = [...atradneDok.pases, ...pasesFromDalas].sort(izsniegtsSort)
            atradneDok.limiti = [...atradneDok.limiti, ...limitiFromDalas].sort(izsniegtsSort)
            atradneDok.licences = [...atradneDok.licences, ...licencesFromDalas].sort(izsniegtsSort)
            return newState
        default:
            return atradneDokSelectedReducers({ state: newState, type, payload, mapId, layerId })
    }
}

const defaultEditReducerForDalaDoks = helpers.createEditReducer({
    editViewName: 'atradnes_dala_dokumentacija_view',
    actionsName: 'atradnes_dala_dokumentacija'
})
const atradnesDalaDokSelectedReducers = getAtradneDokSelectedReducers('atradnes_dala_kartina')
export const atradnesDalaDoks = (state = helpers.editGetInitialState(), { type, payload, mapId, layerId }) => {
    switch (type) {
        // clear doks when atradne clears
        case 'atradnes_dala_clear':
            return helpers.editGetInitialState()
        default:
            const newState = defaultEditReducerForDalaDoks({ state, type, payload })
            return atradnesDalaDokSelectedReducers({ state: newState, type, payload, mapId, layerId })
    }
}

const getAtradneKrajumiSelectedReducers = (mapIdName) => ({ state, type, payload, mapId, layerId }) => {
    switch (type) {
        case 'atradne_krajumi_krajums_selected':
            const selectedKrajums = payload.isSelect ? payload.id : null
            return {
                ...state,
                selectedKrajums,
            }
        case 'MAP_clickedOutsideFeature':
            if (mapId === mapIdName) {
                return {
                    ...state,
                    selectedKrajums: null,
                }
            } else {
                return state
            }
        case 'MAP_clickedFeature':
            if (mapId === mapIdName && layerId === 'atradne_krajums') {
                return {
                    ...state,
                    selectedKrajums: payload.id,
                }
            }
            return state
        default:
            return state
    }
}

const addRemoveSum = (summables, state, payload) => {
    if (state.selectedItem && state.selectedItem[summables]) {
        const newSummables = state.selectedItem[summables].map(k => {
            if (k.id === payload.id) {
                const kNew = { ...k }
                if (!kNew.sum) { kNew.sum = [] } else { kNew.sum = [...kNew.sum] }
                const sumInd = kNew.sum.indexOf(payload.col)
                if (sumInd < 0) {
                    kNew.sum.push(payload.col)
                } else {
                    kNew.sum.splice(sumInd, 1)
                }
                return kNew
            } else {
                return k
            }
        })
        return { ...state, selectedItem: { ...state.selectedItem, [summables]: newSummables } }
    }
    return state
}

const clearSum = (summables, state) => {
    if (state.selectedItem && state.selectedItem[summables]) {
        const newSummables = state.selectedItem[summables].map(k => ({ ...k, sum: [] }))
        return { ...state, selectedItem: { ...state.selectedItem, [summables]: newSummables } }
    }
    return state
}

const defaultEditReducerForKrajumi = helpers.createEditReducer({ editViewName: 'atradne_krajums_view', actionsName: 'atradne_krajumi' })
const atradneKrajumiSelectedReducers = getAtradneKrajumiSelectedReducers('atradnes_kartina')
export const atradneKrajumi = (state = helpers.editGetInitialState(), { type, payload, mapId, layerId }) => {
    const newState = defaultEditReducerForKrajumi({ state, type, payload })
    switch (type) {
        // clear doks when atradne clears
        case 'atradne_clear':
            return helpers.editGetInitialState()
        case 'atradne_krajumi_sum':
            return addRemoveSum('krajumi', newState, payload)
        case 'atradne_krajumi_sum_clear':
            return clearSum('krajumi', newState)
        default:
            return atradneKrajumiSelectedReducers({ state: newState, type, payload, mapId, layerId })
    }
}

const defaultEditReducerForDalaKrajumi = helpers.createEditReducer({ editViewName: 'atradnes_dala_krajums_view', actionsName: 'atradnes_dala_krajumi' })
const atradnesDalaKrajumiSelectedReducers = getAtradneKrajumiSelectedReducers('atradnes_dala_kartina')
export const atradnesDalaKrajumi = (state = helpers.editGetInitialState(), { type, payload, mapId, layerId }) => {
    const newState = defaultEditReducerForDalaKrajumi({ state, type, payload })
    switch (type) {
        // clear doks when atradne clears
        case 'atradnes_dala_clear':
            return helpers.editGetInitialState()
        case 'atradne_krajumi_sum':
            return addRemoveSum('krajumi', newState, payload)
        case 'atradne_krajumi_sum_clear':
            return clearSum('krajumi', newState)
        default:
            return atradnesDalaKrajumiSelectedReducers({ state: newState, type, payload, mapId, layerId })
    }
}

const defaultAtradneTopMenuReducer = helpers.createTopMenuSearchReducer({
    objectType: 'atradne',
    payloadLinkField: 'numurs'
})
export const atradneTopMenu = (state = helpers.topMenuSearchInitialState(), { type, payload }) => {
    return defaultAtradneTopMenuReducer({ state, type, payload })
}

//transform response to desired schema
const processIzmainas = (selectedItem, isGrouped) => {
    selectedItem.izmainas.forEach(izm => {
        const { limita_apjoms } = izm
        if (limita_apjoms) {
            izm._limita_apjoms_daudzums = limita_apjoms.daudzums
            izm._limita_apjoms_korigetais_pirms_izmainas = limita_apjoms.daudzums + izm.sum_prev_limita_apjoma_izmainu_daudzums
            izm._krajumi_uz_1_janv = izm.sum_prev_izmainu_daudzums + limita_apjoms.daudzums
            izm._krajumi_nak_gadaa = izm._krajumi_uz_1_janv + izm.daudzums
            izm._limita_apjoms_daudzuma_merv_nosaukums = limita_apjoms.daudzuma_merv_nosaukums
        }
        izm._isGrouped = isGrouped
    })
}

const defaultEditReducerForIzmainas = helpers.createEditReducer({ editViewName: 'atradne_izmaina_view', actionsName: 'atradne_izmaina' })
export const atradneIzmainas = (state = { ...helpers.editGetInitialState(), isGrouped: true }, { type, payload }) => {
    const newState = defaultEditReducerForIzmainas({ state, type, payload })
    switch (type) {
        case 'atradne_izmaina_clear':
        case 'atradne_clear':
            //don't use newState, as isGrouped is cleared in default edit reducer
            return { ...helpers.editGetInitialState(), isGrouped: state.isGrouped }
        case 'atradne_izmainas_sum':
            return addRemoveSum('izmainas', newState, payload)
        case 'atradne_izmainas_sum_clear':
            return clearSum('izmainas', newState)
        case 'atradne_izmainas_grouped':
            return { ...newState, isGrouped: !newState.isGrouped }
        case 'atradne_izmaina_view_get_success':
        case 'atradne_izmaina_view_save_success':
            processIzmainas(newState.selectedItem, newState.isGrouped)
            return { ...newState, loadingGrouped: false }
        case 'atradne_izmaina_view_grouped_get_request':
        case 'atradne_izmaina_view_get_request':
            return {
                ...newState,
                loadingGrouped: true,
                editable: false
            }
        case 'atradne_izmaina_view_grouped_get_success':
            processIzmainas(payload, newState.isGrouped)
            return {
                ...newState,
                selectedItem: payload,
                loadingGrouped: false
            }
        case 'atradne_izmaina_view_grouped_get_failure':
            return {
                ...newState,
                selectedItem: null
            }
        default:
            return newState
    }
}

const defaultEditReducerForDalaIzmainas = helpers.createEditReducer({ editViewName: 'atradnes_dala_izmaina_view', actionsName: 'atradnes_dala_izmaina' })
export const atradnesDalaIzmainas = (state = { ...helpers.editGetInitialState(), isGrouped: true }, { type, payload }) => {
    const newState = defaultEditReducerForDalaIzmainas({ state, type, payload })
    switch (type) {
        case 'atradnes_dala_clear':
            //don't use newState, as isGrouped is cleared in default edit reducer
            return { ...helpers.editGetInitialState(), isGrouped: state.isGrouped }
        case 'atradne_izmainas_sum':
            return addRemoveSum('izmainas', newState, payload)
        case 'atradne_izmainas_sum_clear':
            return clearSum('izmainas', newState)
        case 'atradne_izmainas_grouped':
            return { ...newState, isGrouped: !newState.isGrouped }

        case 'atradnes_dala_izmaina_view_get_success':
        case 'atradnes_dala_izmaina_view_save_success':
            processIzmainas(newState.selectedItem, newState.isGrouped)
            return { ...newState, loadingGrouped: false }
        case 'atradnes_dala_izmaina_view_grouped_get_request':
        case 'atradnes_dala_izmaina_view_get_request':
            return {
                ...newState,
                loadingGrouped: true,
                editable: false
            }
        case 'atradnes_dala_izmaina_view_grouped_get_success':
            processIzmainas(payload, newState.isGrouped)
            return {
                ...newState,
                selectedItem: payload,
                loadingGrouped: false
            }
        case 'atradnes_dala_izmaina_view_grouped_get_failure':
            return {
                ...newState,
                selectedItem: null
            }
        default:
            return newState
    }
}