import React, {Component} from 'react';
import {connect} from 'react-redux'

import Form from '../forms/Form'
import {
    getDefaultTypeaheadOptionsFromKodifArray,
    getKlasifikatorsFieldCfg
} from '../forms/FormHelpers'
import {getFormatterForKodif} from '../grid/ColumnHelpers'
import {pazemesUdensObjektiSlodzesActions} from '../../actions/pazemesUdensObjektiActions'
import Loading from "../Loading";

const getFormFieldCfgs = ({ metadata, kodif }) => {
    return [
        {
            name: 'gads_sagatavots',
            renderClassName: "bold",
        },
        {
            type: 'autocomplete',
            autocompleteCfg: {
                options: getDefaultTypeaheadOptionsFromKodifArray(kodif, 'UO_PERIODS')
            },
            name: 'periods',
            editable: false
        },
        {
            ...getKlasifikatorsFieldCfg(kodif, 'pazemesUdensObjektaSlodze'),
            renderer: getFormatterForKodif(kodif.pazemesUdensObjektaSlodze),
            name: 'punktveida_slodze'
        },
        {
            ...getKlasifikatorsFieldCfg(kodif, 'pazemesUdensObjektaSlodze'),
            renderer: getFormatterForKodif(kodif.pazemesUdensObjektaSlodze),
            name: 'izkliedeta_slodze'
        },
        {
            ...getKlasifikatorsFieldCfg(kodif, 'pazemesUdensObjektaSlodze'),
            renderer: getFormatterForKodif(kodif.pazemesUdensObjektaSlodze),
            name: 'udens_ieguves_slodze'
        },
        {
            name: 'citas_slodzes',
            type: 'text'
        },
        {
            name: 'piezimes',
            type: 'textarea'
        }
    ]}

class SlodzesComp extends Component {

    render() {
        const {pazemesUdensObjektsSlodzes, kodif, metadata} = this.props

        const { selectedItem, editable, submiting } = pazemesUdensObjektsSlodzes

        // loading while we have no udensObjekts
        if (!selectedItem) {
            return (<Loading/>)
        }

        const periodsValues = getDefaultTypeaheadOptionsFromKodifArray(kodif, 'UO_PERIODS').map(value => value.tah_id)
        const currentPeriodsIndex = periodsValues.indexOf(selectedItem.gads)

        const onNextPrevYear = (periods) => {
            this.props.fetchAct({ ...selectedItem, periods})
        }

        const nextYear = () => {
            return periodsValues[currentPeriodsIndex + 1]
        }

        const prevYear = () => {
            return periodsValues[currentPeriodsIndex - 1]
        }

        // we cache form field cfgs
        // reload form fields if udensObjekts changes
        if(!this.formFieldCfgs || pazemesUdensObjektsSlodzes.id !== this.prevUoId){
            this.formFieldCfgs = getFormFieldCfgs({
                metadata,
                kodif
            })
            this.prevUoId = selectedItem.id
        }

        return <div className="form-container">
            <div className="atradne-title uo-gads d-flex flex-row-reverse">
                <h4 className="num-periods">
                    <i className="fa fa-caret-left" title={prevYear()} aria-hidden="true" onClick={() => onNextPrevYear(prevYear())}></i>
                    <span>{ selectedItem.gads }</span>
                    <i className="fa fa-caret-right" title={nextYear()} aria-hidden="true" onClick={() => onNextPrevYear(nextYear())}></i>
                </h4>
            </div>
            <Form
                editable={editable}
                submiting={submiting}
                dataObj={selectedItem}
                formFieldCfgs={this.formFieldCfgs}
                onSubmit={this.props.saveAct}
                onReset={this.props.stopEditAct}
                onStartEdit={this.props.startEditAct}
                metadataFormCfg="pazemes_udens_objekts_slodzes_view"
                tiesibasLabot="BAIS_PU_LABOT"
            />
        </div>
    }
}

const mapStateToProps = ({pazemesUdensObjektsSlodzes, kodif, metadata, user}) => ({pazemesUdensObjektsSlodzes, kodif, metadata, user})
const PazemesSlodzes =  connect(mapStateToProps, { ...pazemesUdensObjektiSlodzesActions })(SlodzesComp)
export default PazemesSlodzes
