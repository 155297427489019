import React, {Component} from 'react';
import {connect} from 'react-redux'

import Form from '../forms/Form'
import {getKlasifikatorsFieldCfg} from '../forms/FormHelpers'
import {getFormatterForKodif, getNumberFormatter} from '../grid/ColumnHelpers'
import {pazemesUdensObjektiKvantitateActions} from '../../actions/pazemesUdensObjektiActions'
import FormCard from "../forms/FormCard";

class KvantitativaisStavoklisComp extends Component {

    getFormFieldWrapperCfgs = () => {
        return [
            {
                id: 'udens_resursi_wrap',
                comp: FormCard,
                compProps: {
                    title: 'Pazemes ūdeņu resursi',
                    pinnable: true,
                    size: 12
                },
            },
            {
                id: 'testi_wrap',
                comp: FormCard,
                compProps: {
                    title: 'Stāvokļa novērtēšanas testi',
                    pinnable: true,
                    size: 12
                },
            },
            {
                id: 'sledziens_wrap',
                comp: FormCard,
                compProps: {
                    title: 'Gala slēdziens',
                    pinnable: true,
                    size: 12
                },
            }
        ]
    }

    getFormFieldCfgs = ({kodif, user}) => {
        return [
            {
                name: 'udenu_ieguve',
                fieldWrapper: 'udens_resursi_wrap',
                label: <> Pazemes ūdeņu ieguve m<sup>3</sup>/d </>,
                renderer: getNumberFormatter()
            },
            {
                name: 'udenu_krajumi',
                fieldWrapper: 'udens_resursi_wrap',
                label: <> Pazemes ūdeņu krājumi m<sup>3</sup>/d </>,
                renderer: getNumberFormatter()
            },
            {
                name: 'papildinasanas_apjoms_info',
                fieldWrapper: 'udens_resursi_wrap',
                type: 'textarea'
            },
            {
                ...getKlasifikatorsFieldCfg(kodif, 'pazemesUdensObjektaTesti'),
                renderer: getFormatterForKodif(kodif.pazemesUdensObjektaTesti),
                name: 'udenu_bilance',
                fieldWrapper: 'testi_wrap',
            },
            {
                ...getKlasifikatorsFieldCfg(kodif, 'pazemesUdensObjektaTesti'),
                renderer: getFormatterForKodif(kodif.pazemesUdensObjektaTesti),
                name: 'kvant_juras_udenu_intruzija',
                fieldWrapper: 'testi_wrap',
            },
            {
                ...getKlasifikatorsFieldCfg(kodif, 'pazemesUdensObjektaTesti'),
                renderer: getFormatterForKodif(kodif.pazemesUdensObjektaTesti),
                name: 'sauszemes_ekosistemas',
                fieldWrapper: 'testi_wrap',
            },
            {
                ...getKlasifikatorsFieldCfg(kodif, 'pazemesUdensObjektaTesti'),
                renderer: getFormatterForKodif(kodif.pazemesUdensObjektaTesti),
                name: 'saldudenu_ekosistemas',
                fieldWrapper: 'testi_wrap',
            },
            {
                ...getKlasifikatorsFieldCfg(kodif, 'pazemesUdensObjektaNovertejums'),
                renderer: getFormatterForKodif(kodif.pazemesUdensObjektaNovertejums),
                name: 'kvant_stavoklis',
                fieldWrapper: 'sledziens_wrap'
            },
            {
                name: 'kvantitivais_stavoklis_piezimes',
                fieldWrapper: 'sledziens_wrap',
                type: 'textarea'
            }
        ]}

    render() {
        const {selectedItem:pazemesUdensObjektsKvantitate, editable, submiting} = this.props.pazemesUdensObjektsKvantitate

        // initial cfgs can come in as props, or we can create default ones
        const {user, getFormFieldCfgsFunc, getFormFieldWrapperCfgsFunc, kodif, metadataFormCfg} = this.props

        // we cache form field cfgs
        if(!this.formFieldCfgs){
            const f = getFormFieldCfgsFunc ? getFormFieldCfgsFunc : this.getFormFieldCfgs
            this.formFieldCfgs = f({
                kodif,
                user
            })
        }

        if(!this.formFieldWrapperCfgs){
            const f = getFormFieldWrapperCfgsFunc ? getFormFieldWrapperCfgsFunc : this.getFormFieldWrapperCfgs
            this.formFieldWrapperCfgs = f()
        }

        return <div className="form-container">
                    <Form
                        editable={editable}
                        submiting={submiting}
                        dataObj={pazemesUdensObjektsKvantitate}
                        formFieldCfgs={this.formFieldCfgs}
                        formFieldWrapperCfgs={this.formFieldWrapperCfgs}
                        metadataFormCfg={metadataFormCfg}
                        onSubmit={this.props.save}
                        onReset={this.props.stopEdit}
                        onStartEdit={this.props.startEdit}
                        onDelete={user.tiesibas.includes('BAIS_DZEST') ? this.props.delete : null}
                        tiesibasLabot="BAIS_PU_LABOT"
                    />
            </div>
    }
}

const mapStateToProps = ({pazemesUdensObjektsKvantitate, kodif, user}) => ({pazemesUdensObjektsKvantitate, kodif, user, metadataFormCfg: "pazemes_udens_objekts_kvantitate_view"})
const PazemesKvantitativaisStavoklis =  connect(mapStateToProps, {
  startEdit:pazemesUdensObjektiKvantitateActions.startEditAct,
  stopEdit:pazemesUdensObjektiKvantitateActions.stopEditAct,
  delete:pazemesUdensObjektiKvantitateActions.deleteAct,
  save:pazemesUdensObjektiKvantitateActions.saveAct
})(KvantitativaisStavoklisComp)
export default PazemesKvantitativaisStavoklis
