import React from 'react'
import { connect } from 'react-redux'

import {guiSet} from '../../actions/uiActions'
import Grid from '../grid/Grid'
import SplitterLayout from 'react-splitter-layout';

import { Button } from 'reactstrap'

import {
  wiseParametrsSelected,
  wiseParametrsActions
} from '../../actions/wiseActions'

import {
  // getSelectFilterCfg,
  getTextFilterCfg,
  // getDateFilterCfg,
  getNumberFilterCfg,
  getBooleanFormatter,
  getBooleanSelectFilterCfg,
  // dateTimeFormatter,
  // getBadgeFormatterForKodif,
} from '../grid/ColumnHelpers'

import Form from '../forms/Form'

const getColumns = ({kodif}) => {
  return [
    {
      dataField: 'atskaite',
      text: 'Atskaite',
      width: 90,
      filterCfg: getTextFilterCfg()
    }, {
      dataField: 'starpt_kods',
      text: 'Starpt. kods',
      width: 120,
      filterCfg: getTextFilterCfg()
    }, {
      dataField: 'nosaukums_vvis',
      text: 'VVIS nosaukums',
      filterCfg: getTextFilterCfg(),
      width: 200,
    }, {
      dataField: 'parrekina_koef',
      text: 'Pārrēķina koef.',
      filterCfg: getNumberFilterCfg(),
      width: 50,
    }, {
      dataField: 'mervieniba',
      text: 'Mērvienība',
      filterCfg: getTextFilterCfg(),
    }, {
      dataField: 'vertiba_min',
      text: 'Min. vērtība',
      filterCfg: getNumberFilterCfg(),
    }, {
      dataField: 'vertiba_max',
      text: 'Max.. vērtība',
      filterCfg: getNumberFilterCfg(),
    }, {
      dataField: 'wdis_kods',
      text: 'W/W-DIS',
      filterCfg: getTextFilterCfg(),
    }, {
      dataField: 'ir_aktivs',
      text: 'Aktīvs?',
      formatter: getBooleanFormatter(),
      filterCfg: getBooleanSelectFilterCfg(),
    }
  ]
}


const  getFormFieldCfgs = ({kodif}) => {
  return [
    {
      name: 'atskaite',
      label: 'Atskaite',
      editable: false
    },
    {
      name: 'starpt_kods',
      label: 'Starpt. kods',
    },
    {
      name: 'nosaukums_vvis',
      label: 'VVIS nosaukums',
    },
    {
      name: 'parrekina_koef',
      label: 'Pārrēķina koef.',
      type: 'number',
    },
    {
      name: 'mervieniba',
      label: 'Mērvienība',
    },
    {
      name: 'vertiba_min',
      label: 'Min. ziņojamā vērtība',
      type: 'number',
    },
    {
      name: 'vertiba_max',
      label: 'Max. ziņojamā vērtība',
      type: 'number',
    }, {
      name: 'wdis_kods',
      label: 'W/W-DIS',
    }, {
      name: 'ir_aktivs',
      label: 'Ir aktīvs?',
      type: 'checkbox',
    }
  ]
}


class WiseVieluParametri extends React.PureComponent {

  formSizeChange = (w) => {
    this.props.guiSet('wise_parametri_form_width', w);
  }


  rowEvents = {
    onDoubleClick: (e, row, rowIndex) => {
      this.props.wiseParametrsEdit()
    }
  }

  extraGridProps = {
    rowClasses: (row, rowIndex) => {
      if(row && row.ir_noblokets === true){
        return 'row-warning';
      }
    }
  }

  render() {

    if ( ! this.columns) {
      this.columns = getColumns({
        kodif: this.props.kodif
      })
    }

    if ( ! this.formFieldCfgs) {
      this.formFieldCfgs = getFormFieldCfgs({
        kodif: this.props.kodif
      })
    }

    const {editable, submiting, selectedItem, loading, list, count} = this.props.wise
    return (
      <SplitterLayout
        customClassName="pane-paddings-hor"
        percentage
        primaryIndex={0}
        secondaryInitialSize={this.props.gui.wise_parametri_form_width}
        onSecondaryPaneSizeChange={this.formSizeChange}>

        <Grid
          onRowSelected={this.props.wiseParametrsSelected}
          loading={loading}
          dataList={list}
          totalCount={count}
          columns={this.columns}
          rowEvents={this.rowEvents}
          extraGridProps={this.extraGridProps}
        />

        <div className="form-wrapper-pane">
          { selectedItem && (
            <>
              <Form
                editable={editable}
                submiting={submiting}
                dataObj={selectedItem}
                formFieldCfgs={this.formFieldCfgs}
                onSubmit={this.props.wiseParametrsSave}
                onDelete={this.props.wiseParametrsDelete}
                onReset={this.props.wiseParametrsStopEdit}
                onStartEdit={this.props.wiseParametrsEdit}
                tiesibasLabot="WISE_LABOT"
              />
            </>
          )}

          { (this.props.user.tiesibas.includes('URBUMI') && ! editable) && (
            <>
              <div className="actionButtons">
                <Button onClick={this.props.wiseParametrsCreate} color="primary"><i className="fa fa-plus-circle"></i>&nbsp;&nbsp;Jauns parametrs</Button>
              </div>
            </>
          )}
        </div>

      </SplitterLayout>
    )
  }

}

const mapState = ({wise, kodif, gui, user, location}) => ({wise, kodif, gui, user, location})

export default connect(mapState, {
  guiSet,
  wiseParametrsSelected,
  wiseParametrsEdit: wiseParametrsActions.startEditAct,
  wiseParametrsStopEdit: wiseParametrsActions.stopEditAct,
  wiseParametrsSave: wiseParametrsActions.saveAct,
  wiseParametrsDelete: wiseParametrsActions.deleteAct,
  wiseParametrsCreate: wiseParametrsActions.createAct,
})(WiseVieluParametri)
