import React, {Component} from 'react';
import {connect} from 'react-redux'
import {uzzinaActions} from '../../actions/uzzinasActions'
import Form from '../forms/Form'
import {
  getDefaultTypeaheadOptionsFromKodif,
  getDefaultTypeaheadOptionsFromKodifArray,
  getDefaultRendererForMultiField
} from '../forms/FormHelpers'
import {getAradnesLinksRenderer, PielikumiUnSaites} from '../dokumentacija/common'
import {getBadgeFormatterForKodif} from '../grid/ColumnHelpers'
import {getYear} from 'date-fns'
import _ from 'lodash';
import Link from 'redux-first-router-link';

const getFormFieldCfgs = ({kodif, user}) => {
    const fields = [
      {
        name: 'dok_veids',
        label: 'Dokumenta veids',
        type: 'autocomplete',
        required: true,
        autocompleteCfg: {
          options: getDefaultTypeaheadOptionsFromKodif(kodif.UZZINU_DOK)
        },
        renderer: getBadgeFormatterForKodif(kodif.UZZINU_DOK),
      },
      {
        name: 'geofond_parskats',
        label: 'Pārskats',
        type: 'autocomplete',
        autocompleteCfg: {
          remoteSearchViewName: 'parskats_search',
          isValueObjStructure: true
        },
        //renderer: getDefaultRendererForMultiField('tah_label'),
         renderer: (fieldValue) => (
           fieldValue ? <Link to={{ type: 'loc_parskats', payload: { id: fieldValue.tah_id}}}>{fieldValue.tah_label} </Link> : ''
         ),
        overridePropsFunc: (props) => {
          const changedProps = {...props}
          const {editable, currentValues} = props
          const sameFieldTypes = ['pārskats', 'ieguves projekts']
          // ja dok veids nav protokols tad disable un izdzēšam vērtību
          if(editable && currentValues.dok_veids && !_.includes(sameFieldTypes, currentValues.dok_veids)){
            currentValues.geofond_parskats = null
            changedProps.currentVal = currentValues.geofond_parskats
            changedProps.extraFieldProps = { disabled: true }
          }else{
            changedProps.extraFieldProps = { disabled: false }
          }
          return changedProps
        }
      },
      {
        name: 'vgf_numurs',
        label: 'Numurs.',
        type: 'text',
        overridePropsFunc: (props) => {
          const changedProps = {...props}
          const {editable, currentValues} = props
          const sameFieldTypes = ['pārskats', 'ieguves projekts']
          // ja dok veids ir pārskats tad disable
          if(editable && currentValues.dok_veids && _.includes(sameFieldTypes, currentValues.dok_veids)){
            if (currentValues.geofond_parskats)
              currentValues.vgf_numurs = currentValues.geofond_parskats.inv_numurs;
            else
              currentValues.vgf_numurs = '';
            changedProps.currentVal = currentValues.vgf_numurs
            changedProps.extraFieldProps = { disabled: true }
          }else{
            changedProps.extraFieldProps = { disabled: false }
          }
          return changedProps
        }
      },
      {
        name: 'veicejs',
        label: 'Krājumu apstiprinātājs',
        type: 'autocomplete',
        autocompleteCfg: {
          options: getDefaultTypeaheadOptionsFromKodif(kodif.IZP_VEICEJI)
        },
        overridePropsFunc: (props) => {
          const changedProps = {...props}
          const {editable, currentValues} = props
          const sameFieldTypes = ['pārskats', 'ieguves projekts']
          // ja dok veids ir pārskats tad disable un paņem organizācijas no pārskata
          if(_.includes(sameFieldTypes, currentValues.dok_veids)) {
            changedProps.dataObj.veicejs = null;
          }
          if(editable && currentValues.dok_veids && _.includes(sameFieldTypes, currentValues.dok_veids)){
            changedProps.currentVal = null;
            currentValues.veicejs = null;
            changedProps.extraFieldProps = { disabled: true}
          }else{
            changedProps.extraFieldProps = { disabled: false}
          }
          return changedProps
        },
      },
      {
        name: 'organizacijas',
        label: 'Pārskata organizācijas',
        type: 'text',
        overridePropsFunc: (props) => {
          const changedProps = {...props}
          const {editable, currentValues} = props
          const sameFieldTypes = ['pārskats', 'ieguves projekts']
          if (editable){
            if (currentValues.dok_veids && _.includes(sameFieldTypes, currentValues.dok_veids)) {
              if (currentValues.geofond_parskats && currentValues.geofond_parskats.organizacija) {
                currentValues.organizacijas = currentValues.geofond_parskats.organizacija;
              } else {
                currentValues.organizacijas = '';
              }
            } else {
              currentValues.organizacijas = '';
            }
            changedProps.currentVal = currentValues.organizacijas;
            changedProps.extraFieldProps = {disabled: true}
          } else {
            changedProps.extraFieldProps = {disabled: true}
          }
          return changedProps;
        }
      },
      {
        name: 'nosaukums',
        label: 'Nosaukums',
        type: 'text',
        overridePropsFunc: (props) => {
          const changedProps = {...props}
          const {editable, currentValues} = props
          const sameFieldTypes = ['pārskats', 'ieguves projekts']
          if(editable && currentValues.dok_veids) {
            if (_.includes(sameFieldTypes, currentValues.dok_veids)){
              if (currentValues.geofond_parskats)
                currentValues.nosaukums = currentValues.geofond_parskats.virsraksts
              else
                currentValues.nosaukums = ''
              changedProps.currentVal = currentValues.nosaukums
              changedProps.extraFieldProps = { disabled: true}
            } else if (currentValues.nosaukums == null){
                currentValues.nosaukums = kodif.UZZINU_DOK[currentValues.dok_veids].saist_teksts
                changedProps.currentVal = currentValues.nosaukums
            }
          }
          return changedProps
        }
      },
      {
        name: 'autori',
        label: 'Autors',
        type: 'autocomplete',
        autocompleteCfg: {
          options: getDefaultTypeaheadOptionsFromKodifArray(kodif, 'AUTORS'),
          multiple: true,
          keyField: 'autors',
        },
        renderer: getDefaultRendererForMultiField('autors'),
        hiddenFunc: ({currentValues}) => _.includes(['pārskats', 'ieguves projekts'], currentValues.dok_veids),
        overridePropsFunc: (props) => {
          const changedProps = {...props}
          const {editable, currentValues} = props
          const sameFieldTypes = ['pārskats', 'ieguves projekts']
          // ja dok veids ir protokols tad disable un izdzēšam vērtību
          if(editable && currentValues.dok_veids){
            if (kodif.UZZINU_DOK[currentValues.dok_veids].ir_protokols === true || _.includes(sameFieldTypes, currentValues.dok_veids)) {
              if(currentValues.autori && currentValues.autori.length > 0){
                currentValues.autori = []
                changedProps.currentVal = currentValues.autori
              }
              changedProps.extraFieldProps = { disabled: true }
            }
          }else{
            changedProps.extraFieldProps = { disabled: false }
          }
          return changedProps
        }
      },
      {
        name: 'parskata_autori',
        label: 'Pārskata autori',
        type: 'text',
        overridePropsFunc: (props) => {
          const changedProps = {...props}
          const {editable, currentValues} = props
          const sameFieldTypes = ['pārskats', 'ieguves projekts']
          if (editable){
            if (currentValues.dok_veids && !_.includes(sameFieldTypes, currentValues.dok_veids)) {
              currentValues.parskata_autori = '';
            } else {
              if (currentValues.geofond_parskats && currentValues.geofond_parskats.autors) {
                currentValues.parskata_autori = currentValues.geofond_parskats.autors
              } else {
                currentValues.parskata_autori = '';
              }
            }
            changedProps.currentVal = currentValues.parskata_autori;
            changedProps.extraFieldProps = {disabled: true}
          } else {
            changedProps.extraFieldProps = {disabled: true}
          }
          return changedProps;
        }
      },
      {
        name: 'gads',
        label: 'Gads',
        type: 'number',
        validation: (values) => {
          if(values.gads != null && values.gads !== ''){
            if(values.gads < 1900){
              return 'Ievadītais gads ir pārāk mazs!';
            }
            if(values.gads > getYear(new Date())){
              return 'Ievadītais gads ir lielāks par esošo!';
            }
          }
          return null
        },
        overridePropsFunc: (props) => {
          const changedProps = {...props}
          const {editable, currentValues} = props
          const sameFieldTypes = ['pārskats', 'ieguves projekts']
          if(editable && currentValues.dok_veids) {
            if (_.includes(sameFieldTypes, currentValues.dok_veids)){
              if (currentValues.geofond_parskats) {
                currentValues.gads = currentValues.geofond_parskats.gads;
                changedProps.currentVal = currentValues.gads;
              } else {
                currentValues.gads = null
              }
              changedProps.extraFieldProps = { disabled: true}
            } else {
              // changedProps.currentVal = null;
              changedProps.extraFieldProps = { disabled: false}
            }
          }
          return changedProps
        }
      },
      {
        name: 'protokola_nr',
        label: 'Protokola Nr.',
        type: 'text',
        overridePropsFunc: (props) => {
          const changedProps = {...props}
          const {editable, currentValues} = props
          // ja dok veids nav protokols tad disable un izdzēšam vērtību
          if(editable && currentValues.dok_veids && kodif.UZZINU_DOK[currentValues.dok_veids].ir_protokols!== true){
            if(currentValues.protokola_nr){
              currentValues.protokola_nr = ''
              changedProps.currentVal = currentValues.protokola_nr
            }
            changedProps.extraFieldProps = { disabled: true }
          }else{
            changedProps.extraFieldProps = { disabled: false }
          }
          return changedProps
        }
      },
      {
        name: 'protokola_datums',
        label: 'Protokola datums',
        type: 'date',
        overridePropsFunc: (props) => {
          const changedProps = {...props}
          const {editable, currentValues} = props
          // ja dok veids nav protokols tad disable un izdzēšam vērtību
          if(editable && currentValues.dok_veids && kodif.UZZINU_DOK[currentValues.dok_veids].ir_protokols!== true){
            if(currentValues.protokola_datums){
              currentValues.protokola_datums = null
              changedProps.currentVal = currentValues.protokola_datums
            }
            changedProps.extraFieldProps = { disabled: true }
          }else{
            changedProps.extraFieldProps = { disabled: false }
          }
          return changedProps
        }
      },
      {
        name: 'atradnes',
        label: 'Atradnes',
        type: 'autocomplete',
        autocompleteCfg: {
            remoteSearchViewName: 'atradne_search',
            multiple: true,
            keyField: 'numurs',
        },
        renderer: getAradnesLinksRenderer(kodif, 'numurs', null, 'inline-links')
      }
  ]
  if(user.tiesibas.includes('SKATIT_PIELIKUMUS_ZEMES_DZILES')){
    fields.push({
      name: 'faili',
      label: 'Pielikumi',
      type: 'files'
    })
  }
  fields.push({
    name: 'pielikumi_parskats_filename',
    label: 'Pielikumi un saites pie pārskata',
    type: 'text',
    editable: false,
    hiddenFunc: ({currentValues}) => !_.includes(['pārskats', 'ieguves projekts'], currentValues.dok_veids),
    renderer: (filename, dataObj) => <PielikumiUnSaites
      fileId={dataObj.pielikumi_parskats_file_id}
      fileSha256={dataObj.pielikumi_parskats_file_sha256}
      fileName={filename}
      saiteId={dataObj.saites_saite_ids}
      saiteName={dataObj.saites_nosaukumi}
      saiteUrl={dataObj.saites_saites}
    />
  })
  return fields
}

class Uzzina extends Component {

    render() {
      const {uzzina, kodif, saveAct, stopEditAct, startEditAct, deleteAct, user} = this.props

      if(!this.formFieldCfgs){
        this.formFieldCfgs = getFormFieldCfgs({
            kodif, user
        })
    }

    const {editable, submiting, selectedItem} = uzzina

    return (
        <div className="form-wrapper-pane">
            <Form
                editable={editable}
                submiting={submiting}
                dataObj={selectedItem}
                formFieldCfgs={this.formFieldCfgs}
                onSubmit={saveAct}
                onReset={stopEditAct}
                onStartEdit={startEditAct}
                onDelete={deleteAct}
                tiesibasLabot="LABOT_ATRADNES"
            />
        </div>
    )
  }
}

const mapStateToProps = ({uzzina, kodif, user}) => ({uzzina, kodif, user})

export default connect(mapStateToProps, {...uzzinaActions})(Uzzina)
