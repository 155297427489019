import React, {Component} from 'react';
import {connect} from 'react-redux'
import {
  dateFormatter,
  getNumberFilterCfg,
  getNumberFormatter,
  getTextFilterCfg,
  getDateFilterCfg, getEditIconColumn
} from "../grid/ColumnHelpers";
import Grid from "../grid/Grid";
import SplitterLayout from 'react-splitter-layout';

const editIconColumn = getEditIconColumn((row) => (
        {
          type: 'loc_urbuma_merijums',
          payload: {
            id: row.id,
            numurs: row.urbumi_id
          }
        }
    )
)

const getColumns = ({ metadata }) => {
  return [editIconColumn,
    {
      dataField: 'merijuma_datums',
      text: "Mērijuma datums",
      formatter: dateFormatter,
      filterCfg: getDateFilterCfg(),
      width: 80
    },
    {
      dataField: 'nosaukums',
      text: "Nosaukums",
      filterCfg: getTextFilterCfg(),
      width: 80
    },
    {
      dataField: 'vertiba',
      text: "Vērtība",
      formatter: getNumberFormatter(),
      filterCfg: getNumberFilterCfg(),
      width: 80
    },
    {
      dataField: 'mervieniba',
      text: "Mērvienība",
      width: 120,
      filterCfg: getTextFilterCfg()
    },
    {
      dataField: 'karodzini',
      text: "Uzstādītie karodziņi",
      filterCfg: getTextFilterCfg(),
      width: 80
    }
  ]
}

class UrbumsIeguvesUrbumaLimeni extends Component {

  render() {

    const { metadata, ieguves_limeni} = this.props

    const { selected, list, loading, count} = ieguves_limeni

    if(!this.columns){
      this.columns = getColumns({
        metadata
      })
    }

    return (
        <SplitterLayout
            vertical
            percentage
            primaryIndex={1}
            secondaryInitialSize={10}
        >
          <Grid
              selectedId={selected}
              loading={loading}
              dataList={list}
              totalCount={count}
              columns={this.columns}
          >
          </Grid>
        </SplitterLayout>
    )
  }
}

const mapStateToProps = ({ieguves_limeni, user}) => ({ieguves_limeni, user})
export default connect(mapStateToProps)(UrbumsIeguvesUrbumaLimeni)
