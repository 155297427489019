import React, { Component, createRef } from 'react'
import { connect } from 'react-redux'
import Map from "./Map"
import Accordion from './Accordion'
import LayerSwitcher from './ol-layerswitcher'
import MapSearchField from './MapSearchField'
import {
    changeMapBBox, clickedFeature, doubleClickedFeature, clickedOutsideFeature, modifiedFeature,
    tileLayerLoadingError
} from '../../actions/karteActions'
import { guiSet } from '../../actions/uiActions'
import TileLayer from 'ol/layer/Tile.js'
import OSM from 'ol/source/OSM.js'
import XYZ from 'ol/source/XYZ.js'
import * as maputils from './maputils'
import { round } from '../../utils'
import _ from 'lodash'
import FeaturesPopup from './FeaturesPopup'
import { saveLietotajsUzstadijumi } from '../../actions/lietotajsActions'
import { getCurrentSupApp } from '../../actions/helpers'
import { defaultVisibleLayersForSubApp, defaultVisibleLayerGroupsForSubApp } from '../../routes'
import { getFormatterForKodif, getBadgeFormatterForKodif, booleanRenderer } from '../grid/ColumnHelpers'
import { linkRenderer } from '../dokumentacija/common'

const currentYear = new Date().getFullYear()

// tiled background layers
const getTileLayers = () => [
    maputils.createLVMLayer('Ortofoto LVM', 'public:Orto_LKS', 'Ortofotokarte infrasarkanā spektrā © Latvijas Ģeotelpiskās informācijas aģentūra' +
        ', 2013-2016. Autortiesības aizsargātas', 'lvm_orto'),
    maputils.createLVMLayer('Reljefs LVM', 'public:ZemeLKS', 'Digitālā zemes virsmas modeļa pamatdati © Latvijas Ģeotelpiskās informācijas aģe' +
        'ntūra, 2016', 'lvm_zeme'),
    maputils.createLVMLayer('Veģetācijas augstums LVM', 'public:CanopySurfaceHeightLKS', 'Datu sagatavošanā izmantots: Digitālā zemes virsmas modeļa pamatdati © Latvijas ' +
        'Ģeotelpiskās informācijas aģentūra, 2016', 'lvm_veg'),
    maputils.createLVMLayer('Orto infrasarkanais LVM', 'public:OrtoIR_LKS', 'Ortofotokarte infrasarkanā spektrā © Latvijas Ģeotelpiskās informācijas aģentūra' +
        ', 2013-2016. Autortiesības aizsargātas', 'lvm_infra'),
    maputils.createLVMLayer('Zemes slīpums LVM', 'public:SlopeLKS', 'Datu sagatavošanā izmantots: Digitālā zemes virsmas modeļa pamatdati © Latvijas ' +
        'Ģeotelpiskās informācijas aģentūra, 2016', 'lvm_zeme_slipums'),
    new TileLayer({
        title: 'OpenStreetMap oriģinālā',
        id: 'osm_original',
        type: 'base',
        visible: false,
        source: new OSM(),
        backupLayerOnError: true
    }),
    new TileLayer({
        title: 'OpenStreetMap gaišā',
        id: 'osm_light',
        source: new XYZ({
            crossOrigin: 'anonymous',
            //url: 'https://{a-c}.basemaps.cartocdn.com/rastertiles/light_all/{z}/{x}/{y}.png',
            tileUrlFunction: (t) => `https://${String.fromCharCode(97 + Math.floor(Math.random() * 4))}.basemaps.cartocdn.com/rastertiles/light_all/${t[0]}/${t[1]}/${t[2]}.png`,
            attributions: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy' +
                '; <a href="https://carto.com/attribution">CARTO</a>'
        }),
        visible: false,
        type: 'base'
    }),
    new TileLayer({
        title: 'OpenStreetMap',
        id: 'osm',
        source: new XYZ({
            crossOrigin: 'anonymous',
            tileUrlFunction: (t) => `https://${String.fromCharCode(97 + Math.floor(Math.random() * 4))}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/${t[0]}/${t[1]}/${t[2]}.png`,
            attributions: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy' +
                '; <a href="https://carto.com/attribution">CARTO</a>'
        }),
        visible: false,
        type: 'base'
    })
]

const kadastrsUrl = `${window.location.origin}/services/remote/kadastrs`

const kadastrsLayerCfg = {
    type: 'bbox',
    format: 'json',
    epsgCode: 3059,
    bboxUrl: kadastrsUrl,
    layerProps: {
        minZoom: 14
    },
    group: 'kadastrs',
    attributions: `Kadastra karte un administratīvās robežas @ Nekustamā īpašuma valsts kadastra informācijas sistēmas un Valsts adrešu reģistra dati, ${currentYear}. g.`
}

const kadastrsLayers = {
    'Administrativas_robezas': {
        type: 'GeoServerWMS',
        group: 'kadastrs',
        workspace: 'lvgmc',
        title: 'Administratīvās robežas',
        layerProps: { minZoom: 8 },
        attributions: `Kadastra karte un administratīvās robežas @ Nekustamā īpašuma valsts kadastra informācijas sistēmas un Valsts adrešu reģistra dati, ${currentYear}. g.`
    },
    'lvgmc:kk_parcel': {
        ...kadastrsLayerCfg,
        title: 'Zemes vienības',
        styles: maputils.zemesVienibasStyle,
        tooltipFunc: (feat) => `Zemes vienība: ${feat.get('code')} ${feat.get('area_scale') ? ' Platība: ' + round(feat.get('area_scale'), 1) + ' m2' : ''}`,
        legend: {
            features: [
                {
                    label: 'Uzmērīta',
                    geomType: 'polygon',
                    objectcode: '7201060110'
                },
                {
                    label: 'Ierādīta',
                    geomType: 'polygon',
                    objectcode: '7201060210'
                },
                {
                    label: 'Projektēta',
                    geomType: 'polygon',
                    objectcode: '7201060310'
                },
                {
                    label: 'Nav definēts',
                    geomType: 'polygon',
                    objectcode: '7201060410'
                }
            ]
        }
    },
    'lvgmc:kk_parcel_part': {
        ...kadastrsLayerCfg,
        title: 'Zemes vienību daļas',
        styles: maputils.zemesVienibasDalaStyle,
        tooltipFunc: (feat) => `Zemes vienības daļa: ${feat.get('code')} ${feat.get('area_scale') ? ' Platība: ' + round(feat.get('area_scale'), 1) + ' m2' : ''}`,
        legend: maputils.defaultSinglePolygonLegend
    },
    'lvgmc:kk_parcel_error': {
        ...kadastrsLayerCfg,
        title: 'Kļūdainas zemes vienības',
        styles: maputils.zemesVienibasKludainasStyle,
        tooltipFunc: (feat) => `Kļūdaina zemes vienība: ${feat.get('code')} ${feat.get('area_scale') ? ' Platība: ' + round(feat.get('area_scale'), 1) + ' m2' : ''}`,
        legend: maputils.defaultSinglePolygonLegend
    },
    'lvgmc:kk_building': {
        ...kadastrsLayerCfg,
        title: 'Ēkas',
        styles: maputils.ekasStyle,
        tooltipFunc: (feat) => `Ēka: ${feat.get('code')} ${feat.get('area_scale') ? ' Platība: ' + round(feat.get('area_scale'), 1) + ' m2' : ''}`,
        legend: maputils.defaultSinglePolygonLegend
    }
}

const lgiaLayersBase = {
    type: 'wms',
    group: 'lgia',
    epsgCode: 3059,
    layerProps: {
        visible: false
    }
}

const lgiaLayersWMSSourceBase = {
    crossOrigin: 'anonymous',
    ratio: 1.2
}

const lgiaLyersWMSSourceParams = {
    'FORMAT': 'image/png',
    'TRANSPARENT': false
}

const lgiaLayers = {
    "Topomilj": {
        ...lgiaLayersBase,
        title: 'Pārskata karte mērogā 1:1 000 000',
        wmsSourceProps: {
            ...lgiaLayersWMSSourceBase,
            url: '/lksopen/services/TOPO/Topomilj/MapServer/WMSServer',
            params: {
                ...lgiaLyersWMSSourceParams,
                'LAYERS': '1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61',
            },
            attributions: 'Pārskata karte mērogā 1:1 000 000 © Latvijas Ģeotelpiskās informācijas aģentūra'
        }
    },
    "Topo500": {
        ...lgiaLayersBase,
        title: 'Pārskata karte mērogā 1:500 000',
        wmsSourceProps: {
            ...lgiaLayersWMSSourceBase,
            url: '/lksopen/services/TOPO/Topo500/MapServer/WMSServer',
            params: {
                ...lgiaLyersWMSSourceParams,
                'LAYERS': '1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53',
            },
            attributions: 'Pārskata karte mērogā 1:500 000 © Latvijas Ģeotelpiskās informācijas aģentūra'
        }
    },
    "Topo250": {
        ...lgiaLayersBase,
        title: 'Pārskata karte mērogā 1:250 000',
        wmsSourceProps: {
            ...lgiaLayersWMSSourceBase,
            url: '/lksopen/services/TOPO/Topo250/MapServer/WMSServer',
            params: {
                ...lgiaLyersWMSSourceParams,
                'LAYERS': '1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61',
            },
            attributions: 'Pārskata karte mērogā 1:250 000 © Latvijas Ģeotelpiskās informācijas aģentūra'
        }
    },
    "Topo50_v2": {
        ...lgiaLayersBase,
        title: 'Topogrāfiskā karte mērogā 1:50 000 (2. izdevums)',
        wmsSourceProps: {
            ...lgiaLayersWMSSourceBase,
            url: '/lks/services/TOPO/Topo50_v2/MapServer/WMSServer',
            params: {
                ...lgiaLyersWMSSourceParams,
                'LAYERS': '0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49',
            },
            attributions: 'Topogrāfiskā karte mērogā 1:50 000 (2. izdevums) © Latvijas Ģeotelpiskās informācijas aģentūra'
        }
    },
    "Topo10v4": {
        ...lgiaLayersBase,
        title: 'Topogrāfiskā karte mērogā 1:10 000 (4. izdevums)',
        wmsSourceProps: {
            ...lgiaLayersWMSSourceBase,
            url: '/lks/services/TOPO/Topo10v4/MapServer/WMSServer',
            params: {
                ...lgiaLyersWMSSourceParams,
                'LAYERS': '1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114',
            },
            attributions: 'Topogrāfiskā karte mērogā 1:10 000 (4. izdevums) © Latvijas Ģeotelpiskās informācijas aģentūra'
        }
    },
    "Topo10v3": {
        ...lgiaLayersBase,
        title: 'Topogrāfiskā karte mērogā 1:10 000 (3. izdevums)',
        wmsSourceProps: {
            ...lgiaLayersWMSSourceBase,
            url: '/lks/services/TOPO/Topo10v3/MapServer/WMSServer',
            params: {
                ...lgiaLyersWMSSourceParams,
                'LAYERS': '1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96',
            },
            attributions: 'Topogrāfiskā karte mērogā 1:10 000 (3. izdevums) © Latvijas Ģeotelpiskās informācijas aģentūra'
        }
    },
    "DSM_LIDAR": {
        ...lgiaLayersBase,
        title: 'DSM LIDAR',
        wmsSourceProps: {
            ...lgiaLayersWMSSourceBase,
            url: '/lks/services/VIRSMA/DSM_LIDAR/MapServer/WMSServer',
            params: {
                ...lgiaLyersWMSSourceParams,
                'LAYERS': '1',
            },
            attributions: 'Vizualizēts digitālais reljefa modelis © Latvijas Ģeotelpiskās informācijas aģentūra'
        }
    },
    "DTM_LIDAR": {
        ...lgiaLayersBase,
        title: 'DTM LIDAR',
        wmsSourceProps: {
            ...lgiaLayersWMSSourceBase,
            url: '/lks/services/VIRSMA/DTM_LIDAR/MapServer/WMSServer',
            params: {
                ...lgiaLyersWMSSourceParams,
                'LAYERS': '1',
            },
            attributions: 'Vizualizēts digitālais reljefa modelis © Latvijas Ģeotelpiskās informācijas aģentūra'
        }
    },
    "Ortofoto_v1": {
        ...lgiaLayersBase,
        title: '1. cikla ortofotokarte RGB (1994. - 1999. gads)',
        wmsSourceProps: {
            ...lgiaLayersWMSSourceBase,
            url: '/lksopen/services/ORTO/Ortofoto_v1/MapServer/WMSServer',
            params: {
                ...lgiaLyersWMSSourceParams,
                'LAYERS': '1',
            },
            attributions: 'Ortofotokarte krāsainā spektrā © Latvijas Ģeotelpiskās informācijas aģentūra, 1994.-1999.g.'
        }
    },
    "Ortofoto_v1_kartoshema": {
        ...lgiaLayersBase,
        title: 'Ortofoto 1994.-1999.g. kartoshēma',
        wmsSourceProps: {
            ...lgiaLayersWMSSourceBase,
            url: '/lks/services/ORTO/aerofoto/MapServer/WMSServer',
            params: {
                ...lgiaLyersWMSSourceParams,
                'LAYERS': '0',
                'TRANSPARENT': true
            },
            attributions: 'Ortofoto kartoshēma © Latvijas Ģeotelpiskās informācijas aģentūra, 1994.-1999.g.'
        }
    },
    "Ortofoto_v2": {
        ...lgiaLayersBase,
        title: '2. cikla ortofotokarte RGB (2001. - 2005. gads)',
        wmsSourceProps: {
            ...lgiaLayersWMSSourceBase,
            url: '/lksopen/services/ORTO/Ortofoto_v2/MapServer/WMSServer',
            params: {
                ...lgiaLyersWMSSourceParams,
                'LAYERS': '1',
            },
            attributions: 'Ortofotokarte krāsainā spektrā © Latvijas Ģeotelpiskās informācijas aģentūra, 2001.-2005.g.'
        }
    },
    "Ortofoto_v2_kartoshema": {
        ...lgiaLayersBase,
        title: 'Ortofoto 2001.-2005.g. kartoshēma',
        wmsSourceProps: {
            ...lgiaLayersWMSSourceBase,
            url: '/lks/services/ORTO/aerofoto/MapServer/WMSServer',
            params: {
                ...lgiaLyersWMSSourceParams,
                'LAYERS': '1',
                'TRANSPARENT': true
            },
            attributions: 'Ortofoto kartoshēma © Latvijas Ģeotelpiskās informācijas aģentūra, 2001.-2005.g.'
        }
    },
    "Ortofoto_v3_CIR": {
        ...lgiaLayersBase,
        title: '3. cikla ortofotokarte CIR (2007. - 2008. gads)',
        wmsSourceProps: {
            ...lgiaLayersWMSSourceBase,
            url: '/lksopen/services/ORTO/Ortofoto_v3_CIR/MapServer/WMSServer',
            params: {
                ...lgiaLyersWMSSourceParams,
                'LAYERS': '1',
            },
            attributions: 'Ortofotokarte infrasarkanajā spektrā © Latvijas Ģeotelpiskās informācijas aģentūra, 2005.-2008.g.'
        }
    },
    "Ortofoto_v3": {
        ...lgiaLayersBase,
        title: '3. cikla ortofotokarte RGB (2007. - 2008. gads)',
        wmsSourceProps: {
            ...lgiaLayersWMSSourceBase,
            url: '/lksopen/services/ORTO/Ortofoto_v3/MapServer/WMSServer',
            params: {
                ...lgiaLyersWMSSourceParams,
                'LAYERS': '1'
            },
            attributions: 'Ortofotokarte krāsainā spektrā © Latvijas Ģeotelpiskās informācijas aģentūra, 2005.-2008.g.'
        }
    },
    "Ortofoto_v3_kartoshema": {
        ...lgiaLayersBase,
        title: 'Ortofoto 2005.-2008.g. kartoshēma',
        wmsSourceProps: {
            ...lgiaLayersWMSSourceBase,
            url: '/lks/services/ORTO/aerofoto/MapServer/WMSServer',
            params: {
                ...lgiaLyersWMSSourceParams,
                'LAYERS': '2',
                'TRANSPARENT': true
            },
            attributions: 'Ortofoto kartoshēma © Latvijas Ģeotelpiskās informācijas aģentūra, 2005.-2008.g.'
        }
    },
    "Ortofoto_v6": {
        ...lgiaLayersBase,
        title: '6. cikla ortofotokarte RGB (2016. - 2018. gads)',
        wmsSourceProps: {
            ...lgiaLayersWMSSourceBase,
            url: '/lks/services/ORTO/Ortofoto_v6/MapServer/WMSServer',
            params: {
                ...lgiaLyersWMSSourceParams,
                'LAYERS': '1',
            },
            attributions: 'Ortofotokarte krāsainā spektrā © Latvijas Ģeotelpiskās informācijas aģentūra, 2016.-2018.g.'
        }
    },
    "Ortofoto_v6_kartoshema": {
        ...lgiaLayersBase,
        title: 'Ortofoto 2016.-2018.g. kartoshēma',
        wmsSourceProps: {
            ...lgiaLayersWMSSourceBase,
            url: '/lks/services/ORTO/aerofoto/MapServer/WMSServer',
            params: {
                ...lgiaLyersWMSSourceParams,
                'LAYERS': '5',
                'TRANSPARENT': true
            },
            attributions: 'Ortofoto kartoshēma © Latvijas Ģeotelpiskās informācijas aģentūra, 2016.-2018.g.'
        }
    },
    "Ortofoto_v7": {
        ...lgiaLayersBase,
        title: '7. cikla ortofotokarte RGB (2019. - 2021. gads)',
        wmsSourceProps: {
            ...lgiaLayersWMSSourceBase,
            url: '/lks/services/ORTO/Ortofoto_v7/MapServer/WMSServer',
            params: {
                ...lgiaLyersWMSSourceParams,
                'LAYERS': '1',
            },
            attributions: 'Ortofotokarte krāsainā spektrā © Latvijas Ģeotelpiskās informācijas aģentūra, 2019.-2021.g.'
        }
    },
    "Ortofoto_v7_kartoshema": {
        ...lgiaLayersBase,
        title: 'Ortofoto 2019.-2021.g. kartoshēma',
        wmsSourceProps: {
            ...lgiaLayersWMSSourceBase,
            url: '/lks/services/ORTO/aerofoto/MapServer/WMSServer',
            params: {
                ...lgiaLyersWMSSourceParams,
                'LAYERS': '6',
                'TRANSPARENT': true
            },
            attributions: 'Ortofoto kartoshēma © Latvijas Ģeotelpiskās informācijas aģentūra, 2019.-2021.g.'
        }
    }
}

const featureAttributesDictionary = (kodif) => ({
    nr_db: {
        label: "DB Nr",
        renderer: (nr) => !!nr ? nr : null
    },
    nosaukums: {
        label: "Nosaukums"
    },
    name_ec: {
        label: "Nosaukums"
    },
    place_name: {
        label: "Nosaukums"
    },
    izrakteni: {
        label: "Derīgie izrakteņi"
    },
    platiba: {
        label: "Platība",
        renderer: (platiba) => `${platiba} tūkst. m2`
    },
    adresats_nosaukums: {
        label: "Adresāts"
    },
    sakuma_datums: {
        label: "No"
    },
    beigu_datums: {
        label: "Līdz"
    },
    ieraksta_veids: {
        label: "Atradne / Prognozēto resursu laukums"
    },
    nr_kf: {
        label: "Kūdras fonda Nr."
    },
    laukums: {
        label: "Laukums"
    },
    tips: {
        label: "Kūdras tips",
        geoserver_bais_tipologija: {
            label: "ŪO tips"
        }
    },
    kods_starpt: {
        label: "Starptautiskais kods"
    },
    plat_0_ha: {
        label: '"0" dziļuma robeža, ha'
    },
    plat_rup_ha: {
        label: "Rūpnieciskā dziļuma platība, ha"
    },
    izmanto: {
        label: "Izmantošanas veids"
    },
    tipa_veids: {
        label: "Botāniskais sastāvs"
    },
    kods: {
        label: "Kods"
    },
    ekologiska: {
        label: "Kopējā ekoloģiskā kvalitāte",
        renderer: getBadgeFormatterForKodif(kodif.uoKvalitate)
    },
    kategorija: {
        label: "Ūdensobjekta kategorija",
        renderer: getFormatterForKodif(kodif.virszemesUdensObjektaKategorija),
        geoserver_limitu_apjomi: {
            label: "Kategorija"
        },
        geoserver_licencu_apjomi: {
            label: "Kategorija"
        },
        geoserver_krajumi: {
            label: "Kategorija"
        }
    },
    kategorijas: {
        label: "Kategorija"
    },
    kimiska: {
        label: "Kopējā ķīmiskā kvalitāte",
        renderer: getBadgeFormatterForKodif(kodif.uoKvalitate, 'badgeColorKimiska')
    },
    kimiska_bez_pbt: {
        label: "Kopējā ķīmiskā kvalitāte bez noturīgajām, bioakumulatīvajām un toksiskajām (PBT) vielām",
        renderer: getBadgeFormatterForKodif(kodif.uoKvalitate, 'badgeColorKimiska')
    },
    name: {
        label: "Nosaukums"
    },
    category: {
        label: "Veids"
    },
    site_code: {
        label: "Kods"
    },
    thematicid: {
        label: "Kods"
    },
    lasveidigie: {
        label: "Tips",
        renderer: (value) => value === true ? 'Lašveidīgo zivju ūdeņi' : 'Karpveidīgo zivju ūdeņi'
    },
    nametext: {
        label: "Nosaukums"
    },
    periods: {
        label: "Periods"
    },
    punktveida: {
        label: "Punktveida slodze",
        renderer: booleanRenderer
    },
    izkliedeta: {
        label: "Izkliedētā slodze",
        renderer: booleanRenderer
    },
    hidro: {
        label: "Hidromorfoloģiskā slodze",
        renderer: booleanRenderer
    },
    citas: {
        label: "Citas slodzes",
        renderer: booleanRenderer
    },
    izcelsme: {
        label: "Izcelsme",
        renderer: getFormatterForKodif(kodif.udensObjektaIzcelsme),
    },
    atis_veids: {
        label: "ATIS veids"
    },
    atis_description: {
        label: "ATIS"
    },
    atis_code: {
        label: "ATIS kods"
    },
    legal_act: {
        label: "MK noteikumi",
        renderer: linkRenderer
    },
    cabinet_act: {
        label: "MK noteikumi objektam",
        renderer: linkRenderer
    },
    genus_lv: {
        label: "Ģints"
    },
    species_lv: {
        label: "Suga"
    },
    genus_latin: {
        label: "Ģints (latīniski)"
    },
    species_latin: {
        label: "Suga (latīniski)"
    },
    location: {
        label: "Novietojums"
    },
    features: {
        label: "Īpašas iezīmes"
    },
    damages: {
        label: "Bojājumi"
    },
    risk_type: {
        label: "Riski"
    },
    height: {
        label: "Augstums"
    },
    description: {
        label: "Apraksts"
    }
})

const zdzGeoServerLayerCfg = {
    type: 'GeoServerWMS',
    group: 'zdz',
    workspace: 'ZDz',
    clickableRasterLayer: true,
    wmsFeatureInfoLayer: true
}

const dapGeoServerLayerCfg = {
    ...zdzGeoServerLayerCfg,
    group: 'dap',
    workspace: 'lvgmc'
}

const baisGeoServerLayerCfg = {
    ...zdzGeoServerLayerCfg,
    layerProps: {
        zIndex: 8
    },
    group: 'bais',
    workspace: 'bais'
}

const pazemeGeoServerLayerCfg = {
    ...zdzGeoServerLayerCfg,
    layerProps: {
        zIndex: 8
    },
    group: 'pazemes_udeni',
    workspace: 'bais'
}

const ekoKvalLayer = {
    ...baisGeoServerLayerCfg,
    group: 'bais_ekologiska_kvalitate',
    featureInfoLinkParams: (feat) => !!feat.kods ? {
        loc: 'loc_virszemes_udens_objekts',
        extraPayload: { kods: feat.kods }
    } : null
}

const kimKvalLayer = {
    ...ekoKvalLayer,
    group: 'bais_kimiska_kvalitate'
}

const kimKvalBezPbtLayer = {
    ...ekoKvalLayer,
    group: 'bais_kimiska_bez_pbt_kvalitate'
}

const prioritarieZivjuUdeni = {
    ...baisGeoServerLayerCfg,
    group: 'bais_prioritarie_zivju_udeni'
}

const zdzGeoServerLayers = {
    "atis_aizsargjosla": {
        ...zdzGeoServerLayerCfg,
        group: 'noverojumi',
        workspace: 'atis',
        title: 'ATIS aizsargjoslas',
        featureInfoTitle: (feat) => `ATIS aizsargjosla: ${feat['atis_source_id']}`
    },
    "dap_habitat_poly": {
        ...dapGeoServerLayerCfg,
        title: 'Aizsargājamās dzīvotnes - biotopi',
        featureInfoTitle: (feat) => `Biotipi: ${feat['name_ec']}`
    },
    "dap_species_poly": {
        ...dapGeoServerLayerCfg,
        title: 'Aizsargājamo sugu atradnes - daudzstūri',
        featureInfoTitle: (feat) => `Aizsargājamo sugu atradnes: ${feat['species_lv'] ? feat['species_lv'] : ''} ${feat['genus_lv'] ? feat['genus_lv'] : ''}`,
    },
    "dap_species_point": {
        ...dapGeoServerLayerCfg,
        title: 'Aizsargājamo sugu atradnes - punkti',
        featureInfoTitle: (feat) => `Aizsargājamo sugu atradnes: ${feat['species_lv'] ? feat['species_lv'] : ''} ${feat['genus_lv'] ? feat['genus_lv'] : ''}`,
    },
    "dap_trees": {
        ...dapGeoServerLayerCfg,
        title: 'Aizsargājamie koki',
        featureInfoTitle: (feat) => `Aizsargājamie koki: ${feat['species_lv'] ? feat['species_lv'] : ''} ${feat['genus_lv'] ? feat['genus_lv'] : ''} ${feat['name'] ? ' - ' + feat['name'] : ''}`,
    },
    "dap_monument_sites": {
        ...dapGeoServerLayerCfg,
        title: 'Dabas pieminekļi',
        featureInfoTitle: (feat) => `Dabas pieminekļi: ${feat['place_name']}`
    },
    "dap_microres_buffer_pub": {
        ...dapGeoServerLayerCfg,
        title: 'Mikroliegumu buferzonas',
        featureInfoTitle: (feat) => feat['atis_veids']
    },
    "microreserves_pub": {
        ...dapGeoServerLayerCfg,
        title: 'Mikroliegumi',
        featureInfoTitle: (feat) => feat['atis_veids']
    },
    "dap_protected_sites": {
        ...dapGeoServerLayerCfg,
        title: 'Īpaši aizsargājamās dabas teritorijas',
        featureInfoTitle: (feat) => `ĪADT: ${feat['name']}`,
    },
    "geoserver_pazemes_monitoringa_stacijas": {
        ...pazemeGeoServerLayerCfg,
        title: 'Monitoringa stacijas',
        featureInfoTitle: (feat) => `Monitoringa stacija: ${feat['nosaukums']} `,
        featureInfoLinkParams: (feat) => ({
            loc: 'loc_stacija_show',
            extraPayload: { id: feat.id }
        }),
        legend: {
            wms_params: { style: 'bais_pazemes_monitoringa_stacijas_legendam' }
        }
    },
    "geoserver_bais_slodzes_2022": {
        ...ekoKvalLayer,
        group: 'bais_slodzes',
        title: '2022.-2027.g. plānošanas periods (2020.g. vērtējums)',
        featureInfoTitle: (feat) => 'Būtiskas slodzes ūdensobjektos 2022.-2027.g. plānošanas periods (2020.g. vērtējums)'
    },
    "prioritarie_zivju_udeni_ezeri": {
        ...prioritarieZivjuUdeni,
        title: 'Ezeri',
        featureInfoTitle: (feat) => 'Prioritārie zivju ūdeņi - ezeri'
    },
    "prioritarie_zivju_udeni_upes": {
        ...prioritarieZivjuUdeni,
        title: 'Upes',
        featureInfoTitle: (feat) => 'Prioritārie zivju ūdeņi - upes'
    },
    "geoserver_bais_kimiska_bez_pbt_kvalitate_2006_2008": {
        ...kimKvalBezPbtLayer,
        title: '2010.-2015.g. plānošanas periods (2006.-2008.g. dati)',
        featureInfoTitle: (feat) => 'Ķīmiskā kvalitāte bez noturīgajām, bioakumulatīvajām un toksiskajām (PBT) vielām 2010.-2015.g. plānošanas periods (2006.-2008.g. dati)'
    },
    "geoserver_bais_kimiska_bez_pbt_kvalitate_2009_2014": {
        ...kimKvalBezPbtLayer,
        title: '2016.-2021.g. plānošanas periods (2009.-2014.g. dati)',
        featureInfoTitle: (feat) => 'Ķīmiskā kvalitāte bez noturīgajām, bioakumulatīvajām un toksiskajām (PBT) vielām 2016.-2021.g. plānošanas periods (2009.-2014.g. dati)'
    },
    "geoserver_bais_kimiska_bez_pbt_kvalitate_2015_2020": {
        ...kimKvalBezPbtLayer,
        title: '2022.-2027.g. plānošanas periods (2015.-2020.g. dati)',
        featureInfoTitle: (feat) => 'Ķīmiskā kvalitāte bez noturīgajām, bioakumulatīvajām un toksiskajām (PBT) vielām 2022.-2027.g. plānošanas periods (2015.-2020.g. dati)'
    },
    "geoserver_bais_kimiska_kvalitate_2006_2008": {
        ...kimKvalLayer,
        title: '2010.-2015.g. plānošanas periods (2006.-2008.g. dati)',
        featureInfoTitle: (feat) => 'Ķīmiskā kvalitāte 2010.-2015.g. plānošanas periods (2006.-2008.g. dati)'
    },
    "geoserver_bais_kimiska_kvalitate_2009_2014": {
        ...kimKvalLayer,
        title: '2016.-2021.g. plānošanas periods (2009.-2014.g. dati)',
        featureInfoTitle: (feat) => 'Ķīmiskā kvalitāte 2016.-2021.g. plānošanas periods (2009.-2014.g. dati)'
    },
    "geoserver_bais_kimiska_kvalitate_2015_2020": {
        ...kimKvalLayer,
        title: '2022.-2027.g. plānošanas periods (2015.-2020.g. dati)',
        featureInfoTitle: (feat) => 'Ķīmiskā kvalitāte 2022.-2027.g. plānošanas periods (2015.-2020.g. dati)'
    },
    "geoserver_bais_ekologiska_kvalitate_2006_2008": {
        ...ekoKvalLayer,
        title: '2010.-2015.g. plānošanas periods (2006.-2008.g. dati)',
        featureInfoTitle: (feat) => 'Ekoloģiskā kvalitāte 2010.-2015.g. plānošanas periods (2006.-2008.g. dati)'
    },
    "geoserver_bais_ekologiska_kvalitate_2009_2014": {
        ...ekoKvalLayer,
        title: '2016.-2021.g. plānošanas periods (2009.-2014.g. dati)',
        featureInfoTitle: (feat) => 'Ekoloģiskā kvalitāte 2016.-2021.g. plānošanas periods (2009.-2014.g. dati)'
    },
    "geoserver_bais_ekologiska_kvalitate_2015_2020": {
        ...ekoKvalLayer,
        title: '2022.-2027.g. plānošanas periods (2015.-2020.g. dati)',
        featureInfoTitle: (feat) => 'Ekoloģiskā kvalitāte 2022.-2027.g. plānošanas periods (2015.-2020.g. dati)'
    },
    "iadt_pamatteritorijas_bais": {
        ...baisGeoServerLayerCfg,
        title: 'Natura2000 (2020.g.)',
        featureInfoTitle: (feat) => 'Natura2000 (2020.g.) ĪADT'
    },
    "geoserver_bais_tipologija": {
        ...ekoKvalLayer,
        group: 'bais',
        title: 'Tipoloģija',
        featureInfoTitle: (feat) => 'Tipoloģija'
    },
    "geoserver_virszemes_monitoringa_stacijas": {
        ...baisGeoServerLayerCfg,
        title: 'Virszemes ūdeņu kvalitātes monitoringa stacijas',
        featureInfoTitle: (feat) => `Monitoringa stacija: ${feat['nosaukums']} `,
        featureInfoLinkParams: (feat) => ({
            loc: 'loc_stacija_show',
            extraPayload: { id: feat.id }
        }),
        legend: {
            wms_params: { style: 'bais_virszemes_monitoringa_stacijas_legendas' }
        }
    },
    "kudra_eraf_s": {
        ...zdzGeoServerLayerCfg,
        title: 'Kūdras atradnes un iegulas (ERAF dati, 2013)',
        featureInfoTitle: (feat) => `Kūdras atradnes un iegulas: ${feat['nosaukums']} `,
        featureInfoColor: 'color_kudra_s',
        featureInfoLinkParams: (feat) => !!feat.nr_db ? {
            numurs: `K${feat.nr_db} `,
            isDala: false,
            loc: 'loc_atradne'
        } : null
    },
    "kudra_eraf_p": {
        ...zdzGeoServerLayerCfg,
        title: 'Kūdras atradnes un iegulas ≤ 10ha (ERAF dati, 2013)',
        featureInfoTitle: (feat) => `Kūdras atradnes un iegulas ≤ 10ha: ${feat['nosaukums']} `,
        featureInfoColor: 'color_kudra_p',
        featureInfoLinkParams: (feat) => !!feat.nr_db ? {
            numurs: `K${feat.nr_db} `,
            isDala: false,
            loc: 'loc_atradne'
        } : null
    },
    "geoserver_krajumi": {
        ...zdzGeoServerLayerCfg,
        title: 'Krājumu laukumi',
        featureInfoTitle: (feat) => `Krājumu laukumi: ${feat['nosaukums']} (${feat['numurs']}) ${feat['dalas_nosaukums'] ? feat['dalas_nosaukums'] : ''} ${feat['strukturelements'] ? feat['strukturelements'] : ''} ${feat['kategorija'] ? 'Kat. ' + feat['kategorija'] : ''} `,
        featureInfoColor: 'color_krajumi',
        featureInfoLinkParams: (feat) => ({
            numurs: feat.numurs,
            atrId: feat.dalas_id,
            isDala: !!feat.dalas_id,
            loc: 'loc_atradnes_krajums',
            locDala: 'loc_atradnes_dala_krajums',
            extraPayload: { id: feat.id }
        })
    },
    "geoserver_pases_krajumu_sadalijumi": {
        ...zdzGeoServerLayerCfg,
        title: 'Pases',
        featureInfoTitle: (feat) => `Pase: ${feat['nosaukums']} (${feat['numurs']}) ${feat['dalas_nosaukums'] ? feat['dalas_nosaukums'] : ''} ${feat['strukturelements'] ? feat['strukturelements'] : ''} ${feat['kategorijas'] ? 'Kat. ' + feat['kategorijas'] : ''} `,
        featureInfoColor: 'color_pases',
        featureInfoLinkParams: (feat) => ({
            numurs: feat.numurs,
            atrId: feat.dalas_id,
            isDala: !!feat.dalas_id,
            loc: 'loc_atradnes_pase',
            locDala: 'loc_atradnes_dala_pase',
            extraPayload: { id: feat.pase_id }
        })
    },
    "geoserver_limitu_apjomi": {
        ...zdzGeoServerLayerCfg,
        title: 'Limiti',
        featureInfoTitle: (feat) => `Limits: ${feat['nosaukums']} (${feat['numurs']}) ${feat['dalas_nosaukums'] ? feat['dalas_nosaukums'] : ''} ${feat['strukturelements'] ? feat['strukturelements'] : ''} ${feat['kategorija'] ? 'Kat. ' + feat['kategorija'] : ''} `,
        featureInfoColor: 'color_limiti',
        featureInfoLinkParams: (feat) => ({
            numurs: feat.numurs,
            atrId: feat.dalas_id,
            isDala: !!feat.dalas_id,
            loc: 'loc_atradnes_limits',
            locDala: 'loc_atradnes_dala_limits',
            extraPayload: { id: feat.limits_id }
        })
    },
    "geoserver_licencu_apjomi": {
        ...zdzGeoServerLayerCfg,
        title: 'Licences / atļaujas',
        featureInfoTitle: (feat) => `Licence / atļauja: ${feat['nosaukums']} (${feat['numurs']}) ${feat['dalas_nosaukums'] ? feat['dalas_nosaukums'] : ''} ${feat['strukturelements'] ? feat['strukturelements'] : ''} ${feat['kategorija'] ? 'Kat. ' + feat['kategorija'] : ''} `,
        featureInfoColor: 'color_licences',
        featureInfoLinkParams: (feat) => ({
            numurs: feat.numurs,
            atrId: feat.dalas_id,
            isDala: !!feat.dalas_id,
            loc: 'loc_atradnes_licence',
            locDala: 'loc_atradnes_dala_licence',
            extraPayload: { id: feat.licence_id }
        })
    },
    "geoserver_atradnes": {
        ...zdzGeoServerLayerCfg,
        title: 'Atradnes',
        featureInfoTitle: (feat) => `Atradne: ${feat['nosaukums']} (${feat['numurs']})`,
        featureInfoColor: 'light',
        featureInfoLinkParams: (feat) => ({
            numurs: feat.numurs,
            isDala: false,
            loc: 'loc_atradne'
        }),
        legend: {
            wms_params: { style: 'atradnes_css_legendam' }
        }
    }
}

class IzraktenisMap extends Component {

    constructor(props) {
        super(props)
        this.state = {
            menuVisible: true,
            selectedPopupFeatures: [],
            selectedPopupCoordinate: null,
            searchedFeatures: [],
            karteSize: [400, 300]
        }
        this.layerSwitcherElement = createRef()
        this.failedTileLayers = []

        this.is_mounted = false
        this.tileLayers = getTileLayers()
    }

    componentDidUpdate(prevProps) {
        if ((!this.props.user.tiesibas.includes('ARCGIS_SERVISI') || this.props.arcgisServices.loaded) && !this.layerSwitcherRendered) {
            LayerSwitcher.renderPanel(this.map, this.layerSwitcherElement.current, { reverse: true })
            this.layerSwitcherRendered = true
        } else if (this.layerSwitcherRendered && !_.isEqual(Object.keys(prevProps.vectorLayerCfgs), Object.keys(this.props.vectorLayerCfgs))) {
            LayerSwitcher.renderPanel(this.map, this.layerSwitcherElement.current, { reverse: true })
        }
    }

    componentDidMount() {
        this.is_mounted = true
        this.hideMenuInitially()
    }

    onMapInit = (map) => {
        this.map = map
        this.hideMenuInitially()
    }

    hideMenuInitially = () => {
        if (this.is_mounted && (this.props.menuVisible === false || (this.map && this.map.getSize() && this.map.getSize()[0] < 600))) {
            this.setState({
                menuVisible: false
            })
        }
    }

    toggleMenu = () => {
        this.setState({
            menuVisible: !this.state.menuVisible
        })
    }

    //set map size for bbox filter queries for grid
    onMapContainerResize = (mapId, size) => {
        this.setState({
            karteSize: size
        })
        this.props.guiSet(`${mapId} _karte_size`, size)
    }

    onBackgroundLayerError = (layerProps) => {
        if (layerProps.id && this.failedTileLayers.indexOf(layerProps.id) < 0) {
            this.props.tileLayerLoadingError({ error: `Kartes slāni ${layerProps.title} pašlaik nevar attēlot!`, layer: layerProps.id })
            this.failedTileLayers.push(layerProps.id)
        }
    }

    onGetFeatureInfo = ({ event, features }) => {
        // show popup with features
        // process features
        const feats = features.map(f => ({
            ...f,
            title: zdzGeoServerLayers[f.layer] ? zdzGeoServerLayers[f.layer].featureInfoTitle(f) : "Objekts",
            color: zdzGeoServerLayers[f.layer] ? zdzGeoServerLayers[f.layer].featureInfoColor : "secondary",
            featureInfoLinkParams: zdzGeoServerLayers[f.layer] && zdzGeoServerLayers[f.layer].featureInfoLinkParams ? zdzGeoServerLayers[f.layer].featureInfoLinkParams(f) : null,
        }))
        if (feats.length > 0) {
            feats[0].selected = true
        }
        this.setState({
            selectedPopupFeatures: feats,
            selectedPopupCoordinate: event.coordinate
        })
    }

    setSearchedFeatures = (features) => {
        this.setState({
            searchedFeatures: features
        })
    }

    onClickOutsideFeature = (args) => {
        this.setState({ selectedPopupFeatures: [] })
        this.props.clickedOutsideFeature(args)
    }

    onSelectFeatureInPopup = (feature) => {
        this.setState({
            selectedPopupFeatures: this.state.selectedPopupFeatures.map(f => ({ ...f, selected: f === feature && !f.selected }))
        })
    }

    onChangeLayerOpacity = ({ id, opacity }) => {
        if (!!id) {
            const subApp = getCurrentSupApp(this.props.location) ?? 'other'
            const key = `map | ${subApp}| layers | ${id}| opacity`
            this.props.saveLietotajsUzstadijumi({
                nosaukums: key,
                uzstadijumi: '' + opacity
            })
        }
    }

    onChangeLayerVisibility = ({ id, visible }) => {
        if (!!id) {
            const subApp = getCurrentSupApp(this.props.location) ?? 'other'
            const key = `map | ${subApp}| layers | ${id}| visible`
            this.props.saveLietotajsUzstadijumi({
                nosaukums: key,
                uzstadijumi: '' + visible
            })
        }
    }

    parseUzstadijumiValue = (key, uzst) => {
        if (key === 'opacity') {
            return parseFloat(uzst)
        }
        if (key === 'visible') {
            return uzst === 'true'
        }
        return undefined
    }

    render() {

        const { selectedPopupFeatures, selectedPopupCoordinate, menuVisible, searchedFeatures, karteSize } = this.state

        const { location, changeMapBBox, mapId, clickedFeature,
            doubleClickedFeature, vectorLayerCfgs, modifiedFeature, arcgisServices, user, kodif } = this.props
        const { query } = location
        const irLGIAKartes = user.tiesibas.includes('KADASTRA_WFS')

        // loading while we have no arcgis services metadata
        //TODO add timeout in server side for arcgis metadata
        //if (!arcgisServices.loaded) {
        //return <Loading/>
        //}

        if (vectorLayerCfgs) {
            Object.keys(vectorLayerCfgs).forEach((k) => {
                if (!vectorLayerCfgs[k].group) {
                    vectorLayerCfgs[k].group = 'vektorslani'
                }
                if (!vectorLayerCfgs[k].type) {
                    vectorLayerCfgs[k].type = 'vector'
                }
            })
        }

        let vectorLayerCfgsForMap = { ...vectorLayerCfgs }

        let layerGroups = {
            dap: {
                title: 'DAP fona slāņi',
                fold: 'close',
                visible: false
            },
            kadastrs: {
                title: 'Administratīvās robežas un kadastrs',
                fold: 'close',
                visible: false
            },
            noverojumi: {
                title: 'Novērojuma fona slāņi',
                fold: 'close',
                visible: false
            },
            pazemes_udeni: {
                title: 'Pazemes ūdeņu fona slāņi',
                fold: 'close',
                visible: false
            },
            bais: {
                title: 'Virszemes ūdeņu fona slāņi',
                fold: 'close',
                visible: false
            },
            bais_kimiska_bez_pbt_kvalitate: {
                title: 'Kopējā ķīmiskā kvalitāte bez noturīgajām, bioakumulatīvajām un toksiskajām (PBT) vielām',
                fold: 'close',
                visible: false,
                group: 'bais'
            },
            bais_slodzes: {
                title: 'Būtiskas slodzes ūdensobjektos',
                fold: 'close',
                visible: false,
                group: 'bais'
            },
            bais_kimiska_kvalitate: {
                title: 'Ķīmiskā kvalitāte',
                fold: 'close',
                visible: false,
                group: 'bais'
            },
            bais_ekologiska_kvalitate: {
                title: 'Ekoloģiskā kvalitāte',
                fold: 'close',
                visible: false,
                group: 'bais'
            },
            bais_prioritarie_zivju_udeni: {
                title: 'Prioritārie zivju ūdeņi',
                fold: 'close',
                visible: false,
                group: 'bais'
            },
            zdz: {
                title: 'Zemes dzīļu fona slāņi',
                fold: 'close',
                visible: false
            },
            vektorslani: {
                title: 'Virsslāņi',
                fold: 'open',
                visible: true
            }
        }

        vectorLayerCfgsForMap = { ...vectorLayerCfgsForMap, ...zdzGeoServerLayers, ...kadastrsLayers }

        if (arcgisServices.loaded && arcgisServices.servers && arcgisServices.servers.length > 0) {
            arcgisServices.servers.forEach(serv => {
                vectorLayerCfgsForMap = { ...vectorLayerCfgsForMap, ...serv.layers }
                layerGroups = {
                    [serv.name]: {
                        title: serv.name,
                        fold: 'close',
                        visible: false
                    },
                    ...layerGroups
                }
            })
        }

        if (irLGIAKartes) {
            vectorLayerCfgsForMap = { ...vectorLayerCfgsForMap, ...lgiaLayers }
            layerGroups = {
                lgia: {
                    title: 'LĢIA fona slāņi',
                    fold: 'close',
                    visible: false
                },
                ...layerGroups
            }
        }

        if (selectedPopupFeatures.filter(f => f.selected).length > 0) {
            vectorLayerCfgsForMap.zdz_feature_info_object = {
                type: 'vector',
                features: selectedPopupFeatures.filter(f => f.selected),
                title: 'Zemes dzīļu izvēlētais objekts',
                styles: maputils.featureInfoStyle,
                legend: maputils.defaultSinglePolygonLegend
            }
        }

        if (searchedFeatures && searchedFeatures.length > 0) {
            vectorLayerCfgsForMap.search_field_objects = {
                type: 'vector',
                features: searchedFeatures,
                title: 'Meklēšanas ievadlauks',
                styles: maputils.searchedFeaturesStyle,
                tooltipFunc: (feat) => `Atrasts "${feat.get('search_title')}" objekts "${feat.get('search_label')}"`,
                legend: maputils.defaultSinglePolygonLegend
            }
        }

        const subApp = getCurrentSupApp(location) ?? 'other'

        // apply default visible layer groups for subApp
        if (defaultVisibleLayerGroupsForSubApp[subApp]) {
            defaultVisibleLayerGroupsForSubApp[subApp].forEach(x => layerGroups[x].visible = true)
            Object.keys(layerGroups).filter(k => defaultVisibleLayerGroupsForSubApp[subApp].indexOf(k) >= 0)
                .forEach(k => layerGroups[k].visible = true)
        }
        // apply layer visible settings from group visible settings
        Object.keys(vectorLayerCfgsForMap).forEach(k => {
            const group = vectorLayerCfgsForMap[k].group
            if (group && layerGroups[group]) {
                const layerProps = vectorLayerCfgsForMap[k].layerProps || {}
                layerProps.visible = layerGroups[group].visible
                vectorLayerCfgsForMap[k].layerProps = { ...layerProps }
            }
        })
        // apply default visible layers for subApp
        if (defaultVisibleLayersForSubApp[subApp]) {
            Object.keys(vectorLayerCfgsForMap).filter(k => defaultVisibleLayersForSubApp[subApp].indexOf(k) >= 0)
                .forEach(k => {
                    const layerProps = vectorLayerCfgsForMap[k].layerProps || {}
                    layerProps.visible = true
                    vectorLayerCfgsForMap[k].layerProps = { ...layerProps }
                })
        }

        // get per user and subApp layer settings in a form:
        // {
        //   <layer_id_1> : {
        //         opacity: 0.2,
        //         visible: true
        //   },
        //   <layer_id_2> : {
        //         opacity: 0.5
        //   }
        // }

        const key = `map | ${subApp}| layers | `
        const uzstadijumi = user.uzstadijumi
            .filter(u => u.nosaukums.startsWith(key))
            .reduce((layerCfgs, uzst) => {
                const layerCfgParts = uzst.nosaukums.substring(key.length).split('|').map(s => s.trim())
                const layerCfg = layerCfgs[layerCfgParts[0]] || {}
                layerCfg[layerCfgParts[1]] = this.parseUzstadijumiValue(layerCfgParts[1], uzst.uzstadijumi)
                layerCfgs[layerCfgParts[0]] = layerCfg
                return layerCfgs
            }, {})

        // apply setting for base layers
        let baseVisible = false
        this.tileLayers.forEach(l => {
            const cfg = uzstadijumi[l.get("id")]
            if (cfg) {
                if (cfg.visible === true) {
                    l.setVisible(cfg.visible)
                    baseVisible = true
                }
                if (cfg.opacity) {
                    l.setOpacity(cfg.opacity)
                }
            }
        })
        // if no base layer visible set the default one visible
        if (!baseVisible) {
            this.tileLayers.find(l => l.get('id') === 'osm_original').setVisible(true)
        }

        // apply settings for vector layers
        Object.keys(vectorLayerCfgsForMap).forEach(k => {
            const cfg = uzstadijumi[k]
            if (cfg) {
                const layerProps = vectorLayerCfgsForMap[k].layerProps || {}
                vectorLayerCfgsForMap[k].layerProps = { ...layerProps, ...cfg }
            }
        })

        return (
            <div className="map-app-container">
                <Map
                    mapId={mapId}
                    center={query}
                    backgroundLayers={this.tileLayers}
                    onMapContainerResize={this.onMapContainerResize}
                    onChangeMapBBox={changeMapBBox}
                    onClickFeature={clickedFeature}
                    onClickOutsideFeature={this.onClickOutsideFeature}
                    onDoubleClickFeature={doubleClickedFeature}
                    vectorLayerCfgs={vectorLayerCfgsForMap}
                    layerGroups={layerGroups}
                    onModifyFeature={modifiedFeature}
                    onMapInit={this.onMapInit}
                    onBackgroundLayerError={this.onBackgroundLayerError}
                    onGetFeatureInfo={this.onGetFeatureInfo}
                    onPopupClose={this.onClickOutsideFeature}
                    onChangeLayerOpacity={this.onChangeLayerOpacity}
                    onChangeLayerVisibility={this.onChangeLayerVisibility}
                    popup={selectedPopupFeatures && selectedPopupFeatures.length > 0 ? {
                        content: <FeaturesPopup features={selectedPopupFeatures}
                            selectFeature={this.onSelectFeatureInPopup}
                            featureAttributesDictionary={featureAttributesDictionary(kodif)}
                        />,
                        coordinate: selectedPopupCoordinate
                    } : null} >
                    <MapSearchField setSearchedFeatures={this.setSearchedFeatures} maxHeight={(karteSize[1] - 45) + 'px'} />
                    <div className="ol-control side-menu-btn">
                        <button onClick={this.toggleMenu}><i className="fa fa-bars" aria-hidden="true"></i></button>
                    </div>
                    <div className={menuVisible ? 'side-menu' : 'side-menu hidden'} >
                        <Accordion title="Kartes slāņi">
                            <div className="layer-switcher" ref={this.layerSwitcherElement}></div>
                        </Accordion>
                        {/*<Accordion title="Vietas meklēšana">
                            <FilterForm onSearch={searchMap} loading={kadastrs_search.loading}/>
                        </Accordion>

                        <Accordion title="Apzīmējumi">
                            Te varētu ielikt kartes apzīmējumus no ArcGIS servera
                        </Accordion>
                        */}
                    </div>
                </Map>
            </div>
        )
    }

}

const mapStateToProps = ({ location, arcgisServices, user, kodif }) =>
    ({ location, arcgisServices, user, kodif })
export default connect(mapStateToProps, {
    changeMapBBox, clickedFeature, doubleClickedFeature,
    clickedOutsideFeature, guiSet, modifiedFeature, tileLayerLoadingError, saveLietotajsUzstadijumi
})(IzraktenisMap)
