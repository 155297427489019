import {
  callApi,
  getDefaultEditActions,
  pushUrlToBrowserHistory,
  addExtraFilterToQuery
} from './helpers'
import {
  redirect
} from 'redux-first-router'


export const upjuBaseinuApgabali = (query) => (dispatch, getState) => {
  const mapSize = getState().gui['upju_baseinu_apgabali_saraksts_karte_size']
  return dispatch(callApi({
    viewName: 'upju_baseinu_apgabali_list',
    crudMethod: 'listPaging',
    crudParam: addExtraFilterToQuery(query, mapSize)
  }))
}

export const upjuBaseinuApgabalsSelected = (kods) => {
  return {
    type: 'virszemes_udens_objekts_selected',
    payload: kods
  }
}

export const upjuBaseinuApgabalsDoubleSelected = (kods) => (dispatch, getState) => {
  pushUrlToBrowserHistory(getState)
  dispatch(redirect({
    type: 'loc_virszemes_udens_objekts',
    payload: {
      kods: kods
    }
  }))
}

export const upjuBaseinuApgabaliActions = getDefaultEditActions({
  actionTypeName: 'upju_baseinu_apgabals',
  stateName: 'upjuBaseinuApgabals',
  apiViewName: 'upju_baseinu_apgabals',
  afterSave: (dispatch, getState, result, savingNew) => {
    dispatch({
      type: 'upju_baseinu_apgabals_clear'
    })
    dispatch(redirect({
      type: 'loc_virszemes_udens_objekts',
      payload: {
        kods: result.kods
      }
    }))
  },
  fetchArgsToCrudParams: ({
    kods
  }) => ['kods', kods]
})
