import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
    dateFormatter,
} from '../grid/ColumnHelpers'

import IzraktenisMap from '../karte/IzraktenisMap';
import * as maputils from '../karte/maputils'

import SplitterLayout from 'react-splitter-layout';

import {guiSet} from '../../actions/uiActions'
import {licenceSelected, openDok} from '../../actions/dokumentacijaActions'

import Grid from '../grid/Grid'
import BBoxFilterButtonToUrl from '../grid/BBoxFilterButtonToUrl'
import {getCommonColumns, getDoubleClickHandler, getEditColumnForDokSaraksts} from './common'
import ManiObjektiButton from '../grid/ManiObjektiButton'

const editIconColumn = getEditColumnForDokSaraksts({loc: 'loc_atradnes_licence', locDala: 'loc_atradnes_dala_licence'})

const columnNames = ['ir_derigs', 'atradne', 'dalas_nosaukums', 'tips_nosaukums', 'lic_numurs', 'izdevejs',
'adresats_nosaukums', 'izrakteni', 'izsniegts_datums', 'derigs_lidz_datums',
'anulets_datums', 'pase', 'limiti', 'pielikumi_filename', 'aktualizacijas_laiks', 'q_adm_ters']
const getColumns = ({kodif, user}) => {
  const allColumns = getCommonColumns({kodif, user}).filter(c => columnNames.indexOf(c.dataField) !== -1)
  return [editIconColumn,...allColumns]
};

class LicenceSaraksts extends Component {

    mapPaneSizeChange = (h) => {
        this.props.guiSet('licence_saraksts_karte_height', h);
    }

    rowEvents = {
        onDoubleClick: getDoubleClickHandler({loc: 'loc_atradnes_licence', locDala: 'loc_atradnes_dala_licence', openDok: this.props.openDok})
    }

    render() {

        const {kodif, licences, gui, licenceSelected, user} = this.props
        const {list, selected, loading, count} = licences

        const vectorLayerCfgs = {
            licences : {
                title: 'Licences / atļaujas',
                features: list,
                styles: maputils.dokStyles.dokLicencesStyles,
                tooltipFunc: (feat) => `${feat.get('atradne') ? feat.get('atradne').tah_label : ''} ${feat.get('lic_numurs')} ${dateFormatter(feat.get('izsniegts_datums'))} - ${feat.get('adresats_nosaukums')}`,
                selected: selected,
                legend: {
                  features: [
                      {
                        label: 'Licence / atļauja',
                        geomType: 'polygon'
                      },
                      {
                        label: 'Licence / atļauja (izvēlēta)',
                        geomType: 'polygon',
                        selected: true
                      }
                  ]
                }
            }
        }

        if(!this.columns){
            this.columns = getColumns({
                kodif,
                user
            })
        }

        return (
            <SplitterLayout
                vertical
                percentage
                primaryIndex={1}
                secondaryInitialSize={gui.licence_saraksts_karte_height}
                onSecondaryPaneSizeChange={this.mapPaneSizeChange}>

                <IzraktenisMap mapId="licence_saraksts" vectorLayerCfgs={vectorLayerCfgs} />

                <Grid
                    onRowSelected={licenceSelected}
                    loading={loading}
                    selectedId={selected}
                    dataList={list}
                    totalCount={count}
                    columns={this.columns}
                    rowEvents={this.rowEvents}
                >

                <BBoxFilterButtonToUrl/>
                <ManiObjektiButton allText="Rādīt visas" maniText="Rādīt tikai manas licences"/>
                </Grid>

            </SplitterLayout>
        )
    }
}

const mapStateToProps = ({kodif, licences, gui, user}) => {
    return {kodif, licences, gui, user}
}

export default connect(mapStateToProps, {licenceSelected, guiSet, openDok})(LicenceSaraksts)
