import { callApi, getDefaultEditActions } from './helpers'

export const loadDatnuApmaina = (query) => (dispatch) => {
    return dispatch(callApi({
      viewName: 'datnu_apmaina_view',
      crudMethod: 'listPaging',
      crudParam: query
    }))
}

export const datnuApmainaSelected = (id) => {
    return {
      type: 'datnu_apmaina_selected',
      payload: id
    }
}

const afterApmainaSaveOrDelete = (dispatch, getState) => {
    dispatch(loadDatnuApmaina(getState().location.query))
}

export const datnuApmainaActions = getDefaultEditActions({
    actionTypeName : 'datnu_apmaina',
    stateName: 'datnu_apmaina',
    combinedPage: true,
    apiViewName: 'datnu_apmaina_view',
    afterDelete: afterApmainaSaveOrDelete,
    afterSave: afterApmainaSaveOrDelete,
})

