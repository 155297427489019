import * as helpers from './helpers'

const defaultListReducer = (viewName, actionsName, args) => {
  return helpers.createListReducerWithMapClicking({
    listViewName: viewName,
    actionsName: actionsName,
    mapIdName: 'troksnu_karte',
    layerIdName: 'troksni',
  })
}

export const troksni = (state = helpers.listGetInitialState(), {type, payload, reqId, mapId, layerId}) => {
  return defaultListReducer('troksnu_karte_saraksts', 'troksni')({state, type, payload, reqId, mapId, layerId})
}

const defaultListReducerForImports = helpers.createListReducer({listViewName: 'troksni_import_saraksts', actionsName: 'troksniImports'})
const defaultEditReducerForImports = helpers.createEditReducer({editViewName: 'troksni_import_saraksts', actionsName: 'troksniImports'})
export const troksniImports = (state = {
  ...helpers.listEditGetInitialState(),
  modalOpen: false
}, {type, payload, reqId}) => {
  //overwrite or add custom actions catches here
  const stateAfterList = defaultListReducerForImports({state, type, payload, reqId})
  switch(type) {
    case 'troksni_import_saraksts_save_success':
      return {
        ...state,
        selectedItem: payload,
        editable: false,
        submiting: false,
        modalOpen: false
      }
    case 'troksni_import_toggle_modal':
      return {
        ...state,
        modalOpen: !state.modalOpen
      }
    default:
      return defaultEditReducerForImports({state: stateAfterList, type, payload, reqId})
  }
}
