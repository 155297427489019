import { callApi } from './helpers'

export const loadArcgisServicesIfNeeded = (forced = false) => (dispatch, getState) => {
  if(forced || getState().arcgisServices.requested === false){
    return dispatch(callApi({
      viewName: 'arcgis_services',
      crudMethod: 'list'
    }))
  }
}
