import {
  callApi,
  getDefaultEditActions,
} from './helpers'

export const loadTroksni = (query) => (dispatch) => {
  return dispatch(callApi({
    viewName: 'troksnu_karte_saraksts',
    crudMethod: 'listPaging',
    crudParam: query
  }))
}

export const troksniToggleModal = () => (dispatch) => {
  return dispatch({
    type: 'troksni_import_toggle_modal'
  })
}

export const troksniImportActions = getDefaultEditActions({
  actionTypeName: 'troksniImports',
  stateName: 'troksniImport',
  combinedPage: true,
  apiViewName: 'troksni_import_saraksts',
  afterSave: (dispatch, getState) => {
    loadTroksni(getState().location.query)(dispatch)
  },
})
