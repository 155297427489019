import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
    getTextFilterCfg,
    getNumberFilterCfg,
    getEditIconColumn,
    getSelectFilterCfg,
    getBooleanSelectFilterCfg,
    getBooleanFormatter, getBadgeFormatterForKodif
} from '../grid/ColumnHelpers'

import IzraktenisMap from '../karte/IzraktenisMap';
import * as maputils from '../karte/maputils'

import SplitterLayout from 'react-splitter-layout';

import {guiSet} from '../../actions/uiActions'
import {urbumsSelected, urbumsDoubleSelected} from '../../actions/hidrogeoActions'

import Grid from '../grid/Grid'
import BBoxFilterButtonToUrl from '../grid/BBoxFilterButtonToUrl'

const editIconColumn = getEditIconColumn((row) => (
    {   
        type: 'loc_urbums',
        payload: {
            id: row.id
        }
    }
    )
)

const getColumns = ({kodif}) => {
  return [editIconColumn,
    {
        dataField: 'id',
        text: 'DB numurs',
        width: 100,
        filterCfg: getTextFilterCfg()
    }, {
        dataField: 'ter_iedalijums_wfs_all',
        text: 'Administratīvā teritorija',
        filterCfg: getTextFilterCfg()
    }, {
        dataField: 'kad_kadastra_apzimejumi',
        text: 'Kadastra apzīmējumi',
        width: 140,
        filterCfg: getTextFilterCfg()
    }, {
        dataField: 'adrese',
        text: 'Urbuma adrese',
        filterCfg: getTextFilterCfg()
    }, {
        dataField: 'urbsanas_dzilums',
        text: 'Dziļums',
        width: 140,
        filterCfg: getNumberFilterCfg()
    }, {
        dataField: 'urbsanas_gads',
        text: 'Urbšanas gads',
        width: 140,
        filterCfg: getNumberFilterCfg()
    }, {
          dataField: 'urbuma_uzdevuma_kods',
          text: 'Izmantošanas mērķis',
          width: 230,
          formatter: (kods, row) => kods !== '-1' ? `${kods} - ${row.urbuma_uzdevuma_nos}` : null,
          filterCfg: {
              type: 'autocomplete',
              autocompleteCfg: {
                  remoteSearchViewName: 'urbuma_uzdevums_search'
              }
          }
    }, {
          dataField: 'tehniskais_stavoklis',
          text: 'Urbuma stāvoklis',
          formatter: (cellContent) => {
            return getBadgeFormatterForKodif(kodif.urbumiTehniskaisStavoklis)(cellContent)
          },
          width: 120,
          filterCfg: getSelectFilterCfg(kodif.urbumiTehniskaisStavoklis)
    }, {
          dataField: 'pieder_atradnei',
          text: 'Pieder atradnei',
          width: 140,
          formatter: getBooleanFormatter(true),
          filterCfg: getBooleanSelectFilterCfg()
    }, {
          dataField: 'atradne_nosaukums',
          text: 'Atradne nosaukums',
          formatter: (cellContent, row) => {
              return cellContent ? cellContent : "Nav noteikts"
          },
          width: 140,
          filterCfg: {
            type: 'autocomplete',
            autocompleteCfg: {
              remoteSearchViewName: 'urbuma_atradne_nosaukums_search'
            }
          }
    }
]};


class UrbumiSaraksts extends Component {

    mapPaneSizeChange = (h) => {
        this.props.guiSet('urbumi_saraksts_karte_height', h);
    }

    rowEvents = {
        onDoubleClick: (e, row, rowIndex) => {
            this.props.urbumsDoubleSelected(row.id)
        }
    }

    render() {

        const {kodif, urbumi, gui, urbumsSelected} = this.props
        const {list, selected, loading, count} = urbumi

        const vectorLayerCfgs = {
            urbumi : {
                title: 'Urbumi',
                features: list,
                styles: maputils.urbumiStyles,
                tooltipFunc: (feat) => `${feat.get('nr_pirmavota') ? feat.get('nr_pirmavota') : ''} ${feat.get('id') ? 'DB numurs: ' + feat.get('id') : ''}`,
                selected: selected,
                legend: maputils.urbumiLegend
            }
        }

        if(!this.columns){
            this.columns = getColumns({
                kodif
            })
        }

        return (
            <SplitterLayout
                vertical
                percentage
                primaryIndex={1}
                secondaryInitialSize={gui.urbumi_saraksts_karte_height}
                onSecondaryPaneSizeChange={this.mapPaneSizeChange}>

                <IzraktenisMap mapId="urbumi_saraksts" vectorLayerCfgs={vectorLayerCfgs} />

                <Grid 
                    onRowSelected={urbumsSelected} 
                    loading={loading}    
                    selectedId={selected}
                    dataList={list}
                    totalCount={count}
                    columns={this.columns} 
                    rowEvents={this.rowEvents}
                >
                <BBoxFilterButtonToUrl/>
                </Grid>

            </SplitterLayout>
        )
    }
}

const mapStateToProps = ({kodif, urbumi, gui}) => {
    return {kodif, urbumi, gui}
}

export default connect(mapStateToProps, {urbumsSelected, guiSet, urbumsDoubleSelected})(UrbumiSaraksts)