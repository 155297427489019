import React, {Component} from 'react';
import {connect} from 'react-redux'
import Loading from '../Loading'
import GridWithForm from '../grid/GridWithForm'
import {klasifikatorsSelected, klasifikatorsActions, replaceKlasifikators} from '../../actions/kodifikatorsActions'
import {
    getTextFilterCfg, 
    getDateFilterCfg, 
    getBooleanFormatter, 
    getBooleanSelectFilterCfg,
    dateTimeFormatter,
    getBadgeFormatterForKodif
} from '../grid/ColumnHelpers'
import {getDefaultTypeaheadOptionsFromKodif, getDefaultTypeaheadOptionsFromKodifArray, 
  getDefaultRendererForMultiField, getDefaultRendererForMultiFieldHTML, getKodifRenderer, 
  kodifKeyToTypeaheadOption} from '../forms/FormHelpers'
import _ from 'lodash'
import {
  FormGroup,
  Label,
  Input
} from 'reactstrap'
import {addUrlQuery} from '../../actions/uiActions'
import ReplaceObjectsButton from '../forms/ReplaceObjectsButton'
import TypeaheadField from '../forms/TypeaheadField'

const getColumns = ({kodif}) => {
    return [
      {
          dataField: 'grupa_nosaukums',
          text: 'Grupa',
          filterCfg: getTextFilterCfg()
      }, {
          dataField: 'nosaukums_with_parent',
          text: 'Nosaukums',
          formatter: (currentValues, props) => (kodif.kodifGrupas[props.grupa_kods].ir_html ? <div dangerouslySetInnerHTML={{__html: currentValues }} /> : currentValues ),
          filterCfg: getTextFilterCfg()
      }, {
        dataField: 'sort_order',
        text: 'Secība',
        headerClasses: 'align-top',
        width: 70
      }, {
        dataField: 'ir_arhivets',
        text: 'Arhivēts',
        formatter: getBooleanFormatter(),
        filterCfg: getBooleanSelectFilterCfg(),
        width: 82
      }, {
          dataField: 'aktualizacijas_laiks',
          text: 'Labots',
          filterCfg: getDateFilterCfg(),
          formatter: dateTimeFormatter,
          width: 120,
      }
  ]};

const getHiddenFuncByKodifGrupa = (propName, kodif) => ({currentValues, dataObj, editable}) => {
  const objToCheck = editable ? currentValues : dataObj
  return !objToCheck.grupa_kods || kodif.kodifGrupas[objToCheck.grupa_kods][propName] !== true
}

const  getFormFieldCfgs = ({kodif}) => {
  return [
    {
      name: 'grupa_kods',
      label: 'Klasifikatora grupa',
      type: 'autocomplete',
      required: true,
      renderer: getKodifRenderer(kodif.kodifGrupas),
      autocompleteCfg: {
          options: getDefaultTypeaheadOptionsFromKodif(kodif.kodifGrupas),
      },
      extraFieldProps: ({dataObj}) => ({disabled : !!dataObj.id})
    },
    {
      name: 'parent_nosaukums',
      label: 'Pakārtots ierakstam',
      type: 'autocomplete',
      hiddenFunc: getHiddenFuncByKodifGrupa('ir_hierarhisks', kodif),
      overridePropsFunc: (props) => {
        const changedProps = {...props}
        const {currentValues} = props
        changedProps.autocompleteCfg = {
          options : currentValues.grupa_kods ? getDefaultTypeaheadOptionsFromKodif(_.pickBy(kodif[currentValues.grupa_kods], (v => !v.parent_nosaukums))) : []
        }
        return changedProps
      }
    },
    {
      name: 'nosaukums',
      label: 'Nosaukums',
      type: 'text',
      required: true,
      overridePropsFunc: (props) => {
        if(props.currentValues.grupa_kods && kodif.kodifGrupas[props.currentValues.grupa_kods].ir_html){
          return {...props, fieldType : "html"}
        }
        return props
      }
    },
    {
      name: 'vertiba',
      label: 'Vērtība',
      type: 'text',
    },
    {
      name: 'ir_arhivets',
      label: 'Ir arhivēts',
      type: 'checkbox',
    },
    {
      name: 'sort_order',
      label: 'Secība',
      type: 'number',
    },
    {
      name: 'piezimes',
      label: 'Piezīmes',
      type: 'textarea',
      renderClassName: "italic",
    },
    {
      name: 'frakcijas',
      label: 'Frakcijas',
      type: 'autocomplete',
      autocompleteCfg: {
          options: getDefaultTypeaheadOptionsFromKodifArray(kodif, 'FRAKCIJA'),
          multiple: true,
          keyField: 'nos',
      },
      renderer: getDefaultRendererForMultiField('nos'),
      hiddenFunc: getHiddenFuncByKodifGrupa('ir_frakcija', kodif),
    },
    {
      name: 'derigais',
      label: 'Saistītais derīgais izraktenis',
      type: 'autocomplete',
      autocompleteCfg: {
          options: getDefaultTypeaheadOptionsFromKodifArray(kodif, 'DIZRAKT')
      },
      hiddenFunc: getHiddenFuncByKodifGrupa('ir_derigais', kodif),
      validation: (values) => {
        if(values.grupa_kods && kodif.kodifGrupas[values.grupa_kods].ir_derigais === true && !values.derigais){
          return 'Saistītais derīgais izraktenis jāaizpilda obligāti'
        }
        return null
      },
    },
    {
      name: 'daudzuma_merv',
      label: 'Daudzuma mērvienības',
      type: 'autocomplete',
      autocompleteCfg: {
          options: getDefaultTypeaheadOptionsFromKodifArray(kodif, 'DAUDZ_MERV'),
          multiple: true,
          keyField: 'nos',
      },
      hiddenFunc: getHiddenFuncByKodifGrupa('ir_daudzuma_merv', kodif),
      validation: (values) => {
        if(values.grupa_kods && kodif.kodifGrupas[values.grupa_kods].ir_daudzuma_merv === true && (!values.daudzuma_merv || values.daudzuma_merv.length===0)){
          return 'Daudzuma mērvienība jāaizpilda obligāti'
        }
        return null
      },
      renderer: getDefaultRendererForMultiFieldHTML('nos'),
    },
    {
      name: 'lic_tipi',
      label: 'Licenču / atļauju tipi, ko šī iestāde var izsniegt',
      type: 'autocomplete',
      autocompleteCfg: {
          options: getDefaultTypeaheadOptionsFromKodifArray(kodif, 'LIC_TIPI'),
          multiple: true,
          keyField: 'nos',
      },
      hiddenFunc: getHiddenFuncByKodifGrupa('ir_lic_tipi', kodif),
      validation: (values) => {
        if(values.grupa_kods && kodif.kodifGrupas[values.grupa_kods].ir_lic_tipi === true && (!values.lic_tipi || values.lic_tipi.length===0)){
          return 'Licences / atļaujas tips jāaizpilda obligāti'
        }
        return null
      },
      renderer: getDefaultRendererForMultiFieldHTML('nos'),
    },
    {
      name: 'ir_izmaina_komersantiem',
      label: 'Izmaiņas veids komersantiem',
      type: 'checkbox',
      hiddenFunc: getHiddenFuncByKodifGrupa('ir_izmainu_aprekins', kodif),
      fieldNotes: 'Šo izmaiņas veidu var norādīt komersants, ievadot krājumu izmaiņas.'
   },    
   {
    name: 'ir_limitiem',
    label: 'Limita apjoma izmaiņas veids',
    type: 'checkbox',
    hiddenFunc: getHiddenFuncByKodifGrupa('ir_izmainu_aprekins', kodif),
    fieldNotes: 'Šo izmaiņas veidu var norādīt, koriģējot esošu limita apjomu.'
   },
    {
      name: 'izr_veids',
      label: 'Veida klasifikators',
      type: 'autocomplete',
      renderer: getKodifRenderer(kodif.kodifGrupas),
      autocompleteCfg: {
          options: getDefaultTypeaheadOptionsFromKodif(kodif.kodifGrupas),
      },
      hiddenFunc: getHiddenFuncByKodifGrupa('ir_izr_veids', kodif),
    }, 
    {
      name: 'ir_protokols',
      label: 'Protokola veids (formā tiks rādīti lauki - protokola Nr. un datums)',
      type: 'checkbox',
      hiddenFunc: getHiddenFuncByKodifGrupa('ir_dok_veids', kodif),
    },
    {
      name: 'saist_teksts',
      label: 'Noklusētais dokumenta nosaukums',
      type: 'text',
      hiddenFunc: getHiddenFuncByKodifGrupa('ir_dok_veids', kodif),
    },
    {
      name: 'veids',
      label: 'Nepieciešamais atradnes veids',
      type: 'radio',
      renderer: getBadgeFormatterForKodif(kodif.atradnesVeids),
      options: _.omit(kodif.atradnesVeids, ['U']),
      hiddenFunc: getHiddenFuncByKodifGrupa('ir_atradnes_veids', kodif),
    },
    {
      name: 'lnb_id',
      label: 'LNB identifikators',
      hiddenFunc: ({currentValues}) => {return !['PAUTORS', 'PTERITORIJA', 'PORGANIZACIJA'].includes(currentValues.grupa_kods)},
      editable: true,
    }
  ]};

class Klasifikatori extends Component {

    constructor(props) {
      super(props)
      this.state = {
        replaceWith: null,
        replaceWithModal: false
      }
    }

    handleReplace = () => {
      this.props.replaceKlasifikators( this.props.kodifikatori.selectedItem.id, this.state.replaceWith, this.props.kodifikatori.selectedItem.grupa_kods)
      this.setState({
        replaceWithModal: false
      })
    }

    setReplaceWith = (field, value) => {
      this.setState({
        replaceWith: value
      })
    }

    toggleReplaceWithModal = () => {
      this.setState({
        replaceWithModal: !this.state.replaceWithModal
      })
    }

    getCurrentQueryGrupas = () => {
      const {query} = this.props
      const ef = query && query.ef ? JSON.parse(query.ef) : {}
      return ef.grupas ? ef.grupas.split(',') : []
    }

    extraGridProps = {
        rowClasses: (row, rowIndex) => {
            if(row && row.ir_arhivets === true){
                return 'row-archived';
            }
        }  
    }

    changeGrupa = (e) => {
      if(e.target && e.target.value){
        const currentGrupas = this.getCurrentQueryGrupas()
        const newGrupas = e.target.checked ? [...currentGrupas, e.target.value] : currentGrupas.filter(g => g!==e.target.value)
        const grupas = newGrupas.length > 0 ? newGrupas.join(',') : undefined
        let ef = JSON.stringify({grupas})
        ef = ef === '{}' ? undefined : ef
        this.props.addUrlQuery({ef})
      }
    }

    render() {
        const {kodif, kodifikatori, user} = this.props
        const {kodifGrupas} = kodif
        if(!kodifGrupas) {
            return (<Loading/>)
        }
        
        if(!this.columns){
            this.columns = getColumns({
                kodif
            })
        }

        if(!this.formFieldCfgs){
            this.formFieldCfgs = getFormFieldCfgs({
                kodif
            })
        }

        // devide in 4 columns
        const kodifKeys = Object.keys(kodifGrupas)
        const grupasCnt = kodifKeys.length
        const colsCnt = 6
        const cols = Array.from(Array(colsCnt).keys())
        const rowsCnt = Math.ceil(grupasCnt / colsCnt)
        const rows = Array.from(Array(rowsCnt).keys())
        const currentGrupas = this.getCurrentQueryGrupas()

        let replaceKlasifikatori = null
        if(kodifikatori.selectedItem && !kodifikatori.editable && user.tiesibas.includes('LABOT_KLASIFIKATORUS')){
          //for hierarhic klasifikator allow only parent to be replaced with another parent
          let currentKodif = kodif[kodifikatori.selectedItem.grupa_kods]
          let currentKodifKeys = Object.keys(currentKodif)
          if(kodif.kodifGrupas[kodifikatori.selectedItem.grupa_kods].ir_hierarhisks && !kodifikatori.selectedItem.parent_nosaukums){
            currentKodifKeys = currentKodifKeys.filter(k => !currentKodif[k].parent_nosaukums)
          }
          replaceKlasifikatori = <ReplaceObjectsButton 
            buttonText="Aizvietot klasifikatoru"
            handleReplace={this.handleReplace}
            toggleModal={this.toggleReplaceWithModal} 
            modal={this.state.replaceWithModal}
            enabledSave={!!this.state.replaceWith}> 
            <>
            <Label>Aizvietot ar šo klasifikatora ierakstu:</Label>
            <TypeaheadField 
                  fieldName="klasifikators_replace_with"
                  currentVal={this.state.replaceWith}
                  autocompleteCfg={{
                    options: currentKodifKeys.map(kodifKeyToTypeaheadOption(currentKodif)).filter(opt => opt.tah_id !== this.props.kodifikatori.selectedItem.nosaukums_with_parent)
                  }}
                  setFieldValue={this.setReplaceWith}
            />
            <div className="notes">Šeit izvēlētais klasifikatora ieraksts parādīsies datu bāzē visās vietās, kur tiek izmantots aizvietojamais klasifikators. Pēc aizvietošanas aizvietojamais klasifikators tiks automātiski dzēsts.</div>
            </>
          </ReplaceObjectsButton> 
        }

        return (
          <div style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
          <table><tbody>
          { 
            rows.map(r => 
              <tr key={r}>
              {
                cols.map(c => {
                  const ind = c * rowsCnt + r
                  if(grupasCnt > ind){
                    const grupaKey = kodifKeys[ind]
                    return <td key={c}>
                      <FormGroup check inline>
                        <Label check>
                          <Input
                              type="checkbox"
                              checked={currentGrupas.indexOf(grupaKey)>=0}
                              onChange={this.changeGrupa}
                              value={grupaKey}
                          />
                          {kodifGrupas[grupaKey].nos}
                        </Label>
                      </FormGroup>
                    </td>
                  } else {
                    return null
                  }
                })
              }
              </tr>
            )
          }
          </tbody></table>

          <GridWithForm
            {...this.props}
            columns={this.columns}
            formFieldCfgs={this.formFieldCfgs}
            storeObj={kodifikatori}
            formWidthGuiOption="klasifikatori_form_width"
            extraGridProps={this.extraGridProps}
            tiesibasLabot="LABOT_KLASIFIKATORUS"
            extraElementsFormButtons={replaceKlasifikatori}
          />
         </div>
       )

    }

}

const mapStateToProps = ({kodifikatori, kodif, location, user}) => ({kodifikatori, kodif, query: location.query, user})

export default connect(mapStateToProps, {
    selectedAct : klasifikatorsSelected, 
    addUrlQuery,
    ...klasifikatorsActions,
    replaceKlasifikators
  })(Klasifikatori)