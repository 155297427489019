import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
    getDateFilterCfg, getTextFilterCfg, getEditIconColumn, dateFormatter, getNumberFilterCfg
} from '../grid/ColumnHelpers'
import SplitterLayout from 'react-splitter-layout';
import Grid from '../grid/Grid'
import {loadParaugi} from "../../actions/hidrogeoActions";

const editIconColumn = getEditIconColumn((row) => (
        {
            type: 'loc_urbuma_paraugs',
            payload: {
                id: row.id,
                numurs: row.urbumi_id
            }
        }
    )
)

const getColumns = () => {
    return [editIconColumn,
        {
            dataField: 'analizes_datums',
            text: 'Analīzes datums',
            formatter: dateFormatter,
            filterCfg: getDateFilterCfg()
        },{
            dataField: 'parauga_numurs',
            text: 'Parauga numurs',
            filterCfg: getTextFilterCfg()
        },{
            dataField: 'horizont_geol_indekss',
            text: 'Horizonta kods',
            filterCfg: getTextFilterCfg()
        },{
            dataField: 'avots',
            text: 'Avots',
            filterCfg: getTextFilterCfg()
        }, {
            dataField: 'vertikale',
            text: 'Noņemšanas intervāls no',
            filterCfg: getNumberFilterCfg()
        }, {
            dataField: 'horizontale',
            text: 'Noņemšanas intervāls līdz',
            filterCfg: getNumberFilterCfg()
        }
    ]};


class UrbumaParaugi extends Component {

    render() {

        const {paraugi} = this.props
        const {list, selected, loading, count} = paraugi


        if(!this.columns){
            this.columns = getColumns()
        }

        return (
            <SplitterLayout
                vertical
                percentage
                primaryIndex={1}
                secondaryInitialSize={10}
            >
                <Grid
                    loading={loading}
                    selectedId={selected}
                    dataList={list}
                    totalCount={count}
                    columns={this.columns}
                    rowEvents={this.rowEvents}
                >
                </Grid>
            </SplitterLayout>
        )
    }
}

const mapStateToProps = ({paraugi, location}) => {
    return {paraugi, query: location.query, payload: location.payload}
}

export default connect(mapStateToProps, {loadParaugi})(UrbumaParaugi)