import React, {Component} from 'react';
import {connect} from 'react-redux'
import Loading from '../Loading'
import Form from '../forms/Form'
import {pazemesUdensObjektsKimActions} from '../../actions/pazemesUdensObjektiActions'
import FormCard from '../forms/FormCard'
import {
    Row,
} from 'reactstrap'
import {getFormatterForKodif, getBooleanFormatter} from "../grid/ColumnHelpers";
import {getKlasifikatorsFieldCfg} from "../forms/FormHelpers";

const getFormFieldWrapperCfgs = () => {
    return [
        {
            id: 'kvalitate_wrap',
            comp: FormCard,
            compProps: {
                title: 'Ķīmiskā stāvokļa novērtēšanas testi',
                pinnable: false,
                size: 12
            }
        },
        {
            id: 'raksturojosie_parametri_wrap',
            comp: FormCard,
            compProps: {
                title: 'Pazemes ūdensobjekta raksturojošie parametri',
                pinnable: false,
                collapse: true,
                size: 12
            }
        },
        {
            id: 'sledziens_wrap',
            comp: FormCard,
            compProps: {
                title: 'Gala slēdziens',
                pinnable: true,
                size: 12
            }
        }
    ]
}

const getFormFieldCfgs = ({kodif, user}) => () => {
    const kvalTesti = [
        {
            name: 'vispareja_kvalitate',
        },
        {
            name: 'kim_juras_udenu_intruzija'
        },
        {
            name: 'sauszemes_ekosistemas'
        },
        {
            name: 'saldudenu_ekosistemas'
        },
        {
            name: 'dzerama_udens_aizsarg_teritorijas',
        }
    ].map(obj => ({
        ...obj,
        ...getKlasifikatorsFieldCfg(kodif, 'pazemesUdensObjektaTesti'),
        renderer: getFormatterForKodif(kodif.pazemesUdensObjektaTesti),
        fieldWrapper: 'kvalitate_wrap',
    }))

    const raksturojosieParametri =
        {
            label: '',
            sizeLabel: 0,
            sizeForm: 12,
            name: 'raksturojosie_parametri',
            fieldWrapper: 'raksturojosie_parametri_wrap',
            createNewRow: () => ({ir_stavokla_novertejuma: false}),
            multiFieldCfgs: [{
                name: 'tah_id',
                label: 'Parametrs',
                type: 'autocomplete',
                required: true,
                autocompleteCfg: {
                    labelFieldName: 'tah_label',
                    remoteSearchViewName: 'pazemes_udens_objekts_kim_raditajs_search'
                },
                renderer: (fieldValue, dataObj) => dataObj.tah_label
            }, {
                name: 'fona_vertiba',
                renderer: (fieldValue) => fieldValue === null ? '-' : fieldValue
            }, {
                name: 'robezvertiba',
                renderer: (fieldValue) => fieldValue === null ? '-' : fieldValue
            },
             {
                name: 'ir_stavokla_novertejuma',
                renderer: getBooleanFormatter(false)
            },
            // {
            //     name: 'mervieniba'
            // },
                {
                    name: 'piezimes'
                }
            ]
        }

    const galaSledziens = [{
        ...getKlasifikatorsFieldCfg(kodif, 'pazemesUdensObjektaNovertejums'),
        renderer: getFormatterForKodif(kodif.pazemesUdensObjektaNovertejums),
        name: 'kimiskais_stavoklis',
        fieldWrapper: 'sledziens_wrap'
    }, {
        name: 'kimiskais_stavoklis_piezimes',
        fieldWrapper: 'sledziens_wrap',
        type: 'textarea'
    }]

    return [
        ...kvalTesti,
        raksturojosieParametri,
        ...galaSledziens
    ]
}

class KimiskaComp extends Component {


    render() {

        const {pazemesUdensObjektsKim, user, getFormFieldCfgsFunc, kodif,
            getFormFieldWrapperCfgsFunc, metadata} = this.props

        const {selectedItem, editable, submiting} = pazemesUdensObjektsKim

        // loading while we have no virszemesUdensObjekts
        if (!selectedItem) {
            return (<Loading/>)
        }

        // we cache form field cfgs
        // reload form fields if pazemesUdensObjekts changes
        if(!this.formFieldCfgs || pazemesUdensObjektsKim.id !== this.prevUoId){
            const f = getFormFieldCfgsFunc ? getFormFieldCfgsFunc : getFormFieldCfgs({ kodif, metadata })
            this.formFieldCfgs = f({
                kodif,
                user,
                metadata
            })
        }

        if(!this.formFieldWrapperCfgs){
            const f = getFormFieldWrapperCfgsFunc ? getFormFieldWrapperCfgsFunc : getFormFieldWrapperCfgs
            this.formFieldWrapperCfgs = f()
        }

        return <div className="form-container">
            <Form
                sizeLabel={2}
                editable={editable}
                submiting={submiting}
                dataObj={selectedItem}
                formFieldCfgs={this.formFieldCfgs}
                formFieldWrapperCfgs={this.formFieldWrapperCfgs}
                onSubmit={this.props.saveAct}
                onReset={this.props.stopEditAct}
                onStartEdit={this.props.startEditAct}
                formWrapperComp={Row}
                metadataFormCfg="pazemes_udens_objekts_kvalitate_kimiska"
                tiesibasLabot="BAIS_PU_LABOT"
            />
        </div>
    }
}

const mapStateToProps = ({pazemesUdensObjektsKim, kodif, user, metadata}) => ({pazemesUdensObjektsKim, kodif, user, metadata})
const PazemesKimiska =  connect(mapStateToProps, { ...pazemesUdensObjektsKimActions })(KimiskaComp)
export default PazemesKimiska