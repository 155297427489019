import { createStore, applyMiddleware, combineReducers, compose } from 'redux'
import thunk from 'redux-thunk'
import { createLogger } from 'redux-logger'
import { connectRoutes } from 'redux-first-router'
import queryString from 'query-string'
import { createBrowserHistory } from 'history'

import { routesMap, options } from './routes'
import * as lietotajsReducers from './reducers/lietotajsReducers'
import * as uiReducers from './reducers/uiReducers'
import * as atradnesReducers from './reducers/atradnesReducers'
import * as kodifReducers from './reducers/kodifikatorsReducers'
import * as uploadSizeLimitReducers from './reducers/uploadSizeLimitReducers'
import * as uzzinasReducers from './reducers/uzzinasReducers'
import * as datnu_apmaina from  './reducers/apmainaReducers'
import * as dokumentacijaReducers from './reducers/dokumentacijaReducers'
import * as pielikumiReducers from './reducers/pielikumiReducers'
import * as krajumsReducers from './reducers/krajumsReducers'
import * as izmainasReducers from './reducers/izmainasReducers'
import * as auditReducers from './reducers/auditReducers'
import * as pazinojumiReducers from './reducers/pazinojumiReducers'
import * as arcgisReducer from './reducers/arcgisReducer'
import * as virszemesUdensObjektiReducers from './reducers/virszemesUdensObjektiReducers'
import * as pazemesUdensObjektiReducers from './reducers/pazemesUdensObjektiReducers'
import * as upjuBaseinuApgabaliReducers from './reducers/upjuBaseinuApgabaliReducers'
import * as adresatsReducer from './reducers/adresatsReducers'
import * as pasakumiReducers from './reducers/pasakumiReducers'
import * as raditajiReducers from './reducers/raditajiReducers'
import * as hidrogeoReducers from './reducers/hidrogeoReducers'
import * as baisImportReducers from './reducers/importsReducers'
import * as geofondImportsReducers from "./reducers/geofondImportsReducers";
import * as skersprofiliReducers from './reducers/skersprofiliReducers'
import * as notekudeniReducers from './reducers/udens2Reducers'
import * as wiseReducers from './reducers/wiseReducers'
import * as parisReducers from './reducers/parisReducers'
import * as parskatsReducers from './reducers/geofondReducers'
import * as nobidesReducers from './reducers/nobidesReducers'
import * as stacijasReducers from './reducers/stacijasReducers'
import * as vvisReducers from './reducers/vvisReducers'
import * as vnkReducers from './reducers/vnkReducers'
import * as troksniReducers from './reducers/troksniReducers'

import { startWeb } from './actions/uiActions'

import mapMiddleware from './mapMiddleware'


const {
  reducer: location,
  middleware: locationMiddleware,
  enhancer: locationEnhancer,
  initialDispatch,
} = connectRoutes(routesMap, {
  querySerializer: queryString,
  createHistory: createBrowserHistory,
  ...options
})

const allReducers = {
  ...lietotajsReducers,
  ...uiReducers,
  ...atradnesReducers,
  ...kodifReducers,
  ...uploadSizeLimitReducers,
  ...uzzinasReducers,
  ...datnu_apmaina,
  ...dokumentacijaReducers,
  ...pielikumiReducers,
  ...krajumsReducers,
  ...izmainasReducers,
  ...auditReducers,
  ...pazinojumiReducers,
  ...arcgisReducer,
  ...adresatsReducer,
  ...virszemesUdensObjektiReducers,
  ...pazemesUdensObjektiReducers,
  ...upjuBaseinuApgabaliReducers,
  ...pasakumiReducers,
  ...raditajiReducers,
  ...hidrogeoReducers,
  ...baisImportReducers,
  ...skersprofiliReducers,
  ...notekudeniReducers,
  ...wiseReducers,
  ...parisReducers,
  ...parskatsReducers,
  ...nobidesReducers,
  ...stacijasReducers,
  ...vvisReducers,
  ...vnkReducers,
  ...troksniReducers,
  ...geofondImportsReducers,
  location
}

const rootReducer = combineReducers(allReducers)

const middlewares = [thunk, locationMiddleware, mapMiddleware]
if (process.env.NODE_ENV !== 'production' && !process.env.REACT_APP_NO_LOGGER) {
  middlewares.push(createLogger())
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(
  rootReducer,
  composeEnhancers(locationEnhancer, applyMiddleware(...middlewares)),
)

// Load initial state
store.dispatch(startWeb(initialDispatch))

export default store
