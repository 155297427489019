import React, {Component} from 'react';
import {connect} from 'react-redux'
import {guiSet} from '../../actions/uiActions'
import Grid from '../grid/Grid'
import SplitterLayout from 'react-splitter-layout';
import {
    lietotajsSelected,
    lietotajsActions
} from '../../actions/lietotajsActions'
import {
    getSelectFilterCfg, 
    getTextFilterCfg, 
    getDateFilterCfg, 
    getBooleanFormatter, 
    getBooleanSelectFilterCfg,
    dateTimeFormatter,
    getBadgeFormatterForKodif,
} from '../grid/ColumnHelpers'
import Form from '../forms/Form'

const lomaRendererShort = (fieldValue) => fieldValue.map(l => l.loma).join(', ')
const lomaRenderer = (fieldValue) => fieldValue.map(l => <p className="no-margin">{l.tah_label}</p>)

const getColumns = ({kodif}) => {
    return [
    {
        dataField: 'lietotajvards',
        text: 'Lietotājvārds',
        filterCfg: getTextFilterCfg()
    }, {
        dataField: 'avots',
        text: 'Avots',
        width: 90,
        filterCfg: getSelectFilterCfg(kodif.lietotajiAvots),
        formatter: getBadgeFormatterForKodif(kodif.lietotajiAvots),
    }, {
        dataField: 'vards',
        text: 'Vārds',
        filterCfg: getTextFilterCfg()
    }, {
        dataField: 'uzvards',
        text: 'Uzvārds',
        filterCfg: getTextFilterCfg()
    }, {
          dataField: 'epasts',
          text: 'E-pasts',
          filterCfg: getTextFilterCfg(),
          width: 200
      }, {
        dataField: 'personas_kods',
        text: 'Personas kods',
        filterCfg: getTextFilterCfg(),
        width: 140
    }, {
            dataField: 'lomas',
            text: 'Lomas',
            filterCfg: {
                type: 'autocomplete',
                autocompleteCfg: {
                    initialOptionsViewName: 'lomas_list',
                    keyField: 'loma',
                    extraFilterParams: {},
                }
            },
            formatter: lomaRendererShort,
            sort: false,
        },  
      {
          dataField: 'ir_noblokets',
          text: 'Nobloķēts',
          formatter: getBooleanFormatter(),
          filterCfg: getBooleanSelectFilterCfg(),
          width: 70
      }, {
          dataField: 'piesledzies_laiks',
          text: 'Pēdējo reizi pieslēdzies',
          filterCfg: getDateFilterCfg(),
          formatter: dateTimeFormatter,
          width: 200,
      }
  ]};

const  getFormFieldCfgs = ({kodif}) => {
    return [
      {
         name: 'lietotajvards',
         label: 'Lietotājvārds',
         renderClassName: "bold",
         editable: false
      },
      { 
          name: 'avots',
          label: 'Avots',
          renderer: getBadgeFormatterForKodif(kodif.lietotajiAvots),
          editable: false
      },
      {
        name: 'vards',
        label: 'Vārds',
        editable: false
     },
     {
        name: 'uzvards',
        label: 'Uzvārds',
        editable: false
     },
     {
        name: 'epasts',
        label: 'E-pasts',
        editable: false
     },
     {
        name: 'personas_kods',
        label: 'Personas kods',
        editable: false
     },
     {
        name: 'talrunis',
        label: 'Tālrunis',
        editable: false
     },
     {
        name: 'adrese',
        label: 'Adrese',
        editable: false
     },
     {
        name: 'lomas',
        label: 'Lomas',
        type: 'autocomplete',
        required: true,
        autocompleteCfg: {
            initialOptionsViewName: 'lomas_list',
            multiple: true,
            keyField: 'loma',
            extraFilterParams: {},
        },
        renderer: lomaRenderer
    },
      {
        name: 'ir_noblokets',
        label: 'Ir nobloķēts',
        type: 'checkbox',
      }
  ]};
  

class Lietotaji extends Component {

    formSizeChange = (w) => {
        this.props.guiSet('lietotaji_form_width', w);
    }

    rowEvents = {
        onDoubleClick: (e, row, rowIndex) => {
          this.props.lietotajsEdit()
        }
    }

    extraGridProps = {
        rowClasses: (row, rowIndex) => {
            if(row && row.ir_noblokets === true){
                return 'row-warning';
            }
        }  
    }

    render() {
        
        if(!this.columns){
            this.columns = getColumns({
                kodif: this.props.kodif
            })
        }

        if(!this.formFieldCfgs){
            this.formFieldCfgs = getFormFieldCfgs({
                kodif: this.props.kodif
            })
        }

        const {editable, submiting, selectedItem, loading, list, count} = this.props.lietotaji
        return (
            <SplitterLayout
                customClassName="pane-paddings-hor"
                percentage
                primaryIndex={0}
                secondaryInitialSize={this.props.gui.lietotaji_form_width}
                onSecondaryPaneSizeChange={this.formSizeChange}>

                <Grid 
                    onRowSelected={this.props.lietotajsSelected} 
                    loading={loading}    
                    dataList={list}
                    totalCount={count}
                    columns={this.columns} 
                    rowEvents={this.rowEvents}
                    extraGridProps={this.extraGridProps}
                />

                <div className="form-wrapper-pane">
                    { selectedItem ? (
                        <>
                        <Form 
                            editable={editable}
                            submiting={submiting}
                            dataObj={selectedItem}
                            formFieldCfgs={this.formFieldCfgs}
                            onSubmit={this.props.lietotajsSave}
                            onReset={this.props.lietotajsStopEdit}
                            onStartEdit={this.props.lietotajsEdit}
                            tiesibasLabot="LABOT_LIETOTAJUS"
                        />
                        <div>
                        Lai labotu, izvēlieties lietotāju sarakstā
                        </div>
                        </>
                    ) : 
                    <div>
                        Lai labotu, izvēlieties lietotāju sarakstā
                    </div>
                    }
                </div>

        </SplitterLayout>
        )
    }

}

const mapStateToProps = ({lietotaji, kodif, gui}) => ({lietotaji, kodif, gui})

export default connect(mapStateToProps, {
    guiSet, 
    lietotajsSelected,
    lietotajsEdit: lietotajsActions.startEditAct,
    lietotajsStopEdit: lietotajsActions.stopEditAct,
    lietotajsSave: lietotajsActions.saveAct
    })(Lietotaji)
