export const vvisKodifs = (state = {
    requested: false,
    loaded: false,
}, {type, payload}) => {
    switch (type) {
        case 'vvis_klasifikatori_list_request':
            return {...state, requested: true}
        case 'vvis_klasifikatori_list_success':
            const kodifs = payload.reduce((byKf, i) => {
                byKf[i.grupa] = byKf[i.grupa] || {};
                byKf[i.grupa][i.nos] = i
                return byKf;
            }, {});
            return {...state, ...kodifs, kodifGrupas: {}, loaded: true}
        default:
            return state
    }
}
