import {
  callApi,
  getDefaultEditActions,
} from './helpers'

export const loadBaisVirszemesImports = (query) => (dispatch) => {
  return dispatch(callApi({
    viewName: 'bais_virszemes_imports',
    crudMethod: 'listPaging',
    crudParam: query
  }))
}

export const loadBaisPazemesImports = (query) => (dispatch) => {
  return dispatch(callApi({
    viewName: 'bais_pazemes_imports',
    crudMethod: 'listPaging',
    crudParam: query
  }))
}

export const baisImportsSelected = (id) => {
  return {
    type: 'bais_virszemes_imports_selected',
    payload: id
  }
}

export const baisPazemesImportsSelected = (id) => {
  return {
    type: 'bais_pazemes_imports_selected',
    payload: id
  }
}

const afterSaveOrDelete = (dispatch, getState) => {
  dispatch(loadBaisVirszemesImports(getState().location.query))
}

const afterSaveOrDeletePazemes = (dispatch, getState) => {
  dispatch(loadBaisPazemesImports(getState().location.query))
}

export const baisVirszemesImportActions = getDefaultEditActions({
  actionTypeName: 'bais_virszemes_imports',
  stateName: 'bais_virszemes_imports',
  combinedPage: true,
  apiViewName: 'bais_virszemes_imports',
  afterDelete: afterSaveOrDelete,
  afterSave: afterSaveOrDelete,
})

export const baisPazemesImportActions = getDefaultEditActions({
  actionTypeName: 'bais_pazemes_imports',
  stateName: 'bais_pazemes_imports',
  combinedPage: true,
  apiViewName: 'bais_pazemes_imports',
  afterDelete: afterSaveOrDeletePazemes,
  afterSave: afterSaveOrDeletePazemes,
})

export const loadHidrogeoImports = (query) => (dispatch) => {
  return dispatch(callApi({
    viewName: 'hidrogeo_imports',
    crudMethod: 'listPaging',
    crudParam: query
  }))
}

export const hidrogeoImportsSelected = (id) => {
  return {
    type: 'hidrogeo_imports_selected',
    payload: id
  }
}

const afterHidrogeoSaveOrDelete = (dispatch, getState) => {
  dispatch(loadHidrogeoImports(getState().location.query))
}

export const hidrogeoImportActions = getDefaultEditActions({
  actionTypeName: 'hidrogeo_imports',
  stateName: 'hidrogeo_imports',
  combinedPage: true,
  apiViewName: 'hidrogeo_imports',
  afterDelete: afterHidrogeoSaveOrDelete,
  afterSave: afterHidrogeoSaveOrDelete,
})