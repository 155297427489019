import * as helpers from './helpers'
import * as maputils from "../components/karte/maputils";
import Point from "ol/geom/Point";

const defaultListReducerForVnkIesniegumi = helpers.createListReducerWithMapClicking({
    listViewName: 'videi_nodaritie_kaitejumi_list',
    actionsName: 'vnk_iesniegums',
    mapIdName: 'vnk_iesniegumi_saraksts',
    layerIdName: 'vnkIesniegumi'
})
export const vnkIesniegumi = (state = helpers.listGetInitialState(), {type, payload, reqId, mapId, layerId}) => {
    return defaultListReducerForVnkIesniegumi({state, type, payload, reqId, mapId, layerId})
}

const defaultEditReducerForVnkIesniegums = helpers.createEditReducerWithGeometryEditing({
    editViewName: 'videi_nodaritie_kaitejumi_view',
    actionsName: 'vnk_iesniegums',
    geomType: 'point',
    mapIdName: 'vnk_iesniegums_kartina',
    layerIdName: 'vnkIesniegumi'
})
export const vnkIesniegums = (state = helpers.editWithGeometryInitialState(),{type, payload, mapId, layerId, featureGeom}) => {
    switch (type) {
        case 'vnk_iesniegums_clear':
            return helpers.editWithGeometryInitialState()
        case 'vnk_iesniegums_koord_changed':
            const { selectedItem } = state
            const newKoords = [{
                id: payload.id,
                x: Number.parseFloat(payload.x),
                y: Number.parseFloat(payload.y)
            }]
            const newGeom = new Point(maputils.objectToCoords(newKoords).flat())
            return {
                ...state,
                koords: maputils.coordsToObject(newGeom),
                selectedItem: {
                    ...selectedItem,
                    geom: maputils.GeoJSONFormat.writeGeometry(newGeom),
                }
            }
        default:
            return defaultEditReducerForVnkIesniegums({state, type, payload, mapId, layerId, featureGeom})
    }
}