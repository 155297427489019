import {
    callApi,
    getDefaultEditActions,
} from './helpers'

export const loadGeofondImports = (query) => (dispatch) => {
    return dispatch(callApi({
        viewName: 'geofond_imports',
        crudMethod: 'listPaging',
        crudParam: query
    }))
}

export const geofondImportsSelected = (id) => {
    return {
        type: 'geofond_imports_selected',
        payload: id
    }
}

const afterGeofondImportsSaveOrDelete = (dispatch, getState) => {
    dispatch(loadGeofondImports(getState().location.query))
}

export const geofondImportActions = getDefaultEditActions({
    actionTypeName: 'geofond_imports',
    stateName: 'geofond_imports',
    combinedPage: true,
    apiViewName: 'geofond_imports',
    afterDelete: afterGeofondImportsSaveOrDelete,
    afterSave: afterGeofondImportsSaveOrDelete,
})