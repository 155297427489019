import React, {Component} from 'react'
import {connect} from 'react-redux'
import Link from 'redux-first-router-link'
import {Breadcrumb as RSBreadcrumb, BreadcrumbItem} from 'reactstrap';
import {NOT_FOUND, pathToAction} from 'redux-first-router'
import {lastQueryForEachAction} from '../../routes'

const getPaths = (pathname) => {
    var paths = ['/'];

    if (pathname === '/')
        return paths;

    pathname
        .split('/')
        .reduce(function (prev, curr) {
            var currPath = prev + '/' + curr;
            paths.push(currPath);
            return currPath;
        });
    return paths;
};

const crumbs = (actions) => (
    <RSBreadcrumb className="breadcrumbs">
        {actions.map((o, key) => (
            <BreadcrumbItem key={key}>
                <Link to={o}>{decodeURIComponent(o.name)}</Link>
            </BreadcrumbItem>
        ))}
    </RSBreadcrumb>
)

class Breadcrumb extends Component {

    constructor(props) {
        super(props)
        this.state = {actions:[]}
    }

    fetchNameAndUpdateState = (type, fetchName, payload) => {
        fetchName(payload).then(name => {
            this.setState({
                actions: this.state.actions.map(a => a.type === type ?  {...a, name} : a)
            });
        }).catch((error) => {
            //let stay "..." for name when error happens
            console.log(error)
        })
    }

    updateActionsFromLocation = () => {
        const {type, routesMap, pathname, payload} = this.props.location
        if(type === NOT_FOUND){
            this.setState({actions: []})
        } else {
            // update state from props
            const paths = getPaths(pathname);
            const actions = paths
            .map(p => pathToAction(p, routesMap))
            .filter(a => a.type !== NOT_FOUND)
            .map(a => {
                let {name, fetchName, skipTitle, alwaysInTitle} = routesMap[a.type]
                if(fetchName){
                    this.fetchNameAndUpdateState(a.type, fetchName, payload)
                    name = '...'
                } else if(payload){
                    Object.keys(payload).forEach( k=> {
                        name = name.replace(`:${k}`, payload[k])
                    })
                }
                return {...a, name, query: lastQueryForEachAction[a.type], skipTitle, alwaysInTitle}
            })
            this.setState({actions})
        }
    }

    componentDidMount() {
        this.updateActionsFromLocation()
    }

    componentDidUpdate(prevProps) {
        const {pathname} = this.props.location
        //update only if pathname changes
        if(prevProps.location.pathname !== pathname){
            // update state from props
            this.updateActionsFromLocation()
        }
    }

    updatePageTitle = () => {
        let title = ''
        //for default we take only last part in url
        if(this.state.actions.length > 0){
            const lastItem = this.state.actions[this.state.actions.length - 1]
            if(lastItem.skipTitle !==true){
                title = lastItem.name
            }
            //check for other parts to contain alwaysInTitle prop
            title = this.state.actions.reduce((t, a) => {
                if(a.alwaysInTitle === true && a.name && a !== lastItem) {
                    return `${t} ${a.name}`
                } else {
                    return t
                }
            }, title)
        }
        if(!title){
            title = 'Zemes Dzīļu IS'
        }
        document.title = title
    }

    render() {
        this.updatePageTitle()
        return crumbs(this.state.actions)
    }
}

const mapState = ({location}) => ({location})

export default connect(mapState)(Breadcrumb)
