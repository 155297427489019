import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
    getSelectFilterCfg,
    getTextFilterCfg,
    // getDateFilterCfg,
    // dateTimeFormatter,
    // dateFormatter,
    getNumberFilterCfg,
    getEditIconColumn,
    getBadgeFormatterForKodif,
    //getFormatterForKodif
} from '../grid/ColumnHelpers';
import {getDefaultRendererForMultiField} from '../forms/FormHelpers';
import Link from 'redux-first-router-link';
import { Button } from 'reactstrap';
import ArchivedParskatsFilterToUrl from '../grid/ArchivedParskatsFilter';
import {parskatsSelected, openParskatsWithFilter} from '../../actions/geofondActions';

import Grid from '../grid/Grid'

import IzraktenisMap from '../karte/IzraktenisMap';
import BBoxFilterButtonToUrl from '../grid/BBoxFilterButtonToUrl'
import * as maputils from '../karte/maputils'
import {guiSet, showErrorMsg} from '../../actions/uiActions'
import SplitterLayout from 'react-splitter-layout';
// import { atradne } from '../../reducers/atradnesReducers';
// import { getVectorLayerCfgs } from '../dokumentacija/common';

const editIconColumn = getEditIconColumn()
editIconColumn.formatter = (cellContent, row) => {
  return (
      <Link to={{
        type: 'loc_parskats',
        payload: {
            id: `filter${row.query.offset}`,
            inv_numurs: row.inv_numurs
        },
        query : row.query
      }}>
          <Button color="secondary" size="sm">
              <i className="fa fa-file-text-o"></i>
          </Button>
      </Link>
  )
}

const getColumns = ({kodif, user}) => {
  return [
    editIconColumn, {
      dataField: 'inv_numurs',
      text: 'Inv Num',
      width: 90,
      filterCfg: getTextFilterCfg()
  }, {
      dataField: 'virsraksts',
      text: 'Virsraksts',
      filterCfg: getTextFilterCfg(),
//      width: 300,
  }, {
    dataField: 'organizacijas',
    text: 'Organizācija',
    filterCfg: getTextFilterCfg(),
    width: 200,
  }, {
    dataField: 'autori',
    text: 'Autori',
    filterCfg: getSelectFilterCfg(kodif.PAUTORS),
    formatter: getBadgeFormatterForKodif(kodif.PAUTORS),
    width: 110,
  }, {
    dataField: 'rubrikas',
    text: 'Rubrikas',
    filterCfg: {
      type: 'autocomplete',
      autocompleteCfg: {
        remoteSearchViewName: 'rubrikas_search',
      }
    },
    //formatter: getFormatterForKodif(kodif.PRUBRIKA),
    //formatter: (kods, row) => kods !== '-1' ? `${kods} - ${row.rubrika_label}` : null,
    renderer: getDefaultRendererForMultiField('tah_label'),
    width: 300,
  }, {
    dataField: 'gads',
    text: 'Gads',
    filterCfg: getNumberFilterCfg(),
    width: 100
  }, {
  //   dataField: 'teritorijas',
  //   text: 'Teritorija',
  //   filterCfg: getSelectFilterCfg(kodif.PTERITORIJA),
  //   formatter: getBadgeFormatterForKodif(kodif.PTERITORIJA),
  //   width: 100,
  // }, {
    dataField: 'adm_teritorijas',
    text: 'Adm. teritorija',
    filterCfg: getTextFilterCfg(),
    width: 150,
  },{
    dataField: 'valoda',
    text: 'Valoda',
    width: 80
  }, {
    dataField: 'pielikumi',
    text: 'Piel.',
    width: 60,
    formatter: (pielikumi, row) => pielikumi > 0 ? 'Ir' : '',
  },
  //  {
  //   dataField: 'pamatvardi',
  //   text: 'Pamatvārdi',
  //   filterCfg: getTextFilterCfg()
  // },
]
};

class GeofondParskatsMap extends Component {

    rowEvents = {
        onDoubleClick: (e, row) => {
          this.props.openParskatsWithFilter(row.query, row.inv_numurs)
        }
    }

  mapPaneSizeChange = (h) => {
    //this.props.guiSet(this.props.karteHeightProp, h);
    this.props.guiSet('parskats_saraksts_karte_height', h);
  }

    render() {

        const {kodif, parskati_karte, parskatsSelected, user} = this.props
        const {list, selected, loading, count} = parskati_karte

        const vectorLayerCfgs = {
          parskati : {
            title: 'VĢF pārskati',
            features: parskati_karte.list,
            styles: maputils.atradnesStyles,//maputils.dokStyles.dokParskatsStyles,//maputils.atradnesStyles,
            tooltipFunc: (feat) => `${feat.get('inv_numurs')}`,// (${feat.get('numurs')})`,
            selected: selected,
            legend: {
                features: [
                    {
                      label: 'Pārskats',
                      geomType: 'point'
                    },
                    {
                      label: 'Pārskats (izvēlēts)',
                      geomType: 'point',
                      selected: true
                    }
                ]
              }
          }
        }

        if(!this.columns){
            this.columns = getColumns({
                kodif,
                user
            })
        }

        return (
          <SplitterLayout
                vertical
                percentage
                primaryIndex={1}
                secondaryInitialSize={guiSet.parskats_saraksts_karte_height}
                onSecondaryPaneSizeChange={this.mapPaneSizeChange}>

            <IzraktenisMap mapId="parskats_saraksts" vectorLayerCfgs={vectorLayerCfgs} />
            <div className="saraksts-wrapper">
              <Grid
                onRowSelected={parskatsSelected}
                loading={loading}
                selectedId={selected}
                dataList={list}
                totalCount={count}
                columns={this.columns}
                rowEvents={this.rowEvents}
              >
                { this.props.user.tiesibas.includes('PARSKATS_LABOT') ? (
                  <Link to={{ type: 'loc_parskats',
                      payload: {
                          id: 'jauns'
                      }
                  }}>
                    <Button color="primary"><i className="fa fa-plus-circle"></i>&nbsp;&nbsp;Jauns pārskats</Button>
                  </Link>
                  ) : null
                }
                <BBoxFilterButtonToUrl
                  ttitle='Rādīt tabulā pārskatus, kas atrodas kartes skatā'
                  ftitle='Rādīt tabulā pārskatus neatkarīgi no kartes skata'/>
                <ArchivedParskatsFilterToUrl/>
              </Grid>
            </div>
          </SplitterLayout>
        )
    }
}

const mapStateToProps = ({kodif, parskati_karte, user}) => {
    return {kodif, parskati_karte, user}
}

export default connect(mapStateToProps, {guiSet, showErrorMsg, parskatsSelected, openParskatsWithFilter})(GeofondParskatsMap)
