import React, {Component} from 'react';
import {connect} from 'react-redux'
import Form from '../forms/Form'
import {merijumsActions} from "../../actions/hidrogeoActions";
import Loading from "../Loading";
import Link from "redux-first-router-link";
import {Button} from "reactstrap";
import {getDefaultRendererForMultiField} from "../forms/FormHelpers";

const getFormFieldCfgs = ( ) => {
    return [
        {
            name: 'parauga_id',
            label: 'Saite uz paraugu',
            renderer: (nos, row) => (
                row.parauga_id ?
                    <Link className="float-left" to={{
                        type: 'loc_urbuma_paraugs',
                        payload: {
                            numurs: row.urbumi_id,
                            id:  row.parauga_id
                        }
                    }}>
                        <Button color="primary" className="float-left">Paraugs</Button>
                    </Link> : 'Nav noteikts'
            )
        }, {
            name: 'merijuma_datums',
            label: 'Mērijuma datums',
            type: 'date'
        }, {
            name: 'raditaja_id',
            label: 'Rādītājs',
            type: 'autocomplete',
            autocompleteCfg: {
                keyField: 'raditaja_id',
                labelFieldName: 'raditaja_pilns_nosaukums',
                remoteSearchViewName: 'urbuma_raditajs_search'
            },
            renderer: (fieldValue, dataObj) => dataObj.raditaja_pilns_nosaukums
        }, {
            name: 'vertiba',
            label: 'Vērtība',
            type: 'number',
        }, {
            name: 'standart_rad_pilns_nosaukums',
            label: 'Standartmērvienības rādītājs',
            editable: false,
        }, {
            name: 'standart_rad_vertiba_trimmed',
            label: 'Vērtība standartmērvienībās',
            type: 'number',
            editable: false,
        }, {
            name: 'karodzini',
            label: 'Karodziņi',
            type: 'autocomplete',
            autocompleteCfg: {
                multiple: true,
                keyField: 'karodzina_id',
                remoteSearchViewName: 'urbuma_vvis_karodzini_search',
                isValueObjStructure: true
            },
            renderer: getDefaultRendererForMultiField('tah_label')
        }
    ]};

class UrbumaMerijums extends Component {

    render() {
        const { merijums, saveAct, stopEditAct, startEditAct, deleteAct } = this.props

        if(!this.formFieldCfgs){
            this.formFieldCfgs = getFormFieldCfgs()
        }

        if (!merijums) {
            return <Loading />
        }

        const {editable, submiting, selectedItem} = merijums

        return (
            <div className="form-wrapper-pane">
                <Form
                    editable={editable}
                    submiting={submiting}
                    dataObj={selectedItem}
                    formFieldCfgs={this.formFieldCfgs}
                    onSubmit={saveAct}
                    onReset={stopEditAct}
                    onStartEdit={startEditAct}
                    onDelete={deleteAct}
                    tiesibasLabot="URBUMI"
                />
            </div>
        )
    }
}

const mapStateToProps = ({merijums}) => ({merijums})
export default connect(mapStateToProps, {...merijumsActions})(UrbumaMerijums)