import React, {Component} from 'react'
import {connect} from 'react-redux'
import Loading from '../Loading'
import { uoPazemesPasakumiActions } from '../../actions/pazemesUdensObjektiActions'
import { pasakumsPazemesDoubleSelected } from '../../actions/pasakumiActions'
import BootstrapTable from 'react-bootstrap-table-next'
import {columnCfgToBootstrapTableColumn} from '../grid/Grid'
import {getEditIconColumn, getSelectFilterCfg} from "../grid/ColumnHelpers";
import {Button} from "reactstrap";
import Link from "redux-first-router-link";
import {getDefaultTypeaheadOptionsFromKodifArray} from "../forms/FormHelpers";

const editIconColumn = getEditIconColumn((row) => (
    {
      type: 'loc_pazemes_pasakums',
      payload: {
        id: row.id,
        kods: row.uo_kods
      }
    }
  )
)

const getColumns = (kodif) => {
  const columns = [
    editIconColumn,
    {
      dataField: 'kods',
      text: 'Kods',
      width: 50
    },
    {
      dataField: 'nosaukums',
      text: 'Nosaukums',
      width: 400
    },
    {
      dataField: 'apraksts',
      text: 'Apraksts',
      width: 200,
    },
    {
      dataField: 'periods',
      text: 'Periods',
      filterCfg: getSelectFilterCfg(kodif.UO_PERIODS),
      width: 120
    },
    {
      dataField: 'izpilde',
      text: 'Izpilde',
      width: 250
    }
]
  return columns.map(col => columnCfgToBootstrapTableColumn(col))
}

class PazemesPasakumiComp extends Component {

  constructor(props) {
    super(props)
    this.state = {
      licence: null,
      limits: null
    }
  }

  rowEvents = {
    onDoubleClick: (row) => {
      pasakumsPazemesDoubleSelected({
        id: row.id,
        uo_kods: row.uo_kods
      })
    }
  }

  render() {

    const {selectedItem} = this.props.uoPazemesPasakumi

    if (!selectedItem) {
      return (<Loading/>)
    }

    const {kodif} = this.props
    if (!this.pasakumiCols) {
      this.pasakumiCols = getColumns(kodif)
    }

    const periodsValues = getDefaultTypeaheadOptionsFromKodifArray(kodif, 'UO_PERIODS').map(value => value.tah_id)
    const currentPeriodsIndex = periodsValues.indexOf(selectedItem.periods)

    const onNextPrevYear = (periods) => {
      this.props.fetchAct({ ...selectedItem, periods})
    }

    const nextYear = () => {
      return periodsValues[currentPeriodsIndex + 1]
    }

    const prevYear = () => {
      return periodsValues[currentPeriodsIndex - 1]
    }

    return <div className="full-height-form-or-grid-container">
        { this.props.user.tiesibas.includes('BAIS_PU_LABOT') ? (
          <div className="actionButtons">
            <Link to={{ type: 'loc_pazemes_pasakums',
              payload: {
                id: 'jauns',
                kods: selectedItem.kods
              }
            }}>
              <Button color="primary"><i className="fa fa-plus-circle"></i>&nbsp;&nbsp;Jauns pasākums</Button>
            </Link>
          </div>
      ) : null}
      <div className="atradne-title uo-gads d-flex flex-row-reverse">
        <h4 className="num-periods">
          <i className="fa fa-caret-left" title={prevYear()} aria-hidden="true"
             onClick={() => onNextPrevYear(prevYear())}></i>
          <span>{selectedItem.periods}</span>
          <i className="fa fa-caret-right" title={nextYear()} aria-hidden="true"
             onClick={() => onNextPrevYear(nextYear())}></i>
        </h4>
      </div>
      <BootstrapTable
        bootstrap4
        striped
        hover
        condensed
        keyField="id"
        data={selectedItem.pasakumi}
        columns={this.pasakumiCols}
        rowEvents={this.rowEvents}
      />
    </div>
  }
}

const mapStateToProps = ({uoPazemesPasakumi, kodif, user}) => ({
  uoPazemesPasakumi,
  kodif,
  user
})
const PazemesPasakumi = connect(mapStateToProps, {...uoPazemesPasakumiActions})(PazemesPasakumiComp)
export default PazemesPasakumi