import { callApi, getDefaultEditActions, injectActionQueryParameter } from './helpers'

export const loadWiseParametri = (query) => (dispatch, getState) => {
    return dispatch(callApi({
      viewName: 'wise_vielu_parametri_edit',
      crudMethod: 'listPaging',
      crudParam: injectActionQueryParameter(query, { atskaite: getState().location.payload.atskaite }),
    }))
}


export const wiseParametrsSelected = (id) => {
    return { type: 'wise_vielu_parametri_selected', payload : id }
}

export const wiseParametrsActions = getDefaultEditActions({
    actionTypeName : 'wise_vielu_parametri',
    stateName: 'wise',
    combinedPage: true,
    apiViewName: 'wise_vielu_parametri_edit',
    overridedActions: {
    createAct: () => (dispatch, getState) => {
      dispatch({
        type: 'wise_vielu_parametri_edit_new_success',
        payload: {
          vertiba_min: 0.00001,
          vertiba_max: 100,
          wdis_kods: 'W',
          mervieniba: 'ug/L',
          ir_aktivs: true,
          atskaite: getState().location.payload.atskaite,
        },
      })
    }},
    afterDelete: (dispatch, getState) => {
        dispatch(loadWiseParametri(getState().location.query))
    },
    afterSave: (dispatch, getState) => {
        dispatch(loadWiseParametri(getState().location.query))
    },
})

