import React, {Component} from 'react';
import {connect} from 'react-redux'
import {limitsActions} from '../../actions/dokumentacijaActions'
import {getCommonFormFieldCfgs, DokumentacijasKartina, LimitsLink} from './common'

const formFields = ['ir_derigs', 'atradne', 'pase', 'licence', 'adresats', 'kadastra_apzimejumi', 'ipasums',
   'izsniegts_datums', 'derigs_lidz_datums', 'anulets_datums', 'apjomi', 'piezimes', 'aktualizacijas_laiks']

const getFormFieldCfgs = ({kodif, user}) => {
  const commonFields = getCommonFormFieldCfgs({kodif, user})
  const fields = formFields.map(ff => commonFields.find(cf => cf.name === ff))
  if(user.tiesibas.includes('SKATIT_PIELIKUMUS_ZEMES_DZILES')){
    fields.push(commonFields.find(cf => cf.name === 'faili'))
  }
  fields.push({
    name: 'ieprieksejais',
    type: 'autocomplete',
    autocompleteCfg: {
        initialOptionsViewName: 'limiti_search_by_atradne',
        isValueObjStructure: true,
    },
    overridePropsFunc: (props) => {
      const changedProps = {...props}
      const {currentValues} = changedProps
      const placeholder = 'Izvēlieties limitu, kas pievienota norādītajai atradnei'
      if(currentValues.atradne && currentValues.atradne.id){
        changedProps.extraFieldProps = {disabled : false, placeholder}
        const extraFilterParams = {atradne_id: currentValues.atradne.id}
        if(currentValues.id) {
          extraFilterParams.not_limits = currentValues.id
        }
        changedProps.autocompleteCfg = {...changedProps.autocompleteCfg, extraFilterParams}
      } else {
        changedProps.extraFieldProps = {disabled : true, placeholder}
        changedProps.autocompleteCfg = {...changedProps.autocompleteCfg, extraFilterParams : null}
      }
      return changedProps
    },
    renderer: (lim, dataObj) => <LimitsLink limits={lim} atradne={dataObj.atradne}/>
  })
  fields.push({
    name: 'ieprieksejie',
    renderer: (limiti, dataObj) =>  <div className="saistitas">
        {dataObj.nakamie.map((lim, i) => <div key={i}><LimitsLink className='links' limits={lim} atradne={dataObj.atradne} irSaistita/> (nākamais)</div>)}
        {dataObj.ieprieksejie.map((lim, i) => <div key={i}><LimitsLink key={i} className='links' limits={lim} atradne={dataObj.atradne} irSaistita/> (iepriekšējais)</div>)}
        </div>
  })
  return fields
}

const childGeometryTooltipFunc = (feat) => `ID ${feat.get('id') || ''} ${feat.get('kategorija') || ''} ${feat.get('izrakteni') ? feat.get('izrakteni').map(i => i.klasifikators).join(', ') : ''}`
  
class Limits extends Component {

    render() {
      
      const {limits, kodif, saveAct, stopEditAct, startEditAct, deleteAct, koordSelectAct, koordChangeAct, user} = this.props

      if(!this.formFieldCfgs){
        this.formFieldCfgs = getFormFieldCfgs({
            kodif,
            user
        })
      }

      return <DokumentacijasKartina
        sizeForm={10}
        sizeLabel={2}
        geomObj={limits}
        formFieldCfgs={this.formFieldCfgs}
        metadataFormCfg='limits_view'
        saveAct={saveAct}
        stopEditAct={stopEditAct}
        startEditAct={startEditAct} 
        deleteAct={deleteAct}
        koordSelectAct={koordSelectAct} 
        koordChangeAct={koordChangeAct}
        karteWidthProp='limita_kartina_karte_width'
        mapId='limita_kartina'
        editableLayerIdName='limits_koord'
        layerTitles={{
          feat_border: 'Limita apjoma robeža',
          feat: 'Limita apjoms',
          koord: 'Limita apjoma robežpunkti',
          koord_labels: 'Robežpunktu numuri',
        }}
        childGeometryEditing
        childGeometryTooltipFunc={childGeometryTooltipFunc}
      />
    }
}

const mapStateToProps = ({limits, kodif, user}) => ({limits, kodif, user})
export default connect(mapStateToProps, {...limitsActions})(Limits)
