import {callApi, getDefaultEditActions, addExtraFilterToQuery} from './helpers'

export const loadKodifikatorsIfNeeded = (forced = false) => (dispatch, getState) => {
    if(forced || getState().kodif.requested === false){
        return dispatch(callApi({
            viewName: 'klasifikatori',
            crudMethod: 'list'
          }))
    }
}

export const loadKodifikatoruGrupasIfNeeded = (forced = false) => (dispatch, getState) => {
    if(forced || getState().kodif.requestedGrupas === false){
        return dispatch(callApi({
            viewName: 'klasifikatora_grupas',
            crudMethod: 'list'
          }))
    }
}

export const loadKodifikatoraIeraksti = (query) => (dispatch) => {
    return dispatch(callApi({
      viewName: 'klasifikatori_edit',
      crudMethod: 'listPaging',
      crudParam: addExtraFilterToQuery(query)
    }))
}

export const klasifikatorsSelected = (id) => {
    return { type: 'kodifikators_selected', payload : id }
}

const afterSaveOrDelete = (dispatch, getState) => {
        //reload app wide klasifikators
        dispatch(loadKodifikatorsIfNeeded(true))
        //reload table klasifikators
        dispatch(loadKodifikatoraIeraksti(getState().location.query))
}

export const klasifikatorsActions = getDefaultEditActions({
        actionTypeName : 'kodifikators',
        stateName: 'kodifikatori',
        combinedPage: true,
        apiViewName: 'klasifikatori_edit',
        afterDelete: afterSaveOrDelete,
        afterSave: afterSaveOrDelete,
        valuesTransformator: (values) => {
            return {...values, parent_grupa_kods: values.grupa_kods}
        },
})

export const replaceKlasifikators = (fromKlasifikatorsId, toKlasifikators, toKlasifikatorsGrupa) => (dispatch) => {
    const success = () => {
        dispatch(afterSaveOrDelete)
    }
    return dispatch(callApi({
        viewName: 'klasifikatori_aizstat',
        crudMethod: 'delete',
        crudParam: [fromKlasifikatorsId, 'toKlasifikators', toKlasifikators, 'toKlasifikatorsGrupa', toKlasifikatorsGrupa],
        success
    }))
}
