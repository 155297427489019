import React, { Component } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DefaultFooter extends Component {
  render() {

    // eslint-disable-next-line
    const { children, ...attributes } = this.props;

    const version = process.env.REACT_APP_VERSION || 'DEV'

    const isTest = version.toLowerCase().includes("test") || version.toLowerCase().includes("dev")

    return (
      <React.Fragment>
        <span>&copy; 2021 LVĢMC</span>
        {isTest ? <span className="ml-auto red margin-5-hor bold">ŠĪ IR TESTA VERSIJA</span> : null}
        {window.warning_message ? <span className="ml-auto red margin-5-hor bold">{window.warning_message}</span> : null}
        <span className="ml-auto"><a href="https://uniso.atlassian.net/" target="_blank" rel="noopener noreferrer" style={{ marginRight: 10 }}>Pieteikt kļūdu</a>Versija Nr. 1.{version}</span>
      </React.Fragment>
    );
  }
}

DefaultFooter.propTypes = propTypes;
DefaultFooter.defaultProps = defaultProps;

export default DefaultFooter;
