import React, { useState, Fragment } from 'react'
import {
    AsyncTypeahead,
    Highlighter,
    Menu,
    MenuItem
} from 'react-bootstrap-typeahead'
import * as maputils from './maputils'
import _ from 'lodash'

const optimizeQueryInput = (q) => q.replaceAll(/[%']/ig, ' ').trim().split(' ').map(sq => sq.trim()).filter(sq => sq.length > 0)

const processKadastrsApzToFilter = (q) => {
    const input = q.replaceAll(/[%']/ig, ' ').trim()
    if (input && input.length > 4 && /^\d+$/.test(input)) {
        return `code like '${input}%'`
    } else {
        return null
    }
}

const filterBy = () => true
const services = [
    {
        title: 'teritoriālās vienības',
        workspace: 'lvgmc',
        typename: 'valsts_pilsetas,novadi,pilsetas,pagasti,ciemi',
        queryToFilter: (q) => optimizeQueryInput(q).map(sq => `nosaukums ilike '%${sq}%'`).join(' and '),
        labelField: 'std'
    },
    {
        title: 'zemes vienības',
        workspace: 'lvgmc',
        typename: 'kk_parcel',
        queryToFilter: processKadastrsApzToFilter,
        labelField: 'code'
    },
    {
        title: 'zemes vienību daļas',
        workspace: 'lvgmc',
        typename: 'kk_parcel_part',
        queryToFilter: processKadastrsApzToFilter,
        labelField: 'code'
    },
    {
        title: 'ēkas (pēc kadastra apz.)',
        workspace: 'lvgmc',
        typename: 'kk_building',
        queryToFilter: processKadastrsApzToFilter,
        labelField: 'code'
    },
    {
        title: 'adreses',
        workspace: 'lvgmc',
        typename: 'adreses',
        queryToFilter: (q) => optimizeQueryInput(q).map(sq => `std ilike '%${sq}%'`).join(' and '),
        labelField: 'std'
    },
    {
        title: 'Vietvārdi',
        workspace: 'lvgmc',
        typename: 'vdb_objekti',
        queryToFilter: (q) => optimizeQueryInput(q).map(sq => `(pamatnosaukums ilike '%${sq}%' or pamatnosaukums2 ilike '%${sq}%' or objekta_veids ilike '%${sq}%')`).join(' and '),
        labelFunc: (props) => `${props.pamatnosaukums}${props.pamatnosaukums2 ? '(' + props.pamatnosaukums2 + ')' : ''} (${props.objekta_veids}) - ${props.galv_novads}, ${props.galv_pagasts}`
    },
    {
        title: 'Ūdensteces',
        workspace: 'lvgmc',
        typename: 'vdb_udensteces',
        queryToFilter: (q) => optimizeQueryInput(q).map(sq => `(nos ilike '%${sq}%' or nos2 ilike '%${sq}%')`).join(' and '),
        labelFunc: (props) => `${props.nos} ${props.nos2 ? '(' + props.nos2 + ')' : ''}`
    }
]

const MenuCategory = ({ title, options, search, index }) => {
    const [isExpanded, setIsExpanded] = useState(false)

    return (
        <>
            <Menu.Header>{title}</Menu.Header>
            {
                options.map((opt, ind) =>
                    <MenuItem key={index + ind} style={{ display: (ind <= 2 || isExpanded ? 'block' : 'none') }} option={opt} position={index + ind} >
                        <Highlighter search={search}>{opt.search_label}</Highlighter>
                    </MenuItem>
                )
            }
            {options.length > 3 ?
                <p class="dropdown-item show-more" onClick={() => setIsExpanded(!isExpanded)}>{isExpanded ? 'Rādīt mazāk' : `Rādīt vēl ${title}`}</p>
                :
                null}
        </>)
}

const renderMenu = (maxHeight) => (results, menuProps, state) => {
    const byTitle = _.groupBy(results, 'search_title')
    let index = 0
    const items = Object.keys(byTitle)
        .sort((a, b) => (services.findIndex(s => s.title === a) > services.findIndex(s => s.title === b) ? 1 : -1))
        .map((title) => {
            const mc = <MenuCategory key={title} title={title} options={byTitle[title]} search={state.text} index={index} />
            index = index + byTitle[title].length
            return mc
        })
    const menuPropsMod = { ...menuProps, maxHeight }
    return <Menu {...menuPropsMod}>{items}</Menu>;
}

let lastRequestNr = 0

const MapSearchField = ({ setSearchedFeatures, maxHeight = '250px' }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [options, setOptions] = useState([])

    const handleSearch = (query) => {
        if (!query || query.length < 3) {
            return false
        }
        setIsLoading(true)
        lastRequestNr++
        const requestNr = lastRequestNr
        const responses = services.filter(service => service.queryToFilter(query))
            .map(service => maputils.searchGeoServerWFS({
                ...service,
                filter: service.queryToFilter(query)
            }).then((features) => features.map(f => {
                const props = f.getProperties()
                return {
                    ...props,
                    search_label: service.labelFunc ? service.labelFunc(props) : props[service.labelField],
                    search_title: service.title,
                    zoomTo: true
                }
            })))
        if (responses.length > 0) {
            Promise.all(responses).then((values) => {
                if (requestNr === lastRequestNr) {
                    setOptions(values.flat())
                    setIsLoading(false)
                }
            })
        } else {
            setOptions([])
            setIsLoading(false)
        }
    }

    const onChange = (selected) => {
        setSearchedFeatures(selected)
    }

    return (
        <div className="ol-control map-search">
            <AsyncTypeahead
                id="MapSearchField"
                delay={500}
                filterBy={filterBy}
                isLoading={isLoading}
                onSearch={handleSearch}
                onChange={onChange}
                options={options}
                placeholder="Meklēt adresi, vietu vai kadastra apz."
                promptText="Ievadiet vairāk simbolus"
                searchText="Meklē..."
                labelKey="search_label"
                onInputChange={(text, e) => {
                    console.log(text, e);
                }}
                renderMenu={renderMenu(maxHeight)}
                clearButton={true}
                useCache={false}
                emptyLabel="Nav atrasti"
                flip
                positionFixed
                selectHint
                align="right"
            />
            {/*<InputGroup >
                <Button>
                    <i title="Lietošanas informācija" className="fa fa-lg fa-question-circle"></i>
                </Button>
                </InputGroup>*/}
        </div>
    )
}

export default MapSearchField