import * as helpers from './helpers'

const defaultListReducerForPase = helpers.createListReducerWithMapClicking({
    listViewName: 'pase_list',
    actionsName: 'pase',
    mapIdName: 'pases_saraksts',
    layerIdName: 'pases'
})
export const pases = (state = helpers.listGetInitialState(), { type, payload, reqId, mapId, layerId }) => {
    return defaultListReducerForPase({ state, type, payload, reqId, mapId, layerId })
}

const defaultEditReducerForPase = helpers.createEditReducerWithGeometryEditing({
    editViewName: 'pase_view',
    actionsName: 'pase',
    mapIdName: 'pases_kartina',
    layerIdName: 'pase_koord',
    childGeomEditCollection: 'pase_sadalijumi'
})
export const pase = (state = helpers.editWithGeometryInitialState(), { type, payload, mapId, layerId, featureGeom }) => {
    return defaultEditReducerForPase({ state, type, payload, mapId, layerId, featureGeom })
}

const defaultListReducerForLimits = helpers.createListReducerWithMapClicking({
    listViewName: 'limits_list',
    actionsName: 'limits',
    mapIdName: 'limits_saraksts',
    layerIdName: 'limiti'
})
export const limiti = (state = helpers.listGetInitialState(), { type, payload, reqId, mapId, layerId }) => {
    return defaultListReducerForLimits({ state, type, payload, reqId, mapId, layerId })
}

const defaultEditReducerForLimits = helpers.createEditReducerWithGeometryEditing({
    editViewName: 'limits_view',
    actionsName: 'limits',
    mapIdName: 'limita_kartina',
    layerIdName: 'limits_koord',
    childGeomEditCollection: 'apjomi'
})
export const limits = (state = helpers.editWithGeometryInitialState(), { type, payload, mapId, layerId, featureGeom, error }) => {
    const newState = defaultEditReducerForLimits({ state, type, payload, mapId, layerId, featureGeom })
    if (type === 'limits_view_get_failure' && error && error.status === 404) {
        return {
            ...newState,
            errorNotFound: true
        }
    }
    return newState
}

const defaultListReducerForLicence = helpers.createListReducerWithMapClicking({
    listViewName: 'licence_list',
    actionsName: 'licence',
    mapIdName: 'licence_saraksts',
    layerIdName: 'licences'
})
export const licences = (state = helpers.listGetInitialState(), { type, payload, reqId, mapId, layerId }) => {
    return defaultListReducerForLicence({ state, type, payload, reqId, mapId, layerId })
}

const defaultEditReducerForLicence = helpers.createEditReducerWithGeometryEditing({
    editViewName: 'licence_view',
    actionsName: 'licence',
    mapIdName: 'licences_kartina',
    layerIdName: 'licence_koord',
    childGeomEditCollection: 'licences_apjomi'
})
export const licence = (state = helpers.editWithGeometryInitialState(), { type, payload, mapId, layerId, featureGeom, error }) => {
    const newState = defaultEditReducerForLicence({ state, type, payload, mapId, layerId, featureGeom })
    if (type === 'licence_view_get_failure' && error && error.status === 404) {
        return {
            ...newState,
            errorNotFound: true
        }
    }
    return newState
}

const defaultListReducerForLimituAtlikumi = helpers.createListReducerWithMapClicking({
    listViewName: 'limita_apjomu_atlikumi_list',
    actionsName: 'limitu_atlikumi',
    mapIdName: 'limitu_atlikumi_saraksts',
    layerIdName: 'limitu_atlikumi'
})
export const limitu_atlikumi = (state = helpers.listGetInitialState(), { type, payload, reqId, mapId, layerId }) => {
    return defaultListReducerForLimituAtlikumi({ state, type, payload, reqId, mapId, layerId })
}