import React, { Component } from 'react';
import { connect } from 'react-redux'
import {
  FormGroup,
  Label,
} from 'reactstrap';
import GridWithForm from '../grid/GridWithForm'
import { adresatsSelected, adresatsActions, replaceAdresats } from '../../actions/adresatsActions'
import {
  getTextFilterCfg,
  getBadgeFormatterForKodif,
  getSelectFilterCfg,
  getDateFilterCfg,
  dateTimeFormatter,
  getBooleanFormatter,
  getBooleanSelectFilterCfg
} from '../grid/ColumnHelpers'
import { InputField } from '../forms/Form'
import _ from 'lodash'
import { getDefaultRendererForMultiField } from '../forms/FormHelpers'
import ReplaceObjectsButton from '../forms/ReplaceObjectsButton'
import TypeaheadField from '../forms/TypeaheadField'

const getColumns = ({ kodif }) => {
  return [
    {
      dataField: 'tips',
      text: 'Personas tips',
      formatter: getBadgeFormatterForKodif(kodif.personasTips),
      filterCfg: getSelectFilterCfg(kodif.personasTips)
    },
    {
      dataField: 'nosauk_vai_vards_uzv',
      text: 'Nosaukums vai vārds un uzvārds',
      filterCfg: getTextFilterCfg()
    },
    {
      dataField: 'personas_kods',
      text: 'Personas kods',
      filterCfg: getTextFilterCfg()
    },
    {
      dataField: 'registracijas_nr',
      text: 'Reģistrācijas Nr.',
      filterCfg: getTextFilterCfg()
    },
    {
      dataField: 'ir_no_u_registra',
      text: 'Izmantots UR',
      formatter: getBooleanFormatter(),
      filterCfg: getBooleanSelectFilterCfg(),
      width: 82
    }, {
      dataField: 'aktualizacijas_laiks',
      text: 'Labots',
      filterCfg: getDateFilterCfg(),
      formatter: dateTimeFormatter,
      width: 120,
    }
  ]
};

const hiddenForJuridiskas = ({ currentValues }) => currentValues.tips !== 'F'
const hiddenForFiziskas = ({ currentValues }) => currentValues.tips !== 'J'
const validationVardsUzvards = (values) => {
  if (values.tips === 'F' && !values.vards && !values.uzvards) {
    return 'Vai nu vārds vai uzvārds jāaizpilda obligāti!'
  }
  return null
}

const getFormFieldCfgs = ({ kodif }) => {
  return [
    {
      name: 'tips',
      type: 'radio',
      renderer: getBadgeFormatterForKodif(kodif.personasTips),
      options: kodif.personasTips,
      overridePropsFunc: (props) => {
        const changedProps = { ...props }
        const { currentValues, lastEditedFieldName } = props
        // set initial vards uzvards from nosaukums
        if (lastEditedFieldName === 'tips' && currentValues.tips === 'F' && !currentValues.vards && !currentValues.uzvards && currentValues.nosaukums) {
          const parts = currentValues.nosaukums.split(' ')
          if (parts.length > 1) {
            currentValues.vards = parts[0]
            currentValues.uzvards = _.tail(parts).join(' ')
          } else {
            currentValues.uzvards = currentValues.nosaukums
          }
        }
        return changedProps
      }
    },
    {
      name: 'ir_no_u_registra',
      hiddenFunc: hiddenForFiziskas,
      customField: (props) => {
        const { currentVal, setFieldValue } = props
        const setRegisterData = (field, newUzm) => {
          if (newUzm && newUzm.tah_id && newUzm.nosaukums) {
            setFieldValue('nosaukums', newUzm.nosaukums)
            setFieldValue('registracijas_nr', newUzm.tah_id)
          }
        }
        return <div style={{ display: 'flex' }}>
          <div style={{ width: '10%' }}>
            <Label check>
              <InputField
                {...props}
                type="checkbox"
              />
            </Label>
          </div>
          <div style={{ width: '90%' }}>
            <TypeaheadField
              fieldName="u_register_search"
              setFieldValue={setRegisterData}
              autocompleteCfg={{
                remoteSearchViewName: 'u_register_search',
                isValueObjStructure: true
              }}
              extraFieldProps={{
                placeholder: 'Meklēt uzņēmumu reģistrā',
                disabled: !currentVal
              }}
            />
          </div>
        </div>
      }
    },
    {
      name: 'nosaukums',
      hiddenFunc: hiddenForFiziskas,
      validation: (values) => {
        if (values.tips === 'J' && !values.nosaukums) {
          return 'Nosaukums ir obligāts lauks!'
        }
        return null
      },
      overridePropsFunc: (props) => {
        const changedProps = { ...props }
        changedProps.extraFieldProps = {
          disabled: props.currentValues.ir_no_u_registra
        }
        return changedProps
      }
    },
    {
      name: 'registracijas_nr',
      type: 'number',
      validation: (values) => {
        if (values.tips === 'J' && values.registracijas_nr && /\D/.test(values.registracijas_nr)) {
          return 'Reģistrācijas numuram jāsatur tikai cipari.'
        }
        return null
      },
      hiddenFunc: hiddenForFiziskas,
      overridePropsFunc: (props) => {
        const changedProps = { ...props }
        changedProps.extraFieldProps = {
          disabled: props.currentValues.ir_no_u_registra
        }
        return changedProps
      }
    },
    {
      name: 'vards',
      hiddenFunc: hiddenForJuridiskas,
      validation: validationVardsUzvards,
    },
    {
      name: 'uzvards',
      hiddenFunc: hiddenForJuridiskas,
      validation: validationVardsUzvards,
    },
    {
      name: 'personas_kods',
      hiddenFunc: hiddenForJuridiskas,
      type: 'text',
      editable: true,
      validation: (values) => {
        if (values.tips === 'F' && (values.personas_kods_1 || values.personas_kods_2)) {
          if (/\D/.test(values.personas_kods_1) || /\D/.test(values.personas_kods_2))
            return 'Personas kodam jāsatur tikai cipari.'
          if (values.personas_kods_1.toString().length !== 6) {
            return 'Personas koda pirmajai daļai ir jāsatur seši cipari.'
          }
          if (values.personas_kods_2.toString().length !== 5)
            return 'Personas koda otrajai daļai ir jāsatur pieci cipari.'
        }
        return null
      },
      customField: (props) => {
        return <>
          <FormGroup check inline>
            <Label check>
              <InputField
                {...props}
                fieldName="personas_kods_1"
                currentVal={props.currentValues.personas_kods_1}
              />
            </Label>
          </FormGroup>
          <b>-&nbsp;&nbsp;</b>
          <FormGroup check inline>
            <Label check>
              <InputField
                {...props}
                fieldName="personas_kods_2"
                currentVal={props.currentValues.personas_kods_2}
              />
            </Label>
          </FormGroup>
        </>
      },
    },
    {
      name: 'parstavji',
      type: 'autocomplete',
      renderer: getDefaultRendererForMultiField('tah_label'),
      overridePropsFunc: (props) => {
        const changedProps = { ...props }
        const { currentValues } = props
        const { tips, nosaukums, vards, uzvards } = currentValues
        const name = tips === 'F' ? `${vards || ''} ${uzvards || ''}` : tips === 'J' ? nosaukums || '' : ''
        const personuType = tips === 'F' ? 'fiziskas ' : ''
        changedProps.label = `Personu "${name}" var pārstāvēt šādas citas ${personuType}personas`
        changedProps.autocompleteCfg = {
          multiple: true,
          keyField: 'parstavis_id',
          remoteSearchViewName: tips === 'F' ? 'adresats_search_only_fiziskas' : 'adresats_search'
        }
        if (currentValues.id) changedProps.autocompleteCfg.extraFilterParams = { not_id: currentValues.id }
        return changedProps
      },
      renderClassName: "bold"
    }, {
      name: 'var_parstavet',
      renderer: (varParstavet) => <span className="bold" dangerouslySetInnerHTML={{ __html: varParstavet }}></span>,
      overridePropsFunc: (props) => {
        const changedProps = { ...props }
        const { currentValues } = props
        const { tips, nosaukums, vards, uzvards } = currentValues
        const name = tips === 'F' ? `${vards || ''} ${uzvards || ''}` : tips === 'J' ? nosaukums || '' : ''
        changedProps.label = `Persona "${name}" var pārstāvēt šādas personas`
        changedProps.fieldNotes = `Personas, kuras var pārstēvēt "${name}", jāaizpilda pie attiecīgajām personām.`
        return changedProps
      }
    }, {
      name: 'faili',
      label: 'Pielikumi',
      type: 'files'
    }
  ]
};

class Adresati extends Component {

  constructor(props) {
    super(props)
    this.state = {
      replaceWith: null,
      replaceWithModal: false
    }
  }

  handleReplace = () => {
    this.props.replaceAdresats(this.props.adresati.selectedItem.id, this.state.replaceWith.id)
    this.setState({
      replaceWithModal: false
    })
  }

  setReplaceWith = (field, value) => {
    this.setState({
      replaceWith: value
    })
  }

  toggleReplaceWithModal = () => {
    this.setState({
      replaceWithModal: !this.state.replaceWithModal
    })
  }

  render() {
    const { kodif, adresati, user } = this.props
    if (!this.columns) {
      this.columns = getColumns({
        kodif
      })
    }
    if (!this.formFieldCfgs) {
      this.formFieldCfgs = getFormFieldCfgs({
        kodif
      })
    }

    const replaceAdresati = adresati.selectedItem && !adresati.editable && user.tiesibas.includes('LABOT_ADRESATUS') ?
      <ReplaceObjectsButton
        buttonText="Aizvietot adresātu"
        handleReplace={this.handleReplace}
        toggleModal={this.toggleReplaceWithModal}
        modal={this.state.replaceWithModal}
        enabledSave={!!this.state.replaceWith}>
        <>
          <Label>Aizvietot ar šo adresātu:</Label>
          <TypeaheadField
            fieldName="adresats_replace_with"
            currentVal={this.state.replaceWith}
            autocompleteCfg={{
              remoteSearchViewName: 'adresats_search',
              isValueObjStructure: true,
            }}
            setFieldValue={this.setReplaceWith}
          />
          <div className="notes">Šeit izvēlētais adresāts parādīsies datu bāzē visās vietās, kur tiek izmantots aizvietojamais adresāts. Pēc aizvietošanas aizvietojamais adresāts tiks automātiski dzēsts.</div>
        </>
      </ReplaceObjectsButton>
      : null

    return (
      <GridWithForm
        {...this.props}
        columns={this.columns}
        formFieldCfgs={this.formFieldCfgs}
        storeObj={adresati}
        formWidthGuiOption="adresati_form_width"
        metadataFormCfg="adresats_edit"
        tiesibasLabot="LABOT_ADRESATUS"
        extraElementsFormButtons={replaceAdresati}
      />
    )
  }

}

const mapStateToProps = ({ adresati, kodif, user }) => ({ adresati, kodif, user })

export default connect(mapStateToProps, {
  selectedAct: adresatsSelected, replaceAdresats,
  ...adresatsActions
})(Adresati)
