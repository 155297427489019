import React, {Component} from 'react';
import {connect} from 'react-redux'
import {
  getFormatterForKodif,
} from '../grid/ColumnHelpers'
import {addUrlQuery} from '../../actions/uiActions'
import {troksniImportActions, troksniToggleModal} from "../../actions/troksniActions";
import Form from "../forms/Form";
import {Button, Modal, ModalBody, ModalHeader, Spinner} from "reactstrap";
import {getKlasifikatorsFieldCfg} from "../forms/FormHelpers";

const  getFormFieldCfgs = ({ kodif }) => {

  return [
    {
      ...getKlasifikatorsFieldCfg(kodif, 'troksniAvots'),
      name: 'avots',
      renderer: getFormatterForKodif(kodif.troksniAvots)
    },
    {
      label: 'Faila ielāde',
      name: 'file_info_id',
      type: 'file',
      required: true,
    },
  ]};

class TroksniImports extends Component {

  toggleModal() {
    this.setState({ modal: !this.state.modal })
  }

  constructor(props) {
    super(props);

    this.state = { modal: false }
    this.toggleModal = this.toggleModal.bind(this)
  }

  render() {

    const { troksniImports } = this.props

    if(!this.formFieldCfgs){
      this.formFieldCfgs = getFormFieldCfgs({ ...this.props })
    }

    return (
      <>
        <Button onClick={this.props.toggleModal}>Ielādēt trokšņu karti</Button>
        <Modal
          isOpen={troksniImports.modalOpen}
          className="modal-lg">
          <ModalHeader>Ielādēt trokšņu karti</ModalHeader>
          <ModalBody className="text-center">
            {troksniImports.submiting ? <><Spinner></Spinner></> :
            <Form
              editable={true}
              dataObj={{}}
              submiting={troksniImports.submiting}
              onSubmit={this.props.saveAct}
              onReset={this.props.toggleModal}
              formFieldCfgs={this.formFieldCfgs}
              metadataFormCfg="troksni_import_saraksts"
              onStartEdit={null}
              sizeLabel={3}
              tiesibasLabot={'BAIS_LABOT'}
              btnTexts={{save: 'Ielādēt'}}
            /> }
          </ModalBody>
        </Modal>
      </>
    )
  }
}

const mapStateToProps = ({ troksniImports, kodif }) => ({
  troksniImports,
  kodif
})

export default connect(mapStateToProps, {
  addUrlQuery,
  toggleModal: troksniToggleModal,
  ...troksniImportActions,
})(TroksniImports)
