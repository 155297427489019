import React, {Component} from 'react'
import {connect} from 'react-redux'
import {UncontrolledButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap';
import {addUrlQuery} from '../../actions/uiActions'

class BBoxFilterButtonToUrl extends Component {

    toggleBboxFilter = (v) => () => {
        this.props.addUrlQuery({b: v})
    }

    render() {
        const {ttitle, ftitle, query} = this.props
        const bboxEnabled = query && query.b === 't'
        return (
            <UncontrolledButtonDropdown>
                <DropdownToggle
                    color={bboxEnabled
                    ? 'primary'
                    : 'secondary'}
                    caret>
                    Kartes skatā
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem active={bboxEnabled} onClick={this.toggleBboxFilter('t')}>{ttitle || 'Rādīt tabulā objektus, kas atrodas kartes skatā'}</DropdownItem>
                    <DropdownItem active={!bboxEnabled} onClick={this.toggleBboxFilter('f')}>{ftitle || 'Rādīt tabulā objektus neatkarīgi no kartes skata'}</DropdownItem>
                </DropdownMenu>
            </UncontrolledButtonDropdown>
        )
    }
}

const mapStateToProps = ({location}) => {
    return {query: location.query}
}

export default connect(mapStateToProps, {addUrlQuery})(BBoxFilterButtonToUrl)
