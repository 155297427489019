import {callApi} from "./helpers";

export const loadUploadSizeLimitIfNeeded = (forced = false) => (dispatch, getState) => {
    if(forced || getState().uploadSize.requested === false){
        return dispatch(callApi({
            viewName: 'upload_size_limit',
            crudMethod: 'get',
            crudParam: 1
        }))
    }
}