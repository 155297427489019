import {
  callApi,
  getDefaultEditActions,
} from './helpers'

export const loadRaditajiEkologija = (query) => (dispatch) => {
  return dispatch(callApi({
    viewName: 'raditajs_ekologija_edit',
    crudMethod: 'listPaging',
    crudParam: query
  }))
}

export const raditajsEkologijaSelected = (id) => {
  return {
    type: 'raditajsEkologija_selected',
    payload: id
  }
}

const afterSaveOrDelete = (funk) => (dispatch, getState) => {
  dispatch(funk(getState().location.query))
}

export const raditajiEkologijaActions = getDefaultEditActions({
  actionTypeName: 'raditajsEkologija',
  stateName: 'raditajiEkologija',
  combinedPage: true,
  apiViewName: 'raditajs_ekologija_edit',
  afterDelete: afterSaveOrDelete(loadRaditajiEkologija),
  afterSave: afterSaveOrDelete(loadRaditajiEkologija),
})


export const loadRaditajiKimiska = (query) => (dispatch) => {
  return dispatch(callApi({
    viewName: 'raditajs_kimiska_edit',
    crudMethod: 'listPaging',
    crudParam: query
  }))
}

export const raditajsKimiskaSelected = (id) => {
  return {
    type: 'raditajsKimiska_selected',
    payload: id
  }
}

export const raditajiKimiskaActions = getDefaultEditActions({
  actionTypeName: 'raditajsKimiska',
  stateName: 'raditajiKimiska',
  combinedPage: true,
  apiViewName: 'raditajs_kimiska_edit',
  afterDelete: afterSaveOrDelete(loadRaditajiKimiska),
  afterSave: afterSaveOrDelete(loadRaditajiKimiska),
})

export const loadRaditajiQl = (query) => (dispatch) => {
  return dispatch(callApi({
    viewName: 'raditajs_ql_edit',
    crudMethod: 'listPaging',
    crudParam: query
  }))
}

export const raditajsQlSelected = (id) => {
  return {
    type: 'raditajsQl_selected',
    payload: id
  }
}

export const raditajiQlActions = getDefaultEditActions({
  actionTypeName: 'raditajsQl',
  stateName: 'raditajiQl',
  combinedPage: true,
  apiViewName: 'raditajs_ql_edit',
  afterDelete: afterSaveOrDelete(loadRaditajiQl),
  afterSave: afterSaveOrDelete(loadRaditajiQl),
})
