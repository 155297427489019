import React from 'react'
import {Badge} from 'reactstrap'
import Link from 'redux-first-router-link'
import {getLinkParams} from '../dokumentacija/common'

const AttributeRow = ({attributeTranslation, feature, k}) => <tr>
  <td>{attributeTranslation.label}</td>
  <td>{attributeTranslation.renderer ? attributeTranslation.renderer(feature[k], feature) : feature[k]}</td>
</tr>

const FeaturesPopup = ({features, selectFeature, featureAttributesDictionary}) => (
    <div className="feature-info">
     {features.map(f =>
        <div key={`${f.layer}_${f.id}`} >
            <Badge className="pointer" color={f.color} onClick={() => selectFeature(f)}>
            {f.selected ? <i className="fa fa-minus-square"></i>
             : <i className="fa fa-plus-square"></i> }
             &nbsp;&nbsp;{f.title}
            </Badge>
            {f.hasOwnProperty('ir_derigs') ?
            <span className="ir-derigs-badge-area">
                {f.ir_derigs ? <Badge color='success'>Ir spēkā</Badge> : <Badge className="small-badge" color='danger'>Nav spēkā</Badge>}
            </span> : null}
            {f.selected ? <>
            {f.featureInfoLinkParams ? <div className="open-link">
            <Link to={getLinkParams(f.featureInfoLinkParams)} target='_blank'>
              <u>Atvērt objekta kartiņu</u>
            </Link></div> : null}
            <table className="table react-bootstrap-table table-striped"><tbody>
             { Object.keys(featureAttributesDictionary).map(k => f.hasOwnProperty(k) ?
              featureAttributesDictionary[k][f.layer] ?
              <AttributeRow key={k} k={k} feature={f} attributeTranslation={featureAttributesDictionary[k][f.layer]}/>
              :
              <AttributeRow key={k} k={k} feature={f} attributeTranslation={featureAttributesDictionary[k]}/>
              : null )
            }
          </tbody></table></> : null}
        </div>
     )}
    </div>
  )
export default FeaturesPopup