import React, {Component} from 'react';
import {connect} from 'react-redux'
import Loading from '../Loading'
import GridWithForm from '../grid/GridWithForm'
import {datnuApmainaActions, datnuApmainaSelected} from '../../actions/apmainaActions';
import {dateFormatter, getDateFilterCfg, getTextFilterCfg} from '../grid/ColumnHelpers'
import {addUrlQuery} from '../../actions/uiActions'
import {showErrorMsg} from '../../actions/uiActions'
import * as api from "../../api";
import {Button} from "reactstrap";

const getColumns = ({kodif}) => {
  return [
    {
      dataField: 'file_reg_num',
      text: 'Reģistrācijas numurs',
      filterCfg: getTextFilterCfg()
    }, {
      dataField: 'filename',
      text: 'Files',
      filterCfg: getTextFilterCfg()
    }, {
      dataField: 'nosaukums',
      text: 'Pārskata nosaukums',
      filterCfg: getTextFilterCfg()
    }, {
      dataField: 'autors',
      text: 'Autors',
      filterCfg: getTextFilterCfg()
    }, {
      dataField: 'statuss',
      text: 'Statuss',
      filterCfg: getTextFilterCfg()
    }, {
      dataField: 'lietotajs',
      text: 'Lietotājs',
      filterCfg: getTextFilterCfg()
    }, {
      dataField: 'datums',
      text: 'Datums',
      formatter: dateFormatter,
      filterCfg: getDateFilterCfg()
    }
  ]
};

const getFormFieldCfgs = ({kodif}) => {
  return [
    {
      name: 'file_reg_num',
      label: 'Reģistrācijas numurs',
      editable: false
    }, {
      name: 'nosaukums',
      label: 'Pārskata nosaukums',
      required: true
    }, {
      name: 'autors',
      label: 'Autors',
      required: true
    }, {
      name: 'informacija',
      label: 'Iestāde/uzņēmums u.c. informācija',
    }, {
      name: 'lietotajs',
      label: 'Lietotājs',
      editable: false
    }, {
      name: 'datnu_apmaina_ieprieksejais_id',
      label: 'Iepriekšējais',
      editable: false
    }, {
      name: 'statuss',
      label: 'Statuss',
      editable: false
    }, {
      name: 'datums',
      type: 'date',
      label: 'Datums',
      editable: false
    }, {
      name: 'file_info_id',
      label: 'Faila ielāde',
      type: 'largefile',
      required: true
    }
  ]
}

class DatnuApmaina extends Component {

  doIesniegt = (data) => () => {
    api.saveApi('do_datnu_apmaina_iesniegt', data)
        .then( (data) => {
          window.location.reload()
        })
  }

  render() {
    const { datnu_apmaina, saveAct, stopEditAct, startEditAct, deleteAct } = this.props

    if(datnu_apmaina.loading) {
      return (<Loading/>)
    }

    if(!this.columns){
      this.columns = getColumns({
        kodif: this.props.kodif
      })
    }

    if(!this.formFieldCfgs){
      this.formFieldCfgs = getFormFieldCfgs({
        kodif: this.props.kodif
      })
    }

    const renderIesniegtButton = (data) => {
      const label = 'Iesniegt'
      if (!this.props.datnu_apmaina.selectedItem || !this.props.datnu_apmaina.selectedItem.id || this.props.datnu_apmaina.editable || this.props.datnu_apmaina.selectedItem.statuss === 'Iesniegts') {
        return <></>
      } else if (this.props.datnu_apmaina.selectedItem.loading) {
        return <Button color="default">{ label }</Button>
      } else {
        return <Button color="success" onClick={this.doIesniegt(data)}>{ label }</Button>
      }
    }

    return (
          <GridWithForm
            {...this.props}
            columns={this.columns}
            formFieldCfgs={this.formFieldCfgs}
            storeObj={this.props.datnu_apmaina}
            saveAct={saveAct}
            stopEditAct={stopEditAct}
            startEditAct={startEditAct}
            deleteAct={deleteAct}
            formWidthGuiOption="datnu_apmaina_form_width"
            metadataFormCfg="datnu_apmaina_view"
            tiesibasLabot="DATNU_APMAINA_LABOT"
            handleDelete={null}
            editable={false}
            extraElementsFormButtons={renderIesniegtButton(this.props.datnu_apmaina.selectedItem)}
          />
    )
  }
}

const mapStateToProps = ({datnu_apmaina, kodif, location, user}) => ({datnu_apmaina, kodif, query: location.query, user})

export default connect(mapStateToProps, {
  selectedAct : datnuApmainaSelected,
  addUrlQuery,
  ...datnuApmainaActions,
  showErrorMsg})(DatnuApmaina)