import { callApi, getDefaultEditActions, pushUrlToBrowserHistory, addExtraFilterToQuery } from './helpers'
import { redirect } from 'redux-first-router'
import { kategorijas } from '../reducers/kodifikatorsReducers'
import { atradneToLinkParams } from '../components/dokumentacija/common'

export const createActArgsToCrudParams = ({ numurs, dala, limita_apjoms_id }) => {
  const params = {}
  if (numurs) {
    params.numurs = numurs
  }
  if (dala) {
    params.dalaId = dala
  }
  if (limita_apjoms_id) {
    params.limita_apjoms_id = limita_apjoms_id
  }
  return params
}

export const loadPases = (query) => (dispatch, getState) => {
  const mapSize = getState().gui['pases_saraksts_karte_size']
  return dispatch(callApi({
    viewName: 'pase_list',
    crudMethod: 'listPaging',
    crudParam: addExtraFilterToQuery(query, mapSize)
  }))
}

export const paseActions = getDefaultEditActions({
  actionTypeName: 'pase',
  stateName: 'pase',
  apiViewName: 'pase_view',
  childGeomEditCollection: 'pase_sadalijumi',
  resultTransformator: (pase) => {
    if (pase && pase.pase_sadalijumi) {
      pase.pase_sadalijumi.forEach(ps => {
        ps.kategorijasObj = {}
        Object.keys(kategorijas).forEach(k => {
          ps.kategorijasObj[k] = ps.kategorijas && ps.kategorijas.includes(k)
        })
      })
    }
    return pase
  },
  valuesTransformator: (values) => {
    const pase = { ...values }
    if (pase && pase.pase_sadalijumi) {
      pase.pase_sadalijumi.forEach(ps => {
        ps.kategorijas = Object.keys(ps.kategorijasObj).filter(k => ps.kategorijasObj[k] === true).join("")
      })
    }
    return pase
  },
  createActArgsToCrudParams
})

export const paseSelected = (id) => {
  return { type: 'pase_selected', payload: id }
}

export const loadLimiti = (query) => (dispatch, getState) => {
  const mapSize = getState().gui['limits_saraksts_karte_size']
  return dispatch(callApi({
    viewName: 'limits_list',
    crudMethod: 'listPaging',
    crudParam: addExtraFilterToQuery(query, mapSize)
  }))
}

export const limitsActions = getDefaultEditActions({
  actionTypeName: 'limits',
  stateName: 'limits',
  apiViewName: 'limits_view',
  childGeomEditCollection: 'apjomi',
  createActArgsToCrudParams
})

export const limitsSelected = (id) => {
  return { type: 'limits_selected', payload: id }
}

export const loadLicences = (query) => (dispatch, getState) => {
  const mapSize = getState().gui['licence_saraksts_karte_size']
  return dispatch(callApi({
    viewName: 'licence_list',
    crudMethod: 'listPaging',
    crudParam: addExtraFilterToQuery(query, mapSize)
  }))
}

export const licenceActions = getDefaultEditActions({
  actionTypeName: 'licence',
  stateName: 'licence',
  apiViewName: 'licence_view',
  childGeomEditCollection: 'licences_apjomi',
  createActArgsToCrudParams,
})

export const licenceSelected = (id) => {
  return { type: 'licence_selected', payload: id }
}

export const openDokFromMap = (payload, loc, locDala, idColumn) => (dispatch, getState) => {
  const atradneParams = payload.atradne ? atradneToLinkParams(payload.atradne) : {
    numurs: payload.atr_numurs,
    atrId: payload.atradne_id,
    isDala: payload.atr_ieraksta_veids === 'D'
  }
  dispatch(openDok({
    ...atradneParams,
    id: idColumn ? payload[idColumn] : payload.id,
    loc,
    locDala
  }))
}

export const openDok = ({ loc, locDala, numurs, isDala, atrId, id }) => (dispatch, getState) => {
  //remember prev url
  pushUrlToBrowserHistory(getState)
  const payload = {
    numurs, id
  }
  if (isDala) {
    payload.dala = atrId
  }
  dispatch(redirect({
    type: isDala ? locDala : loc,
    payload
  }))
}

export const openNextOrPrevDok = (dokType, selectedItem, dokId) => (dispatch, getState) => {
  const atradne = selectedItem.atradne
  let loc, locDala = null
  if (dokType === 'pase') {
    loc = 'loc_atradnes_pase'
    locDala = 'loc_atradnes_dala_pase'
  } else if (dokType === 'licence') {
    loc = 'loc_atradnes_licence'
    locDala = 'loc_atradnes_dala_licence'
  } else if (dokType === 'limits') {
    loc = 'loc_atradnes_limits'
    locDala = 'loc_atradnes_dala_limits'
  } else if (dokType === 'krajums') {
    loc = 'loc_atradnes_krajums'
    locDala = 'loc_atradnes_dala_krajums'
  }
  dispatch(openDok({
    id: dokId,
    numurs: atradne.numurs,
    atrId: atradne.id,
    loc,
    locDala,
    isDala: atradne.ieraksta_veids === 'D'
  }))
}

export const loadLimituAtlikumi = (query) => (dispatch, getState) => {
  const mapSize = getState().gui['limitu_atlikumi_saraksts_karte_size']
  return dispatch(callApi({
    viewName: 'limita_apjomu_atlikumi_list',
    crudMethod: 'listPaging',
    crudParam: addExtraFilterToQuery(query, mapSize)
  }))
}

export const limituAtlikumiSelected = (id) => {
  return { type: 'limitu_atlikumi_selected', payload: id }
}