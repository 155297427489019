import React, { Component, useState } from 'react'
import { connect } from 'react-redux'
import Loading from '../Loading'
import BootstrapTable from 'react-bootstrap-table-next'
import filterFactory, { dateFilter, selectFilter, multiSelectFilter, textFilter, numberFilter, customFilter, Comparator } from 'react-bootstrap-table2-filter'
import paginationFactory, { PaginationProvider, SizePerPageDropdownStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator'
import ToolkitProvider from 'react-bootstrap-table2-toolkit'
import _ from 'lodash'
import { addUrlQuery, exportOds } from '../../actions/uiActions'
import * as constants from '../../constants'
import {
  UncontrolledDropdown,
  ButtonGroup,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap'
import AutocompleteGridFilter from './AutocompleteGridFilter'
import ExportButton from "./ExportButton"
import YearGridFilter from "./YearGridFilter"
import MultiselectFilter from "./MultiselectFilter"
import arrow from '../../assets/img/right-arrow.svg'

const ColumnToggleDropdown = ({
  columns,
  onColumnToggle
}) => {
  const [modal, setModal] = useState(false)
  return (<>
    <Button color="secondary" onClick={() => { setModal(!modal) }}><i className="fa fa-columns"></i>&nbsp;&nbsp;Kolonnas</Button>
    <Modal
      isOpen={modal}
      className="modal-md">
      <ModalHeader>Attēlojamās kolonnas</ModalHeader>
      <ModalBody className="text-center">
        <ButtonGroup vertical>
          {columns.filter(c => !c.isDummyField && c.text).map(c => (<React.Fragment key={c.dataField}>
            <Button
              className="mt-0 mb-1"
              color="primary"
              outline={c.hidden}
              onClick={() => onColumnToggle(c)}>
              {c.text}</Button>{' '}
          </React.Fragment>))}
        </ButtonGroup>
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => { setModal(false) }}>Aizvērt</Button>
      </ModalFooter>
    </Modal>
  </>)
}

const BottomBarPagingButtons = ({ paginationProps, dataSize, sizePerPage, from, to }) =>
  <div className="part">
    <SizePerPageDropdownStandalone {...paginationProps} />
    {dataSize / sizePerPage > 1 ? <PaginationListStandalone {...paginationProps} /> : null}
    <div className="pagination-total"><p>Tiek attēloti ieraksti {from} līdz {to} no <b>&nbsp;{dataSize}</b></p> </div>
    {dataSize > 500 & sizePerPage > 500 ?
      (<div className="pagination-total danger"><p>Tiek attēlotas daudz rindas. Tabula var strādāt lēni!</p></div>) : null}
  </div>

const BottomBarRightSideButtons = ({ columns, onColumnToggle, currentFilter, clearFilters, exportViewname }) => <div className="part-pages">
  <ColumnToggleDropdown columns={columns} onColumnToggle={onColumnToggle} />
  <ExportButton viewname={exportViewname} />
  {currentFilter ? <Button color="primary" onClick={clearFilters}>Noņemt tabulas filtrus</Button> : null}
</div>

const IzraktenisBootstrapTable = ({ baseProps, currentSort, onTableChange, paginationTableProps, filter, selectRow, tableClasses, rowEvents, expandRow, extraGridProps, remote }) =>
  <BootstrapTable
    {...baseProps}
    bootstrap4
    remote={remote}
    striped
    hover
    condensed
    defaultSorted={currentSort}
    onTableChange={onTableChange}
    {...paginationTableProps}
    filter={filter}
    selectRow={selectRow}
    wrapperClasses="grid-saraksts"
    classes={tableClasses}
    rowEvents={rowEvents}
    expandRow={expandRow}
    {...extraGridProps}
  />

const sizePerPageRenderer = ({
  options,
  currSizePerPage,
  onSizePerPageChange
}) => {
  const selected = options.find(o => String(o.page) === String(currSizePerPage))
  const selectedText = selected ? selected.text : currSizePerPage
  return <UncontrolledDropdown direction="up" className="float-left">
    <DropdownToggle caret>
      {selectedText}
    </DropdownToggle>
    <DropdownMenu>
      {options.map(option => (
        <DropdownItem key={option.text} onClick={() => onSizePerPageChange(option.page)}>{option.text}</DropdownItem>
      ))}
    </DropdownMenu>
  </UncontrolledDropdown>
}

export const columnCfgToBootstrapTableColumn = (columnCfg) => {
  const headerStyle = () => columnCfg.width ? { width: `${columnCfg.width}px` } : { minWidth: '50px' }
  return { sort: true, headerStyle, ...columnCfg }
}

class BootstrapGrid extends Component {

  constructor(props) {
    super(props);
    // hack for scrolling selected item into table view
    this.updatingSelected = false
    this.selectRowDefaults = {
      mode: 'radio',
      clickToSelect: true,
      hideSelectColumn: true,
      classes: 'selection-row',
      onSelect: this.handleOnSelect
    }

    this.state = {
      columns: [],
      showScrollLeftButton: false,
      showScrollRightButton: false
    }

    const { currentFilter, columns } = props
    //add default params to columns, add initial filter values..
    const initialFilter = currentFilter ? JSON.parse(currentFilter) : {}
    this.filterFuncsForClear = []
    const getFilter = (filterEmptyValue) => (filter) => {
      this.filterFuncsForClear.push({ filter, filterEmptyValue })
    }
    this.state.columns = columns.map(c => {
      let filter = undefined
      let filterRenderer = undefined
      if (c.filterCfg) {
        const defaultValue = initialFilter[c.dataField] || undefined
        switch (c.filterCfg.type) {
          case 'text':
            filter = textFilter({ getFilter: getFilter(''), defaultValue, ...c.filterCfg })
            break;
          case 'select':
            filter = selectFilter({ getFilter: getFilter(''), defaultValue, ...c.filterCfg })
            break;
          case 'multiselect':
            filter = multiSelectFilter({ getFilter: getFilter(''), defaultValue, ...c.filterCfg })
            filter = customFilter()
            filterRenderer = (onFilter, column) =>
              <MultiselectFilter
                onFilter={onFilter}
                column={column}
                defaultValue={defaultValue}
                options={c.filterCfg.options || {}}
                getFilterFunc={getFilter('')}
              />
            break;
          case 'date':
            const dateDefaultValue = {
              date: new Date(initialFilter[c.dataField]),
              comparator: initialFilter[c.dataField + '_comparator']
            }
            filter = dateFilter({ getFilter: getFilter({ date: '', comparator: Comparator.EQ }), defaultValue: dateDefaultValue, ...c.filterCfg })
            break;
          case 'number':
            const numberDefaultValue = {
              number: initialFilter[c.dataField],
              comparator: initialFilter[c.dataField + '_comparator']
            }
            filter = numberFilter({ getFilter: getFilter({ number: null, comparator: Comparator.EQ }), defaultValue: numberDefaultValue, ...c.filterCfg })
            break;
          case 'year':
            filter = customFilter()
            filterRenderer = (onFilter, column) =>
              <YearGridFilter
                onFilter={onFilter}
                column={column}
                defaultValue={defaultValue}
                getFilterFunc={getFilter(new Date().getFullYear())}
              />
            break;
          case 'autocomplete':
            filter = customFilter()
            const autocompleteCfg = c.filterCfg.autocompleteCfg || {}
            filterRenderer = (onFilter, column) =>
              <AutocompleteGridFilter
                onFilter={onFilter}
                column={column}
                autocompleteCfg={autocompleteCfg}
                defaultValue={defaultValue}
                getFilterFunc={getFilter('')}
              />
            break;
          default:
            throw new Error('Please provide callback function')
        }
      }

      return { filter, filterRenderer, ...columnCfgToBootstrapTableColumn(c) }
    }
    )
    // add id for focusing hack
    if (this.state.columns.length > 0) {
      this.state.columns[0].attrs = (cell, row) => ({ id: `rowid_${row.id}` })
    }
  }

  componentDidMount() {
    const tableEl = document.getElementsByClassName("grid-saraksts")
    if (tableEl && tableEl.length > 0) {
      tableEl[0].addEventListener('scroll', (e) => {
        this.updateScrollLeftButtons(e.target)
      })
      this.updateScrollLeftButtons(tableEl[0])
    }
  }

  updateScrollLeftButtons = (tableDiv) => {
    if (tableDiv) {
      this.setState({
        showScrollLeftButton: tableDiv.scrollLeft > 0,
        showScrollRightButton: (tableDiv.scrollWidth - tableDiv.clientWidth - tableDiv.scrollLeft) > 0
      })
    }
  }

  componentDidUpdate(prevProps) {

    const { selectedId } = this.props

    //hack for scrolling selected item into table view
    //check for changes in selected item or in atradnes
    if (selectedId !== prevProps.selectedId) {
      //if table was updating selected then we do nothing
      if (this.updatingSelected) {
        this.updatingSelected = false
      } else {
        const el = document.getElementById('rowid_' + selectedId)
        if (el) {
          el.scrollIntoView();
          const offset = 90
          const minAllowedDistFromBottom = 140
          //scroll past sticking header (if this is not last row)
          const tableEl = document.getElementsByClassName("grid-saraksts")[0]
          const rowDistFromBottom = tableEl.scrollHeight - el.offsetTop
          if (rowDistFromBottom > minAllowedDistFromBottom) {
            tableEl.scrollTop -= offset;
          }
        }
      }
    }
  }


  handleOnSelect = (row, isSelect) => {
    if (isSelect && this.props.onRowSelected) {
      this.updatingSelected = true
      this.props.onRowSelected(row.id)
    }
  }

  //grid calls this when sorting, filtering, paging changes..
  //reflect changes to URL
  onTableChange = (type, newState) => {
    let ps = undefined
    let p = undefined
    let f = undefined
    let s = undefined

    //paging params
    if (newState.sizePerPage !== constants.DEFAULT_SARAKSTS_PAGE_SIZE) {
      ps = newState.sizePerPage
    }
    if (newState.page > 1) {
      p = newState.page
    }

    // filter params
    // newState has filters.numurs.filterVal
    // server expects {"numurs":"897", "nosaukums": "teraud"}
    if (newState.filters && !_.isEmpty(newState.filters)) {
      const filters = {}
      Object.keys(newState.filters).forEach(fieldName => {
        const tableFilter = newState.filters[fieldName]
        if (tableFilter.filterType === 'DATE') {
          const { date, comparator } = tableFilter.filterVal
          if (date && comparator) {
            filters[fieldName + '_comparator'] = comparator
            filters[fieldName] = date
          }
        } else if (tableFilter.filterType === 'NUMBER') {
          const { number, comparator } = tableFilter.filterVal
          if (number && comparator) {
            filters[fieldName + '_comparator'] = comparator
            filters[fieldName] = Number(number)
          }
        } else {
          filters[fieldName] = tableFilter.filterVal
        }
      });
      if (!_.isEmpty(filters)) {
        f = JSON.stringify(filters)
      }
    }

    //sort params
    if (newState.sortField && newState.sortOrder) {
      s = `${newState.sortOrder === 'desc' ? '~' : ''}${newState.sortField}`
    }

    const query = this.props.enablePaging ? { f, p, ps, s } : { f, s }

    this.props.addUrlQuery(query)
  }

  //basically we need to update grid only if data is changed, everything else (filters, sorting, paging ...)
  //comes from grid itself and should be set only initially
  shouldComponentUpdate(nextProps, nextState) {
    const propsForUpdate = ['dataList', 'totalCount', 'selectedId']
    const stateForUpdate = ['columns', 'showScrollRightButton', 'showScrollLeftButton']
    return propsForUpdate.filter(key => nextProps[key] !== this.props[key]).length > 0 || stateForUpdate.filter(key => nextState[key] !== this.state[key]).length > 0
  }

  clearFilters = () => {
    this.filterFuncsForClear.forEach(f => f.filter(f.filterEmptyValue))
  }

  onColumnToggle = (col) => {
    this.setState({
      columns: this.state.columns.map(c => {
        if (c.dataField === col.dataField) {
          c.hidden = !c.hidden
        }
        return c
      })
    })
  }

  scrollHor = (px) => {
    const tableEl = document.getElementsByClassName("grid-saraksts")[0]
    tableEl.scrollLeft = tableEl.scrollLeft + px
  }

  scrollLeft = () => {
    this.scrollHor(-100)
  }

  scrollRight = () => {
    this.scrollHor(100)
  }

  render() {

    const { currentPage, currentPageSize, currentFilter, currentSortQuery, enablePaging, exportViewname } = this.props
    const { showScrollRightButton, showScrollLeftButton } = this.state

    const currentSort = []
    if (currentSortQuery) {
      let order = 'asc'
      let dataField = currentSortQuery
      if (currentSortQuery.startsWith('~')) {
        order = 'desc'
        dataField = currentSortQuery.substring(1)
      }
      currentSort.push({ order, dataField })
    }

    const selectRow = this.props.selectRow || {
      ...this.selectRowDefaults,
      selected: [this.props.selectedId]
    }

    //if any column don't have width prop we should calculate min-width for the grid
    //otherwise column will be hidden on small screens
    const calculateWidth = this.state.columns.filter(col => col.hidden !== true && !col.width).length > 0
    let tableClasses = ''
    if (calculateWidth) {
      const minWidth = this.state.columns.reduce((sum, col) => col.hidden === true ? sum : col.width ? col.width + sum : 100 + sum, 0.0)
      const classWidth = minWidth < 400 ? 400 : minWidth < 600 ? 600 : minWidth < 800 ? 800 : minWidth < 1000 ? 1000 : minWidth < 1200 ? 1200 : 1400
      tableClasses = "table-" + classWidth
    }

    return (
      <>
        {showScrollLeftButton ? <img src={arrow} alt="Pa kreisi" className="scroll-left-btn" onClick={this.scrollLeft} /> : null}
        {enablePaging ?
          <PaginationProvider pagination={paginationFactory({
            custom: true,
            page: currentPage,
            sizePerPage: currentPageSize,
            totalSize: this.props.totalCount,
            showTotal: true,
            sizePerPageList: [{
              text: `Rādīt ${constants.DEFAULT_SARAKSTS_PAGE_SIZE}`,
              value: constants.DEFAULT_SARAKSTS_PAGE_SIZE,
            }, {
              text: 'Rādīt visus',
              value: 100000
            }],
            sizePerPageRenderer
          })}>
            {({
              paginationProps,
              paginationTableProps
            }) => {
              const { page, pageStartIndex, sizePerPage, dataSize } = paginationProps
              let from = ((page - pageStartIndex) * sizePerPage);
              from = dataSize === 0 ? 0 : from + 1;
              const to = Math.min(sizePerPage * page, dataSize);
              return <ToolkitProvider
                keyField="id"
                data={this.props.dataList}
                columns={this.state.columns}
                columnToggle
              >
                {props => (
                  <>
                    <IzraktenisBootstrapTable
                      baseProps={props.baseProps}
                      currentSort={currentSort}
                      onTableChange={this.onTableChange}
                      paginationTableProps={paginationTableProps}
                      filter={filterFactory()}
                      selectRow={selectRow}
                      tableClasses={tableClasses}
                      rowEvents={this.props.rowEvents}
                      expandRow={this.props.expandRow}
                      extraGridProps={this.props.extraGridProps}
                      remote={true}
                    />
                    <div className="table-bottom-bar">
                      <BottomBarPagingButtons
                        paginationProps={paginationProps}
                        dataSize={dataSize}
                        sizePerPage={sizePerPage}
                        from={from}
                        to={to}
                      />
                      <BottomBarRightSideButtons
                        columns={this.state.columns}
                        onColumnToggle={this.onColumnToggle}
                        currentFilter={currentFilter}
                        clearFilters={this.clearFilters}
                        exportViewname={exportViewname}
                      />
                    </div>
                  </>
                )}
              </ToolkitProvider>
            }
            }
          </PaginationProvider>
          :
          <ToolkitProvider
            keyField="id"
            data={this.props.dataList}
            columns={this.state.columns}
            columnToggle
          >
            {props => (
              <>
                <IzraktenisBootstrapTable
                  baseProps={props.baseProps}
                  currentSort={currentSort}
                  onTableChange={this.onTableChange}
                  paginationTableProps={{}}
                  filter={filterFactory()}
                  selectRow={selectRow}
                  tableClasses={tableClasses}
                  rowEvents={this.props.rowEvents}
                  expandRow={this.props.expandRow}
                  extraGridProps={this.props.extraGridProps}
                  remote={{ pagination: false, filter: true, sort: true }}
                />
                <div className="table-bottom-bar">
                  <div className="part">
                    <div className="pagination-total"><p><b>{this.props.totalCount}</b> ieraksti</p> </div>
                  </div>
                  <BottomBarRightSideButtons
                    columns={this.state.columns}
                    onColumnToggle={this.onColumnToggle}
                    currentFilter={currentFilter}
                    clearFilters={this.clearFilters}
                    exportViewname={exportViewname}
                  />
                </div>
              </>
            )}
          </ToolkitProvider>
        }

        {showScrollRightButton ? <img src={arrow} alt="Pa labi" className="scroll-right-btn" onClick={this.scrollRight} /> : null}
      </>
    )
  }

}

class Grid extends Component {
  render() {

    const { query, loading } = this.props
    const loader = loading === true ? (<Loading />) : null
    const currentPage = query && query.p ? parseInt(query.p, 10) : 1
    const currentPageSize = query && query.ps ? parseInt(query.ps, 10) : constants.DEFAULT_SARAKSTS_PAGE_SIZE
    const currentFilter = query && query.f ? query.f : ''

    return (
      <div className="saraksts">
        {loader}
        <div className="actionButtons">
          {this.props.children}
        </div>
        <BootstrapGrid
          enablePaging={this.props.enablePaging !== false}
          currentSortQuery={query ? query.s : undefined}
          dataList={this.props.dataList}
          totalCount={this.props.totalCount}
          currentPage={currentPage}
          currentPageSize={this.props.currentPageSize || currentPageSize}
          selectedId={this.props.selectedId}
          rowEvents={this.props.rowEvents}
          extraGridProps={this.props.extraGridProps}
          addUrlQuery={this.props.addUrlQuery}
          currentFilter={currentFilter}
          columns={this.props.columns}
          onRowSelected={this.props.onRowSelected}
          expandRow={this.props.expandRow}
          exportViewname={this.props.exportViewname}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ location }) => {
  return { query: location.query }
}

export default connect(mapStateToProps, { addUrlQuery, exportOds })(Grid)
