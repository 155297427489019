import React, { Component, useState } from 'react'
import { connect } from 'react-redux'
import { Badge, FormGroup, Label, Button } from 'reactstrap'
import Link from 'redux-first-router-link'
import Form, { InputField } from '../forms/Form'
import { guiSet, showErrorMsg } from '../../actions/uiActions'
import {
  dateFormatter,
  getDateFilterCfg,
  dateTimeFormatter,
  getTextFilterCfg,
  getNumberFilterCfg,
  getBooleanFormatter,
  getBooleanSelectFilterCfg,
  getBadgeFormatterForKodif,
  getSelectFilterCfg,
  getEditIconColumn,
  getMultiBadgeFormatterForKodif,
  IDRenderer
} from '../grid/ColumnHelpers'
import * as maputils from '../karte/maputils'
import SplitterLayout from 'react-splitter-layout'
import KoordTable, { ShowKoordTableBtn } from '../karte/KoordTable'
import IzraktenisMap from '../karte/IzraktenisMap'
import Loading from '../Loading'
import {
  getDefaultTypeaheadOptionsFromKodif,
  kodifKeyToTypeaheadOption,
  validateYears,
  getMultiKlasifikatorsFieldCfg,
  getKlasifikatorsFieldCfg,
  DaudzumsField,
  getFileHref
} from '../forms/FormHelpers'
import { round } from '../../utils'
import { TEXT_ERR_DERIGIE } from '../../constants'
import _ from 'lodash'
import { childGeomSelectAct } from '../../actions/helpers'


export const atradneToLinkParams = (atradne) => ({
  numurs: atradne.numurs,
  atrId: atradne.id,
  isDala: atradne.ieraksta_veids === 'D'
})

export const getDoubleClickHandler = ({ loc, locDala, openDok, idColumn }) => (e, row, rowIndex) => {
  const atradneParams = row.atradne ? atradneToLinkParams(row.atradne) : {
    numurs: row.atr_numurs,
    atrId: row.atradne_id,
    isDala: row.atr_ieraksta_veids === 'D'
  }
  openDok({
    ...atradneParams,
    id: idColumn ? row[idColumn] : row.id,
    loc,
    locDala
  })
}

export const getLinkParams = ({ loc, locDala, numurs, isDala, atrId, extraPayload }) => {
  const payload = {
    numurs,
  }
  if (isDala) {
    payload.dala = atrId
  }
  return {
    type: isDala ? locDala : loc,
    payload: { ...payload, ...extraPayload }
  }
}

export const getEditColumnForDok = ({ loc, locDala, numurs, isDala, atrId }) => getEditIconColumn((row) =>
  getLinkParams({ loc, locDala, numurs, isDala, atrId, extraPayload: { id: row.id } }))

export const getEditColumnForDokSaraksts = ({ loc, locDala }) => getEditIconColumn((row) => {
  return getLinkParams({
    ...atradneToLinkParams(row.atradne),
    loc,
    locDala,
    extraPayload: { id: row.id },
  })
})

export const PaseLink = (props) => {
  const { pase, atradne, className, irSaistita } = props
  if (!pase) {
    return null
  }
  return <div className="badge-column">
    <Link
      className={className}
      to={getLinkParams({
        ...atradneToLinkParams(atradne),
        loc: 'loc_atradnes_pase',
        locDala: 'loc_atradnes_dala_pase',
        extraPayload: { id: pase.id },
      })}>
      {irSaistita ?
        <>{pase.tah_label} &nbsp;&nbsp;&nbsp;
          {pase.ir_derigs ?
            <Badge color='success'>Derīga</Badge>
            :
            <Badge color='danger'>Nederīga</Badge>
          }
        </>
        :
        <Badge color={pase.ir_derigs
          ? 'success'
          : 'danger'}>
          {pase.tah_label}
        </Badge>
      }
    </Link>
  </div>
}

export const LimitsLink = (props) => {
  const { limits, atradne, className, irSaistita } = props
  if (!limits) {
    return null
  }
  return <div className="badge-column">
    <Link
      className={className}
      to={getLinkParams({
        ...atradneToLinkParams(atradne),
        loc: 'loc_atradnes_limits',
        locDala: 'loc_atradnes_dala_limits',
        extraPayload: { id: limits.id },
      })}>
      {irSaistita ?
        <>{limits.tah_label} &nbsp;&nbsp;&nbsp;
          {limits.ir_derigs ?
            <Badge color='success'>Derīgs</Badge>
            :
            <Badge color='danger'>Nederīgs</Badge>
          }
        </>
        :
        <Badge color={limits.ir_derigs
          ? 'success'
          : 'danger'}>
          {limits.tah_label}
        </Badge>
      }
    </Link>
  </div>
}

export const LicenceLink = (props) => {
  const { licence, atradne, className, irSaistita } = props
  if (!licence) {
    return null
  }
  return <div className="badge-column">
    <Link
      className={className}
      to={getLinkParams({
        ...atradneToLinkParams(atradne),
        loc: 'loc_atradnes_licence',
        locDala: 'loc_atradnes_dala_licence',
        extraPayload: { id: licence.id },
      })}>
      {irSaistita ?
        <>{licence.tah_label} &nbsp;&nbsp;&nbsp;
          {licence.ir_derigs ?
            <Badge color='success'>Derīga</Badge>
            :
            <Badge color='danger'>Nederīga</Badge>
          }
        </>
        :
        <Badge color={licence.ir_derigs
          ? 'success'
          : 'danger'}>
          {licence.tah_label}
        </Badge>
      }
    </Link>
  </div>
}

export const DalaLink = (props) => {
  const { numurs, dala, query } = props
  const linkParams = {
    type: 'loc_atradnes_dala',
    payload: {
      numurs,
      dala: dala.id
    }
  }
  if (query) {
    linkParams.query = query
  }
  return <Link className={dala.arhivets_iemesls ? 'cross-out' : ''}
    to={linkParams}>
    {dala.nosaukums} {dala.sinonimi ? `(${dala.sinonimi})` : null} {dala.arhivets_iemesls ? `(${dala.arhivets_iemesls})` : null}
  </Link>
}

export const KrajumsLink = (props) => {
  const { krajums, atradne, className } = props
  if (!krajums) {
    return null
  }
  return <div className="badge-column">
    <Link
      className={className}
      to={getLinkParams({
        ...atradneToLinkParams(atradne),
        loc: 'loc_atradnes_krajums',
        locDala: 'loc_atradnes_dala_krajums',
        extraPayload: { id: krajums.id },
      })}>
      <Badge color='color_8'>{krajums.tah_label}</Badge>
    </Link>
  </div>
}

export const getDalaColumnForDok = (numurs) => ({
  dataField: 'dala',
  text: 'Atradnes daļa / struktūrelements',
  formatter: (dala, row) => {
    if (dala) {
      return <><DalaLink numurs={numurs} dala={dala} />{row.dalas_nosaukums ? ` : ${row.dalas_nosaukums}` : ''}</>
    } else {
      return row.dalas_nosaukums ? row.dalas_nosaukums : ''
    }
  },
  sortValue: (dala, row) => dala ? `${dala.nosaukums} : ${row.dalas_nosaukums}` : row.dalas_nosaukums || '',
  width: 90
})

export const getDalaColumnWithNosaukumsLabel = ({ numurs, isDala, atrId }) => isDala ? {
  dataField: 'dalas_nosaukums',
  text: 'Laukums',
  width: 100,
} : {
  dataField: 'dalas_nosaukums_label',
  text: 'Atradnes daļa / struktūrelements',
  width: 120,
  formatter: (dala, row) => row.dala_id && atrId !== row.dala_id ? <Link to={{
    type: 'loc_atradnes_dala',
    payload: {
      numurs: numurs,
      dala: row.dala_id
    }
  }}>{dala}</Link> : dala,
}

export const AtradneOrDalaLink = (props) => {
  const { id, numurs, nosaukums, query } = props
  if (props.ieraksta_veids === 'D') {
    return <DalaLink numurs={numurs} dala={{ id, nosaukums }} query={query} />
  } else {
    return <AtradneLink {...props} hideNumurs />
  }
}

export const getAradnesLinksRenderer = (kodif, numursField, extraInfoRendererFunc, className = 'links') => (fieldValue, dataObj) => {
  //filter out items without id, those might be added when editing and stayed on reset
  return fieldValue.filter(atr => !!atr.id).map((atr, i) => (
    <div key={i} className={className}>
      <AtradneLink numurs={atr[numursField]} kodif={kodif} ieraksta_veids={atr.ieraksta_veids} nosaukums={atr.nosaukums} />
      {extraInfoRendererFunc ? extraInfoRendererFunc(kodif, atr) : null}
    </div>
  ))
}

export const AtradneLink = (props) => {
  const { numurs, ieraksta_veids, nosaukums, kodif, hideNumurs, query } = props
  const linkParams = {
    type: 'loc_atradne',
    payload: { numurs }
  }
  if (query) {
    linkParams.query = query
  }
  return <div className="badge-column"><Link
    to={linkParams}>
    <Badge color={kodif.atradnesIerakstaVeidsExtended[ieraksta_veids].badgeColor}>{nosaukums} {hideNumurs !== true ? numurs : ''}</Badge>
  </Link></div>
}

export const linkRenderer = (link) => link ? <a href={link} rel="noreferrer" target="_blank">{link}</a> : null

export const getHyperlinkRenderer = (fieldValue) => fieldValue ? fieldValue.split(",").map(u => u.trim()).map(uri =>
  <div key={uri}>
    {linkRenderer(uri)}
  </div>
) : null


const LimitaApjomaDaudzums = ({ korDaudzums, korIzmainas, row, atradne, user }) => {
  const [showIzmainas, setShowIzmainas] = useState(false)
  return <>
    <b> {round(korDaudzums)} </b>
    <span dangerouslySetInnerHTML={{ __html: row.daudzuma_merv }}></span>
    {atradne && row.id && user.tiesibas.includes('LABOT_DOKUMENTACIJU') ?
      <Link to={getLinkParams({
        loc: 'loc_atradnes_izmaina_grouped',
        locDala: 'loc_atradnes_dala_izmaina_grouped',
        numurs: atradne.numurs,
        isDala: row.atradne && row.atradne.id && row.atradne.ieraksta_veids === 'D',
        atrId: row.atradne ? row.atradne.id : null,
        extraPayload: { id: 'jauns', limita_apjoms_id: row.id }
      })}>
        <Button color="primary" className="cell-button" size="sm"><i className="fa fa-pencil"></i>&nbsp;&nbsp;Koriģēt ar izmaiņu</Button>
      </Link>
      : null}
    {korIzmainas.length > 0 ?
      <Button onClick={() => setShowIzmainas(!showIzmainas)} color="secondary" className="cell-button" size="sm">
        {korIzmainas.length} korekcijas
      </Button>
      : null}
    {showIzmainas ? <table><tbody>
      {korIzmainas.map(izm => <React.Fragment key={izm.id}>
        <tr><td>Datums:</td><td>{dateFormatter(izm.datums)}</td></tr>
        <tr><td>Veids:</td><td>{izm.izmainas_veids_nosaukums}</td></tr>
        <tr><td className="cell-border-bottom">Izmaiņa:</td><td className="cell-border-bottom">{round(izm.before)} <b>{izm.sign} {round(izm.absDaudzums)}</b> = {round(izm.after)}</td></tr>
      </React.Fragment>)}
    </tbody></table> : null}
  </>
}

const reformatIzrakteni = (q_izrakteni_sep) => q_izrakteni_sep.split('%$%').join(', ')

const licenceRendererWithSaistitas = (licence, dataObj, ind) => licence ? <div className="saistitas" key={ind}>
  {licence.nakamie.map((lic, i) => <div key={i}><LicenceLink className='links' licence={lic} atradne={dataObj.atradne} irSaistita /> (nākamā)</div>)}
  <LicenceLink licence={licence} atradne={dataObj.atradne} /><b>(piesaistītā)</b>
  {licence.ieprieksejie.map((lic, i) => <div key={i}><LicenceLink className='links' licence={lic} atradne={dataObj.atradne} irSaistita /> (iepriekšējā)</div>)}
</div> : null

const paseRendererWithSaistitas = (pase, dataObj, ind) => pase ? <div className="saistitas" key={ind}>
  {pase.nakamie.map((pas, i) => <div key={i}><PaseLink key={i} className='links' pase={pas} atradne={dataObj.atradne} irSaistita /> (nākamā)</div>)}
  <PaseLink pase={pase} atradne={dataObj.atradne} /><b>(piesaistītā)</b>
  {pase.ieprieksejie.map((pas, i) => <div key={i}><PaseLink key={i} className='links' pase={pas} atradne={dataObj.atradne} irSaistita /> (iepriekšējā)</div>)}
</div> : null

const limitsRendererWithSaistitas = (limits, dataObj, ind) => limits ? <div className="saistitas" key={ind}>
  {limits.nakamie.map((lim, i) => <div key={i}><LimitsLink className='links' limits={lim} atradne={dataObj.atradne} irSaistita /> (nākamais)</div>)}
  <LimitsLink limits={limits} atradne={dataObj.atradne} /><b>(piesaistītais)</b>
  {limits.ieprieksejie.map((lim, i) => <div key={i}><LimitsLink className='links' limits={lim} atradne={dataObj.atradne} irSaistita /> (iepriekšējais)</div>)}
</div> : null

export const pielikumiColumn = () => ({
  dataField: 'pielikumi_filename',
  text: 'Pielikumi',
  filterCfg: getTextFilterCfg(),
  width: 110,
  formatter: (filename, row) => {
    if (filename) {
      const file_ids = row.pielikumi_file_id.split("%&%")
      const file_sha256s = row.pielikumi_file_sha256.split("%&%")
      return filename.split("%&%").map((f, i) =>
        <div className="attach-filename" key={i}>{getFileHref({ file_id: file_ids[i], file_sha256: file_sha256s[i], filename: f })}</div>
      )
    }
    return null
  }
})

export const PielikumiUnSaites = ({ fileId, fileSha256, fileName, saiteId, saiteName, saiteUrl }) => {
  let file_ids, file_sha256s, file_filename, saite_ids, nosaukumi, saites
  if (fileId) {
    file_ids = fileId.split("%&%")
    file_sha256s = fileSha256.split("%&%")
    file_filename = fileName.split("%&%")
  }
  if (saiteId) {
    saite_ids = saiteId.split("%&%")
    nosaukumi = saiteName.split("%&%")
    saites = saiteUrl.split("%&%")
  }
  return <>
    {file_ids ? file_ids.map((id, i) => <div className="attach-filename" key={id}>{getFileHref({ file_id: id, file_sha256: file_sha256s[i], filename: file_filename[i] })}</div>) : null}
    {saite_ids ? saite_ids.map((id, i) => <div className="attach-filename" key={id}><Saite saite={saites[i]} nosaukums={nosaukumi[i]} /></div>) : null}
  </>
}

export const pielikumiUnSaitesColumn = () => ({
  dataField: 'pielikumi_filename',
  text: 'Pielikumi',
  filterCfg: getTextFilterCfg(),
  width: 110,
  formatter: (filename, row) => <PielikumiUnSaites
    fileId={row.pielikumi_file_id}
    fileSha256={row.pielikumi_file_sha256}
    fileName={row.pielikumi_file_filename}
    saiteId={row.saites_saite_ids}
    saiteName={row.saites_nosaukumi}
    saiteUrl={row.saites_saites}
  />
})

export const Saite = ({ saite, nosaukums }) => (
  <>
    {saite && !saite.toLowerCase().startsWith('file') ?
      nosaukums ? <a href={saite} rel="noreferrer" target="_blank">{nosaukums}</a> :
        <a href={saite} rel="noreferrer" target="_blank">{saite}</a> :
      <span>{nosaukums}: {saite}</span>}
  </>
)

export const irParbauditiColumn = () => ({
  dataField: 'ir_parbauditi',
  text: 'Dati pār - baudīti',
  formatter: getBooleanFormatter(true),
  filterCfg: getBooleanSelectFilterCfg(),
  width: 70
})

export const irParbauditiField = () => ({
  name: 'ir_parbauditi',
  renderer: getBooleanFormatter(true)
})

export const getCommonColumns = ({ kodif, user, locijums }) => {
  const kategorijaFormatter = getBadgeFormatterForKodif(kodif.kategorijas)
  return [
    {
      dataField: 'id',
      text: 'ID',
      width: 70,
      formatter: IDRenderer
    },
    {
      dataField: 'ir_derigs',
      text: locijums && locijums === 'M' ? 'Derīgs' : locijums && locijums === 'P' ? 'Derīgi' : 'Derīga',
      formatter: getBooleanFormatter(true),
      filterCfg: getBooleanSelectFilterCfg(),
      width: 62
    }, {
      dataField: 'tips_nosaukums',
      text: 'Veids',
      formatter: getBadgeFormatterForKodif(kodif.LIC_TIPI),
      filterCfg: getSelectFilterCfg(kodif.LIC_TIPI),
      width: 80
    }, {
      dataField: 'atradne',
      text: 'Atradne vai daļa',
      formatter: (atr) => <AtradneOrDalaLink
        id={atr.id}
        numurs={atr.numurs}
        ieraksta_veids={atr.ieraksta_veids}
        nosaukums={atr.tah_label}
        kodif={kodif} />,
      width: 156,
      filterCfg: {
        type: 'autocomplete',
        autocompleteCfg: {
          remoteSearchViewName: 'atradne_search'
        }
      },
      sort: false
    }, {
      dataField: 'lic_numurs',
      text: 'Numurs',
      filterCfg: getTextFilterCfg(),
      width: 110
    }, {
      dataField: 'dalas_nosaukums',
      text: 'Struktūrelements',
      filterCfg: getTextFilterCfg(),
      width: 140
    }, {
      dataField: 'izdevejs',
      text: 'Izdevējs',
      filterCfg: getSelectFilterCfg(kodif.LIC_IZDEV),
      width: 160
    }, {
      dataField: 'adresats_nosaukums',
      text: 'Adresāts',
      filterCfg: getTextFilterCfg(),
      width: 160
    }, {
      dataField: 'kadastra_apzimejumi',
      text: 'Kadastra apz.',
      filterCfg: getTextFilterCfg(),
      width: 200
    }, {
      dataField: 'ipasums_nosaukums',
      text: 'Īpašums',
      filterCfg: getTextFilterCfg(),
      width: 200
    }, {
      dataField: 'q_izrakteni',
      text: 'Derīgie izrakteņi',
      filterCfg: getSelectFilterCfg(kodif.IZR_KRAJ),
      width: 130
    }, {
      dataField: 'izpetes_gads',
      text: 'Izpētes gads',
      filterCfg: getTextFilterCfg(),
      width: 100
    }, {
      dataField: 'gads',
      text: 'Gads',
      filterCfg: getNumberFilterCfg(),
      width: 55
    }, {
      dataField: 'krajumu_statuss_nosaukums',
      text: 'Krājumu statuss',
      filterCfg: getSelectFilterCfg(kodif.KRAJ_STATUSS),
      width: 130
    }, {
      dataField: 'krajumu_apr_veids_nosaukums',
      text: 'Krājumu aprēķina veids',
      filterCfg: getSelectFilterCfg(kodif.KRAJ_APR_VEIDS),
      width: 130
    }, {
      dataField: 'kategorija',
      text: 'Kategorija',
      formatter: getBadgeFormatterForKodif(kodif.kategorijas),
      filterCfg: getSelectFilterCfg(kodif.kategorijas),
      width: 75
    }, {
      dataField: 'izrakteni',
      text: 'Derīgie izrakteņi',
      filterCfg: getSelectFilterCfg(kodif.DIZRAKT),
      width: 130
    }, {
      dataField: 'daudzums',
      text: 'Daudzums',
      formatter: (daudzums, row) => <><b> {daudzums} </b><span dangerouslySetInnerHTML={{ __html: row.daudzuma_merv_nosaukums }}></span></>,
      width: 110,
      filterCfg: getNumberFilterCfg(),
      align: 'right'
    }, {
      dataField: 'daudzums_tukst_t',
      text: 'Krājumi / resursi, tūkst.t (kūdra, sapr. un dūņas)',
      filterCfg: getNumberFilterCfg(),
      width: 100
    }, {
      dataField: 'daudzums_tukst_mkub',
      text: 'Krājumi / resursi, tūkst.m3 (kūdra, sapr. un dūņas)',
      filterCfg: getNumberFilterCfg(),
      width: 100
    }, {
      dataField: 'atlikums',
      text: 'Pašreizējais atlikums',
      formatter: (atlikums, row) => <><b> {
        atlikums
      } </b><span dangerouslySetInnerHTML={{ __html: row.daudzuma_merv_nosaukums }}></span></>,
      width: 110,
      filterCfg: getNumberFilterCfg(),
      align: 'right'
    }, {
      dataField: 'sakums_datums',
      text: 'Datums',
      formatter: dateFormatter,
      filterCfg: getDateFilterCfg(),
      width: 106
    }, {
      dataField: 'izsniegts_datums',
      text: locijums && locijums === 'M' ? 'Izsniegts' : locijums && locijums === 'P' ? 'Izsniegti' : 'Izsniegta',
      formatter: dateFormatter,
      filterCfg: getDateFilterCfg(),
      width: 106
    }, {
      dataField: 'derigs_lidz_datums',
      text: locijums && locijums === 'M' ? 'Derīgs līdz' : locijums && locijums === 'P' ? 'Derīgi līdz' : 'Derīga līdz',
      formatter: dateFormatter,
      filterCfg: getDateFilterCfg(),
      width: 106
    }, {
      dataField: 'anulets_datums',
      text: locijums && locijums === 'M' ? 'Anulēts' : locijums && locijums === 'P' ? 'Anulēti' : 'Anulēta',
      formatter: dateFormatter,
      filterCfg: getDateFilterCfg(),
      width: 106
    }, {
      dataField: 'krajumu_stavoklis_uz_datums',
      text: 'Limitā noteiktais krājumu stāvoklis uz',
      formatter: dateFormatter,
      filterCfg: getDateFilterCfg(),
      width: 106
    }, {
      dataField: 'platiba',
      text: 'Platība t. m2',
      filterCfg: getNumberFilterCfg(),
      width: 110
    }, {
      dataField: 'platiba_ha',
      text: 'Platība ha',
      filterCfg: getNumberFilterCfg(),
      width: 100
    }, {
      dataField: 'limiti',
      text: 'Limiti',
      filterCfg: getTextFilterCfg(),
      formatter: (fieldValue, row) => fieldValue.map((lim, i) => <LimitsLink key={i} limits={lim} atradne={row.atradne} />),
      sort: false,
      width: 186
    }, {
      dataField: 'licences',
      text: 'Licences / atļaujas',
      filterCfg: getTextFilterCfg(),
      formatter: (fieldValue, row) => fieldValue.map((lic, i) => <LicenceLink key={i} licence={lic} atradne={row.atradne} />),
      sort: false,
      width: 186
    }, {
      dataField: 'pase',
      text: 'Pase',
      filterCfg: getTextFilterCfg(),
      formatter: (pase, row) => pase
        ? <PaseLink pase={pase} atradne={row.atradne} />
        : null,
      sort: false,
      width: 166
    }, {
      dataField: 'licence',
      text: 'Licence / atļauja',
      filterCfg: getTextFilterCfg(),
      formatter: (lic, row) => lic
        ? <LicenceLink licence={lic} atradne={row.atradne} />
        : null,
      sort: false,
      width: 166
    }, {
      dataField: 'derigs_lidz_iemesls_nosaukums',
      text: 'Iemesls (zaudējot spēku)',
      width: 130
    }, user && user.tiesibas.includes('SKATIT_PIELIKUMUS_ZEMES_DZILES') ? pielikumiColumn() : {}, {
      dataField: 'piezimes',
      text: 'Piezīmes',
      width: 130
    }, {
      dataField: 'aktualizacijas_laiks',
      text: locijums && locijums === 'M' ? 'Labots' : locijums && locijums === 'P' ? 'Laboti' : 'Labota',
      formatter: dateTimeFormatter,
      filterCfg: getDateFilterCfg(),
      width: 116
    }, irParbauditiColumn(),
    {
      dataField: 'izmainas_veids',
      text: 'Izmaiņu iemesls',
      width: 100,
    },
    {
      dataField: 'apjomi',
      text: 'Limita apjomi',
      width: 200,
      formatter: (apjomi) => <table className="plain-table"><tbody>
        {apjomi.map(apj => <tr key={apj.id}>
          <td>{apj.q_izrakteni_sep ? reformatIzrakteni(apj.q_izrakteni_sep) : null}</td>
          <td style={{ width: '2em' }}>{kategorijaFormatter(apj.kategorija)}</td>
          <td><b> {apj.korigetais_daudzums} </b><span dangerouslySetInnerHTML={{ __html: apj.daudzuma_merv_nosaukums }}></span></td>
        </tr>)}
      </tbody></table>
    },
    {
      dataField: 'licences_apjomi',
      text: 'Licences / atļaujas apjomi',
      width: 200,
      formatter: (apjomi) => <table className="plain-table"><tbody>
        {apjomi.map(apj => <tr key={apj.id}>
          <td>{apj.q_izrakteni}</td>
          <td style={{ width: '2em' }}>{kategorijaFormatter(apj.kategorija)}</td>
          <td><b> {apj.daudzums} </b><span dangerouslySetInnerHTML={{ __html: apj.daudzuma_merv_nosaukums }}></span></td>
        </tr>)}
      </tbody></table>
    },
    {
      dataField: 'daudzums_zem_udens',
      text: 'Krājumi / resursi (zem ūdens)',
      formatter: (daudzumsZU, row) => daudzumsZU ? <><b> {daudzumsZU} </b><span dangerouslySetInnerHTML={{ __html: row.daudzuma_merv_nosaukums }}></span></> : null,
      width: 110,
      filterCfg: getNumberFilterCfg(),
      align: 'right',
      hidden: true
    },
    {
      dataField: 'q_adm_ters',
      text: 'Administratīvā teritorija',
      filterCfg: getTextFilterCfg(),
      width: 130,
      hidden: true
    }
  ]
}

export const getCommonFormFieldCfgs = ({ kodif, user }) => {
  const platibaFieldCfg = {
    name: 'platiba',
    customField: (props) => {
      const { currentValRow, lastEditedFieldName, fieldNameRow } = props
      if ((lastEditedFieldName === 'platiba_ha' || lastEditedFieldName === `${fieldNameRow}.platiba_ha`) && currentValRow.platiba_ha != null) {
        currentValRow.platiba = round(currentValRow.platiba_ha * 10)
      }
      if ((lastEditedFieldName === 'platiba' || lastEditedFieldName === `${fieldNameRow}.platiba`) && currentValRow.platiba != null) {
        currentValRow.platiba_ha = round(currentValRow.platiba / 10.0)
      }
      //we add custom onchange/handleChange event, otherwise we can not know which field have changed
      return <>
        <FormGroup check inline>
          <InputField
            {...props}
            currentVal={currentValRow.platiba}
            extraFieldProps={{ placeholder: 'tūkst. m2', style: { width: 60 } }}
          />
        </FormGroup>
        <FormGroup check inline>
          <Label check>
            tūkst. m2 &nbsp;&nbsp;&nbsp;&nbsp;
          </Label>
        </FormGroup>
        <FormGroup check inline>
          <InputField
            {...props}
            fieldName={fieldNameRow ? `${fieldNameRow}.platiba_ha` : 'platiba_ha'}
            currentVal={currentValRow.platiba_ha}
            extraFieldProps={{ placeholder: 'ha', style: { width: 60 } }}
          />
        </FormGroup>
        <FormGroup check inline>
          <Label check>
            ha
          </Label>
        </FormGroup>
      </>
    },
    renderer: (platiba, dataObj) => platiba != null ? <><p className="no-margin"><b>{platiba}</b> tūkst. m2</p><p className="no-margin"><b>{dataObj.platiba_ha}</b> ha </p></> : ''
  }
  const fields = [
    {
      name: 'id',
      label: 'ID',
      type: 'text',
      editable: false,
      fieldEditable: false,
      renderer: IDRenderer
    },
    {
      name: 'atradne',
      type: 'autocomplete',
      required: true,
      autocompleteCfg: {
        options: [],
        isValueObjStructure: true,
      },
      overridePropsFunc: (props) => {
        const changedProps = { ...props }
        changedProps.autocompleteCfg = { ...changedProps.autocompleteCfg, options: props.dataObj.atr_options }
        return changedProps
      },
      renderer: (atr) => <AtradneOrDalaLink
        id={atr.id}
        numurs={atr.numurs}
        ieraksta_veids={atr.ieraksta_veids}
        nosaukums={atr.tah_label}
        kodif={kodif} />
    },
    {
      name: 'dalas_nosaukums',
    },
    {
      name: 'adresats',
      required: true,
      type: 'autocomplete',
      autocompleteCfg: {
        remoteSearchViewName: 'adresats_search',
        //dati ienāk un jānodod objekta struktūrā
        isValueObjStructure: true,
      },
      renderer: (adr) => adr ? adr.tah_label : ''
    }, {
      name: 'ipasums',
      type: 'autocomplete',
      autocompleteCfg: {
        remoteSearchViewName: 'ipasums_search',
        allowNew: true,
        isValueObjStructure: true,
        labelFieldName: 'nosaukums'
      },
      renderer: (ip) => ip && ip.nosaukums ? ip.nosaukums : ''
    }, {
      name: 'kadastra_apzimejumi',
      editable: false,
      fieldNotes: 'Zemes vienību kadastra apzīmējumi tiek aizpildīti automātiski no VZD WFS servisa pēc ģeometrijas labošanas.',
      renderClassName: ''
    },
    {
      ...getKlasifikatorsFieldCfg(kodif, 'kategorijas'),
      name: 'kategorija',
      renderer: getBadgeFormatterForKodif(kodif.kategorijas),
    },
    {
      name: 'izsniegts_datums',
      required: true,
    },
    {
      name: 'sakums_datums',
      required: true,
    },
    {
      name: 'derigs_lidz_datums',
      required: true,
      validation: (values) => {
        if (values.izsniegts_datums && values.derigs_lidz_datums && values.derigs_lidz_datums < values.izsniegts_datums) {
          return '"Derīgs līdz" datumam jābūt lielākam par izsniegšanas datumu!'
        }
        return null
      },
    },
    {
      name: 'anulets_datums',
      validation: (values) => {
        if (values.izsniegts_datums && values.anulets_datums && values.anulets_datums < values.izsniegts_datums) {
          return 'Anulēšanas datumam jābūt lielākam par izsniegšanas datumu!'
        }
        if (values.derigs_lidz_datums && values.anulets_datums && values.derigs_lidz_datums < values.anulets_datums) {
          return 'Anulēšanas datumam jābūt mazākam par "derīgs līdz" datumu!'
        }
        return null
      },
    },
    platibaFieldCfg,
    {
      name: 'limiti',
      renderer: (limiti, dataObj) => limiti.map((lim, i) =>
        limitsRendererWithSaistitas(lim, dataObj, i)
      ),
      fieldNotes: 'Sasaiste ar limitu jāveido Limita kartiņā.',
    },
    {
      name: 'licences',
      renderer: (licences, dataObj) => licences.map((l, i) =>
        licenceRendererWithSaistitas(l, dataObj, i)
      ),
      fieldNotes: 'Sasaiste ar licenci jāveido Licences kartiņā.',
    }
    , {
      name: 'piezimes',
    },
    {
      name: 'aktualizacijas_laiks',
      editable: false,
      hiddenFunc: ({ currentValues }) => !currentValues.id
    },
    {
      name: 'ir_derigs',
      renderer: getBooleanFormatter(true),
      hiddenFunc: ({ currentValues }) => !currentValues.id
    },
    {
      name: 'pase',
      type: 'autocomplete',
      autocompleteCfg: {
        initialOptionsViewName: 'pase_search_by_atradne',
        isValueObjStructure: true,
      },
      overridePropsFunc: (props) => {
        const changedProps = { ...props }
        const { currentValues } = changedProps
        const placeholder = 'Izvēlieties pasi, kas pievienota norādītajai atradnei'
        if (currentValues.atradne && currentValues.atradne.id) {
          changedProps.extraFieldProps = { disabled: false, placeholder }
          changedProps.autocompleteCfg = { ...changedProps.autocompleteCfg, extraFilterParams: { atradne_id: currentValues.atradne.id } }
        } else {
          changedProps.extraFieldProps = { disabled: true, placeholder }
          changedProps.autocompleteCfg = { ...changedProps.autocompleteCfg, extraFilterParams: null }
        }
        return changedProps
      },
      renderer: paseRendererWithSaistitas
    },
    {
      name: 'licence',
      type: 'autocomplete',
      autocompleteCfg: {
        initialOptionsViewName: 'licence_search_by_atradne',
        isValueObjStructure: true,
      },
      overridePropsFunc: (props) => {
        const changedProps = { ...props }
        const { currentValues } = changedProps
        const placeholder = 'Izvēlieties licenci, kas pievienota norādītajai atradnei'
        if (currentValues.atradne && currentValues.atradne.id) {
          changedProps.extraFieldProps = { disabled: false, placeholder }
          changedProps.autocompleteCfg = { ...changedProps.autocompleteCfg, extraFilterParams: { atradne_id: currentValues.atradne.id } }
        } else {
          changedProps.extraFieldProps = { disabled: true, placeholder }
          changedProps.autocompleteCfg = { ...changedProps.autocompleteCfg, extraFilterParams: null }
        }
        return changedProps
      },
      renderer: licenceRendererWithSaistitas
    },
    {
      ...getMultiKlasifikatorsFieldCfg(kodif, 'IZR_KRAJ'),
      name: 'izrakteni',
      required: true,
      overridePropsFunc: (props) => {
        const changedProps = { ...props }
        const { currentValues } = changedProps
        const { atradne } = currentValues
        let atrIzrakteniKlas = []
        if (atradne && atradne.izrakteni) {
          const atradnesIzrakteni = atradne.izrakteni.map(izr => izr.klasifikators)
          atrIzrakteniKlas = Object.keys(kodif.IZR_KRAJ).filter(k => atradnesIzrakteni.indexOf(kodif.IZR_KRAJ[k].derigais) > -1).map(kodifKeyToTypeaheadOption(kodif.IZR_KRAJ))
        }
        changedProps.autocompleteCfg = { ...changedProps.autocompleteCfg, options: atrIzrakteniKlas }
        changedProps.extraFieldProps = { disabled: atrIzrakteniKlas.length === 0 }
        changedProps.errorForField = atrIzrakteniKlas.length === 0 ? TEXT_ERR_DERIGIE : undefined
        return changedProps
      }
    },
    {
      name: 'daudzums',
      customField: (props) => {
        const { currentValRow } = props
        let izrMervienibas = []
        if (currentValRow.izrakteni) {
          izrMervienibas = currentValRow.izrakteni.flatMap(i => kodif.IZR_KRAJ[i.klasifikators].daudzuma_merv).map(m => m.nos)
        }
        return <DaudzumsField
          {...props}
          currentValuesObj={currentValRow}
          izrMervienibas={izrMervienibas}
          kodif={kodif}
        />
      },
      validation: (values) => {
        if (values.daudzums != null && !values.daudzuma_merv) {
          return 'Daudzuma mērvienība ir obligāti jānorāda!'
        }
        return null
      },
      renderer: (daudzums, values) => daudzums != null ? <><b> {daudzums} </b><span dangerouslySetInnerHTML={{ __html: values.daudzuma_merv }}></span></> : null,
    },
    {
      name: 'numurs'
    }, {
      ...getKlasifikatorsFieldCfg(kodif, 'LIC_IZDEV'),
      name: 'izdevejs',
      required: true,
    }, {
      ...getKlasifikatorsFieldCfg(kodif, 'LIC_TIPI'),
      name: 'tips',
      required: true,
      overridePropsFunc: (props) => {
        const changedProps = { ...props }
        const { currentValues, currentVal } = changedProps
        const licTipiNoIzdeveja = currentValues.izdevejs ? kodif.LIC_IZDEV[currentValues.izdevejs].lic_tipi.map(m => m.nos) : []
        const tipiOptions = getDefaultTypeaheadOptionsFromKodif(kodif.LIC_TIPI).filter(t => licTipiNoIzdeveja.indexOf(t.tah_id) >= 0)
        if (!currentVal && tipiOptions.length === 1) {
          currentValues.tips = tipiOptions[0].tah_id
          changedProps.currentVal = currentValues.tips
        } else if (currentVal && tipiOptions.filter(t => t.tah_id === currentVal).length === 0) {
          currentValues.tips = null
          changedProps.currentVal = currentValues.tips
        }
        changedProps.autocompleteCfg = { ...changedProps.autocompleteCfg, options: tipiOptions }
        changedProps.extraFieldProps = { disabled: tipiOptions.length === 0 }
        return changedProps
      },
      renderer: getBadgeFormatterForKodif(kodif.LIC_TIPI),
    },
    {
      name: 'faili',
      label: 'Pielikumi',
    }, {
      name: 'izpetes_gads',
      validation: (values) => validateYears(values.izpetes_gads)
    }, {
      ...getKlasifikatorsFieldCfg(kodif, 'KRAJ_STATUSS'),
      name: 'krajumu_statuss',
    }, {
      ...getKlasifikatorsFieldCfg(kodif, 'KRAJ_APR_VEIDS'),
      name: 'krajumu_apr_veids',
    }, {
      ...getKlasifikatorsFieldCfg(kodif, 'KRAJ_IEMESLI'),
      name: 'derigs_lidz_iemesls',
    }, irParbauditiField(),
    {
      name: 'daudzums_zem_udens',
      validation: (row) => row.daudzums != null && row.daudzums_zem_udens != null && row.daudzums_zem_udens > row.daudzums ? 'Daudzums "zem ūdens" ir ievadīts lielāks par kopējo daudzumu' : null,
      customField: (props) => {
        const { currentValRow } = props
        return <>
          <FormGroup check inline>
            <InputField
              {...props}
            />
          </FormGroup>
          <FormGroup check inline>
            <Label check>
              {currentValRow.daudzuma_merv ?
                <span dangerouslySetInnerHTML={{ __html: currentValRow.daudzuma_merv }}></span>
                : null
              }
            </Label>
          </FormGroup>
        </>
      },
      renderer: (daudzZU, values) => daudzZU != null ? <><b> {daudzZU} </b><span dangerouslySetInnerHTML={{ __html: values.daudzuma_merv }}></span></> : null,
    }, {
      name: 'daudzums_tukst_t'
    }, {
      name: 'daudzums_tukst_mkub'
    },
    {
      name: 'apjomi',
      createNewRow: () => ({ ir_parbauditi: true }),
      multiFieldCfgs: [
        {
          name: 'child_geom_select_btn',
          text: '',
          // couldn't pass down the redux dispatch, so window.the_store.dispatch is used
          renderer: (cellContent, row) => <Button
            color="secondary"
            size="sm"
            onClick={() => window.the_store.dispatch(childGeomSelectAct({ id: row.id, key: row.key }))} >
            <i title="Rādīt koordinātes" className="fa fa-map-marker"></i>
          </Button>,
          editable: false
        },
        {
          name: 'id',
          editable: false,
          fieldEditable: false,
          renderer: IDRenderer
        },
        {
          name: 'atradne',
          type: 'autocomplete',
          autocompleteCfg: {
            isValueObjStructure: true,
          },
          overridePropsFunc: (props) => {
            const changedProps = { ...props }
            const { currentValRow, dataObj } = props
            changedProps.autocompleteCfg = { ...changedProps.autocompleteCfg, options: dataObj.atr_options.filter(ao => ao.ieraksta_veids === 'D') }
            currentValRow.atradne_id = currentValRow.atradne ? currentValRow.atradne.id : null
            return changedProps
          },
          renderer: (atr) => atr && atr.ieraksta_veids === 'D' ? <AtradneOrDalaLink
            id={atr.id}
            numurs={atr.numurs}
            ieraksta_veids={atr.ieraksta_veids}
            nosaukums={atr.tah_label}
            kodif={kodif} /> : null
        },
        {
          name: 'dalas_nosaukums'
        },
        {
          ...getMultiKlasifikatorsFieldCfg(kodif, 'DIZRAKT'),
          name: 'izrakteni',
          required: true,
          overridePropsFunc: (props) => {
            const changedProps = { ...props }
            const { currentValues, currentValRow } = props
            let optionsIzr = []
            if (currentValues.atradne) {
              let izrakteni = currentValues.atradne.izrakteni
              if (currentValRow.atradne) {
                izrakteni = currentValRow.atradne.izrakteni
              }
              const atradnesIzrakteni = izrakteni.map(izr => izr.klasifikators)
              optionsIzr = Object.keys(kodif.DIZRAKT).filter(k => atradnesIzrakteni.indexOf(k) > -1).map(kodifKeyToTypeaheadOption(kodif.DIZRAKT))
              changedProps.errorForField = optionsIzr.length === 0 ? TEXT_ERR_DERIGIE : changedProps.errorForField
            }
            changedProps.autocompleteCfg = { ...changedProps.autocompleteCfg, options: optionsIzr }
            return changedProps
          }
        },
        {
          name: 'kategorija',
          type: 'autocomplete',
          autocompleteCfg: {
            options: getDefaultTypeaheadOptionsFromKodif(_.pickBy(kodif.kategorijas, (v => v.ievadeDokumentacija))),
          },
          required: true,
          renderer: getBadgeFormatterForKodif(kodif.kategorijas)
        },
        {
          name: 'platiba_apr',
          label: 'Platība',
          type: 'text',
          editable: false,
          fieldEditable: false,
          renderer: (platiba, dataObj) => platiba != null ? <><p className="no-margin"><b>{round(platiba, 2)}</b> tūkst. m2</p><p className="no-margin"><b>{round(dataObj.platiba_apr_ha, 2)}</b> ha </p></> : ''
        },
        {
          name: 'daudzums',
          required: true,
          customField: (props) => {
            const { currentValRow } = props
            let izrMervienibas = []
            if (currentValRow.izrakteni) {
              izrMervienibas = currentValRow.izrakteni.flatMap(i => kodif.DIZRAKT[i.klasifikators].daudzuma_merv).map(m => m.nos)
            }
            const irIzmainas = currentValRow.izmainas && currentValRow.izmainas.length > 0
            return <><DaudzumsField
              {...props}
              currentValuesObj={currentValRow}
              izrMervienibas={izrMervienibas}
              kodif={kodif}
              extraFieldProps={{ disabled: irIzmainas }}
            />
              {irIzmainas ?
                <div className="notes">Šim limita apjomam ir ievadītas krājumu izmaiņas, lai labotu apjomu jāveido jauna izmaiņa ar "Koriģēt" pogu.</div>
                : null}</>
          },
          validation: (values) => {
            if (values.daudzums != null && !values.daudzuma_merv) {
              return 'Daudzuma mērvienība ir obligāti jānorāda!'
            }
            return null
          },
          renderer: (daudz, row, props) => {
            if (daudz != null) {
              let korIzmainas = []
              let korDaudzums = daudz
              if (row.izmainas) {
                korIzmainas = row.izmainas.filter(izm => izm.izmainas_veids_nosaukums && kodif.IZMAINA[izm.izmainas_veids_nosaukums] && kodif.IZMAINA[izm.izmainas_veids_nosaukums].ir_limitiem)
                  .reduce((newIzm, izm, ind) => {
                    const before = ind === 0 ? daudz : newIzm[ind - 1].after
                    newIzm.push({
                      ...izm,
                      before,
                      after: before + izm.daudzums,
                      absDaudzums: Math.abs(izm.daudzums),
                      sign: izm.daudzums < 0 ? "-" : "+"
                    })
                    return newIzm
                  }, [])
                if (korIzmainas.length > 0) {
                  korDaudzums = korIzmainas[korIzmainas.length - 1].after
                }
              }
              return <LimitaApjomaDaudzums korDaudzums={korDaudzums} korIzmainas={korIzmainas} row={row} atradne={props.dataObj.atradne} user={user} />
            }
            return null
          }
        },
        {
          name: 'krajumu_stavoklis_uz_datums'
        },
        {
          name: 'piezimes',
          type: 'text',
        },
        irParbauditiField()
      ],
      onSelectMultiFieldRow: (row) => {
        window.the_store.dispatch(childGeomSelectAct({ id: row.id, key: row.key }))
      },
      warnings: (allValues) => {
        if (allValues.licence && allValues.apjomi) {
          const licApj = allValues.licence.licences_apjomi
          if (licApj && licApj.length > 0) {
            // ja kādam licences apjomam nav aizpildīta kategorija, tad salīdzinām bez kategorijas
            const withKategorija = licApj.filter(la => !la.kategorija).length === 0
            const sumByDerigais = (sumObj, la) => {
              const key = withKategorija ? `${la.kategorija} kat. ${reformatIzrakteni(la.q_izrakteni_sep)}` : reformatIzrakteni(la.q_izrakteni_sep)
              if (sumObj[key] == null) {
                sumObj[key] = la.daudzums
              } else {
                sumObj[key] = sumObj[key] + la.daudzums
              }
              return sumObj
            }
            const licApjByDer = licApj.filter(la => la.daudzums != null && !!la.q_izrakteni_sep).reduce(sumByDerigais, {})
            const limituApjomi = allValues.licence.piesaistitie_limita_apjomi.filter(la => la.limits_id !== allValues.id)
            const limituApjomiByDer = limituApjomi.reduce(sumByDerigais, {})
            const currentApjomi = allValues.apjomi.filter(apj => apj.daudzums != null && (apj.kategorija || !withKategorija) && apj.izrakteni && apj.izrakteni.length > 0).reduce((sumObj, apj) => {
              const izrKey = apj.izrakteni.map(izr => izr.klasifikators).sort().join(', ')
              const key = withKategorija ? `${apj.kategorija} kat. ${izrKey}` : izrKey
              if (sumObj[key] == null) {
                sumObj[key] = apj.daudzums
              } else {
                sumObj[key] = sumObj[key] + apj.daudzums
              }
              return sumObj
            }, {})
            let warning = ''
            Object.keys(currentApjomi).forEach(der => {
              if (licApjByDer[der] != null) {
                const limituApjomiCitos = limituApjomiByDer[der] ? limituApjomiByDer[der] : 0.0
                if (limituApjomiCitos + currentApjomi[der] > licApjByDer[der]) {
                  warning = `${warning} Apjoms limitos der. izraktenim: "${der}" pārsniedz Licencē norādīto!`
                }
              }
            })
            return warning ? warning : null
          }
        }
        return null
      },
    }
  ]
  const apjomiFieldMultiCfgs = fields.find(c => c.name === 'apjomi').multiFieldCfgs
  const multiFieldCfgsLicencesApjomi = [
    { ...apjomiFieldMultiCfgs.find(c => c.name === 'child_geom_select_btn') },
    { ...apjomiFieldMultiCfgs.find(c => c.name === 'id') },
    { ...apjomiFieldMultiCfgs.find(c => c.name === 'atradne') },
    { ...apjomiFieldMultiCfgs.find(c => c.name === 'dalas_nosaukums') },
    { ...apjomiFieldMultiCfgs.find(c => c.name === 'izrakteni') },
    { ...apjomiFieldMultiCfgs.find(c => c.name === 'kategorija'), required: false },
    { ...apjomiFieldMultiCfgs.find(c => c.name === 'platiba_apr') },
    {
      name: 'daudzums',
      customField: (props) => {
        const { currentValRow } = props
        let izrMervienibas = []
        if (currentValRow.izrakteni) {
          izrMervienibas = currentValRow.izrakteni.flatMap(i => kodif.DIZRAKT[i.klasifikators].daudzuma_merv).map(m => m.nos)
        }
        return <DaudzumsField
          {...props}
          currentValuesObj={currentValRow}
          izrMervienibas={izrMervienibas}
          kodif={kodif}
        />
      },
      renderer: (daudzums, values) => daudzums != null ? <><b> {daudzums} </b><span dangerouslySetInnerHTML={{ __html: values.daudzuma_merv }}></span></> : null,
    },
    { ...apjomiFieldMultiCfgs.find(c => c.name === 'piezimes') }
  ]
  fields.push({
    name: 'licences_apjomi',
    multiFieldCfgs: multiFieldCfgsLicencesApjomi,
    onSelectMultiFieldRow: (row) => {
      window.the_store.dispatch(childGeomSelectAct({ id: row.id, key: row.key }))
    }
  })
  const multiFieldCfgsPasesSadalijumi = [
    { ...apjomiFieldMultiCfgs.find(c => c.name === 'child_geom_select_btn') },
    { ...apjomiFieldMultiCfgs.find(c => c.name === 'id') },
    { ...apjomiFieldMultiCfgs.find(c => c.name === 'atradne') },
    { ...apjomiFieldMultiCfgs.find(c => c.name === 'dalas_nosaukums') },
    { ...apjomiFieldMultiCfgs.find(c => c.name === 'izrakteni') },
    {
      name: 'kategorijasObj',
      type: 'multicheckbox',
      renderer: getMultiBadgeFormatterForKodif(kodif.kategorijas),
      options: _.pickBy(kodif.kategorijas, (v => v.ievadeDokumentacija))
    },
    { ...apjomiFieldMultiCfgs.find(c => c.name === 'platiba_apr') },
    { ...apjomiFieldMultiCfgs.find(c => c.name === 'piezimes') }
  ]
  fields.push({
    name: 'pase_sadalijumi',
    multiFieldCfgs: multiFieldCfgsPasesSadalijumi,
    createNewRow: () => ({ kategorijasObj: { A: false, N: false } }),
    onSelectMultiFieldRow: (row) => {
      window.the_store.dispatch(childGeomSelectAct({ id: row.id, key: row.key }))
    }
  })
  return fields
}

const getKoordLayer = (layerTitles, koords, selectedKoord, editable) => ({
  title: layerTitles.koord,
  features: koords,
  styles: maputils.borderPointStyles,
  tooltipFunc: (feat) => `Nr ${feat.get('id')} (${feat.get('x')};${feat.get('y')})`,
  selected: selectedKoord,
  zIndex: 15,
  editable: editable,
  legend: maputils.defaultSinglePointLegend
})

const getKoordLabelsLayer = (layerTitles, koords) => ({
  title: layerTitles.koord_labels,
  features: koords,
  styles: maputils.borderPointStylesLabels,
  selectable: false,
  zIndex: 20,
  declutter: true
})

const getDbLabelLayer = (layerTitles, obj) => ({
  title: layerTitles.number_label,
  features: obj,
  styles: maputils.dbNumberLabelStyleLabel,
  selectable: true,
  zIndex: 20,
  declutter: true
})

export const getVectorLayerCfgs = ({ geomObj, layerTitles, editableLayerIdName, childGeometryEditing, childGeometryTooltipFunc }) => {
  const vectorLayerCfgs = {}
  if (geomObj.koordsFeat || childGeometryEditing) {
    vectorLayerCfgs.feat_border = {
      title: layerTitles.feat_border,
      features: childGeometryEditing ? geomObj.childKoords : geomObj.koordsFeat,
      styles: maputils.kartinasStyles.atradneBorderCurrent,
      legend: maputils.defaultSinglePolygonLegend,
      selectable: false
    }
    vectorLayerCfgs.feat = {
      ...vectorLayerCfgs.feat_border,
      title: layerTitles.feat,
      styles: maputils.kartinasStyles.atradneFillCurrent,
      opacity: 0.1,
      tooltipFunc: childGeometryTooltipFunc ? childGeometryTooltipFunc : undefined,
      zoomTo: childGeometryEditing ? (geomObj.childKoords && geomObj.childKoords[0] ? geomObj.childKoords[0].id : undefined) : geomObj.koordsFeat.id,
      legend: maputils.defaultSinglePolygonLegend,
      zoomToOnlyFirstTime: true
    }
  }
  if (!childGeometryEditing) {
    vectorLayerCfgs[editableLayerIdName] = getKoordLayer(layerTitles, geomObj.koords, geomObj.selectedKoord, geomObj.editable)
    if (geomObj.koordsFeat && layerTitles.number_label) {
      vectorLayerCfgs.number_label = getDbLabelLayer(layerTitles, geomObj.koordsFeat)
    }
    if (geomObj.koords.length > 1) {
      vectorLayerCfgs.koord_labels = getKoordLabelsLayer(layerTitles, geomObj.koords)
    }
  } else {
    const activeGeom = geomObj.childKoords.find(ck => ck.active)
    if (activeGeom) {
      vectorLayerCfgs[editableLayerIdName] = getKoordLayer(layerTitles, activeGeom.koords, geomObj.selectedKoord, geomObj.editable)
      vectorLayerCfgs.koord_labels = getKoordLabelsLayer(layerTitles, activeGeom.koords)
    }
  }

  return vectorLayerCfgs
}

class DokumentacijasKartinaComp extends Component {

  mapPaneSizeChange = (w) => {
    this.props.guiSet(this.props.karteWidthProp, w)
  }

  mapPaneSizeChangeHor = (h) => {
    this.props.guiSet('dokumentacija_karte_height', h)
  }

  render() {
    const { geomObj, layerTitles, editableLayerIdName, mapId, formFieldCfgs, karteWidthProp,
      gui, saveAct, stopEditAct, startEditAct, deleteAct, koordSelectAct, koordChangeAct,
      showErrorMsg, sizeForm, sizeLabel, metadataFormCfg, vectorLayerCfgs, childGeometryEditing,
      childGeometryTooltipFunc, isPointGeometry } = this.props

    const { editable, submiting, selectedItem, errorNotFound } = geomObj

    if (errorNotFound) {
      return <h5 className="margin-10">Detalizētāka informācija par dokumentāciju nav pieejama</h5>
    }

    if (!selectedItem) {
      return (<Loading />)
    }

    const showCoordsBtn = childGeometryEditing ? null : <ShowKoordTableBtn />

    const vectorLayerCfg = vectorLayerCfgs || getVectorLayerCfgs({ geomObj, layerTitles, editableLayerIdName, childGeometryEditing, childGeometryTooltipFunc })

    const activeChildGeom = childGeometryEditing && geomObj.childKoords.find(ck => ck.active)

    const largeScreen = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0) > 900

    return (<>
      <SplitterLayout
        key={largeScreen ? 'hor' : 'ver'}
        vertical={!largeScreen}
        customClassName="pane-paddings-hor"
        percentage
        primaryIndex={0}
        secondaryInitialSize={largeScreen ? gui[karteWidthProp] : this.props.gui.dokumentacija_karte_height}
        onSecondaryPaneSizeChange={largeScreen ? this.mapPaneSizeChange : this.mapPaneSizeChangeHor}>
        <div className={'form-pane-scrollable' + (largeScreen ? '' : ' topside')}>
          <Form
            sizeForm={sizeForm}
            sizeLabel={sizeLabel}
            editable={editable}
            submiting={submiting}
            dataObj={selectedItem}
            formFieldCfgs={formFieldCfgs}
            onSubmit={saveAct}
            onReset={stopEditAct}
            onStartEdit={startEditAct}
            onDelete={deleteAct}
            extraElements={showCoordsBtn}
            metadataFormCfg={metadataFormCfg}
            tiesibasLabot={this.props.tiesibasLabot || "LABOT_DOKUMENTACIJU"}
          />
          {childGeometryEditing ? null :
            <KoordTable
              editable={geomObj.editable}
              koords={geomObj.koords}
              isPointGeometry={isPointGeometry}
              selectedKoord={geomObj.selectedKoord}
              validation={geomObj.koordsFeat && geomObj.koordsFeat.validation}
              koordSelectAct={koordSelectAct}
              koordChangeAct={koordChangeAct}
              showErrorMsg={showErrorMsg}
            />
          }
        </div>
        {childGeometryEditing && activeChildGeom ?
          <SplitterLayout
            vertical
            percentage
            primaryIndex={1}
            secondaryInitialSize={50}>
            <IzraktenisMap mapId={mapId} vectorLayerCfgs={vectorLayerCfg} />
            <div className="form-pane-scrollable">
              <KoordTable
                editable={geomObj.editable}
                koords={activeChildGeom.koords}
                isPointGeometry={isPointGeometry}
                selectedKoord={geomObj.selectedKoord}
                validation={activeChildGeom.koordsFeat && activeChildGeom.koordsFeat.validation}
                koordSelectAct={koordSelectAct}
                koordChangeAct={koordChangeAct}
                showErrorMsg={showErrorMsg}
                geomTitle={activeChildGeom.id ? `ID ${activeChildGeom.id}` : null}
                alwaysShow
              />
            </div>
          </SplitterLayout>
          :
          <div className="rightside">
            <div className="karte-wrapper">
              <IzraktenisMap mapId={mapId} vectorLayerCfgs={vectorLayerCfg} />
            </div>
          </div>
        }
      </SplitterLayout>
    </>
    )
  }
}

const mapStateToProps = ({ gui }) => ({ gui })
export const DokumentacijasKartina = connect(mapStateToProps, { guiSet, showErrorMsg })(DokumentacijasKartinaComp)
