import * as helpers from "./helpers";

const defaultListReducerForImports = helpers.createListReducer({listViewName: 'bais_virszemes_imports', actionsName: 'bais_virszemes_imports'})
const defaultEditReducerForImports = helpers.createEditReducer({editViewName: 'bais_virszemes_imports', actionsName: 'bais_virszemes_imports'})
export const bais_virszemes_imports = (state = helpers.listEditGetInitialState(), {type, payload, reqId}) => {
  //overwrite or add custom actions catches here

  const stateAfterList = defaultListReducerForImports({state, type, payload, reqId})
  return defaultEditReducerForImports({state: stateAfterList, type, payload, reqId})
}

const defaultListReducerForPazemesImports = helpers.createListReducer({listViewName: 'bais_pazemes_imports', actionsName: 'bais_pazemes_imports'})
const defaultEditReducerForPazemesImports = helpers.createEditReducer({editViewName: 'bais_pazemes_imports', actionsName: 'bais_pazemes_imports'})
export const bais_pazemes_imports = (state = helpers.listEditGetInitialState(), {type, payload, reqId}) => {
  const stateAfterList = defaultListReducerForPazemesImports({state, type, payload, reqId})
  return defaultEditReducerForPazemesImports({state: stateAfterList, type, payload, reqId})
}

const defaultListReducerForHidrogeoImports = helpers.createListReducer({listViewName: 'hidrogeo_imports', actionsName: 'hidrogeo_imports'})
const defaultEditReducerForHidrogeoImports = helpers.createEditReducer({editViewName: 'hidrogeo_imports', actionsName: 'hidrogeo_imports'})
export const hidrogeo_imports = (state = helpers.listEditGetInitialState(), {type, payload, reqId}) => {
  //overwrite or add custom actions catches here

  const stateAfterList = defaultListReducerForHidrogeoImports({state, type, payload, reqId})
  return defaultEditReducerForHidrogeoImports({state: stateAfterList, type, payload, reqId})
}