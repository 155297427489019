export const uploadSize = (state = {
    requested: false,
    loaded: false,
}, { type, payload }) => {
    switch (type) {
        case 'upload_size_limit_get_request':
            return { ...state, requested: true }
        case 'upload_size_limit_get_success':
            return { ...state, smallFileLimit: payload.small_file_limit_bytes, largeFileLimit: payload.large_file_limit_bytes, loaded: true }
        default:
            return state
    }
}