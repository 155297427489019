import {callApi, getDefaultEditActions} from './helpers'

export const loadAdresati = (query) => (dispatch) => {
    return dispatch(callApi({
      viewName: 'adresats_edit',
      crudMethod: 'listPaging',
      crudParam: query
    }))
}

export const adresatsSelected = (id) => {
    return { type: 'adresats_selected', payload : id }
}

const afterSaveOrDelete = (dispatch, getState) => {
        //reload table adresati
        dispatch(loadAdresati(getState().location.query))
}

export const adresatsActions = getDefaultEditActions({
    actionTypeName : 'adresats',
    stateName: 'adresati',
    combinedPage: true,
    apiViewName: 'adresats_edit',
    afterDelete: afterSaveOrDelete,
    afterSave: afterSaveOrDelete,
})

export const replaceAdresats = (fromAdresatsId, toAdresatsId) => (dispatch, getState) => {
    const success = () => {
        dispatch(loadAdresati(getState().location.query));
    }
    return dispatch(callApi({
        viewName: 'adresats_aizstat',
        crudMethod: 'delete',
        crudParam: [fromAdresatsId, 'toAdresatsId', toAdresatsId],
        success
    }))
}
