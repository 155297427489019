import React, {Component} from 'react';
import {connect} from 'react-redux'
import {urbumuIeguvesActions, urbumuIeguveRowSelected} from '../../actions/hidrogeoActions'
import Loading from '../Loading'
import SplitterLayout from 'react-splitter-layout'
import Form from '../forms/Form'
import IzraktenisMap from '../karte/IzraktenisMap'
import {guiSet} from '../../actions/uiActions'
import BootstrapTable from 'react-bootstrap-table-next'
import {columnCfgToBootstrapTableColumn} from '../grid/Grid'
import * as maputils from '../karte/maputils'
import {
  getEditIconColumn
} from '../grid/ColumnHelpers'

const  getFormFieldCfgs = ({kodif}) => {
    return [
      { name: 'id', renderClassName: "bold"},
      { name: 'nosaukums', renderClassName: "bold"},
      { name: 'min_gads'},
      { name: 'max_gads'}
    ]
}

const editIconColumn = getEditIconColumn((row) => (
  {
      type: 'loc_urbums',
      payload: {
          id: row.urb_id
      }
  }
  )
)

const getColumns = ({kodif}) => {
  const columns = [editIconColumn, {
      dataField: 'id',
      text: 'ID',
      width: 50
  },{
      dataField: 'gads',
      text: 'Gads',
      width: 50
  },{
      dataField: 'stundu_skaits',
      text: 'Stundu skaits dnn',
      width: 100
  },{
      dataField: 'fakt_ieguve',
      text: 'Faktiskā ieguve',
      width: 100
  },{
      dataField: 'apr_ieguve',
      text: 'Aprēķinātā ieguve',
      width: 100
  },{
      dataField: 'urb_id',
      text: 'Urbuma Nr.',
      width: 100
  },{
      dataField: 'urb_nr_pirmavota',
      text: 'Urbuma Nr. pēc pirmavota',
      width: 100
  },{
      dataField: 'urb_adrese',
      text: 'Urbuma adrese un piederība'
  },{
      dataField: 'urb_organizacija',
      text: 'Urbuma organizācija'
  }]
  return columns.map( col=> columnCfgToBootstrapTableColumn(col))
}

class UrbumaIeguve extends Component {

    mapPaneSizeChange = (w) => {
      this.props.guiSet('urbumu_ieguve_kartina_karte_width', w);
    }

    mapPaneSizeChangeHor = (h) => {
      this.props.guiSet('urbumu_ieguve_kartina_karte_height', h);
    }

    handleOnSelect = (row, isSelect) => {
      this.props.urbumuIeguveRowSelected({id: row.id, isSelect})
    }

    render() {

      const {urbumu_ieguve, kodif, gui} = this.props

      const {selectedItem, selectedUrbums, selectedIeguves} = urbumu_ieguve

      if (!selectedItem) {
          return (<Loading/>)
      }

      if(!this.formFieldCfgs){
        this.formFieldCfgs = getFormFieldCfgs({
            kodif: kodif
        })
      }

      if(!this.columns){
        this.columns = getColumns({kodif})
      }

      const vectorLayerCfg = {
        urbumi: {
          title: 'Urbumi',
          features: selectedItem.urbumi,
          styles: maputils.urbumiStyles,
          tooltipFunc: (feat) => `${feat.get('id')}: ${feat.get('urb_nr_pirmavota') ? feat.get('urb_nr_pirmavota') : ''} ${feat.get('urb_adrese') ? feat.get('urb_adrese') : ''}`,
          selected: selectedUrbums,
          legend: maputils.urbumiLegend
        }
      }

      const largeScreen = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0) > 900

      return (
        <SplitterLayout
                  key={largeScreen ? 'hor' : 'ver'}
                  vertical={!largeScreen}
                  customClassName="pane-paddings-hor"
                  percentage
                  primaryIndex={0}
                  secondaryInitialSize={largeScreen ? gui.urbumu_ieguve_kartina_karte_width : gui.urbumu_ieguve_kartina_karte_height}
                  onSecondaryPaneSizeChange={largeScreen ? this.mapPaneSizeChange : this.mapPaneSizeChangeHor}>
        <div className={'form-pane-scrollable' + (largeScreen ? '' : ' topside')}>
              <Form
                  editable={false}
                  dataObj={selectedItem}
                  formFieldCfgs={this.formFieldCfgs}
                  metadataFormCfg="urbumi_ieguves_vietas_view"
              />
              <h4>Ieguve</h4>
              <BootstrapTable
                  bootstrap4
                  striped
                  hover
                  condensed
                  keyField="id"
                  data={ selectedItem.urbumi_ieguves }
                  columns={ this.columns }
                  selectRow={{
                        mode: 'radio',
                        clickToSelect: true,
                        hideSelectColumn: true,
                        classes: 'selection-row',
                        onSelect: this.handleOnSelect,
                        selected: selectedIeguves ? selectedIeguves : []
                  }}
              />
        </div>
        <div className="rightside">
          <div className="karte-wrapper">
              <IzraktenisMap mapId="urbumu_ieguve_kartina" vectorLayerCfgs={vectorLayerCfg}/>
          </div>
        </div>
        </SplitterLayout>
      )
    }
}

const mapStateToProps = ({urbumu_ieguve, kodif, gui}) => ({urbumu_ieguve, kodif, gui})
export default connect(mapStateToProps, {...urbumuIeguvesActions, guiSet, urbumuIeguveRowSelected})(UrbumaIeguve)
