import React, {Component} from 'react'
import {connect} from 'react-redux'
import Loading from '../Loading'
import {columnCfgToBootstrapTableColumn} from '../grid/Grid'
import BootstrapTable from 'react-bootstrap-table-next'
import Form from '../forms/Form'
import { Button } from 'reactstrap'
import {getCommonColumns, getEditColumnForDok, getLinkParams,
  getDalaColumnWithNosaukumsLabel, getCommonFormFieldCfgs} from '../dokumentacija/common'
import Link from 'redux-first-router-link'
import {atradneKrajumsSelected, atradneKrajumsSum, atradneKrajumsClearSum, krajumiActions, dalaKrajumiActions} from '../../actions/atradnesActions'
import {openDok} from '../../actions/dokumentacijaActions'
import {PlusButton, SummingResultPanel, getDalaFieldCfg,
  getMultiKlasifikatorsFieldCfg, kodifKeyToTypeaheadOption, checkAtradnesTypeFromVeids,
  getKudrasSpecificFields, getSapropelisSpecificFields, getAtradnesVeidsFromIzraktKraj} from '../forms/FormHelpers'
import {TEXT_ERR_DERIGIE} from '../../constants'

const getKrajumiColumns = ({kodif, numurs, isDala, atrId, atradneKrajumsSum, atrVeids}) => {

  const commonColumns = getCommonColumns({kodif, locijums: 'P'})

  const columns = [
    getEditColumnForDok({loc: 'loc_atradnes_krajums', locDala:'loc_atradnes_dala_krajums', numurs, isDala, atrId}),
    commonColumns.find(c => c.dataField === 'id'),
    getDalaColumnWithNosaukumsLabel({numurs, isDala, atrId}),
    {
      ...commonColumns.find(c => c.dataField === 'izrakteni'),
      formatter : (izr) => izr.map(i => i.klasifikators).join(', '),
      sortValue: (izr) => izr.map(i => i.klasifikators).join(' '),
    },
    commonColumns.find(c => c.dataField === 'izpetes_gads'),
    {
      dataField: 'krajumu_statuss',
      text: 'Krājumu statuss',
      width: 180
    },{
      dataField: 'krajumu_apr_veids',
      text: 'Krājumu aprēķina veids',
      width: 140
    },
    commonColumns.find(c => c.dataField === 'kategorija')
  ]
  if(checkAtradnesTypeFromVeids(atrVeids, 'B')){
    columns.push({
      dataField: 'daudzums',
      text: 'Krājumi / resursi (kopā)' + (atrVeids !== 'B' ? ' (būvm.)' : ''),
      formatter: (daudzums, row) => {
        if(daudzums != null){
          return <>
                  <b>{daudzums} </b><span dangerouslySetInnerHTML={{__html: row.daudzuma_merv }}></span>
                  <PlusButton onClick={atradneKrajumsSum} row={row} fieldName='daudzums'/>
                </>
        }
        return null
      },
      width: 130,
      align: 'right'
    },
    {
      dataField: 'daudzums_zem_udens',
      text: 'Krājumi / resursi (zem ūdens)' + (atrVeids !== 'B' ? ' (būvm.)' : ''),
      formatter: (daudzums, row) => {
        if(daudzums != null){
          return <>
                  {daudzums} <span dangerouslySetInnerHTML={{__html: row.daudzuma_merv }}></span>
                  <PlusButton onClick={atradneKrajumsSum} row={row} fieldName='daudzums_zem_udens'/>
                </>
        }
        return null
      },
      width: 130,
      align: 'right'
    })
  }

  if(checkAtradnesTypeFromVeids(atrVeids, 'KD')){
    columns.push({
      dataField: 'kudras_tips',
      text: 'Kūdras veids',
      width: 140
    }, {
      dataField: 'kudras_sadal_pak',
      text: 'Sadalīšanās pakāpe',
      width: 140
    })
  }

  if(checkAtradnesTypeFromVeids(atrVeids, 'S')){
    columns.push({
      dataField: 'saprop_klase',
      text: 'Sapropeļa klase',
      width: 140
    }, {
      dataField: 'saprop_tips',
      text: 'Sapropeļa tips',
      width: 140
    }, {
      dataField: 'saprop_veids',
      text: 'Sapropeļa veids',
      width: 140
    })
  }

  if(checkAtradnesTypeFromVeids(atrVeids, 'KDSC')){
    columns.push({
      dataField: 'daudzums_tukst_t',
      text: 'Krājumi / resursi, tūkst.t'  + (checkAtradnesTypeFromVeids(atrVeids, 'B') ? ' (kūdra, sapr. un dūņas)' : ''),
      formatter: (daudzums, row) => {
        if(daudzums != null){
          return <>
                  <b>{daudzums}</b>
                  <PlusButton onClick={atradneKrajumsSum} row={row} fieldName='daudzums_tukst_t'/>
                </>
        }
        return null
      },
      width: 130,
      align: 'right'
    }, {
      dataField: 'daudzums_tukst_mkub',
      text: 'Krājumi / resursi, tūkst.m3' + (checkAtradnesTypeFromVeids(atrVeids, 'B') ? ' (kūdra, sapr. un dūņas)' : ''),
      formatter: (daudzums, row) => {
        if(daudzums != null){
          return <>
                  <b>{daudzums}</b>
                  <PlusButton onClick={atradneKrajumsSum} row={row} fieldName='daudzums_tukst_mkub'/>
                </>
        }
        return null
      },
      width: 130,
      align: 'right'
    })
  }

  columns.push(
    {
      ...commonColumns.find(c => c.dataField === 'sakums_datums'),
      text : 'Datums, uz kuru attiecas krājumi'
    },
    {
      ...commonColumns.find(c => c.dataField === 'derigs_lidz_datums'),
      text : 'Datums, līdz kuram ieraksts ir spēkā'
    },
    {
      dataField: 'derigs_lidz_iemesls',
      text: 'Iemesls (zaudējot spēku)',
      width: 130
    },
    commonColumns.find(c => c.dataField === 'piezimes'),
    commonColumns.find(c => c.dataField === 'ir_parbauditi')
    )

  return columns.map( col => columnCfgToBootstrapTableColumn(col))
}

const disableByIzraktenisVeids = (kodif, enableForVeidi) => (props) => {
  const changedProps = {...props}
  const {currentValRow} = changedProps
  const izr = currentValRow.izrakteni
  if(izr && izr.length > 0){
      const veidi = izr.map(i => getAtradnesVeidsFromIzraktKraj(kodif, i.klasifikators)).join('')
      changedProps.extraFieldProps = {disabled : !checkAtradnesTypeFromVeids(veidi, enableForVeidi)}
  } else {
    changedProps.extraFieldProps = {disabled : true}
  }
  return changedProps
}

const getFormFieldCfgs = (isDala = false) => ({kodif, atrVeids}) => {
  const commonFields = [...getCommonFormFieldCfgs({kodif}), ...getKudrasSpecificFields(kodif), ...getSapropelisSpecificFields(kodif)]
  const multiFieldCfgs = [
    commonFields.find(cf => cf.name === 'id'),
    getDalaFieldCfg(isDala),
    {
      ...getMultiKlasifikatorsFieldCfg(kodif, 'IZR_KRAJ'),
      name: 'izrakteni',
      required: true,
      overridePropsFunc: (props) => {
        const changedProps = {...props}
        const {dataObj, currentValRow} = changedProps
        //by default take from atradne
        let izrakteni = dataObj.izrakteni
        if(currentValRow.dala_id && dataObj.id !== currentValRow.dala_id){
          // take izrakteni from dala
          const dala = dataObj.dalas.find(d => d.id === currentValRow.dala_id)
          if(dala){
            izrakteni = dala.dalas_izrakteni
          }
        }
        const atradnesIzrakteni = izrakteni.map(izr => izr.klasifikators)
        const atrIzrakteniKlas = Object.keys(kodif.IZR_KRAJ).filter(k => atradnesIzrakteni.indexOf(kodif.IZR_KRAJ[k].derigais) > -1).map(kodifKeyToTypeaheadOption(kodif.IZR_KRAJ))
        changedProps.autocompleteCfg = {...changedProps.autocompleteCfg, options : atrIzrakteniKlas}
        changedProps.extraFieldProps = {disabled : atrIzrakteniKlas.length === 0}
        changedProps.errorForField = atrIzrakteniKlas.length === 0 ? TEXT_ERR_DERIGIE : changedProps.errorForField
        return changedProps
      }
    },
    commonFields.find(cf => cf.name === 'izpetes_gads'),
    commonFields.find(cf => cf.name === 'krajumu_statuss'),
    commonFields.find(cf => cf.name === 'krajumu_apr_veids'),
    commonFields.find(cf => cf.name === 'kategorija')
  ]
  if(checkAtradnesTypeFromVeids(atrVeids, 'B')){
    multiFieldCfgs.push({
      ...commonFields.find(cf => cf.name === 'daudzums'),
      required: atrVeids === 'B',
    },
    commonFields.find(cf => cf.name === 'daudzums_zem_udens')
    )
  }

  if(checkAtradnesTypeFromVeids(atrVeids, 'KD')){
    const fields = [
      commonFields.find(cf => cf.name === 'kudras_tips'),
      commonFields.find(cf => cf.name === 'kudras_sadal_pak')
    ]
    fields.forEach(f => f.overridePropsFunc = disableByIzraktenisVeids(kodif, 'KD'))
    multiFieldCfgs.push(
      ...fields
    )
  }

  if(checkAtradnesTypeFromVeids(atrVeids, 'S')){
    const fields = [
      commonFields.find(cf => cf.name === 'saprop_klase'),
      commonFields.find(cf => cf.name === 'saprop_tips'),
      commonFields.find(cf => cf.name === 'saprop_veids')
    ]
    fields.forEach(f => f.overridePropsFunc = disableByIzraktenisVeids(kodif, 'S'))
    multiFieldCfgs.push(
      ...fields
    )
  }

  if(checkAtradnesTypeFromVeids(atrVeids, 'KDSC')){
    const fields = [
      commonFields.find(cf => cf.name === 'daudzums_tukst_t'),
      commonFields.find(cf => cf.name === 'daudzums_tukst_mkub')
    ]
    fields.forEach(f => f.overridePropsFunc = disableByIzraktenisVeids(kodif, 'KDSC'))
    multiFieldCfgs.push(
      ...fields
    )
  }

  multiFieldCfgs.push({
    ...commonFields.find(cf => cf.name === 'sakums_datums'),
    required: false,
  },
  {
    ...commonFields.find(cf => cf.name === 'derigs_lidz_datums'),
    required: false,
  },
  commonFields.find(cf => cf.name === 'derigs_lidz_iemesls'),
  {
    ...commonFields.find(cf => cf.name === 'piezimes'),
    type: 'text'
  },
  commonFields.find(cf => cf.name === 'ir_parbauditi'))
  return [{
      name: 'krajumi',
      label: undefined,
      multiFieldCfgs: multiFieldCfgs,
      createNewRow: () => ({ir_parbauditi: true}),
      renderer: () => null,
      sizeLabel: 0,
      sizeForm: 12
  }]
}

class AtradnesKrajumiComp extends Component {

      constructor(props) {
        super(props);
        this.selectRowDefaults = {
            mode: 'radio',
            clickToSelect: true,
            hideSelectColumn: true,
            classes: 'selection-row'
        }
      }

      UNSAFE_componentWillReceiveProps(nextProps) {
        const {editable} = this.props.atradneKrajumi
        const {editable : nextEditable} = nextProps.atradneKrajumi
        this.startedEditing = (!editable && nextEditable)
      }

      handleOnSelect = (row, isSelect) => {
          this.props.atradneKrajumsSelected({id: row.id, isSelect})
      }


      getDoubleClickHandler = ({loc, locDala}) => {
        const {isDala, atradneKrajumi } = this.props
        const {selectedItem} = atradneKrajumi
        const {id : atrId} = selectedItem
        const numurs = isDala ? selectedItem.atradnes_numurs : selectedItem.numurs
        return (e, row, rowIndex) => {
          this.props.openDok({
            id: row.id,
            numurs,
            atrId,
            loc,
            locDala,
            isDala: isDala
          })
        }
      }

      render() {

        const {atradneKrajumi, kodif, user, isDala, atradneKrajumsSum,
          atradneKrajumsClearSum, getFormFieldCfgsFunc, metadataFormCfg} = this.props
        const {selectedItem, selectedKrajums, editable, submiting} = atradneKrajumi

        if (!selectedItem) {
            return (<Loading/>)
        }

        const {id : atrId} = selectedItem
        const numurs = isDala ? selectedItem.atradnes_numurs : selectedItem.numurs
        const atrVeids = isDala ? selectedItem.atradnes_veids : selectedItem.veids

        if(!this.krajumiColumns){
            this.krajumiColumns = getKrajumiColumns({kodif, numurs, isDala, atrId, atradneKrajumsSum, atrVeids})
        }

        if(!this.formFieldCfgs){
          const f = getFormFieldCfgsFunc ? getFormFieldCfgsFunc : getFormFieldCfgs()
          this.formFieldCfgs = f({
              kodif, user, atrVeids
          })
        }

        const extraElements = !editable && user.tiesibas.includes('LABOT_ATRADNES_KRAJUMUS') ? <Link to={getLinkParams({
                loc : 'loc_atradnes_krajums',
                locDala : 'loc_atradnes_dala_krajums',
                numurs, isDala, atrId, extraPayload: {id: 'jauns'}})}>
          <Button color="primary"><i className="fa fa-plus-circle"></i>&nbsp;&nbsp;Jauni krājumi</Button>
        </Link> : null

        if(editable && this.startedEditing && this.bootstrapTable && this.bootstrapTable.table && this.bootstrapTable.sortContext
          && this.bootstrapTable.sortContext.state.sortColumn) {
            const gridData = this.bootstrapTable.table.props.data
            selectedItem.krajumi.sort((a, b) => gridData.findIndex(el => el.id === a.id) - gridData.findIndex(el => el.id === b.id))
        }

        return <div className="full-height-form-or-grid-container">
              <Form
                  className={editable ? '' : 'hidden'}
                  editable={editable}
                  submiting={submiting}
                  dataObj={this.startedEditing ? {...selectedItem} : selectedItem}
                  formFieldCfgs={this.formFieldCfgs}
                  onSubmit={this.props.saveAct}
                  onReset={this.props.stopEditAct}
                  onStartEdit={this.props.startEditAct}
                  btnTexts={{edit: 'Labot krājumus'}}
                  extraElements={extraElements}
                  metadataFormCfg={metadataFormCfg}
                  tiesibasLabot="LABOT_ATRADNES_KRAJUMUS"
              />
              { !editable ?
              <>
              <BootstrapTable
                  bootstrap4
                  striped
                  hover
                  condensed
                  keyField="id"
                  data={ selectedItem.krajumi }
                  columns={ this.krajumiColumns }
                  ref={ n => this.bootstrapTable = n }
                  rowEvents={{onDoubleClick: this.getDoubleClickHandler({
                    loc : 'loc_atradnes_krajums',
                    locDala : 'loc_atradnes_dala_krajums'})}}
                  selectRow={{
                                ...this.selectRowDefaults,
                                onSelect: this.handleOnSelect,
                                selected: selectedKrajums ? [selectedKrajums] : []
                            }}
              />
              <SummingResultPanel summables={selectedItem.krajumi} onClickClear={atradneKrajumsClearSum} mervFieldName='daudzuma_merv'/>
              </>
              : null }
        </div>
    }
}

const mapStateToProps = ({atradneKrajumi, kodif, user}) => ({
  atradneKrajumi,
  kodif,
  user,
  metadataFormCfg: "atradne_krajums_view"
})
const AtradnesKrajumi =  connect(mapStateToProps, {
  ...krajumiActions, openDok, atradneKrajumsSelected, atradneKrajumsSum, atradneKrajumsClearSum
})(AtradnesKrajumiComp)
export default AtradnesKrajumi

const mapStateToPropsDala = ({atradnesDalaKrajumi, kodif, user}) => ({
  atradneKrajumi: atradnesDalaKrajumi,
  kodif,
  user,
  isDala: true,
  getFormFieldCfgsFunc: getFormFieldCfgs(true),
  metadataFormCfg: "atradnes_dala_krajums_view"
})
export const AtradnesKrajumiDala = connect(mapStateToPropsDala, {
  ...dalaKrajumiActions, openDok, atradneKrajumsSelected, atradneKrajumsSum, atradneKrajumsClearSum
})(AtradnesKrajumiComp)