import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
    dateFormatter,
} from '../grid/ColumnHelpers'

import IzraktenisMap from '../karte/IzraktenisMap';
import * as maputils from '../karte/maputils'

import SplitterLayout from 'react-splitter-layout';

import {guiSet} from '../../actions/uiActions'
import {krajumsSelected} from '../../actions/krajumsActions'
import {openDok} from '../../actions/dokumentacijaActions'

import Grid from '../grid/Grid'
import BBoxFilterButtonToUrl from '../grid/BBoxFilterButtonToUrl'
import {getCommonColumns, getDoubleClickHandler, getEditColumnForDokSaraksts} from '../dokumentacija/common'
import ManiObjektiButton from '../grid/ManiObjektiButton'

const editIconColumn = getEditColumnForDokSaraksts({loc: 'loc_atradnes_krajums', locDala: 'loc_atradnes_dala_krajums'})

const columnNames = ['atradne', 'dalas_nosaukums', 'izpetes_gads', 'krajumu_statuss_nosaukums', 'krajumu_apr_veids_nosaukums', 'kategorija', 'q_izrakteni',
     'daudzums', 'daudzums_tukst_t', 'daudzums_tukst_mkub', 'derigs_lidz_datums', 'ir_parbauditi', 'aktualizacijas_laiks', 'q_adm_ters', 'daudzums_zem_udens', 'sakums_datums']
const getColumns = ({kodif}) => {
  const allColumns = getCommonColumns({kodif, locijums: 'P'}).filter(c => columnNames.indexOf(c.dataField) !== -1)
  allColumns.find(c => c.dataField === 'daudzums').text = 'Krājumi / resursi (būvm.)'
  {
    const x = allColumns.find(c => c.dataField === 'sakums_datums')
    x.text = 'Datums, uz kuru attiecas krājumi'
    x.hidden = true
  }
  return [editIconColumn,...allColumns]
};

class KrajumsSaraksts extends Component {

    mapPaneSizeChange = (h) => {
        this.props.guiSet('krajumu_saraksts_karte_height', h);
    }

    rowEvents = {
        onDoubleClick: getDoubleClickHandler({loc: 'loc_atradnes_krajums', locDala: 'loc_atradnes_dala_krajums', openDok: this.props.openDok})
    }

    render() {

        const {kodif, krajumi, gui, krajumsSelected} = this.props
        const {list, selected, loading, count} = krajumi

        const vectorLayerCfgs = {
            krajumi : {
                title: 'Krājumi',
                features: list,
                styles: maputils.dokStyles.dokKrajumiStyles,
                tooltipFunc: (feat) => `${feat.get('atradne') ? feat.get('atradne').tah_label : ''} ${dateFormatter(feat.get('sakums_datums'))} - ${feat.get('kategorija')} ${feat.get('q_izrakteni')}`,
                selected: selected,
                legend: {
                    features: [
                        {
                          label: 'Krājumi',
                          geomType: 'polygon'
                        },
                        {
                          label: 'Krājumi (izvēlēti)',
                          geomType: 'polygon',
                          selected: true
                        }
                    ]
                  }
            }
        }

        if(!this.columns){
            this.columns = getColumns({
                kodif
            })
        }

        return (
            <SplitterLayout
                vertical
                percentage
                primaryIndex={1}
                secondaryInitialSize={gui.krajumu_saraksts_karte_height}
                onSecondaryPaneSizeChange={this.mapPaneSizeChange}>

                <IzraktenisMap mapId="krajumu_saraksts" vectorLayerCfgs={vectorLayerCfgs} />

                <Grid
                    onRowSelected={krajumsSelected}
                    loading={loading}
                    selectedId={selected}
                    dataList={list}
                    totalCount={count}
                    columns={this.columns}
                    rowEvents={this.rowEvents}
                >

                <BBoxFilterButtonToUrl/>
                <ManiObjektiButton allText="Rādīt visus" maniText="Rādīt krājumus tikai manās atradnēs"/>
                </Grid>

            </SplitterLayout>
        )
    }
}

const mapStateToProps = ({kodif, krajumi, gui, user}) => {
    return {kodif, krajumi, gui, user}
}
export default connect(mapStateToProps, {krajumsSelected, guiSet, openDok})(KrajumsSaraksts)
