import React from 'react';
import BootstrapTable from "react-bootstrap-table-next";

export const getTableRenderer = (columnDefs, extraProps = {}) => (field) => {
  return (
    <BootstrapTable
      bootstrap4
      remote
      striped
      hover
      condensed
      keyField='id'
      data={field}
      columns={columnDefs}
      wrapperClasses="grid-saraksts"
      { ...extraProps }
    />
  )
}

export const mdToGridColumns = (view, columns, metadata) => {
  const yamlTypeMap = {
    'decimal': 'number',
    'boolean': 'bool'
  }

  if (!metadata[view]) {
    return []
  }

  const md = metadata[view]
  const getField = (field) => {
    return md.fields.find(f => f.name === field)
  }

  return columns.map((c) => {
    const f = getField(c.name)
    if (f) {
      f.type = yamlTypeMap[f.type] || f.type
      return {
        dataField: c.name,
        text: f.label,
        ...f,
        ...c
      }
    }
    return {
      dataField: c.name,
      ...c
    }
  })
}
