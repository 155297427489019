export const round = (num, toDecPlaces = 6) => {
    const to = Math.pow(10, toDecPlaces)
    return Math.round(num * to) / to
}

export const getValuesFromNestedObject = (valObj) => {
    const values = []
    Object.keys(valObj).forEach(key => {
        if (typeof valObj[key] === 'object' && valObj[key] != null) {
            values.push(...getValuesFromNestedObject(valObj[key]))
        } else {
            values.push(valObj[key])
        }
    })
    return values.filter(v => !!v)
}