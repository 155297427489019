import {callApi, redirectToPrevLocation, getDefaultEditActions} from './helpers'
import * as api from '../api'

export const lietotajsInfo = () => (dispatch) => {
    const viewName = 'lietotajs_info'
    return dispatch(callApi({viewName, crudMethod: 'list'}))
}

export const userApi = () => (dispatch) => {
    return dispatch(callApi({
        viewName: 'userApi',
        apiMethod: api.fetchUserApi
    }))
}

export const login = (values, actions) => (dispatch, getState) => {
    const viewName = 'login'
    const apiMethod = api.login
    const apiArgs = [values.username, values.password]
    const success = (payload, reqId) => {
        dispatch(userApi()).then(() => {
            actions.setSubmitting(false)
            dispatch(redirectToPrevLocation)
        })
    }
    const failure = (error, reqId) => {
        actions.resetForm()
    }
    return dispatch(callApi({viewName, apiMethod, apiArgs, success, failure}))
}

export const logout = () => (dispatch) => {
    const viewName = 'logout'
    const apiMethod = api.logout
    return dispatch(callApi({viewName, apiMethod}))
}

export const loadLietotaji = (query) => (dispatch) => {
    return dispatch(callApi({
      viewName: 'lietotajs_edit',
      crudMethod: 'listPaging',
      crudParam: query
    }))
}

export const lietotajsSelected = (id) => {
    return { type: 'lietotajs_selected', payload : id }
}

export const lietotajsActions = getDefaultEditActions({
    actionTypeName : 'lietotajs',
    stateName: 'user',
    combinedPage: true,
    apiViewName: 'lietotajs_edit',
    afterSave: (dispatch, getState) => {
        dispatch(loadLietotaji(getState().location.query))
    },
})

export const saveLietotajsUzstadijumi = (uzstadijumi) => (dispatch, getState) => {
    if( getState().user && getState().user.avots !== 'G'){
        //update local redux store
        dispatch({
            type: 'update_user_uzstadijumi',
            payload: uzstadijumi
        })
        //save to server
        return dispatch(callApi({
            viewName: 'lietotaja_uzstadijumi',
            crudMethod: 'save',
            crudParam: uzstadijumi
          }))
    }
}