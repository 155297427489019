import React, {Component} from 'react'
import {connect} from 'react-redux'
import Grid from '../grid/Grid'
import {
    getSelectFilterCfg,
    getTextFilterCfg,
    getDateFilterCfg,
    dateTimeFormatter,
    getBadgeFormatterForKodif,
    getSelectFilterCfgForViewsFromMetadata,
} from '../grid/ColumnHelpers'
import {getLinkFromViewname} from '../../routes'
import Loading from "../Loading";

export const getColumns = ({kodif, metadata}) => {
    return [
        {
            dataField: 'lietotajs',
            text: 'Lietotājs',
            filterCfg: getTextFilterCfg()
        }, {
            dataField: 'piekluves_laiks',
            text: 'Laiks',
            formatter: dateTimeFormatter,
            filterCfg: getDateFilterCfg(),
            width: 150
        }, {
            dataField: 'piekluves_tips',
            text: 'Veids',
            filterCfg: getSelectFilterCfg(kodif.personasDatuPiekluvesTips),
            formatter: getBadgeFormatterForKodif(kodif.personasDatuPiekluvesTips),
        }, {
            dataField: 'personas_dati',
            text: 'Personas dati',
            filterCfg: getTextFilterCfg()
        }, {
            dataField: 'view_name',
            text: 'Sadaļa',
            filterCfg: getSelectFilterCfgForViewsFromMetadata(metadata),
            formatter: (viewname, row) => getLinkFromViewname({
                viewname,
                entity_id: row.entity_id,
                metadata,
            })
        }
    ]};

class AuditsPersonasDatu extends Component {

    render() {

        const {kodif, metadata} = this.props

        if (!metadata.loaded) {
            return (<Loading/>)
        }

        if (!this.columns) {
            this.columns = getColumns({kodif, metadata})
        }

        return (
            <div className="saraksts-wrapper">
                <Grid
                    onRowSelected={this.props.pasakumsSelected}
                    loading={this.props.personasDatuAudits.loading}
                    dataList={this.props.personasDatuAudits.list}
                    totalCount={this.props.personasDatuAudits.count}
                    columns={this.columns}
                >
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = ({personasDatuAudits, kodif, metadata}) => ({personasDatuAudits, kodif, metadata})
export default connect(mapStateToProps)(AuditsPersonasDatu)
