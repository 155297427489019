import React from 'react'

import {connect} from 'react-redux'

import DefaultLayout from './layout/DefaultLayout'
import Login from './Login'
import Alerts from './Alerts'
import Loading from './Loading'

import {guiUnset} from '../actions/uiActions'

const MainComp = (props) => {
    const {type} = props.location
    if (type === 'loc_login') {
        return (<Login/>)
    } else if (props.user != null && props.kodif.loaded === true && props.vvisKodifs.loaded === true && props.metadata.loaded === true && props.userApi.loaded === true) {
        return (<DefaultLayout {...props}/>)
    } else {
        return (<Loading/>)
    }
}

const App = (props) => {

    return (
        <React.Fragment>
            <Alerts/>
            <MainComp {...props}/>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    const {location, user, kodif, vvisKodifs, gui, metadata, userApi} = state
    return {location, user, kodif, vvisKodifs, gui, metadata, userApi}
}

export default connect(mapStateToProps, {guiUnset})(App)
