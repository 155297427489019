import React, {Component} from 'react';
import {connect} from 'react-redux'
import Grid from '../grid/Grid'
import {
    getTextFilterCfg, 
    getDateFilterCfg, 
    getBooleanSelectFilterCfg,
    dateFormatter,
    getSelectFilterCfgForViewsFromMetadata,
    getSelectFilterCfg
} from '../grid/ColumnHelpers'
import { Button } from 'reactstrap';
import {pazinojumsApskatits, visiPazinojumiApskatiti} from '../../actions/pazinojumiActions'
import {getLinkFromViewname} from '../../routes'
import {AtradneOrDalaLink} from '../dokumentacija/common'

const getColumns = ({kodif, metadata, onClickApskatits}) => {
    return [
        {
            dataField: 'ir_skatits',
            text: 'Apskatīts',
            formatter: (apskatits, row) => apskatits ? 'Jā' : <Button color="primary"  size="sm" onClick={onClickApskatits(row.id)}>Uzstādīt kā apskatītu</Button>,
            filterCfg: getBooleanSelectFilterCfg(),
            width: 80
        },{
            dataField: 'veids',
            text: 'Veids',
            width: 100,
            filterCfg: getSelectFilterCfg(kodif.pazinojumuVeidi)
        }, {
            dataField: 'teksts',
            text: 'Paziņojums',
            width: 300,
            filterCfg: getTextFilterCfg()
        }, {
            dataField: 'dok_derigs_lidz_datums',
            text: 'Termiņš',
            formatter: dateFormatter,
            filterCfg: getDateFilterCfg(),
            width: 130,
        }, {
            dataField: 'viewname',
            text: 'Sadaļa',
            filterCfg: getSelectFilterCfgForViewsFromMetadata(metadata),
            formatter: (viewname, row) => getLinkFromViewname({
                viewname, 
                entity_id: row.entity_id, 
                metadata, 
                atr_numurs: row.atradne ? row.atradne.numurs : null, 
                atr_id: row.atradne ? row.atradne.id : null, 
            }),
            width: 110,
        }, {
            dataField: 'atradne',
            text: 'Saistītā atradne vai daļa',
            formatter: (atr) => atr ? <AtradneOrDalaLink
                id={atr.id}
                numurs={atr.numurs}
                ieraksta_veids={atr.ieraksta_veids}
                nosaukums={atr.tah_label}
                kodif={kodif}/> : null,
            filterCfg: {
                type: 'autocomplete',
                autocompleteCfg: {
                    remoteSearchViewName: 'atradne_search'
                }
            },
            sort: false,
            width: 200
        }, {
            dataField: 'izveidots_datums',
            text: 'Izveidots',
            formatter: dateFormatter,
            filterCfg: getDateFilterCfg(),
            width: 130,
        }
  ]};

class PazinojumiSaraksts extends Component {

    onClickApskatits = (pazId) => () => {
        this.props.pazinojumsApskatits(pazId)
    }

    render() {
        
        if(!this.columns){
            this.columns = getColumns({
                kodif: this.props.kodif,
                metadata:  this.props.metadata,
                onClickApskatits: this.onClickApskatits
            })
        }

        const {loading, list, count} = this.props.pazinojumi

        return (
            <div className="saraksts-wrapper">
                <Grid 
                    loading={loading}    
                    dataList={list}
                    totalCount={count}
                    columns={this.columns}>
                <Button color="secondary" onClick={this.props.visiPazinojumiApskatiti}>Visus paziņojumus uzstādīt kā apskatītus</Button>
                </Grid>
            </div>
        )
    }

}

const mapStateToProps = ({pazinojumi, kodif, metadata}) => ({pazinojumi, kodif, metadata})
export default connect(mapStateToProps, {pazinojumsApskatits, visiPazinojumiApskatiti})(PazinojumiSaraksts)
