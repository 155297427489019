import React, {Component} from 'react';
import {connect} from 'react-redux'

import Form from '../forms/Form'
import {getKlasifikatorsFieldCfg} from '../forms/FormHelpers'
import {getFormatterForKodif, getNumberFormatter} from '../grid/ColumnHelpers'
import {virszemesUdensObjektiActions} from '../../actions/virszemesUdensObjektiActions'

class PamatinformacijaComp extends Component {
    render() {
      const getFormFieldCfgs = ({kodif, user}) => {
        return [
          {
            name: 'kods',
            renderClassName: "bold",
          },
          {
            name: 'nosaukums',
            renderClassName: "bold"
          }, {
            ...getKlasifikatorsFieldCfg(kodif, 'udensObjektaIzcelsme'),
            name: 'izcelsme',
            renderer: getFormatterForKodif(kodif.udensObjektaIzcelsme)
          }, {
            ...getKlasifikatorsFieldCfg(kodif, 'virszemesUdensObjektaKategorija'),
            name: 'kategorija',
            renderer: getFormatterForKodif(kodif.virszemesUdensObjektaKategorija)
          }, {
            ...getKlasifikatorsFieldCfg(kodif, 'UO_TIPS'),
            name: 'uo_tips',
          }, {
            name: 'uo_garums',
            renderer: getNumberFormatter(2)
          }, {
            name: 'ezera_spogulvirsma',
            renderer: getNumberFormatter(2)
          }, {
            name: 'sateces_baseina_laukums',
            renderer: getNumberFormatter(2)
          }, {
            name: 'dalbaseina_laukums',
            renderer: getNumberFormatter(2)
          }, {
            name: 'uoe_vdj_dzilums_m',
            renderer: getNumberFormatter(1),
            editable: false
          }
      ].filter(obj => {
        if(!this.props.virszemesUdensObjekts?.selectedItem?.uoe_vdj_dzilums_m) {
          return obj.name !== 'uoe_vdj_dzilums_m'
        } else return obj.name !== ''
      })}

        const {selectedItem:virszemesUdensObjekts, editable, submiting} = this.props.virszemesUdensObjekts

        // initial cfgs can come in as props, or we can create default ones
        const {user, getFormFieldCfgsFunc, kodif, metadataFormCfg} = this.props

        // we cache form field cfgs
        if(!this.formFieldCfgs){
            const f = getFormFieldCfgsFunc ? getFormFieldCfgsFunc : getFormFieldCfgs
            this.formFieldCfgs = f({
                kodif,
                user,
            })
        }
        return <div className="form-container">
                    <Form
                        editable={editable}
                        submiting={submiting}
                        dataObj={virszemesUdensObjekts}
                        formFieldCfgs={this.formFieldCfgs}
                        metadataFormCfg={metadataFormCfg}
                        onSubmit={this.props.save}
                        onReset={this.props.stopEdit}
                        onStartEdit={this.props.startEdit}
                        onDelete={user.tiesibas.includes('BAIS_DZEST') ? this.props.delete : null}
                        tiesibasLabot="BAIS_VU_PAMAT_LABOT"
                    />
            </div>
    }
}

// connect Pamatinformacija with virszemesUdensObjekts state
const mapStateToProps = ({virszemesUdensObjekts, kodif, user}) => ({virszemesUdensObjekts, kodif, user, metadataFormCfg: "virszemes_udens_objekts_view"})
const VirszemesPamatinformacija =  connect(mapStateToProps, {
  startEdit:virszemesUdensObjektiActions.startEditAct,
  stopEdit:virszemesUdensObjektiActions.stopEditAct,
  delete:virszemesUdensObjektiActions.deleteAct,
  save:virszemesUdensObjektiActions.saveAct
})(PamatinformacijaComp)
export default VirszemesPamatinformacija
