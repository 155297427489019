import React from 'react'
import { connect } from 'react-redux'

import { Alert, Button } from 'reactstrap'

import * as api from '../../api'

import * as fmt from '../grid/ColumnHelpers'

const WHATEVER = 1

class ParisValidacijaParskats extends React.PureComponent {

  constructor (props) {
    super(props)

    this.state = {
      loading: false,
      error: false,
      procesi: [],
    }

    this.doValidation = this.doValidation.bind(this)
    this.refresh = this.refresh.bind(this)
  }


  refresh () {
    this.setState({ loading: 'refresh', error: false })
    api.listApi('paris_validacijas_procesi').then( procesi => {
      this.setState({ procesi, loading: false })
    } )
    .catch( e => this.setState({ loading: false, error: `${e}` }) )
  }


  componentDidMount () {
    this.refresh()
  }


  doValidation () {

    this.setState({ loading: 'validation', error: false })

    api.saveApi('do_paris_validation', { id: WHATEVER })
    .then( () => {
      this.setState({ loading: false })
      this.refresh()
    } )
    .catch( e => this.setState({ loading: false, error: `${e}` }) )

  }

  renderValidateButton () {
    const label = 'Palaist datu pārbaudi'
    if (this.state.loading === 'validation') {
      return <Button color="default">{ label }</Button>
    } else {
      return <Button color="primary" onClick={this.doValidation}>{ label }</Button>

    }
  }

  renderProcessTable () {
    return (
      <div className="mt-4">
        {
          this.state.loading === 'refresh'
            ? <Button color="default"><span className="fa fa-refresh mr-1" /> Atjaunot sarakstu</Button>
            : <Button color="primary" onClick={this.refresh}><span className="fa fa-refresh mr-1" /> Atjaunot sarakstu</Button>
        } 
        <table className="table mt-1">
          <thead>
            <tr>
              <th>Uzsākts</th>
              <th>Pabeigts</th>
              <th>Objekti</th>
              <th>Problēmas</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {
              this.state.procesi.map( p => (
                <tr key={p.id}>
                  <td>{ fmt.dateTimeFormatter(p.izveidots_datums) }</td>
                  <td>{ fmt.dateTimeFormatter(p.pabeigts_datums) }</td>
                  <td>{ p.n_objekti_apstr || 0 } / { p.n_objekti || 0 }</td>
                  <td>{ p.n_objekti_prob || 0 } </td>
                  <td>
                    {
                      p.pabeigts_datums
                        ? <Button size="sm" onClick={() => this.props.dispatch({ type: 'loc_udens_paris_validacija_rezultati', payload: { id: p.id } })}>Rezultāti</Button>
                        : null
                    }
                  </td>
                </tr>

              ) )

            }
          </tbody>
        </table>
      </div>
    )

  }

  render () {
    return (
      <div>
        <h2>Pārskatu sistēmas “2-Ūdens” ūdens ieguves vietu un DB “Urbumi” ierakstu validācija</h2>
        {
          this.state.error ? <Alert color="danger">{ this.state.error }</Alert> : null
        }
        {
          this.renderValidateButton()
        }
        {
          this.renderProcessTable()
        }

      </div>
    )
  }
}

const mapState = ({ location }) => ({ location })

export default connect(mapState)(ParisValidacijaParskats)
