import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
    getTextFilterCfg, 
    getNumberFilterCfg, 
    getEditIconColumn
} from '../grid/ColumnHelpers'

import IzraktenisMap from '../karte/IzraktenisMap';
import * as maputils from '../karte/maputils'

import SplitterLayout from 'react-splitter-layout';

import {guiSet} from '../../actions/uiActions'
import {urbumuIeguveSelected, urbumuIeguveDoubleSelected} from '../../actions/hidrogeoActions'

import Grid from '../grid/Grid'
import BBoxFilterButtonToUrl from '../grid/BBoxFilterButtonToUrl'

const editIconColumn = getEditIconColumn((row) => (
    {   
        type: 'loc_urbumu_ieguve',
        payload: {
            id: row.id
        }
    }
    )
)

const getColumns = ({kodif}) => {
  return [editIconColumn,
    {
        dataField: 'id',
        text: 'DB numurs',
        width: 100,
        filterCfg: getTextFilterCfg()
    },{
        dataField: 'nosaukums',
        text: 'Nosaukums',
        filterCfg: getTextFilterCfg()
    },{
        dataField: 'min_gads',
        text: 'Ieguve no gada',
        width: 180,
        filterCfg: getNumberFilterCfg()
    }, {
        dataField: 'max_gads',
        text: 'Ieguve līdz gadam',
        width: 180,
        filterCfg: getNumberFilterCfg()
    }
]};


class UrbumuIeguvjuSaraksts extends Component {

    mapPaneSizeChange = (h) => {
        this.props.guiSet('urbumu_ieguves_saraksts_karte_height', h);
    }

    rowEvents = {
        onDoubleClick: (e, row, rowIndex) => {
            this.props.urbumuIeguveDoubleSelected(row.id)
        }
    }

    render() {

        const {kodif, urbumuIeguves, gui, urbumuIeguveSelected} = this.props
        const {list, selected, loading, count} = urbumuIeguves

        const vectorLayerCfgs = {
            ieguves : {
                title: 'Ieguves vietas',
                features: list,
                styles: maputils.urbumiStyles,
                tooltipFunc: (feat) => `${feat.get('id')} - ${feat.get('nosaukums') ? feat.get('nosaukums') : ''}`,
                selected: selected
            }
        }

        if(!this.columns){
            this.columns = getColumns({
                kodif
            })
        }

        return (
            <SplitterLayout
                vertical
                percentage
                primaryIndex={1}
                secondaryInitialSize={gui.urbumu_ieguves_saraksts_karte_height}
                onSecondaryPaneSizeChange={this.mapPaneSizeChange}>

                <IzraktenisMap mapId="urbumu_ieguves_saraksts" vectorLayerCfgs={vectorLayerCfgs} />

                <Grid 
                    onRowSelected={urbumuIeguveSelected} 
                    loading={loading}    
                    selectedId={selected}
                    dataList={list}
                    totalCount={count}
                    columns={this.columns} 
                    rowEvents={this.rowEvents}
                >
                <BBoxFilterButtonToUrl/>
                </Grid>

            </SplitterLayout>
        )
    }
}

const mapStateToProps = ({kodif, urbumuIeguves, gui}) => {
    return {kodif, urbumuIeguves, gui}
}

export default connect(mapStateToProps, {urbumuIeguveSelected, urbumuIeguveDoubleSelected, guiSet})(UrbumuIeguvjuSaraksts)