import React, {Component} from 'react';
import {connect} from 'react-redux'
import Loading from '../Loading'

import Form from '../forms/Form'
import {getDefaultTypeaheadOptionsFromKodif, getDefaultRendererForMultiField} from '../forms/FormHelpers'
import {IDRenderer, getBadgeFormatterForKodif} from '../grid/ColumnHelpers'

import {atrasanasActions, dalaAtrasanasActions} from '../../actions/atradnesActions'
import {showErrorMsg} from '../../actions/uiActions'

import KoordTable, {ShowKoordTableBtn} from '../karte/KoordTable'

import {getFieldRenderWithDalasData} from './Pamatinformacija'

const isSapropelAtradne = (dataObj) =>
  (dataObj.veids && dataObj.veids.includes('S')) || (dataObj.atradnes_veids && dataObj.atradnes_veids.includes('S'))

export const  getFormFieldCfgs = ({kodif, user, isDala}) => {
    return [
      {
        name: 'id',
        label: 'ID',
        type: 'text',
        editable: false,
        renderer: IDRenderer
      }, {
        name: 'ep_tips',
        label: 'Tips',
        type: 'radio',
        renderer: getBadgeFormatterForKodif(kodif.ep_tips),
        options: kodif.ep_tips,
        hiddenFunc: ({dataObj}) => !isSapropelAtradne(dataObj)
      }, {
        name: 'ezera_nosaukums',
        label: 'Nosaukums',
        type: 'text',
        hiddenFunc: ({dataObj}) => !isSapropelAtradne(dataObj)
      }, {
        name: 'sinonimi',
        label: 'Sinonīmi',
        type: 'text',
        hiddenFunc: ({dataObj}) => !isSapropelAtradne(dataObj)
      }, {
        name: 'ter_iedalijums_wfs',
        label: 'Administratīvā teritorija',
        editable: false,
        fieldNotes: 'Administratīvās un teritoriālās vienības tiek aizpildītas automātiski no WFS servisa pēc ģeometrijas labošanas.',
        renderer: (allAdm, vals) => {
          // group by limenis_1
          const grouped = allAdm.reduce((grouped, adm) => {
            if(!grouped[adm.limenis_1]) {
              grouped[adm.limenis_1] = []
            }
            if(adm.limenis_2){
              grouped[adm.limenis_1].push(adm.limenis_2)
            }
            return grouped
          }, {})
          return <>
          {Object.keys(grouped).map(adm => <p key={adm} className="links">{adm}{grouped[adm].length > 0 ? ' - ' + grouped[adm].join('; ') : null}</p>)}
          {vals.atradne_ter_iedalijums_wfs_kluda ? <p className="danger links">{vals.atradne_ter_iedalijums_wfs_kluda}</p> : null}
          </>
        }
      },{
        name: 'ter_iedalijums',
        label: 'Administratīvā teritorija (ATVK2010)',
        type: 'autocomplete',
        autocompleteCfg: {
          multiple: true,
          keyField: 'ter_iedalijums_id',
          remoteSearchViewName: 'ter_iedalijums_search'
        },
        renderer: getDefaultRendererForMultiField('tah_label'),
      }, {
        name: 'akvatorijs',
        label: 'Akvatorija',
        type: 'autocomplete',
        autocompleteCfg: {
          options: getDefaultTypeaheadOptionsFromKodif(kodif.AKVATOR)
        },
        hiddenFunc: ({dataObj}) => isSapropelAtradne(dataObj)
      }, {
        name: 'fonda_numurs_ezeru',
        label: 'Ezera fonda numurs',
        type: 'text',
        hiddenFunc: ({dataObj}) => !isSapropelAtradne(dataObj)
      }, {
        name: 'udenstilpju_kl_kods',
        label: 'Ūdenstilpju klasifikatora kods',
        renderer: getFieldRenderWithDalasData('udenstilpju_kl_kods'),
        type: 'autocomplete',
        autocompleteCfg: {
          options: getDefaultTypeaheadOptionsFromKodif(kodif.UDENSTILPJU_KODI)
        },
        hiddenFunc: ({dataObj}) => !isSapropelAtradne(dataObj)
      }, {
        name: 'upes_baseins',
        label: 'Upes baseins',
        type: 'autocomplete',
        autocompleteCfg: {
          options: getDefaultTypeaheadOptionsFromKodif(kodif.RIVER_POOL)
        },
        hiddenFunc: ({dataObj}) => !isSapropelAtradne(dataObj)
      }, {
        name: 'trofstav',
        label: 'Trofiskais stāvoklis',
        type: 'autocomplete',
        renderer: getFieldRenderWithDalasData('trofstav'),
        autocompleteCfg: {
          options: getDefaultTypeaheadOptionsFromKodif(kodif.TROPHIC_STATE)
        },
        hiddenFunc: ({dataObj}) => !isSapropelAtradne(dataObj)
      }, {
        name: 'aizaugsanas_pakape',
        label: 'Aizaugšanas pakāpe',
        renderer: getFieldRenderWithDalasData('aizaugsanas_pakape'),
        type: 'autocomplete',
        autocompleteCfg: {
          options: getDefaultTypeaheadOptionsFromKodif(kodif.LAKE_OCCLUSION)
        },
        hiddenFunc: ({dataObj}) => !isSapropelAtradne(dataObj)
      }, {
        name: 'ep_virsmas_platiba',
        label: 'Ūdens virsmas platība, ha',
        renderer: getFieldRenderWithDalasData('ep_virsmas_platiba'),
        type: 'number',
        hiddenFunc: ({dataObj}) => !isSapropelAtradne(dataObj)
      }, {
        name: 'ep_dzilums_max',
        label: 'Ūdens dziļums lielākais, m',
        renderer: getFieldRenderWithDalasData('ep_dzilums_max'),
        type: 'number',
        hiddenFunc: ({dataObj}) => !isSapropelAtradne(dataObj)
      }, {
        name: 'ep_dzilums_vid',
        label: 'Ūdens dziļums vidējais, m',
        renderer: getFieldRenderWithDalasData('ep_dzilums_vid'),
        type: 'number',
        hiddenFunc: ({dataObj}) => !isSapropelAtradne(dataObj)
      }, {
        name: 'ep_iepl_aizpild_koef',
        label: 'Ezera/Purva ieplakas aizpildes koeficents',
        type: 'number',
        hiddenFunc: ({dataObj}) => true || !isSapropelAtradne(dataObj)
      }, {
        name: 'atjaunot_geom_no_krajumiem',
        label: 'Aizpildīt atrašanās vietu no krājumiem',
        type: 'checkbox',
        hiddenFunc: ({editable}) => !editable,
        overridePropsFunc: (props) => {
          const changedProps = {...props}
          const {dataObj} = props
          if(dataObj.ir_krajumu_geometrijas){
            changedProps.extraFieldProps = {
              disabled: false
            }
            changedProps.fieldNotes = null
          } else {
            changedProps.extraFieldProps = {
              disabled: true
            }
            changedProps.fieldNotes = `${isDala ? 'Atradnes daļai': 'Atradnei'} nav krājumi ar aizpildītām ģeometrijām`
          }
          return changedProps
        }
      }
    ]}

  const dalaFieldNames = ['id', 'aizaugsanas_pakape', 'trofstav','ep_virsmas_platiba','ep_dzilums_vid','ep_dzilums_max', 'atjaunot_geom_no_krajumiem']
  export const  getFormFieldCfgsForDala = ({kodif, user}) => {
    const atradneFields = getFormFieldCfgs({kodif, user, isDala : true}).filter(f => dalaFieldNames.includes(f.name))
    atradneFields.unshift({
      name: 'atradnes_ezera_nosaukums',
      label: 'Nosaukums',
      editable: false,
      hiddenFunc: ({dataObj}) => !isSapropelAtradne(dataObj)
    })
    atradneFields.unshift({
      name: 'atradnes_ep_tips',
      label: 'Tips',
      type: 'radio',
      renderer: getBadgeFormatterForKodif(kodif.ep_tips),
      options: kodif.ep_tips,
      editable: false,
      hiddenFunc: ({dataObj}) => !isSapropelAtradne(dataObj)
    })
    return atradneFields
  }

class AtrasanasComp extends Component {

      render() {
        // initial cfgs can come in as props, or we can create default ones
        const {user, getFormFieldCfgsFunc, kodif, atradneAtrasanas, koordSelectAct, koordChangeAct, showErrorMsg} = this.props

        const {selectedItem, editable, submiting} = atradneAtrasanas

        // loading while we have no atradne
        if (!selectedItem) {
            return (<Loading/>)
        }

        // we cache form field cfgs
        if(!this.formFieldCfgs){
            const f = getFormFieldCfgsFunc ? getFormFieldCfgsFunc : getFormFieldCfgs
            this.formFieldCfgs = f({
                kodif,
                user
            })
        }

        const showCoordsBtn = <ShowKoordTableBtn/>

        return <div className="form-with-grid-container">
                    <Form
                        editable={editable}
                        submiting={submiting}
                        dataObj={selectedItem}
                        formFieldCfgs={this.formFieldCfgs}
                        onSubmit={this.props.saveAct}
                        onReset={this.props.stopEditAct}
                        onStartEdit={this.props.startEditAct}
                        extraElements={showCoordsBtn}
                        tiesibasLabot="LABOT_ATRADNES"
                    />
                    <KoordTable
                      editable={atradneAtrasanas.editable}
                      koords={atradneAtrasanas.koords}
                      selectedKoord={atradneAtrasanas.selectedKoord}
                      validation={atradneAtrasanas.koordsFeat && atradneAtrasanas.koordsFeat.validation}
                      koordSelectAct={koordSelectAct}
                      koordChangeAct={koordChangeAct}
                      showErrorMsg={showErrorMsg}
                      isPointGeometry
                    />
            </div>
    }
}

const mapStateToProps = ({atradneAtrasanas, kodif, user}) => ({atradneAtrasanas, kodif, user})
const Atrasanas =  connect(mapStateToProps, {...atrasanasActions, showErrorMsg})(AtrasanasComp)
export default Atrasanas

const mapStateToPropsDala = ({atradnesDalaAtrasanas, kodif, user}) => ({atradneAtrasanas:atradnesDalaAtrasanas, kodif, user, getFormFieldCfgsFunc: getFormFieldCfgsForDala})
export const AtrasanasAtradnesDala =  connect(mapStateToPropsDala, {...dalaAtrasanasActions, showErrorMsg})(AtrasanasComp)
