import React, {Component} from 'react';
import {connect} from 'react-redux'
// import {getYear} from 'date-fns'
import Form from '../forms/Form'
// import {
//   getDefaultTypeaheadOptionsFromKodif,
//   getDefaultTypeaheadOptionsFromKodifArray,
//   getDefaultRendererForMultiField,
// } from '../forms/FormHelpers'
// import {getBadgeFormatterForKodif} from '../grid/ColumnHelpers'
import {pasakumsPazemesActions} from "../../actions/pasakumiActions";

const getFormFieldCfgs = ({kodif}) => {
  return [
    {
      name: 'udens_objekts_id',
      label: 'Ūdens objekts',
      type: 'autocomplete',
      autocompleteCfg: {
        remoteSearchViewName: 'udens_objekts_search',
        labelFieldName: 'udens_objekts_nosaukums'
      },
      renderer: (val, row) => (
        <span>{ row.udens_objekts_nosaukums }</span>
      )
    }, {
      name: 'uo_pasakuma_klasifikators_id',
      label: 'Pasākums',
      type: 'autocomplete',
      autocompleteCfg: {
        remoteSearchViewName: 'uo_pasakumu_klasifikators_search',
        labelFieldName: 'pas_nosaukums'
      },
      renderer: (val, row) => (
        <span>{ row.pas_nosaukums }</span>
      )
    },
    {
      name: 'apraksts',
      label: 'Apraksts',
      type: 'textarea',
    },
    {
      name: 'izpilde',
      label: 'Izpilde',
      type: 'textarea',
    },
    {
      name: 'faili',
      label: 'Pielikumi',
      type: 'files'
    }
  ]};

class PazemesPasakums extends Component {

  render() {
    const {pasakums, kodif, saveAct, stopEditAct, startEditAct, deleteAct} = this.props

    if(!this.formFieldCfgs){
      this.formFieldCfgs = getFormFieldCfgs({
        kodif: kodif
      })
    }

    const {editable, submiting, selectedItem} = pasakums

    return (
      <div className="form-wrapper-pane">
        <Form
          editable={editable}
          submiting={submiting}
          dataObj={selectedItem}
          formFieldCfgs={this.formFieldCfgs}
          onSubmit={saveAct}
          onReset={stopEditAct}
          onStartEdit={startEditAct}
          onDelete={deleteAct}
          tiesibasLabot={'BAIS_PU_LABOT'}
        />
      </div>
    )
  }
}

const mapStateToProps = ({pasakums, kodif}) => ({pasakums, kodif})

export default connect(mapStateToProps, {...pasakumsPazemesActions})(PazemesPasakums)
