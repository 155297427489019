import React, { Component } from 'react'
import { connect } from 'react-redux'
import Link from 'redux-first-router-link'
import { izmainaActions } from '../../actions/izmainaActions'
import Form, { InputField } from '../forms/Form'
import {
  getMultiKlasifikatorsFieldCfg, getKlasifikatorsFieldCfg, getDefaultTypeaheadOptionsFromKodif
} from '../forms/FormHelpers'
import { getCommonFormFieldCfgs, LicenceLink, getLinkParams } from '../dokumentacija/common'
import { FormGroup, Label, Button } from 'reactstrap'
import { round } from '../../utils'
import { getYear } from 'date-fns'
import { getBadgeFormatterForKodif } from '../grid/ColumnHelpers'
import Loading from '../Loading'
import _ from 'lodash'

export const merv = (dataObj) => dataObj.limita_apjoms ? <span dangerouslySetInnerHTML={{ __html: dataObj.limita_apjoms.daudzuma_merv_nosaukums }}></span> : null

export const ievadesStatuss = ({ kodif, user }) => ({
  name: 'ievades_statuss',
  type: 'autocomplete',
  autocompleteCfg: {
    options: getDefaultTypeaheadOptionsFromKodif(_.pickBy(kodif.ievadesStatuss, (v => user.tiesibas.includes(v.loma)))),
  },
  renderer: getBadgeFormatterForKodif(kodif.ievadesStatuss),
  editable: user.tiesibas.includes('LABOT_ATRADNES_IZMAINAS')
})

export const datumsField = () => ({
  name: 'datums',
  cellClassName: 'semi-narrow',
  // set default date to prev year 31st Dec
  overridePropsFunc: (props) => {
    const changedProps = { ...props }
    const { currentValRow, currentVal } = props
    if (!currentVal && !currentValRow.id) {
      if (currentValRow.ir_limita_izmaina) {
        currentValRow.datums = new Date()
      } else {
        const year = getYear(new Date()) - 1
        currentValRow.datums = new Date(year, 11, 31)
      }
      changedProps.currentVal = currentValRow.datums
    }
    return changedProps
  }
})

export const licenceField = () => ({
  name: 'licence',
  type: 'autocomplete',
  overridePropsFunc: (props) => {
    const changedProps = { ...props }
    const { dataObj } = props
    changedProps.autocompleteCfg = {
      options: dataObj.lic_options,
      isValueObjStructure: true,
    }
    return changedProps
  },
  editable: true,
  renderer: lic => lic ? lic.tah_label : null
})

export const limitaApjomsField = () => ({
  name: 'limita_apjoms',
  type: 'autocomplete',
  overridePropsFunc: (props) => {
    const changedProps = { ...props }
    const { dataObj, currentValRow, lastEditedFieldName, currentVal } = props
    const allowedLicences = !currentValRow.licence ? null : [
      currentValRow.licence.id,
      ...currentValRow.licence.ieprieksejie.map(i => i.id),
      ...currentValRow.licence.nakamie.map(i => i.id)
    ]
    changedProps.autocompleteCfg = {
      options: dataObj.lim_options.filter(lim => allowedLicences ? allowedLicences.includes(lim.licence_id) : true),
      isValueObjStructure: true,
    }
    if (lastEditedFieldName === 'licence' && currentVal) {
      const found = changedProps.autocompleteCfg.options.find(opt => opt.id === currentVal.id)
      if (!found) {
        currentValRow.limita_apjoms = null
        changedProps.currentVal = null
      }
    }
    return changedProps
  },
  editable: true,
  renderer: (lim) => lim ? lim.tah_label : null
})

export const izrakteniField = ({ kodif }) => ({
  ...getMultiKlasifikatorsFieldCfg(kodif, 'DIZRAKT'),
  name: 'izrakteni',
  overridePropsFunc: (props) => {
    const changedProps = { ...props }
    const { currentValRow, lastEditedFieldName, fieldNameRow } = props
    // take allowed izrakteni options from limita_apjoms
    if (currentValRow.limita_apjoms) {
      const allOptions = changedProps.autocompleteCfg.options
      const filteredOptions = currentValRow.limita_apjoms.q_izrakteni_sep.split('%$%').map(i => allOptions.find(o => o.tah_id === i))
      if ((lastEditedFieldName === 'limita_apjoms' || lastEditedFieldName === `${fieldNameRow}.limita_apjoms` || (!lastEditedFieldName && currentValRow.ir_limita_izmaina)) &&
        (!currentValRow.izrakteni || currentValRow.izrakteni.length === 0)) {
        currentValRow.izrakteni = filteredOptions.map(o => ({ ...o, klasifikators: o.tah_id }))
        changedProps.currentVal = currentValRow.izrakteni
      }
      changedProps.autocompleteCfg = { ...changedProps.autocompleteCfg, options: filteredOptions }
    }
    return changedProps
  },
  required: true
})

export const izmainasVeidsField = ({ kodif, user }) => ({
  ...getKlasifikatorsFieldCfg(kodif, 'IZMAINA'),
  overridePropsFunc: (props) => {
    const { currentValRow } = props
    if (currentValRow.ir_limita_izmaina) {
      const changedProps = { ...props }
      const filteredOptions = changedProps.autocompleteCfg.options.filter(opt => kodif.IZMAINA[opt.tah_id].ir_limitiem)
      changedProps.autocompleteCfg = { ...changedProps.autocompleteCfg, options: filteredOptions }
      return changedProps
    }
    if (!user.tiesibas.includes('LABOT_ATRADNES_IZMAINAS')) {
      const changedProps = { ...props }
      const filteredOptions = changedProps.autocompleteCfg.options.filter(opt => kodif.IZMAINA[opt.tah_id].ir_izmaina_komersantiem)
      changedProps.autocompleteCfg = { ...changedProps.autocompleteCfg, options: filteredOptions }
      return changedProps
    }
    return props
  },
  cellClassName: 'narrow',
  name: 'izmainas_veids',
  required: true
})

export const izmantosanaField = ({ kodif }) => ({
  ...getMultiKlasifikatorsFieldCfg(kodif, 'IZM_VEIDS_BIL'),
  name: 'izmantosanas'
})

const getFormFieldCfgs = ({ kodif, user }) => {
  const commonFields = getCommonFormFieldCfgs({ kodif })
  return [
    commonFields.find(cf => cf.name === 'atradne'),
    commonFields.find(cf => cf.name === 'dalas_nosaukums'),
    datumsField(),
    {
      ...licenceField(),
      renderer: (lic, dataObj) => lic ? <LicenceLink licence={lic} atradne={dataObj.atradne} /> : null
    },
    limitaApjomsField(),
    izrakteniField({ kodif }),
    {
      name: 'daudzums',
      label: 'Daudzums',
      type: 'number',
      customField: (props) => {
        const { currentValues, lastEditedFieldName } = props
        if (lastEditedFieldName !== 'limitaAtlikums') {
          const zimesReizinajums = currentValues.zime === 'S' ? -1.0 : 1.0
          const daudzums = currentValues.daudzums != null ? currentValues.daudzums : 0.0
          if (currentValues.limita_apjoms) {
            if (currentValues.limita_apjoms.id === currentValues.initialLimitaApjomsId) {
              currentValues.limitaAtlikums = round(currentValues.limita_apjoms.daudzums + currentValues.sum_prev_izmainu_daudzums + daudzums * zimesReizinajums)
            } else {
              currentValues.limitaAtlikums = round(currentValues.limita_apjoms.daudzums + currentValues.limita_apjoms.sum_izmainu_daudzums + daudzums * zimesReizinajums)
            }
            if (currentValues.ir_limita_izmaina) {
              currentValues.limitaAtlikums = round(currentValues.limita_apjoms.korigetais_daudzums + daudzums * zimesReizinajums)
            }
          }
        }
        if (lastEditedFieldName === 'limitaAtlikums' && currentValues.limita_apjoms) {
          const limitaAtlikums = currentValues.limitaAtlikums != null ? currentValues.limitaAtlikums : 0.0
          let izmaina
          if (currentValues.limita_apjoms.id === currentValues.initialLimitaApjomsId) {
            izmaina = limitaAtlikums - currentValues.limita_apjoms.daudzums - currentValues.sum_prev_izmainu_daudzums
          } else {
            izmaina = limitaAtlikums - currentValues.limita_apjoms.daudzums - currentValues.limita_apjoms.sum_izmainu_daudzums
          }
          if (currentValues.ir_limita_izmaina) {
            izmaina = limitaAtlikums - currentValues.limita_apjoms.korigetais_daudzums
          }
          currentValues.daudzums = round(Math.abs(izmaina))
          currentValues.zime = izmaina < 0 ? 'S' : 'P'
        }
        return <>
          {currentValues.limita_apjoms ?
            currentValues.ir_limita_izmaina ?
              <FormGroup>
                <Label><b>{currentValues.limita_apjoms.korigetais_daudzums}</b>&nbsp;</Label>
                <Label dangerouslySetInnerHTML={{ __html: currentValues.limita_apjoms.daudzuma_merv_nosaukums }}></Label>
                <Label>&nbsp;(iepriekšējais limita apjoms)</Label>
              </FormGroup>
              :
              <>
                <FormGroup>
                  {currentValues.limita_apjoms.id === currentValues.initialLimitaApjomsId ?
                    <Label><b>{round(currentValues.limita_apjoms.daudzums + currentValues.sum_prev_limita_apjoma_izmainu_daudzums)}</b>&nbsp;</Label>
                    : <Label><b>{currentValues.limita_apjoms.korigetais_daudzums}</b>&nbsp;</Label>
                  }
                  <Label dangerouslySetInnerHTML={{ __html: currentValues.limita_apjoms.daudzuma_merv_nosaukums }}></Label>
                  <Label>&nbsp;(limita apjoms)</Label>
                </FormGroup>
                <FormGroup>
                  {currentValues.limita_apjoms.id === currentValues.initialLimitaApjomsId ?
                    <Label><b>{round(currentValues.limita_apjoms.daudzums + currentValues.sum_prev_izmainu_daudzums)}</b>&nbsp;</Label>
                    : <Label><b>{round(currentValues.limita_apjoms.daudzums + currentValues.limita_apjoms.sum_izmainu_daudzums)}</b>&nbsp;</Label>
                  }
                  <Label dangerouslySetInnerHTML={{ __html: currentValues.limita_apjoms.daudzuma_merv_nosaukums }}></Label>
                  <Label>&nbsp;(limita atlikums pirms izmaiņas)</Label>
                </FormGroup>
              </>
            : null}
          <FormGroup check inline >
            <Label check>
              <InputField {...props} fieldName='zime' fieldType='radio' currentVal={currentValues.zime}
                extraFieldProps={{ value: 'S', checked: currentValues.zime === 'S', id: 'zime_S' }}
              /> Samazināt
            </Label>
          </FormGroup>
          <FormGroup check inline >
            <Label check>
              <InputField {...props} fieldName='zime' fieldType='radio' currentVal={currentValues.zime}
                extraFieldProps={{ value: 'P', checked: currentValues.zime === 'P', id: 'zime_P' }}
              /> Palielināt
            </Label>
          </FormGroup>
          <FormGroup>
            <FormGroup check inline >
              <InputField
                {...props}
                fieldType="number"
                currentVal={currentValues.daudzums}
                extraFieldProps={{ placeholder: 'Izmaiņas daudzums', style: { width: 200, marginRight: 10 } }}
              />
              <Label>{merv(currentValues)}</Label>
              <Label >&nbsp;(izmaiņas daudzums)</Label>
            </FormGroup>
          </FormGroup>
          {currentValues.limita_apjoms ?
            <FormGroup>
              <FormGroup check inline >
                <InputField
                  {...props}
                  fieldType="number"
                  fieldName='limitaAtlikums'
                  currentVal={currentValues.limitaAtlikums}
                  extraFieldProps={{ placeholder: currentValues.ir_limita_izmaina ? 'Jaunais limita apjoms' : 'Jaunais limita atlikums', style: { width: 200, marginRight: 10 } }}
                />
                <Label>{merv(currentValues)}</Label>
                <Label >&nbsp;({currentValues.ir_limita_izmaina ? "jaunais limita apjoms" : "jaunais limita atlikums"})</Label>
              </FormGroup>
            </FormGroup>
            : null}
        </>
      },
      renderer: (daudzums, dataObj) =>
        <>
          {dataObj.limita_apjoms ?
            <><p className="no-margin"> {round(dataObj.limita_apjoms.daudzums + dataObj.sum_prev_limita_apjoma_izmainu_daudzums)} {merv(dataObj)} &nbsp;(limita apjoms)</p>
              <p className="no-margin">{round(dataObj.limita_apjoms.daudzums + dataObj.sum_prev_izmainu_daudzums)} {merv(dataObj)} &nbsp;(limita atlikums pirms izmaiņas)</p></>
            : null}
          <p className="no-margin"><b> {dataObj.initialDaudzums} </b> {merv(dataObj)} &nbsp;(izmaiņas daudzums)</p>
          {dataObj.limita_apjoms ?
            <p className="no-margin">{round(dataObj.limita_apjoms.daudzums + dataObj.sum_prev_izmainu_daudzums + dataObj.initialDaudzums)} {merv(dataObj)} &nbsp;(jaunais limita atlikums)</p>
            : null}
        </>,
      validation: (values) => {
        if (values.daudzums == null || values.daudzums < 0) {
          return 'Daudzumam jābūt norādītam kā pozitīvam skaitlim (lai ievadītu negatīvu, izmantojiet "Samazināt")'
        }
        return null
      }
    },
    izmainasVeidsField({ kodif, user }),
    izmantosanaField({ kodif }),
    commonFields.find(cf => cf.name === 'piezimes'),
    {
      name: 'faili',
      hiddenFunc: ({ dataObj }) => !user.tiesibas.includes('SKATIT_ATRADNES_IZMAINU_PIELIKUMUS') && !dataObj.ir_parstavis
    },
    {
      ...commonFields.find(cf => cf.name === 'ir_parbauditi'),
      hiddenFunc: () => !user.tiesibas.includes('LABOT_ATRADNES_IZMAINAS')
    },
    ievadesStatuss({ kodif, user })
  ]
}

class Izmaina extends Component {

  render() {
    const { izmaina, kodif, saveAct, stopEditAct, startEditAct, deleteAct, user } = this.props

    const { editable, submiting, selectedItem } = izmaina
    if (!selectedItem) {
      return (<Loading />)
    }

    if (!this.formFieldCfgs) {
      this.formFieldCfgs = getFormFieldCfgs({ kodif, user })
    }

    const tiesibasLabot = user.tiesibas.includes('PUBLICET_ATRADNES_IZMAINAS') ||
      (user.tiesibas.includes('LABOT_ATRADNES_IZMAINAS') && selectedItem.ievades_statuss !== 'P') ||
      (selectedItem.ir_parstavis === true && selectedItem.ievades_statuss === 'K')

    const extraElements = <> {!editable && (user.tiesibas.includes('LABOT_ATRADNES_IZMAINAS') || selectedItem.ir_parstavis === true) ? <Link to={getLinkParams({
      loc: 'loc_atradnes_izmaina',
      locDala: 'loc_atradnes_dala_izmaina',
      numurs: selectedItem.atradne.numurs,
      isDala: selectedItem.atradne.ieraksta_veids === 'D',
      atrId: selectedItem.atradne.id,
      extraPayload: { id: 'jauns' }
    })}>
      <Button color="primary"><i className="fa fa-plus-circle"></i>&nbsp;&nbsp;{user.tiesibas.includes('LABOT_ATRADNES_IZMAINAS') ? 'Jauna izmaiņa ' : 'Ievadīt ieguvi '}atradnē "{selectedItem.atradne.numurs}"</Button>
    </Link> : null}
    </>

    return (
      <div className="form-wrapper-pane">
        <Form
          editable={editable}
          submiting={submiting}
          dataObj={selectedItem}
          formFieldCfgs={this.formFieldCfgs}
          onSubmit={saveAct}
          onReset={stopEditAct}
          onStartEdit={startEditAct}
          onDelete={deleteAct}
          metadataFormCfg="izmaina_view"
          tiesibasLabot={tiesibasLabot}
          extraElements={extraElements}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ izmaina, kodif, user }) => ({ izmaina, kodif, user })

export default connect(mapStateToProps, { ...izmainaActions })(Izmaina)
