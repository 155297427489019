import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
  getTextFilterCfg,
  getEditIconColumn,
  getFormatterForKodif,
  getSelectFilterCfg
} from '../grid/ColumnHelpers'
import {
  Button,
} from 'reactstrap';
import { getDefaultRendererForMultiField } from '../forms/FormHelpers';

import Link from 'redux-first-router-link';

import {guiSet} from '../../actions/uiActions'
import {pasakumsSelected, pasakumsVirszemesDoubleSelected} from '../../actions/pasakumiActions'

import Grid from '../grid/Grid'

const editIconColumn = getEditIconColumn((row) => (
    {
      type: `loc_${row.path.includes('/virszemes-udens/pasakumi') ? 'virszemes' : 'pazemes'}_pasakums`,
      payload: {
        id: row.id,
        kods: row.uo_kods
      }
    }
  )
)

class Pasakumi extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modalSearch: false
    };
  }

  toggleModalSearch = () => {
    this.setState({
      modalSearch: !this.state.modalSearch
    });
  }

  rowEvents = {
    onDoubleClick: (e, row, rowIndex) => {
      this.props.pasakumsVirszemesDoubleSelected(row.id)
    }
  }

  render() {
    const getColumns = ({kodif}) => {
      return [editIconColumn, {
        dataField: 'uo_kods',
        text: 'ŪO kods',
        filterCfg: getTextFilterCfg(),
        width: 100
      }, {
        dataField: 'uo_nosaukums',
        text: 'Ūdens objekts',
        filterCfg: getTextFilterCfg(),
        width: 200
      }, {
        dataField: 'pas_nosaukums',
        text: 'Nosaukums',
        filterCfg: getTextFilterCfg()
      },
      {
        dataField: 'pas_slodzes_veids',
        text: 'Ietekmētā slodze',
        filterCfg: getSelectFilterCfg(kodif.pasakumiSlodze),
        width: 300,
        formatter: getFormatterForKodif(kodif.pasakumiSlodze)
      }, {
        dataField: 'periodi',
        text: 'Periods',
        sort: false,
        filterCfg: getSelectFilterCfg(kodif.UO_PERIODS),
        width: 120,
        formatter: getDefaultRendererForMultiField('klasifikators')
      }, {
        dataField: 'izpilde',
        text: 'Izpilde',
        filterCfg: getTextFilterCfg(),
        width: 300
      }
    ]
  }
    
    if(!this.columns){
      this.columns = getColumns({
        kodif: this.props.kodif
      })
    }

    let tiesibas
    let route
    if (this.props.pasakumi?.list?.every(i => i.uo_kategorija === 'pazemes')) {
      tiesibas = "BAIS_PU_LABOT"
      route = 'loc_pazemes_pasakums'
    } else if (this.props.pasakumi?.list?.every(i => i.uo_kategorija !== 'pazemes')) {
      tiesibas = "BAIS_VU_PAMAT_LABOT"
      route = 'loc_virszemes_pasakums'
    }

    return (
      <div className="saraksts-wrapper">
        <Grid
          onRowSelected={this.props.pasakumsSelected}
          loading={this.props.pasakumi.loading}
          selectedId={this.props.pasakumi.selected}
          dataList={this.props.pasakumi.list}
          totalCount={this.props.pasakumi.count}
          columns={this.columns}
          rowEvents={this.rowEvents}
        >

          { this.props.user.tiesibas.includes(tiesibas) ? (
            <Link to={{ type: route,
              payload: {
                kods: 'jauns',
                id: 'jauns'
              }
            }}>
              <Button color="primary"><i className="fa fa-plus-circle"></i>&nbsp;&nbsp;Jauns</Button>
            </Link>
          ) : null }
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = ({kodif, pasakumi, gui, user}) => {
  return {kodif, pasakumi, gui, user}
}

export default connect(mapStateToProps, {pasakumsSelected, guiSet, pasakumsVirszemesDoubleSelected})(Pasakumi)
