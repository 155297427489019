import * as helpers from './helpers'

const reduce_list = helpers.createListReducer({listViewName: 'wise_vielu_parametri_edit', actionsName: 'wise_vielu_parametri'})
const reduce_edit = helpers.createEditReducer({editViewName: 'wise_vielu_parametri_edit', actionsName: 'wise_vielu_parametri'})

export const wise = (state = helpers.listEditGetInitialState(), {type, payload, reqId}) => {
    const s = reduce_list({ state, type, payload, reqId })
    return reduce_edit({ state: s, type, payload, reqId })
}

