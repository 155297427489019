import React, { Component } from 'react'
import { connect } from 'react-redux'
import Loading from '../Loading'
import Link from 'redux-first-router-link'
import Form from '../forms/Form'
import TypeaheadField from '../forms/TypeaheadField'
import { izmainasActions, dalaIzmainasActions, atradneIzmainasSum, atradneIzmainasClearSum, atradneChangeGrouped, atradnesDalaChangeGrouped } from '../../actions/atradnesActions'
import BootstrapTable from 'react-bootstrap-table-next'
import { Button, Label, Input, FormGroup } from 'reactstrap'
import { getCommonColumns, getLinkParams, atradneToLinkParams, getDalaColumnWithNosaukumsLabel, irParbauditiField } from '../dokumentacija/common'
import { datumsField, licenceField, limitaApjomsField, izrakteniField, izmainasVeidsField, izmantosanaField, ievadesStatuss } from '../izmainas/Izmaina'
import { columnCfgToBootstrapTableColumn } from '../grid/Grid'
import { getDalaFieldCfg, PlusButton, SummingResultPanel, fileRenderer } from '../forms/FormHelpers'
import { round } from '../../utils'
import { getMultiBadgeFormatterForKodifUniqueLetters, getEditIconColumn, periodsOrYearFormatter, dateFormatter } from '../grid/ColumnHelpers'
import _ from 'lodash'

const licColorStyleFunc = (cell, row) => row.licColor ? { backgroundColor: row.licColor } : {}
const limColorStyleFunc = (cell, row) => row.limColor ? { backgroundColor: row.limColor } : {}

const getColumns = ({ kodif, numurs, isDala, atrId, atradneIzmainasSum, atradne, user }) => {

  const commonColumns = getCommonColumns({ kodif })
  const columns = [
    getEditIconColumn((row) => getLinkParams({ loc: 'loc_atradnes_izmaina_grouped', locDala: 'loc_atradnes_dala_izmaina_grouped', numurs, isDala, atrId, extraPayload: { id: row._isGrouped ? row.id : row.izmainu_grupa_id } })),
    {
      dataField: 'datums',
      text: 'Izmaiņu periods',
      formatter: periodsOrYearFormatter,
      width: 100
    },
    getDalaColumnWithNosaukumsLabel({ numurs, isDala, atrId }),
    {
      dataField: 'licence.adresats_nosaukums',
      text: 'Licences / atļaujas adresāts',
      width: 120,
      style: licColorStyleFunc,
      formatter: (nos, row) => row.licence ?
        <Link
          to={getLinkParams({
            ...atradneToLinkParams(atradne),
            loc: 'loc_atradnes_licence',
            locDala: 'loc_atradnes_dala_licence',
            extraPayload: { id: row.licence.id },
          })}>
          {nos}
        </Link>
        : null,
    },
    {
      dataField: 'licence.numurs',
      text: 'Licences / atļaujas Nr.',
      width: 100,
      style: licColorStyleFunc,
      sortFunc: (a, b, order) => {
        if (!a) { return order === 'asc' ? 1 : -1 }
        if (!b) { return order === 'asc' ? -1 : 1 }
        const aNr = parseInt(a.match(/\d+/))
        const bNr = parseInt(b.match(/\d+/))
        if (aNr === bNr) {
          return order === 'asc' ? a.localeCompare(b) : -1 * a.localeCompare(b)
        }
        return order === 'asc' ? aNr - bNr : bNr - aNr
      }
    },
    {
      ...commonColumns.find(c => c.dataField === 'izsniegts_datums'),
      dataField: 'licence.izsniegts_datums',
      text: 'Licence / atļauja derīga no',
      style: licColorStyleFunc
    },
    {
      ...commonColumns.find(c => c.dataField === 'derigs_lidz_datums'),
      dataField: 'licence.derigs_lidz_datums',
      text: 'Licence / atļauja derīga līdz',
      style: licColorStyleFunc
    },
    {
      ...commonColumns.find(c => c.dataField === 'anulets_datums'),
      dataField: 'licence.anulets_datums',
      text: 'Licence / atļauja anulēta',
      style: licColorStyleFunc
    },
    {
      dataField: 'limita_apjoms.l_adresats_nosaukums',
      text: 'Limita adresāts',
      width: 120,
      style: limColorStyleFunc,
      formatter: (nos, row) => row.limita_apjoms ?
        <Link
          to={getLinkParams({
            ...atradneToLinkParams(atradne),
            loc: 'loc_atradnes_limits',
            locDala: 'loc_atradnes_dala_limits',
            extraPayload: { id: row.limita_apjoms.l_id },
          })}>
          {nos}
        </Link>
        : null,
    },
    {
      ...commonColumns.find(c => c.dataField === 'izsniegts_datums'),
      dataField: 'limita_apjoms.l_izsniegts_datums',
      text: 'Limits derīgs no',
      style: limColorStyleFunc
    },
    {
      ...commonColumns.find(c => c.dataField === 'derigs_lidz_datums'),
      dataField: 'limita_apjoms.l_derigs_lidz_datums',
      text: 'Limits derīgs līdz',
      style: limColorStyleFunc
    },
    {
      ...commonColumns.find(c => c.dataField === 'anulets_datums'),
      dataField: 'limita_apjoms.l_anulets_datums',
      text: 'Limits anulēts',
      style: limColorStyleFunc
    },
    {
      ...commonColumns.find(c => c.dataField === 'kategorija'),
      dataField: 'limita_apjoms.kategorija',
      style: limColorStyleFunc
    },
    {
      ...commonColumns.find(c => c.dataField === 'izrakteni'),
      formatter: (izr) => izr.map(i => i.klasifikators).join(', '),
      sortValue: (izr) => izr.map(i => i.klasifikators).join(' '),
    },
    {
      ...commonColumns.find(c => c.dataField === 'krajumu_stavoklis_uz_datums'),
      dataField: 'limita_apjoms.la_krajumu_stavoklis_uz_datums'
    },
    {
      dataField: '_limita_apjoms_korigetais_pirms_izmainas',
      text: 'Limita apjoms',
      formatter: (daudzums, row) => {
        if (daudzums != null) {
          return <>
            {round(daudzums)} <span dangerouslySetInnerHTML={{ __html: row._limita_apjoms_daudzuma_merv_nosaukums }}></span>
            <PlusButton onClick={atradneIzmainasSum} row={row} fieldName='_limita_apjoms_korigetais_pirms_izmainas' />
          </>
        }
        return null
      },
      width: 130,
      align: 'right'
    },
    {
      dataField: '_krajumi_uz_1_janv',
      text: 'Krājumi uz gada 1.janv.',
      formatter: (daudzums, row) => {
        if (daudzums != null) {
          return <>
            <span className={`${daudzums < 0 ? 'red' : ''}`}>{round(daudzums)}</span>
            <PlusButton onClick={atradneIzmainasSum} row={row} fieldName='_krajumi_uz_1_janv' />
          </>
        }
        return null
      },
      width: 90,
      align: 'right'
    },
    {
      ...commonColumns.find(c => c.dataField === 'daudzums'),
      text: 'Krājumu izmaiņas',
      formatter: (daudzums, row) => {
        if (daudzums != null) {
          return <>
            {round(daudzums)}
            <PlusButton onClick={atradneIzmainasSum} row={row} fieldName='daudzums' />
          </>
        }
        return null
      },
      width: 90
    },
    commonColumns.find(c => c.dataField === 'izmainas_veids'),
    {
      dataField: '_krajumi_nak_gadaa',
      text: 'Krājumi nākamā gada 1. janv.',
      formatter: (daudzums, row) => {
        if (daudzums != null) {
          return <>
            <span className={`bold ${daudzums < 0 ? 'red' : ''}`}>{round(daudzums)}</span>
            <PlusButton onClick={atradneIzmainasSum} row={row} fieldName='_krajumi_nak_gadaa' />
          </>
        }
        return null
      },
      width: 90,
      align: 'right'
    },
    {
      dataField: 'izmantosanas',
      text: 'Izmantošana',
      width: 110,
      formatter: (izm) => _.uniq(izm.map(i => i.klasifikators)).join(', '),
      sortValue: (izm) => _.uniq(izm.map(i => i.klasifikators)).join(' '),
    },
    commonColumns.find(c => c.dataField === 'piezimes'),
    commonColumns.find(c => c.dataField === 'ir_parbauditi')
  ]
  // pievienojam ievades statusa kolonu visiem, izņemot publiskiem lietotājiem
  if (user.tiesibas.includes('SKATIT_IESNIEGTAS_ATRADNES_IZMAINAS') || atradne.ir_parstavis_atradnei === true) {
    columns.splice(1, 0, {
      dataField: 'ievades_statuss',
      text: 'Statuss',
      formatter: getMultiBadgeFormatterForKodifUniqueLetters(kodif.ievadesStatuss),
      width: 75
    })
  }
  if (user.tiesibas.includes('SKATIT_ATRADNES_IZMAINU_PIELIKUMUS') || atradne.ir_parstavis_atradnei === true) {
    columns.splice(columns.length - 2, 0, {
      dataField: 'faili',
      text: 'Pamatojošā dokumentācija',
      formatter: fileRenderer,
      width: 150
    })
  }
  return columns.map(col => columnCfgToBootstrapTableColumn(col))
}

const isLimitaApjomsKorigesanaIzmaina = (izm, kodif) => izm.izmainas_veids && kodif.IZMAINA[izm.izmainas_veids] && kodif.IZMAINA[izm.izmainas_veids].ir_limitiem

const getSumIzmainas = ({ currentValRow, currentValues, onlyLimitaApjomsIzmainas = false, kodif }) => {
  let sumIzmainas = null
  if (currentValRow.limita_apjoms && currentValRow.datums) {
    sumIzmainas = currentValues.izmainas.filter(izm =>
      (onlyLimitaApjomsIzmainas === false || isLimitaApjomsKorigesanaIzmaina(izm, kodif)) &&
      izm.daudzums != null &&
      izm !== currentValRow &&
      izm.limita_apjoms &&
      izm.limita_apjoms.id === currentValRow.limita_apjoms.id &&
      (izm.datums < currentValRow.datums ||
        (izm.datums.getTime() === currentValRow.datums.getTime() && ((currentValRow.id && izm.id && izm.id < currentValRow.id) ||
          (!currentValRow.id && izm.id) || (!currentValRow.id && !izm.id && izm.key < currentValRow.key))))
    ).reduce((sum, izm) => izm.daudzums + sum, 0.0)
    return sumIzmainas
  }
}

const renderLimitaApjoms = (kodif) => ({ currentValRow, currentValues }) => {
  const sumIzmainas = getSumIzmainas({ currentValRow, currentValues, kodif, onlyLimitaApjomsIzmainas: true })
  if (sumIzmainas != null) {
    const korigetaisApjoms = currentValRow.limita_apjoms.daudzums + sumIzmainas
    return <>{round(korigetaisApjoms)} &nbsp;
      <span dangerouslySetInnerHTML={{ __html: currentValRow.limita_apjoms.daudzuma_merv_nosaukums }}></span> </>
  }
  return null
}

const renderKrajumiUz1Janv = (kodif) => ({ currentValRow, currentValues }) => {
  const sumIzmainas = getSumIzmainas({ currentValRow, currentValues, kodif })
  if (sumIzmainas != null) {
    const atl = currentValRow.limita_apjoms.daudzums + sumIzmainas
    return <span className={`${atl < 0 ? 'red' : ''}`}>{round(atl)}</span>
  }
  return null
}

const getFormFieldCfgs = (isDala = false) => ({ kodif, user }) => {
  return [{
    name: 'izmainas',
    label: undefined,
    multiFieldCfgs: [
      ievadesStatuss({ kodif, user }),
      datumsField(),
      getDalaFieldCfg(isDala),
      licenceField(),
      limitaApjomsField(),
      izrakteniField({ kodif }),
      {
        name: 'limita_apjoms_la_krajumu_stavoklis_uz_datums',
        label: 'Limitā noteiktais krājumu stāvoklis uz',
        editable: false,
        renderer: (val, row, props) => {
          const { currentValRow } = props
          if (currentValRow && currentValRow.limita_apjoms && currentValRow.limita_apjoms.la_krajumu_stavoklis_uz_datums) {
            return dateFormatter(currentValRow.limita_apjoms.la_krajumu_stavoklis_uz_datums)
          } else {
            return null
          }
        },
        cellClassName: 'semi-narrow padding-5'
      }, {
        name: 'limita_apjoms_daudzums',
        label: 'Limita apjoms',
        customField: renderLimitaApjoms(kodif),
        renderer: (val, row, props) => renderLimitaApjoms(kodif)(props),
        cellClassName: 'semi-narrow padding-5'
      }, {
        name: 'limita_apjoms_daudzuma_merv_nosaukums',
        label: 'Krājumi uz gada 1.janv.',
        customField: renderKrajumiUz1Janv(kodif),
        renderer: (val, row, props) => renderKrajumiUz1Janv(kodif)(props),
        cellClassName: 'semi-narrow padding-5'
      }, {
        name: 'daudzums',
        cellClassName: 'narrow',
        overridePropsFunc: (props) => {
          const changedProps = { ...props }
          const { currentValRow, currentValues, lastEditedFieldName, fieldNameRow } = props
          const sumIzmainas = getSumIzmainas({ currentValRow, currentValues, kodif })
          if (lastEditedFieldName === `${fieldNameRow}.atlikums` && currentValRow.atlikums != null && sumIzmainas != null) {
            currentValRow.daudzums = round(currentValRow.atlikums - currentValRow.limita_apjoms.daudzums - sumIzmainas)
            changedProps.currentVal = currentValRow.daudzums
          }
          return changedProps
        },
        required: true
      }, {
        name: 'atlikums',
        label: 'Krājumi nākamā gada 1. janv',
        type: "number",
        cellClassName: 'narrow',
        overridePropsFunc: (props) => {
          const changedProps = { ...props }
          const { currentValRow, currentValues, lastEditedFieldName, fieldNameRow } = props
          const sumIzmainas = getSumIzmainas({ currentValRow, currentValues, kodif })
          if (lastEditedFieldName !== `${fieldNameRow}.atlikums` && currentValRow.daudzums != null && sumIzmainas != null) {
            currentValRow.atlikums = round(currentValRow.limita_apjoms.daudzums + currentValRow.daudzums + sumIzmainas)
            changedProps.currentVal = currentValRow.atlikums
          }
          return changedProps
        },
        renderer: (val, row) => val != null ? <span className={`bold ${val < 0 ? 'red' : ''}`}>{val}</span> : null
      },
      izmainasVeidsField({ kodif, user }),
      izmantosanaField({ kodif }),
      {
        name: 'faili',
        type: 'files'
      },
      {
        name: 'piezimes',
        type: "text"
      },
      irParbauditiField()
    ],
    renderer: () => null,
    createNewRow: () => ({ ir_parbauditi: true, faili: [], ievades_periods: 'D' }),
    sizeLabel: 0,
    sizeForm: 12
  }]
}

const isIzmainaVisible = (izmaina, licenceFilter, limitsFilter, dalaFilter) => (!licenceFilter ||
  !licenceFilter.id ||
  (izmaina.licence && izmaina.licence.id === licenceFilter.id))
  &&
  (!limitsFilter ||
    !limitsFilter.id ||
    (izmaina.limita_apjoms && izmaina.limita_apjoms.id === limitsFilter.id))
  &&
  (!dalaFilter ||
    !dalaFilter.id ||
    (izmaina.dala_id === dalaFilter.id))

class AtradnesIzmainasComp extends Component {

  constructor(props) {
    super(props)
    this.state = {
      licence: null,
      limits: null
    }
  }

  // if atradne have changed reset state and columns
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { selectedItem, editable } = this.props.atradneIzmainas
    const { selectedItem: nextSelectedItem, editable: nextEditable } = nextProps.atradneIzmainas
    if (!selectedItem || !nextSelectedItem || selectedItem.id !== nextSelectedItem.id) {
      this.setState({
        licence: null,
        limits: null
      })
      this.izmainasCols = null
    }
    this.startedEditing = (!editable && nextEditable)
  }

  rowEvents = {
    onDoubleClick: (e, row, rowIndex) => {
      // open izmaina edit page
      //TODO
      // this.props.openUzzinaFromAtradne({id:row.uzzina_id, numurs: this.props.atradne.selectedItem.numurs})
    }
  }

  filterData = (fieldName, val) => {
    const newVal = {}
    newVal[fieldName] = val
    this.setState({
      ...newVal
    })
  }

  render() {

    const { selectedItem, editable, submiting, isGrouped, loadingGrouped } = this.props.atradneIzmainas

    // loading while we have no atradne
    if (!selectedItem || !this.props.atradne) {
      return (<Loading />)
    }
    const { selectedItem: atradne } = this.props.atradne

    const { kodif, isDala, user, getFormFieldCfgsFunc, metadataFormCfg, atradneIzmainasSum, atradneIzmainasClearSum, atradneChangeGrouped } = this.props
    const { id: atrId } = selectedItem
    const numurs = isDala ? selectedItem.atradnes_numurs : selectedItem.numurs
    if (!this.izmainasCols) {
      this.izmainasCols = getColumns({ kodif, numurs, isDala, atrId, atradneIzmainasSum, atradne: selectedItem, user })
    }

    if (!this.formFieldCfgs) {
      const f = getFormFieldCfgsFunc ? getFormFieldCfgsFunc : getFormFieldCfgs()
      this.formFieldCfgs = f({
        kodif, user
      })
    }

    const extraElements = <> <div className="field float-right" style={{ maxWidth: 350 }}>
      <TypeaheadField
        fieldName="limits"
        currentVal={this.state.limits}
        autocompleteCfg={{
          isValueObjStructure: true,
          options: selectedItem.lim_options,
        }}
        extraFieldProps={{
          placeholder: 'Filtrēt pēc Limita',
          disabled: editable
        }}
        setFieldValue={this.filterData}
      />
    </div>
      <div className="field float-right" style={{ maxWidth: 350 }}>
        <TypeaheadField
          fieldName="licence"
          currentVal={this.state.licence}
          autocompleteCfg={{
            options: selectedItem.lic_options,
            isValueObjStructure: true,
          }}
          extraFieldProps={{
            placeholder: 'Filtrēt pēc Licences',
            disabled: editable
          }}
          setFieldValue={this.filterData}
        />
      </div>
      <div className="field float-right" style={{ maxWidth: 350 }}>
        <TypeaheadField
          fieldName="dala"
          currentVal={this.state.dala}
          autocompleteCfg={{
            options: [{ tah_id: selectedItem.id, tah_label: `Visa atradne` }]
              .concat(selectedItem.dalas ? selectedItem.dalas.map(d => ({ tah_id: d.id, tah_label: `${d.nosaukums}` })) : []),
            isValueObjStructure: true,
          }}
          extraFieldProps={{
            placeholder: 'Filtrēt pēc Atradnes daļas',
            disabled: editable
          }}
          setFieldValue={this.filterData}
        />
      </div>

      {!editable && (user.tiesibas.includes('LABOT_ATRADNES_IZMAINAS') || atradne.ir_parstavis === true) ? <Link to={getLinkParams({
        loc: 'loc_atradnes_izmaina_grouped',
        locDala: 'loc_atradnes_dala_izmaina_grouped',
        numurs, isDala, atrId, extraPayload: { id: 'jauns' }
      })}>
        <Button color="primary"><i className="fa fa-plus-circle"></i>&nbsp;&nbsp;{user.tiesibas.includes('LABOT_ATRADNES_IZMAINAS') ? 'Jauna izmaiņa' : 'Ievadīt ieguvi'}</Button>
      </Link> : null}

      <FormGroup check inline>
        <Label check>
          <Input type="checkbox" value={isGrouped} onChange={atradneChangeGrouped} checked={isGrouped} /> apvienot izmaiņas gada ietvaros
        </Label>
      </FormGroup>
    </>

    //change filtered izmainas only if filter have changed
    let filtersChanged = false
    if (editable) {
      if (this.startedEditing && this.bootstrapTable && this.bootstrapTable.table && this.bootstrapTable.sortContext
        && this.bootstrapTable.sortContext.state.sortColumn) {
        const gridData = this.bootstrapTable.table.props.data
        selectedItem.izmainas.sort((a, b) => gridData.findIndex(el => el.id === a.id) - gridData.findIndex(el => el.id === b.id))
      }
      selectedItem.izmainas.forEach(izm => {
        const newHiddenRow = !isIzmainaVisible(izm, this.state.licence, this.state.limits, this.state.dala)
        if (newHiddenRow !== izm.hiddenRow) {
          filtersChanged = true
          izm.hiddenRow = newHiddenRow
        }
      })
    }

    return <div className="full-height-form-or-grid-container">
      <Form
        className={editable ? '' : 'hidden'}
        editable={editable}
        submiting={submiting}
        dataObj={(filtersChanged || this.startedEditing) ? { ...selectedItem } : selectedItem}
        formFieldCfgs={this.formFieldCfgs}
        onSubmit={this.props.saveAct}
        onReset={this.props.stopEditAct}
        onStartEdit={this.props.startEditAct}
        btnTexts={{ edit: 'Labot izmaiņas' }}
        extraElements={extraElements}
        metadataFormCfg={metadataFormCfg}
        tiesibasLabot="PUBLICET_ATRADNES_IZMAINAS"
        disabledEditTip={isGrouped ? 'Grupētas izmaiņas nevar labot šajā skatā, skatiet individuālas izmaiņas!' : false}
      />
      {!editable && !loadingGrouped ?
        <>
          <BootstrapTable
            bootstrap4
            striped
            hover
            condensed
            keyField="id"
            data={selectedItem.izmainas.filter(izm => isIzmainaVisible(izm, this.state.licence, this.state.limits, this.state.dala))}
            columns={this.izmainasCols}
            rowEvents={this.rowEvents}
            ref={n => this.bootstrapTable = n}
          />
          <SummingResultPanel summables={selectedItem.izmainas} onClickClear={atradneIzmainasClearSum}
            mervFieldName='_limita_apjoms_daudzuma_merv_nosaukums' />
        </>
        : null}
      {loadingGrouped ?
        <Loading />
        : null}
    </div>
  }
}

const mapStateToProps = ({ atradneIzmainas, kodif, user, atradne }) => ({ atradne, atradneIzmainas, kodif, user, metadataFormCfg: "atradne_izmaina_view" })
const AtradnesIzmainas = connect(mapStateToProps, { ...izmainasActions, atradneIzmainasSum, atradneIzmainasClearSum, atradneChangeGrouped })(AtradnesIzmainasComp)
export default AtradnesIzmainas

const mapStateToPropsDala = ({ atradnesDalaIzmainas, kodif, user, atradnesDala }) => ({
  atradne: atradnesDala,
  isDala: true,
  atradneIzmainas: atradnesDalaIzmainas,
  kodif,
  user,
  getFormFieldCfgsFunc: getFormFieldCfgs(true),
  metadataFormCfg: "atradnes_dala_izmaina_view"
})
export const AtradnesIzmainasDala = connect(mapStateToPropsDala, { ...dalaIzmainasActions, atradneIzmainasSum, atradneIzmainasClearSum, atradneChangeGrouped: atradnesDalaChangeGrouped })(AtradnesIzmainasComp)
