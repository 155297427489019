import * as helpers from "./helpers";

const defaultListReducerForNobides = helpers.createListReducer({listViewName: 'uo_nobides_edit', actionsName: 'nobides'})
const defaultEditReducerForNobides = helpers.createEditReducer({editViewName: 'uo_nobides_edit', actionsName: 'nobides'})
export const nobides = (state = helpers.listEditGetInitialState(), {type, payload, reqId}) => {
  //overwrite or add custom actions catches here

  const stateAfterList = defaultListReducerForNobides({state, type, payload, reqId})
  return defaultEditReducerForNobides({state: stateAfterList, type, payload, reqId})
}
