import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Button} from 'reactstrap';
import {addUrlQuery} from '../../actions/uiActions'

class ManiObjektiButton extends Component {

    toggleFilter = () => {
        const efObj = {...this.getQueryExtraFilter(), mani: this.maniEnabled() ? undefined : 't'}
        let ef = JSON.stringify(efObj)
        ef = ef === '{}' ? undefined : ef
        this.props.addUrlQuery({ef})
    }

    getQueryExtraFilter = () => {
        const {query} = this.props
        return query && query.ef ? JSON.parse(query.ef) : {}
    }

    maniEnabled = () => this.getQueryExtraFilter().mani === 't'

    render() {
        const {user, allText, maniText} = this.props
        const irMani = this.maniEnabled()
        return (
            <>
            { user.ir_parstavis_atradnem === true ?
                <Button onClick={this.toggleFilter} color={irMani ? "primary" : "secondary"}>
                    {
                        irMani ? allText : maniText
                    }
                </Button>
            : null}
            </>
        )
    }
}

const mapStateToProps = ({location, user}) => {
    return {query: location.query, user}
}

export default connect(mapStateToProps, {addUrlQuery})(ManiObjektiButton)
