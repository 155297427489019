import React, {Component} from 'react';
import {connect} from 'react-redux'
import Loading from '../Loading'
import GridWithForm from '../grid/GridWithForm'
import {
  getTextFilterCfg,
} from '../grid/ColumnHelpers'
import {
  getDefaultRendererForMultiField, getKlasifikatorsFieldCfg, getKodifRenderer,
  getMultiKlasifikatorsFieldCfg,
} from '../forms/FormHelpers'
import {addUrlQuery} from '../../actions/uiActions'
import {pasakumuKlasifActions, pasakumuKlasifSelected} from "../../actions/pasakumiActions";

const getColumns = ({kodif}) => {
  return [
    {
      dataField: 'kods',
      text: 'Kods',
      filterCfg: getTextFilterCfg(),
      width: 100
    }, {
      dataField: 'nosaukums',
      text: 'Nosaukums',
      filterCfg: getTextFilterCfg()
    }, {
      dataField: 'periodi',
      text: 'Plānošanas periodi',
      // filterCfg: getTextFilterCfg(),
      formatter: getDefaultRendererForMultiField('klasifikators'),
      classes: 'display-linebreak',
      width: 150
    }
  ]};

const  getFormFieldCfgs = ({kodif}) => {

  return [
    {
      name: 'kods',
      type: 'text',
      required: true,
    },
    {
      name: 'nosaukums',
      type: 'text',
      required: true,
      // extraFieldProps: () => ({ fieldType : "html" })
    },
    {
      name: 'apraksts',
      type: 'text',
      required: true,
      // extraFieldProps: () => ({ fieldType : "html" })
    },
    {
      ...getKlasifikatorsFieldCfg(kodif, 'pasakumsSlodze'),
      name: 'slodzes_veids',
      renderer: getKodifRenderer(kodif.pasakumsSlodze)
    },
    {
      ...getKlasifikatorsFieldCfg(kodif, 'pasakumsVeids'),
      name: 'veids',
      renderer: getKodifRenderer(kodif.pasakumsVeids)
    },
    {
      ...getKlasifikatorsFieldCfg(kodif, 'pasakumsUoKategorija'),
      name: 'uo_kategorija',
      renderer: getKodifRenderer(kodif.pasakumsUoKategorija)
    },
    {
      ...getKlasifikatorsFieldCfg(kodif, 'pasakumsMerogs'),
      name: 'merogs',
      renderer: getKodifRenderer(kodif.pasakumsMerogs)
    },
    {
      name: 'efekts',
      type: 'text',
    },
    {
      name: 'termins',
      type: 'text',
    },
    {
      name: 'institucijas',
      type: 'text',
    },
    {
      name: 'finansejums',
      type: 'text',
    },
    {
      ...getMultiKlasifikatorsFieldCfg(kodif, 'UO_PERIODS'),
      name: "periodi",
    },
    {
      ...getMultiKlasifikatorsFieldCfg(kodif, 'UO_PASAKUMA_VIRZIENS'),
      name: "virzieni",
    },
  ]};

class PasakumuKlasifikators extends Component {

  getCurrentQueryGrupas = () => {
    const {query} = this.props
    const ef = query && query.ef ? JSON.parse(query.ef) : {}
    return ef.grupas ? ef.grupas.split(',') : []
  }

  extraGridProps = {
    rowClasses: (row, rowIndex) => {
      if(row && row.ir_arhivets === true){
        return 'row-archived';
      }
    }
  }

  render() {
    const pasakumuKlasif = this.props.pasakumuKlasif
    if(!pasakumuKlasif || pasakumuKlasif.loading) {
      return (<Loading/>)
    }

    if(!this.columns){
      this.columns = getColumns({
        kodif: this.props.kodif
      })
    }

    if(!this.formFieldCfgs){
      this.formFieldCfgs = getFormFieldCfgs({
        kodif: this.props.kodif
      })
    }

    let tiesibas
    if (pasakumuKlasif?.list[0]?.uo_kategorija === 'pazemes') {
      tiesibas = "BAIS_PU_LABOT"
    } else if (pasakumuKlasif?.list[0]?.uo_kategorija !== 'pazemes') {
      tiesibas = "BAIS_VU_PAMAT_LABOT"
    }
    return (
      <GridWithForm
        {...this.props}
        columns={this.columns}
        formFieldCfgs={this.formFieldCfgs}
        storeObj={pasakumuKlasif}
        formWidthGuiOption="pasakumu_klasifikators_form_width"
        metadataFormCfg="pasakumu_klasifikators_edit"
        extraGridProps={this.extraGridProps}
        tiesibasLabot={tiesibas}
      />
    )

  }

}

const mapStateToProps = ({pasakumuKlasif, kodif, location}) => ({pasakumuKlasif, kodif, query: location.query})

export default connect(mapStateToProps, {
  selectedAct : pasakumuKlasifSelected,
  addUrlQuery,
  ...pasakumuKlasifActions
})(PasakumuKlasifikators)
