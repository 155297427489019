import {lietotajsInfo, userApi} from './lietotajsActions'
import {redirect} from 'redux-first-router'
import {callApi} from './helpers'
import {fetchMetadata} from '../api'

export const startWeb = (initialDispatch) => (dispatch) => {
    //in all cases we make initialDispatch - in routes we will be redirected, if no user data
    return dispatch(lietotajsInfo()).then(() => {
        dispatch(userApi()).then(() => {
            initialDispatch()
        })
    })
}

export const guiSet = (element, value = true) => {
    return {type: 'gui_set', element, value}
}
export const guiUnset = (element) => {
    return {type: 'gui_unset', element}
}
export const addUrlQuery = (queryObj) => (dispatch, getState) => {
  const {type, query, payload} = getState().location
  const newQuery = {...query, ...queryObj, exportOds : undefined}
  return dispatch(redirect({type, payload, query : newQuery}))
}
export const exportOds = () => (dispatch, getState) => {
    const {type, query, payload} = getState().location
    const newQuery = {...query, exportOds : true}
    return dispatch(redirect({type, payload, query : newQuery}))
}
export const showErrorMsg = (message) => {
    return {type: 'flash', message}
}

export const loadMetadataIfNeeded = (forced = false) => (dispatch, getState) => {
    if(forced || getState().metadata.requested === false){
        return dispatch(callApi({
            viewName: 'metadata',
            apiMethod: fetchMetadata
          }))
    }
}
