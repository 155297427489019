import React, {Component} from 'react'
import {connect} from 'react-redux'
import Grid from '../grid/Grid'
import {
    getSelectFilterCfg,
    getTextFilterCfg,
    getDateFilterCfg,
    dateTimeFormatter,
    getBadgeFormatterForKodif,
    getSelectFilterCfgForViewsFromMetadata,
    translateAuditPath,
    translateAuditValue
} from '../grid/ColumnHelpers'
import Loading from '../Loading'
import {getLinkFromViewname} from '../../routes'
import {AtradneOrDalaLink} from '../dokumentacija/common'
import IzraktenisMap from '../karte/IzraktenisMap'
import * as maputils from '../karte/maputils'
import { Button } from 'reactstrap'
import {isEmptyValue} from '../forms/FormHelpers'

export const getColumns = ({kodif, metadata}) => {
    return [
        {
            dataField: 'lietotajvards',
            text: 'Lietotājs',
            filterCfg: getTextFilterCfg()
        }, {
            dataField: 'access_time',
            text: 'Laiks',
            formatter: dateTimeFormatter,
            filterCfg: getDateFilterCfg(),
            width: 150,
        }, {
            dataField: 'access_type',
            text: 'Veids',
            width: 120,
            filterCfg: getSelectFilterCfg(kodif.izmainuVesturesVeids),
            formatter: getBadgeFormatterForKodif(kodif.izmainuVesturesVeids),
        }, {
            dataField: 'status',
            text: 'Statuss',
            width: 120,
            filterCfg: getSelectFilterCfg(kodif.izmainuVesturesStatuss),
            formatter: getBadgeFormatterForKodif(kodif.izmainuVesturesStatuss)
        }, {
            dataField: 'view_name',
            text: 'Sadaļa',
            filterCfg: getSelectFilterCfgForViewsFromMetadata(metadata),
            formatter: (viewname, row) => getLinkFromViewname({
                viewname,
                entity_id: row.entity_id,
                metadata,
                atr_numurs: row.atr_numurs,
                atr_id: row.atra_id
            })
        }, {
            dataField: 'atradnes',
            text: 'Saistītās atradnes un daļas',
            formatter: (atradnes) => atradnes.map(atr =>
               <AtradneOrDalaLink
                key={atr.id}
                id={atr.id}
                numurs={atr.numurs}
                ieraksta_veids={atr.ieraksta_veids}
                nosaukums={atr.tah_label}
                kodif={kodif}/>),
            filterCfg: {
                type: 'autocomplete',
                autocompleteCfg: {
                    remoteSearchViewName: 'atradne_search'
                }
            },
            sort: false
        }
  ]};

const openWindowWithGeometry = (geom) => () => {
    const olGeom = maputils.GeoJSONFormat.readGeometry(geom)
    var wnd = window.open("about:blank", "", "_blank")
    wnd.document.write(maputils.WKTFormat.writeGeometry(olGeom))
}

export const getExpandRowDef = ({metadata}) => ({
    renderer: row => (
    <>
    {row.error ?
        <div className="danger">{row.error}</div> : null
    }
    <table className="vesture-diffs">
        <thead>
            <tr>
                <th className="path">Ievadlauks</th>
                <th className="old_value">Iepriekšējā vērtība</th>
                <th className="new_value">Jaunā vērtība</th>
            </tr>
        </thead>
        <tbody>
            {row.diffs.map( (d,i) =>
                <tr key={i}>
                        <td>{translateAuditPath({metadata, path: d.path, viewName: row.view_name})}</td>
                        {(d.path && d.path[d.path.length -1] === 'geom') ?
                            <td colSpan="2">
                                <div className="karte-audits">
                                    <IzraktenisMap mapId="audits" menuVisible={false}
                                        vectorLayerCfgs={
                                            { new : {
                                                title: 'Jaunā ģeometrija',
                                                features: [{geom : d.new_value, id: 1}],
                                                styles: maputils.auditStyle,
                                                tooltipFunc: () => 'Jaunā ģeometrija',
                                                selected: 1,
                                                zoomTo: isEmptyValue(d.new_value) ? undefined : 1,
                                                epsgCode: 3059,
                                                legend: {
                                                  features: [
                                                      {
                                                          label: 'Jaunā ģeometrija',
                                                          geomType: 'polygon',
                                                          selected: true
                                                      }
                                                  ]
                                                }
                                            },
                                            old : {
                                                title: 'Iepriekšējā ģeometrija',
                                                features: [{geom : d.old_value, id: 2}],
                                                styles: maputils.auditStyle,
                                                tooltipFunc: () => 'Iepriekšējā ģeometrija',
                                                zoomTo: isEmptyValue(d.new_value) ? 2 : undefined,
                                                epsgCode: 3059,
                                                legend: {
                                                  features: [
                                                      {
                                                          label: 'Iepriekšējā ģeometrija',
                                                          geomType: 'polygon',
                                                          selected: false
                                                      }
                                                  ]
                                                }
                                            }}
                                        } />
                                </div>
                                <div>
                                { !isEmptyValue(d.old_value) ? <Button color="primary" style={{margin: 4}} size="sm" onClick={openWindowWithGeometry(d.old_value)}>Iepriekšējā ģeometrija WKT formātā</Button> : null }
                                { !isEmptyValue(d.new_value) ? <Button color="primary" style={{margin: 4}} size="sm" onClick={openWindowWithGeometry(d.new_value)}>Jaunā ģeometrija WKT formātā</Button> : null }
                                </div>
                            </td>
                        :
                            <>
                                <td>{translateAuditValue(d.old_value)}</td>
                                <td>{translateAuditValue(d.new_value)}</td>
                            </>
                        }

                </tr>
            )}
      </tbody>
    </table>
    </>
    ),
    showExpandColumn: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => (isAnyExpands ?
        <i className="fa fa-minus-square expand-collapse-button"></i>
        : <i className="fa fa-plus-square expand-collapse-button"></i>),
    expandColumnRenderer: ({ expanded }) => (expanded ?
        <i className="fa fa-minus-square expand-collapse-button-row"></i>
        : <i className="fa fa-plus-square expand-collapse-button-row"></i>
    )
})

class Audits extends Component {

      render() {

        const {metadata} = this.props

        if (!metadata.loaded) {
            return (<Loading/>)
        }

        if(!this.columns){
            this.columns = getColumns({
                kodif: this.props.kodif,
                metadata
            })
        }

        if(!this.expandRow){
            this.expandRow = getExpandRowDef({
                metadata
            })
        }

        const {loading, list, count} = this.props.audits

        return (
            <div className="saraksts-wrapper">
                <Grid
                    loading={loading}
                    dataList={list}
                    totalCount={count}
                    columns={this.columns}
                    expandRow={this.expandRow}
                />
            </div>
        )
    }
}

const mapStateToProps = ({audits, kodif, metadata}) => ({audits, kodif, metadata})
export default connect(mapStateToProps)(Audits)
