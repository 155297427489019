import React from 'react'

import * as api from '../../api'

class SingleFileUploader extends React.PureComponent {
  // generic file uplāderis

  constructor (props) {
    super(props)

    this.state = {
      error: null,
      is_uploading: false,
      limit: props.limit
    }

    this.fileInput = React.createRef()

    ;([
      'renderError',
      'renderDropArea',

      'dropClicked',
      'fileInputChanged',

      'uploadOne',
      'uploadSome',

      'dragEntered',
      'dragDropped',
    ]).forEach( f => {
      if ( ! this[f] ) {
        console.error(`Warning: ${f} missing`)
      } else {
        this[f] = this[f].bind(this)
      }
    } )
  }


  uploadOne (file) {
    if (file.size > this.state.limit) {
      this.setState({error:"Fails ir pārāk liels!"})
    } else {
      this.setState({is_uploading: true})
      return api.uploadFile(file)
          .then((res) => {
            this.setState({is_uploading: false})
            this.props.onFileUploaded(res)
          })
    }
  }



  uploadSome (files, idx = 0) {
    if (files.length > idx) {
      return this.uploadOne(files.item(idx))
        .then( () => this.uploadSome(files, idx + 1) )
    }
  }


  fileInputChanged (ev) {

    ev.preventDefault()

    if (this.state.is_uploading) {
      console.log("Still uploading, actions locked")
      return false
    }

    this.setState({ is_uploading: true, error: null })

    this.uploadSome(ev.target.files).then(
      () => {
        this.setState({ is_uploading: false })
        this.fileInput.current.value = null
      }
    ).catch( (error) => {
      this.setState({
        error: `${error}`,
        is_uploading: false,
      })
    } )

  }

  dragEntered (ev) {
    ev.preventDefault()
    ev.stopPropagation()
  }

  dragDropped (ev) {
    ev.preventDefault()
    ev.stopPropagation()

    if (ev.dataTransfer.files.length === 0) {
      alert('Nav saņemts neviens fails?')
    } else {
      this.uploadSome(ev.dataTransfer.files)
    }
  }


  dropClicked () {
    if (this.state.is_uploading) return false
    this.fileInput.current && this.fileInput.current.click()
  }


  renderDropArea () {

    const c = "csfu-droparea " + (this.state.is_uploading ? ' is-uploading' : '')

    return (
      <button type="button" className={c} 
        onClick={this.dropClicked}
        onDragEnter={this.dragEntered} 
        onDragOver={this.dragEntered}
        onDrop={this.dragDropped}>
        <div className="csfu-droparea-inner">
          <span className="fa fa-upload" />
          {
            this.props.filename ? this.props.filename : "Ielādē failu"
          }
          <div>
            {
              this.props.filename ? "" : "Vai uzvelc ar peli virsū"
            }
          </div>
        </div>
      </button>
    )
  }


  renderError () {
    if (this.state.error) {
      return (
        <p className="alert alert-danger">{ this.state.error }</p>
      )
    }
  }


  render () {
    return (
      <div className="c-single-file-uploader">
        <input 
          type="file"
          multiple={false}
          ref={this.fileInput}
          onChange={this.fileInputChanged}
          className="d-none"
        />
        {
          this.renderDropArea()
        }
        {
          this.renderError()
        }
      </div>
    )
  }
}


const viewSingleFile = (value, all_props) => {
  const cu = all_props.file || {}
  return cu.id ? <a className="btn btn-small btn-outline-download flex-grow-1 text-left" href={`/services/download/${cu.id}/${cu.sha_256}`}><span className="fa fa-download" />{ cu.filename }</a> : null
}


const SingleFileUploaderField = (props) => {

  const cu = props.currentValRow.file || {}
  return props.editable ? (

    cu.id ? (

      <div className="d-flex">
        { viewSingleFile(null, props.currentValRow) }
        <button onClick={() => {
          props.setFieldValue(props.fieldName, null)
          props.setFieldValue("file", {})
        }} className="btn btn-small btn-outline-danger ml-2"><span className="fa fa-trash" /></button>
      </div>

    ) : (

      <SingleFileUploader
        filename={ cu.filename }
        limit={props.uploadSize.smallFileLimit}
        onFileUploaded={fi => {
          props.setFieldValue(props.fieldName, fi.id)
          props.setFieldValue("file", fi)
        }} />

    )


  ) : viewSingleFile(null, props.currentValRow)
}


export { SingleFileUploader , SingleFileUploaderField, viewSingleFile }
export default SingleFileUploader
