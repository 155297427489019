import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
  getBooleanFormatter,
  getBooleanSelectFilterCfg, getSelectFilterCfg,
  getTextFilterCfg,
} from '../grid/ColumnHelpers'

import Karte from '../karte/IzraktenisMap';
import * as maputils from '../karte/maputils'

import SplitterLayout from 'react-splitter-layout';

import {guiSet} from '../../actions/uiActions'

import Grid from '../grid/Grid'
import {stacijasDoubleSelected, stacijasMeteoSelected} from "../../actions/stacijasActions";

const getColumns = ({vvisKodifs}) => {
  return [{
    dataField: 'monit_veids',
    text: 'Monitoringa veids',
    // width: 240,
    filterCfg: getSelectFilterCfg(vvisKodifs.B10),
  }, {
    dataField: 'nosaukums',
    text: 'Nosaukums',
    // width: 60,
    filterCfg: getTextFilterCfg()
  }, {
    dataField: 'piederiba',
    text: 'Piederība',
    // width: 120,
    filterCfg: getSelectFilterCfg(vvisKodifs.B04),
  }, {
    dataField: 'atis_code',
    text: 'ATIS kods',
    // width: 120,
    filterCfg: getTextFilterCfg()
  }, {
    dataField: 'descr',
    text: 'ATIS objekts',
    // width: 120,
    filterCfg: getTextFilterCfg()
  }, {
    dataField: 'ir_atis',
    text: 'Ir definēts ATIS',
    // width: 120,
    formatter: getBooleanFormatter(),
    filterCfg: getBooleanSelectFilterCfg()
  }, {
    dataField: 'ir_aktivs',
    text: 'Aktīva',
    // width: 120,
    formatter: getBooleanFormatter(),
    filterCfg: getBooleanSelectFilterCfg()
  }, ]
};

class StacijaListBase extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modalList: false
    };
  }

  mapPaneSizeChange = (h) => {
    this.props.guiSet('udens_nov_saraksts_karte_height', h);
  }

  rowEvents = {
    onDoubleClick: (e, row) => {
      this.props.stacijasDoubleSelected(row.id)
    }
  }

  render() {
    const vectorLayerCfgs = {
      stacijas : {
        title: 'Novērojumu stacijas',
        features: this.props.stacijas.list,
        styles: maputils.uoStyles.stacijas,
        tooltipFunc: (feat) => feat.get('nosaukums'),
        selected: this.props.stacijas.selected,
        epsgCode: 3059,
        legend: maputils.defaultSinglePointLegend
      }
    }

    const extraGridProps = {
      // keyField: 'rowid'
    }

    if(!this.columns){
      this.columns = getColumns({
        vvisKodifs: this.props.vvisKodifs
      })
    }

    return (
      <SplitterLayout
        vertical
        percentage
        primaryIndex={1}
        secondaryInitialSize={this.props.gui.udens_nov_saraksts_karte_height}
        onSecondaryPaneSizeChange={this.mapPaneSizeChange}>

        <Karte mapId="stacijas" vectorLayerCfgs={vectorLayerCfgs} />

        <Grid
          onRowSelected={this.props.selected}
          loading={this.props.stacijas.loading}
          selectedId={this.props.stacijas.selected}
          dataList={this.props.stacijas.list}
          totalCount={this.props.stacijas.count}
          columns={this.columns}
          rowEvents={this.rowEvents}
          extraGridProps={extraGridProps}
        >
        </Grid>
      </SplitterLayout>
    )
  }
}

export const MeteoStacijaList = connect(({vvisKodifs, stacijasMeteo, gui, user}) => ({
  stacijas: stacijasMeteo,
  vvisKodifs,
  gui,
  user
}), {
  selected: stacijasMeteoSelected,
  stacijasDoubleSelected,
  guiSet,
})(StacijaListBase)
