import * as helpers from './helpers'

const defaultListReducerForPazinojumi = helpers.createListReducer({listViewName: 'pazinojums_list'})
export const pazinojumi = (state = helpers.listGetInitialState(), {type, payload, reqId}) => {
    //overwrite or add custom actions catches here
    return defaultListReducerForPazinojumi({state, type, payload, reqId})
}

export const pazinojumuSkaits = (state = helpers.listGetInitialState(), {type, payload, reqId}) => {
    switch (type) {
        case 'pazinojumu_skaits_list_request':
            return {
                ...state,
                loading: true,
                lastReqId: reqId
            }
        case 'pazinojumu_skaits_list_failure':
            if (state.lastReqId === reqId) {
                return {
                    ...state,
                    list: [],
                    loading: false
                }
            } else {
                return state
            }
        case 'pazinojumu_skaits_list_success':
            if (state.lastReqId === reqId) {
                return {
                    ...state,
                    list: payload,
                    loading: false
                }
            } else {
                return state
            }
        default:
            return state
    }
}
