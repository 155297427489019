import React, {Component} from 'react';
import {connect} from 'react-redux'
import Grid from '../grid/Grid'
import {
    getSelectFilterCfg,
    getTextFilterCfg,
    getDateFilterCfg,
    dateTimeFormatter,
    dateFormatter,
    getNumberFilterCfg,
    getEditIconColumn,
    getBadgeFormatterForKodif
} from '../grid/ColumnHelpers'
import Link from 'redux-first-router-link';
import { Button, Badge } from 'reactstrap';
import {openUzzina} from '../../actions/uzzinasActions'
import {pielikumiUnSaitesColumn} from '../dokumentacija/common'

const getAtradnesFormatter = (kodif) => (fieldValue) => fieldValue.map((atr, i) =>
        <div key={i} className="badge-column">
        <Link
            to={{
                type: 'loc_atradne',
                payload: {
                    numurs: atr.numurs
                }
            }}
            >
            <Badge color={kodif.atradnesIerakstaVeidsExtended[atr.ieraksta_veids].badgeColor}>{atr.nosaukums} {atr.numurs}</Badge>
        </Link>
        </div>
    )

const editIconColumn = getEditIconColumn((row) => (
    {
        type: 'loc_uzzina',
        payload: {
            id: row.id
        }
    }
    )
)

// export for use in AtradnesUzzinas
export const getColumns = ({kodif, user}) => {
    const cols = [
        editIconColumn,
        {
            dataField: 'dok_veids',
            text: 'Dok. veids',
            width: 100,
            filterCfg: getSelectFilterCfg(kodif.UZZINU_DOK),
            formatter: getBadgeFormatterForKodif(kodif.UZZINU_DOK),
        }, {
            dataField: 'vgf_numurs',
            text: 'VĢF numurs',
            width: 120,
            filterCfg: getTextFilterCfg()
        }, {
            dataField: 'veicejs',
            text: 'Izpētes veicējs vai krājumu apstiprinātājs',
            filterCfg: getTextFilterCfg(),
            width: 180
        }, {
            dataField: 'nosaukums',
            text: 'Nosaukums',
            filterCfg: getTextFilterCfg(),
            width: 140
        }, {
            dataField: 'autors',
            text: 'Autors',
            filterCfg: getTextFilterCfg(),
            width: 120,
        }, {
            dataField: 'gads',
            text: 'Gads',
            filterCfg: getNumberFilterCfg(),
            width: 90,
        }, {
            dataField: 'protokola_nr',
            text: 'Protokola Nr.',
            filterCfg: getTextFilterCfg(),
            width: 110,
        }, {
            dataField: 'protokola_datums',
            text: 'Protokola dat.',
            formatter: dateFormatter,
            filterCfg: getDateFilterCfg(),
            width: 130,
        }, {
            dataField: 'atradnes',
            text: 'Atradnes',
            formatter: getAtradnesFormatter(kodif),
            filterCfg: {
                type: 'autocomplete',
                autocompleteCfg: {
                    remoteSearchViewName: 'atradne_search'
                }
            },
            sort: false,
            width: 220,
        }, {
            dataField: 'aktualizacijas_laiks',
            text: 'Labots',
            formatter: dateTimeFormatter,
            filterCfg: getDateFilterCfg(),
            width: 120,
        }
  ]
  if(user.tiesibas.includes('SKATIT_PIELIKUMUS_ZEMES_DZILES')){
    cols.push(pielikumiUnSaitesColumn())
  }
  return cols
}

class UzzinuSaraksts extends Component {

    rowEvents = {
        onDoubleClick: (e, row, rowIndex) => {
           // open uzzina edit page
           this.props.openUzzina(row.id)
        }
    }

    render() {

        if(!this.columns){
            this.columns = getColumns({
                kodif: this.props.kodif,
                user: this.props.user
            })
        }

        const {loading, list, count} = this.props.uzzinas

        return (
            <div className="saraksts-wrapper">
                <Grid
                    loading={loading}
                    dataList={list}
                    totalCount={count}
                    columns={this.columns}
                    rowEvents={this.rowEvents}
                >
                { this.props.user.tiesibas.includes('LABOT_ATRADNES') ? (
                    <Link to={{ type: 'loc_uzzina',
                        payload: {
                            id: 'jauns'
                        }
                    }}>
                        <Button color="primary"><i className="fa fa-plus-circle"></i>&nbsp;&nbsp;Jauna uzziņa</Button>
                    </Link>
                ) : null }
                </Grid>
            </div>
        )
    }

}

const mapStateToProps = ({uzzinas, kodif, user}) => ({uzzinas, kodif, user})

export default connect(mapStateToProps, {openUzzina})(UzzinuSaraksts)
