import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
//  getBooleanFormatter,
    getTextFilterCfg,
    // getDateFilterCfg,
    // dateTimeFormatter,
    // dateFormatter,
 //   getNumberFilterCfg,
   getEditIconColumn,
// getBooleanSelectFilterCfg,
    //getFormatterForKodif
} from '../grid/ColumnHelpers';
// import {getDefaultRendererForMultiField} from '../forms/FormHelpers';
import Link from 'redux-first-router-link';
import { Button } from 'reactstrap';
// import {guiSet} from '../../actions/uiActions';
import {rubrikaSelected, openRubrika} from '../../actions/geofondActions';

import Grid from '../grid/Grid'

const editIconColumn = getEditIconColumn((row) => (
    {
        type: 'loc_rubrika',
        payload: {
            id: row.id
        }
    }
    )
)

const getColumns = ({kodif, user}) => {
  return [
    editIconColumn, 
    {
      dataField: 'nosaukums',
      text: 'Rubrikas kods',
      width: 150,
      filterCfg: getTextFilterCfg()
    }, {
      dataField: 'piezimes',
      text: 'Rubrikas nosaukums',
      filterCfg: getTextFilterCfg(),
    },
    ]
};


class GeofondRubrikas extends Component {

    rowEvents = {
        onDoubleClick: (e, row, rowIndex) => {
          this.props.openRubrika(row.id)
        }
    }

    render() {

        const {kodif, rubrikas, rubrikaSelected, user} = this.props
        const {list, selected, loading, count} = rubrikas

        if(!this.columns){
            this.columns = getColumns({
                kodif,
                user
            })
        }

        return (
            <div className="saraksts-wrapper">
            <Grid
                onRowSelected={rubrikaSelected}
                loading={loading}
                selectedId={selected}
                dataList={list}
                totalCount={count}
                columns={this.columns}
                rowEvents={this.rowEvents}
            >
                { this.props.user.tiesibas.includes('PARSKATS_LABOT') ? (
                    <Link to={{ type: 'loc_rubrika',
                        payload: {
                            id: 'jauns'
                        }
                    }}>
                        <Button color="primary"><i className="fa fa-plus-circle"></i>&nbsp;&nbsp;Jauna rubrika</Button>
                    </Link>
                ) : null }
                {/* <ArchivedParskatsFilterToUrl/> */}
            </Grid>
            </div>
        )
    }
}

const mapStateToProps = ({kodif, rubrikas, user}) => {
    return {kodif, rubrikas, user}
}

export default connect(mapStateToProps, {rubrikaSelected, openRubrika})(GeofondRubrikas)
