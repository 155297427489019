import React, {Component} from 'react';
import {connect} from 'react-redux'
import {
  dateFormatter,
  getBooleanFormatter,
  getEditIconColumn, getNumberFilterCfg,
  getNumberFormatter,
  getTextFilterCfg,
  getDateFilterCfg,
  getBooleanSelectFilterCfg
} from "../grid/ColumnHelpers";
import {addUrlQuery} from "../../actions/uiActions";
import Grid from "../grid/Grid";
import SplitterLayout from "react-splitter-layout";
import {loadMerijumi} from "../../actions/hidrogeoActions";
import Link from "redux-first-router-link";
import {Button} from "reactstrap";

const editIconColumn = getEditIconColumn((row) => (
        {
          type: 'loc_urbuma_merijums',
          payload: {
            id: row.id,
            numurs: row.urbumi_id
          }
        }
    )
)

const getColumns = ({ metadata }) => {
  return [editIconColumn,
    {
      dataField: 'kods',
      text: "Kods",
      width: 100,
      filterCfg: getTextFilterCfg()
    },
    {
      dataField: 'nosaukums',
      text: "Nosaukums",
      width: 100,
      filterCfg: getTextFilterCfg()
    },
    {
      dataField: 'vertiba',
      text: "Vērtība",
      formatter: getNumberFormatter(),
      filterCfg: getNumberFilterCfg(),
      width: 120
    },
    {
      dataField: 'mervieniba',
      text: "Mērvienība",
      width: 120,
      filterCfg: getTextFilterCfg()
    },
    {
      dataField: 'metodika',
      text: "Metodika",
      width: 100,
      filterCfg: getTextFilterCfg()
    },
    {
      dataField: 'analizes_datums',
      text: "Analīzes datums",
      formatter: dateFormatter,
      filterCfg: getDateFilterCfg(),
      width: 120
    },
    {
      dataField: 'parauga_numurs',
      text: "Parauga numurs",
      width: 100,
      filterCfg: getTextFilterCfg()
    },
    {
      dataField: 'horizont_geol_indekss',
      text: "Horizonta kods",
      width: 100,
      filterCfg: getTextFilterCfg()
    },
    {
      dataField: 'karodzini',
      text: "Uzstādītie karodziņi",
      filterCfg: getTextFilterCfg(),
      width: 120
    },
    {
      dataField: 'ir_ql',
      text: "Nav sasniegta QL vērtība",
      formatter: getBooleanFormatter(),
      filterCfg: getBooleanSelectFilterCfg(),
      width: 120
    }
  ]
}

class UrbumsSastavs extends Component {

  render() {

    const { metadata, addUrlQuery, merijumi, query, payload} = this.props

    const { selected, list, loading, count} = merijumi

    let gads = query && query.gads ? query.gads : new Date().getFullYear()

    if(!this.columns){
      this.columns = getColumns({
        metadata
      })
    }

    const onNextPrevYear = (newGads) => {
      query.gads = newGads
      query.p = 1
      addUrlQuery({gads: newGads})
      query.payload = payload
    }

    const nextYear = () => {
      return parseInt(gads) + 1
    }

    const prevYear = () => {
      return parseInt(gads) - 1
    }

    return (
        <SplitterLayout
            vertical
            percentage
            primaryIndex={1}
            secondaryInitialSize={10}
        >
          <div className="atradne-title uo-gads d-flex flex-row-reverse">
            <h4 className="atradne-num">
              <i className="fa fa-caret-left" title={prevYear()} aria-hidden="true" onClick={() => onNextPrevYear(prevYear())}></i>
              <span>{ gads }</span>
              <i className="fa fa-caret-right" title={nextYear()} aria-hidden="true" onClick={() => onNextPrevYear(nextYear())}></i>
            </h4>
          </div>
          <Grid
              currentPageSize={10}
              selectedId={selected}
              loading={loading}
              dataList={list}
              totalCount={count}
              columns={this.columns}
          >
            { this.props.user.tiesibas.includes('URBUMI') ? (
                <Link to={{ type: 'loc_urbuma_paraugs',
                  payload: {
                    id: 'jauns',
                    numurs: payload.id
                  }
                }}>
                  <Button color="primary"><i className="fa fa-plus-circle"></i>&nbsp;&nbsp;Izveidot paraugu</Button>
                </Link>
            ) : null }
          </Grid>
        </SplitterLayout>
    )
  }
}

const mapStateToProps = ({merijumi, metadata, location, user}) => ({merijumi, metadata, query: location.query, payload: location.payload, user})
export default connect(mapStateToProps, {addUrlQuery, loadMerijumi})(UrbumsSastavs)
