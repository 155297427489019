import React, { Component }  from 'react'
import {connect} from 'react-redux'
import {logout} from '../../actions/lietotajsActions'
import {UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap'

class CurrentUserDropdown extends Component {

    render() {

        const {user, logout, className} = this.props

        return (
           <> { user && user.avots !== 'G' ?
              user.avots === 'P' ?
                 <div className={"padding-10 " + className}> { user.vards && user.uzvards ? `${user.vards} ${user.uzvards}` : user.lietotajvards } </div>
              : <UncontrolledDropdown className={"padding-10 " + className}>
                  <DropdownToggle caret nav>
                  {user.vards && user.uzvards ? `${user.vards} ${user.uzvards}` : user.lietotajvards}
                  </DropdownToggle>
                  <DropdownMenu>
                    {/*<DropdownItem><i className="fa fa-wrench"></i> Uzstādījumi</DropdownItem>*/}
                    <DropdownItem onClick={logout}><i className="fa fa-lock"></i> Iziet</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
            : null }
        </> )
    }
}

const mapStateToProps = ({user}) => ({user})

export default connect(mapStateToProps, {logout})( CurrentUserDropdown )
