import React, {Component} from 'react';
import {connect} from 'react-redux'
import {licenceActions} from '../../actions/dokumentacijaActions'
import {getCommonFormFieldCfgs, DokumentacijasKartina, LicenceLink} from './common'

const formFields = ['ir_derigs', 'atradne', 'dalas_nosaukums', 'pase', 'izdevejs', 'tips', 'numurs', 'adresats',
   'izsniegts_datums', 'derigs_lidz_datums', 'anulets_datums', 'licences_apjomi',
   'piezimes', 'aktualizacijas_laiks', 'limiti']

const getFormFieldCfgs = ({kodif, user}) => {
  const commonFields = getCommonFormFieldCfgs({kodif})
  const fields = formFields.map(ff => commonFields.find(cf => cf.name === ff))
  if(user.tiesibas.includes('SKATIT_PIELIKUMUS_ZEMES_DZILES')){
    fields.push(commonFields.find(cf => cf.name === 'faili'))
  }
  fields.push({
    name: 'ieprieksejais',
    type: 'autocomplete',
    autocompleteCfg: {
        initialOptionsViewName: 'licence_search_by_atradne',
        isValueObjStructure: true,
    },
    overridePropsFunc: (props) => {
      const changedProps = {...props}
      const {currentValues} = changedProps
      const placeholder = 'Izvēlieties licenci, kas pievienota norādītajai atradnei'
      if(currentValues.atradne && currentValues.atradne.id){
        changedProps.extraFieldProps = {disabled : false, placeholder}
        const extraFilterParams = {atradne_id: currentValues.atradne.id}
        if(currentValues.id) {
          extraFilterParams.not_licence = currentValues.id
        }
        changedProps.autocompleteCfg = {...changedProps.autocompleteCfg, extraFilterParams}
      } else {
        changedProps.extraFieldProps = {disabled : true, placeholder}
        changedProps.autocompleteCfg = {...changedProps.autocompleteCfg, extraFilterParams : null}
      }
      return changedProps
    },
    renderer: (lic, dataObj) => <LicenceLink licence={lic} atradne={dataObj.atradne}/>
  })
  fields.push({
    name: 'ieprieksejie',
    renderer: (licences, dataObj) =>  <div className="saistitas">
        {dataObj.nakamie.map((lic, i) => <div key={i}><LicenceLink className='links' licence={lic} atradne={dataObj.atradne} irSaistita/> (nākamā)</div>)}
        {dataObj.ieprieksejie.map((lic, i) => <div key={i}><LicenceLink className='links' licence={lic} atradne={dataObj.atradne} irSaistita/> (iepriekšējā)</div>)}
        </div>
  })
  return fields
}

const childGeometryTooltipFunc = (feat) => `ID ${feat.get('id') || ''} ${feat.get('kategorija') || ''} ${feat.get('izrakteni') ? feat.get('izrakteni').map(i => i.klasifikators).join(', ') : ''}`

class Licence extends Component {

    render() {
      
      const {licence, kodif, saveAct, stopEditAct, startEditAct, deleteAct, koordSelectAct, koordChangeAct, user} = this.props

      if(!this.formFieldCfgs){
        this.formFieldCfgs = getFormFieldCfgs({
            kodif, user
        })
      }

      return <DokumentacijasKartina
        geomObj={licence}
        formFieldCfgs={this.formFieldCfgs}
        saveAct={saveAct}
        stopEditAct={stopEditAct}
        startEditAct={startEditAct} 
        deleteAct={deleteAct}
        koordSelectAct={koordSelectAct} 
        koordChangeAct={koordChangeAct}
        karteWidthProp='licences_kartina_karte_width'
        mapId='licences_kartina'
        editableLayerIdName='licence_koord'
        layerTitles={{
          feat_border: 'Apjoma robeža',
          feat: 'Licences /atļaujas apjoms',
          koord: 'Robežpunkti',
          koord_labels: 'Robežpunktu numuri',
        }}
        metadataFormCfg="licence_view"
        childGeometryEditing
        childGeometryTooltipFunc={childGeometryTooltipFunc}
      />
    }
}

const mapStateToProps = ({licence, kodif, user}) => ({licence, kodif, user})
export default connect(mapStateToProps, {...licenceActions})(Licence)
