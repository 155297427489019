import React, {Component} from 'react';

import { Card, CardBody, CardHeader, Collapse, Col } from 'reactstrap';

export default class FormCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
          collapse: true,
          fullscreen: false
        };
      }

    toggle = () => {
      this.setState({ collapse: !this.state.collapse})
    }

    toggleFullscreen = () => {
      this.setState({ fullscreen: !this.state.fullscreen})
    }

    render() {
        const {title} = this.props
        const size = this.props.size ? this.props.size : 6
        if (this.props.hiddenFunc && this.props.hiddenFunc(this.props)) {
            return null
        }
        return  <Col xl={size} className={'card-col' + (this.state.collapsed ? ' collapsed' : '') + (this.state.fullscreen ? ' fullscreen-mode' : '')}>
                    <Card>
                        <CardHeader>
                        {title}
                        <div className="card-header-actions">
                          <span onClick={this.toggle} className="card-header-action btn btn-minimize">
                              <i className={'fa fa-lg ' + (this.state.collapse ?  'fa-minus-square-o' : 'fa-plus-square-o' )}></i>
                          </span>
                          <span onClick={this.toggleFullscreen} className="card-header-action btn btn-minimize">
                              <i className='fa fa-arrows-alt'></i>
                          </span>
                        </div>
                        </CardHeader>
                        <Collapse isOpen={this.state.collapse}>
                          <CardBody>
                              { this.props.children }
                          </CardBody>
                        </Collapse>
                    </Card>
              </Col>
    }
}