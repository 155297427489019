import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
  getFormatterForKodif, getmultiSelectFilterCfg,
  getSelectFilterCfg,
} from '../grid/ColumnHelpers'

import Karte from '../karte/IzraktenisMap';
import * as maputils from '../karte/maputils'

import SplitterLayout from 'react-splitter-layout';

import {guiSet} from '../../actions/uiActions'

import Grid from '../grid/Grid'
import TroksniImports from "./Imports";

const getColumns = ({kodif}) => {
  return [{
    dataField: 'avots',
    text: 'Datu avots',
    filterCfg: getSelectFilterCfg(kodif.troksniAvots),
    formatter: getFormatterForKodif(kodif.troksniAvots)
  }, {
    dataField: 'layer_name',
    text: 'Slānis',
    filterCfg: getmultiSelectFilterCfg(kodif.troksniLayer),
    formatter: getFormatterForKodif(kodif.troksniLayer)
  }, {
    dataField: 'source',
    text: 'Avots',
    filterCfg: getmultiSelectFilterCfg(kodif.troksniSource),
    formatter: getFormatterForKodif(kodif.troksniSource)
  }, {
    dataField: 'category',
    text: 'Kategorija',
    filterCfg: getmultiSelectFilterCfg(kodif.troksniCategory),
    formatter: getFormatterForKodif(kodif.troksniCategory)
  }]
};

class TroksnuSarakstsComp extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modalList: false
    };
  }

  mapPaneSizeChange = (h) => {
    this.props.guiSet('troksnu_karte_saraksts_karte_height', h);
  }

  rowEvents = {
  }

  render() {
    const { user, troksni } = this.props

    const vectorLayerCfgs = {
      troksni : {
        title: 'Trokšņu karte',
        features: this.props.troksni.list,
        styles: maputils.atradnesStyles,
        // tooltipFunc: (feat) => feat.get('nosaukums'),
        // selected: this.props.troksni.selected,
        epsgCode: 3059,
      }
    }

    const editRole = user && user.tiesibas.includes('TK_LABOT')

    if(!this.columns){
      this.columns = getColumns({ kodif: this.props.kodif })
    }

    return (
      <SplitterLayout
        vertical
        percentage
        primaryIndex={1}
        secondaryInitialSize={this.props.gui.troksnu_karte_saraksts_karte_height}
        onSecondaryPaneSizeChange={this.mapPaneSizeChange}>

        <Karte mapId="troksnu_karte" vectorLayerCfgs={vectorLayerCfgs} />

        <Grid
          loading={troksni.loading}
          selectedId={troksni.selected}
          dataList={troksni.list}
          totalCount={troksni.count}
          columns={this.columns}
        >
          { editRole ? (<TroksniImports></TroksniImports>) : null }
        </Grid>
      </SplitterLayout>
    )
  }
}

export const TroksnuSaraksts = connect(({troksni, kodif, gui, user}) => ({
  troksni,
  kodif,
  gui,
  user
}), {
  guiSet,
})(TroksnuSarakstsComp)
