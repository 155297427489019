import React from 'react';
import {EditorState, RichUtils} from 'draft-js'; 
import {convertFromHTML, convertToHTML} from 'draft-convert';
import Editor from 'draft-js-plugins-editor';
import createToolbarPlugin from 'draft-js-static-toolbar-plugin';
import 'draft-js-static-toolbar-plugin/lib/plugin.css';
import {
  SubButton,
  SupButton,
  ItalicButton
} from "draft-js-buttons";

import { stateToHTML } from "draft-js-export-html";

const staticToolbarPlugin = createToolbarPlugin();
const { Toolbar } = staticToolbarPlugin;
const plugins = [staticToolbarPlugin];

export default class HTMLEditor extends React.Component {
  constructor(props) {
    super(props);
    const contentState = convertFromHTML({
      htmlToStyle: (nodeName, node, currentStyle) => {
        if (nodeName === 'sup') {
          return currentStyle.add('SUPERSCRIPT');
        }
        if (nodeName === 'sub') {
          return currentStyle.add('SUBSCRIPT');
        } else {
          return currentStyle;
        }
      }
    })(props.value);
    this.state = {
      editorState: EditorState.createWithContent(contentState),
      currVal: props.value
    };
    
    this.onChange = (editorState) => {
      const {setFieldValue, fieldName} = this.props
      const html = convertToHTML({
          styleToHTML: (style) => {
            if (style === 'SUPERSCRIPT') {
              return <sup />;
            } else if (style === 'SUBSCRIPT') {
              return <sub />;
            }
          },
          blockToHTML: (block) => {
            return {
              start: '',
              end: ''
            };
          }
      })(editorState.getCurrentContent());
      
      this.setState({
        editorState,
        currVal: editorState.getCurrentContent().getPlainText(),
        editorContentHtml: stateToHTML(editorState.getCurrentContent())
      });
      setFieldValue(fieldName, html)
    }
    this.handleKeyCommand = this.handleKeyCommand.bind(this);
    
  }
  
  handleKeyCommand(command, editorState) {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return 'handled';
    }
    return 'not-handled';
  }
  
  render() {
    return (
      <div className="draftjs-editor">
        <div className="draftjs-editor-container">
          <Editor
            editorState={this.state.editorState}
            handleKeyCommand={this.handleKeyCommand}
            onChange={this.onChange}
            plugins={plugins}
            customStyleMap={ {
              SUBSCRIPT: { fontSize: '0.75em', verticalAlign: 'sub' },
              SUPERSCRIPT: { fontSize: '0.75em', verticalAlign: 'super' }
            }}
          />
        </div>
        <div className="draftjs-toolbar-container">
          <Toolbar>
            {
              (externalProps) => (
                <div>
                  <SubButton {...externalProps} />
                  <SupButton {...externalProps} />
                  <ItalicButton {...externalProps} />
                </div>
              )
            }
          </Toolbar>
        </div>
      </div>
    );
  } 
}