import * as helpers from './helpers'
import * as maputils from '../components/karte/maputils'
import { LineString, Polygon } from "ol/geom";

const defaultListReducer = (viewName, actionsName, args) => {
  return helpers.createListReducerWithMapClicking({
    listViewName: viewName,
    actionsName: actionsName,
    mapIdName: 'stacijas',
    layerIdName: 'stacijas',
  })
}

export const stacijasMeteo = (state = helpers.listGetInitialState(), { type, payload, reqId, mapId, layerId }) => {
  return defaultListReducer('noverojumu_stacijas_list', 'stacijasMeteo')({ state, type, payload, reqId, mapId, layerId })
}

const defaultEditReducer = helpers.createEditReducer({
  editViewName: 'noverojumu_stacija_view',
  actionsName: 'stacija'
})
export const stacija = (state = helpers.editGetInitialState(), { type, payload }) => {
  switch (type) {
    case 'noverojumu_stacija_generet_aizsargjoslas_save_request':
      return {
        ...state,
        generating: true
      }
    case 'noverojumu_stacija_generet_aizsargjoslas_save_success':
    case 'noverojumu_stacija_generet_aizsargjoslas_save_failure':
      return {
        ...state,
        generating: false
      }
    default:
      return defaultEditReducer({ state, type, payload })
  }
}

const defaultEditReducerForAtisObjekts = helpers.createEditReducer({
  editViewName: 'atis_objekts_view',
  actionsName: 'atis_objekts'
})
export const atisObjekts = (state = {
  ...helpers.editGetInitialState(),
  geom: {
    geom: null,
    lastAction: null
  },
  newGeom: null,
  koords: []
}, { type, payload, layerId, mapId, featureGeom }) => {
  const result = defaultEditReducerForAtisObjekts({ state, type, payload })

  const setNewGeomFromKoords = (newGeom, koords) => {
    switch (newGeom.getType()) {
      case 'Point':
        newGeom.setCoordinates(maputils.objectToCoords(koords).flat())
        break
      case 'LineString':
        newGeom.setCoordinates(maputils.objectToCoords(koords))
        break
      default:
        newGeom.setCoordinates([maputils.objectToCoords(koords.concat(koords[0]))])
    }
  }

  switch (type) {
    case 'atis_objekts_view_save_success':
      return {
        ...result,
        selectedItem: null,
        geom: null,
        newGeom: null,
        koords: []
      }
    case 'atis_objekts_stop_edit':
      return {
        ...result,
        selectedItem: null,
        editable: false,
        submiting: false,
        geom: null,
        newGeom: null,
        koords: []
      }
    case 'atis_objekts_view_get_success':
      const loaded = maputils.GeoJSONFormat.readGeometry(payload.geom)
      return {
        ...result,
        editable: true,
        geom: {
          geom: payload.geom,
          id: result.selectedItem?.id,
          lastAction: ""
        },
        koords: maputils.coordsToObject(loaded),
        newGeom: loaded
      }
    case 'atis_objekts_point':
    case 'atis_objekts_line':
    case 'atis_objekts_polygon':
      if (!payload || !payload.geom) {
        return result
      }
      if (state.geom && state.geom.lastAction === type) {
        return result
      }
      const center = maputils.GeoJSONFormat.readGeometry(payload.geom)
      const [x, y] = center.getCoordinates()
      let createGeom = null
      switch (type) {
        case 'atis_objekts_line':
          createGeom = new LineString([[x - 100, y], [x + 100, y]])
          break
        case 'atis_objekts_polygon':
          createGeom = new Polygon([[[x - 100, y - 100], [x + 100, y - 100], [x + 100, y + 100], [x - 100, y + 100], [x - 100, y - 100]]])
          break
        default:
          createGeom = center.clone()
      }

      return {
        ...result,
        geom: {
          geom: maputils.GeoJSONFormat.writeGeometry(createGeom),
          lastAction: type,
          id: result.selectedItem?.id,
        },
        koords: maputils.coordsToObject(createGeom),
        newGeom: createGeom
      }
    case 'atis_objekts_koord_selected':
      return {
        ...state,
        selectedKoord: payload
      }
    case 'atis_objekts_koord_changed':
      const { koords, newGeom, geom } = state
      koords[payload.id - 1] = {
        id: payload.id,
        x: Number.parseFloat(payload.x),
        y: Number.parseFloat(payload.y)
      }
      setNewGeomFromKoords(newGeom, koords)
      return {
        ...state,
        geom: {
          geom: maputils.GeoJSONFormat.writeGeometry(newGeom),
          lastAction: geom.lastAction,
          id: state.selectedItem?.id,
        },
        koords: maputils.coordsToObject(newGeom),
        newGeom
      }
    case 'atis_objekts_koord_deleted':
      let newKoords = state.koords.filter(k => k.id !== payload.id)
      setNewGeomFromKoords(state.newGeom, newKoords)
      return {
        ...state,
        geom: {
          geom: maputils.GeoJSONFormat.writeGeometry(state.newGeom),
          lastAction: state.geom.lastAction,
          id: state.selectedItem?.id,
        },
        newGeom: state.newGeom,
        koords: maputils.coordsToObject(state.newGeom)
      }
    case 'MAP_clickedFeature':
      if (layerId === 'atis_objekts_koord' && mapId === 'stacija-view') {
        return {
          ...state,
          selectedKoord: payload.id
        }
      } else {
        return state
      }
    case 'MAP_modifiedFeature':
      if (layerId === 'atisObjekts' && mapId === 'stacija-view') {
        return {
          ...state,
          koords: maputils.coordsToObject(featureGeom),
          newGeom: featureGeom
        }
      } else {
        return state
      }
    default:
      return result
  }
}
