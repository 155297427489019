import React, {Component} from 'react'
import {connect} from 'react-redux'
import Loading from '../Loading'
import {columnCfgToBootstrapTableColumn} from '../grid/Grid'
import BootstrapTable from 'react-bootstrap-table-next'
import {
    Button,
} from 'reactstrap'
import {getCommonColumns, getEditColumnForDok, getLinkParams, getDalaColumnForDok} from '../dokumentacija/common'
import Link from 'redux-first-router-link'
import {atradneDokSelected} from '../../actions/atradnesActions'
import {openDok} from '../../actions/dokumentacijaActions'

const pasesColumnNames = ['ir_derigs', 'adresats_nosaukums', 'izsniegts_datums', 'derigs_lidz_datums', 'anulets_datums', 'pielikumi_filename']
const getPasesColumns = ({kodif, numurs, isDala, atrId, user}) => {
  const commonColumns = getCommonColumns({kodif, user}).filter(c => pasesColumnNames.indexOf(c.dataField) !== -1)
  const columns = [getEditColumnForDok({loc: 'loc_atradnes_pase', locDala:'loc_atradnes_dala_pase', numurs, isDala, atrId})]
  if(!isDala){
    commonColumns.splice(1, 0, getDalaColumnForDok(numurs))
  }
  return [...columns, ...commonColumns].map( col=> columnCfgToBootstrapTableColumn(col))
}

const limitsColumnNames = ['ir_derigs', 'adresats_nosaukums', 'apjomi', 'izsniegts_datums', 'derigs_lidz_datums', 'anulets_datums', 'pielikumi_filename']
const getLimitiColumns = ({kodif, numurs, isDala, atrId, user}) => {
  const commonColumns = getCommonColumns({kodif, user, locijums: 'M'}).filter(c => limitsColumnNames.indexOf(c.dataField) !== -1)
  const columns = [getEditColumnForDok({loc: 'loc_atradnes_limits', locDala:'loc_atradnes_dala_limits', numurs, isDala, atrId})]
  if(!isDala){
    commonColumns.splice(1, 0, getDalaColumnForDok(numurs))
  }
  commonColumns.find(cc=> cc.dataField === 'izsniegts_datums').text = 'Izsniegts'
  commonColumns.find(cc=> cc.dataField === 'derigs_lidz_datums').text = 'Derīgs līdz'
  return [...columns, ...commonColumns].map( col=> columnCfgToBootstrapTableColumn(col))
}

const licenceColumnNames = ['ir_derigs', 'tips_nosaukums', 'lic_numurs', 'adresats_nosaukums', 'licences_apjomi', 'izsniegts_datums', 'derigs_lidz_datums', 'anulets_datums', 'pielikumi_filename']
const getLicencesColumns = ({kodif, numurs, isDala, atrId, user}) => {
  const commonColumns = getCommonColumns({kodif, user}).filter(c => licenceColumnNames.indexOf(c.dataField) !== -1)
  const columns = [getEditColumnForDok({loc: 'loc_atradnes_licence', locDala:'loc_atradnes_dala_licence', numurs, isDala, atrId})]
  if(!isDala){
    commonColumns.splice(1, 0, getDalaColumnForDok(numurs))
  }
  return [...columns, ...commonColumns].map( col=> columnCfgToBootstrapTableColumn(col))
} 

const rowClasses = (row, rowIndex) => !row.selected ? (row.selectedSaist ? 'selection-row-saist' : row.selectedIeprNak ? 'selection-row-iepr' : '' ) : ''

class AtradnesDokumentacijaComp extends Component {

      constructor(props) {
        super(props);
        this.selectRowDefaults = {
            mode: 'radio',
            clickToSelect: true,
            hideSelectColumn: true,
            classes: 'selection-row'
        }
      }

      getHandleOnSelect = (dokType) => (row, isSelect) => {
          this.props.atradneDokSelected({dokType, id: row.id, isSelect})
      }


      getDoubleClickHandler = ({loc, locDala}) => {
        const {numurs, id : atrId} = this.props.atradneDoks.selectedItem
        return (e, row, rowIndex) => {
          this.props.openDok({
            id: row.id, 
            numurs, 
            atrId,
            loc,
            locDala,
            isDala: this.props.isDala
          })
        }
      }

      render() {
        
        const {atradneDoks, kodif, user, isDala} = this.props
        const {selectedItem} = atradneDoks

        if (!selectedItem) {
            return (<Loading/>)
        }

        if(!this.paseColumns){
            this.paseColumns = getPasesColumns({kodif, numurs : selectedItem.numurs, isDala, atrId: selectedItem.id, user})
        }
        if(!this.limitiColumns){
            this.limitiColumns = getLimitiColumns({kodif, numurs : selectedItem.numurs, isDala, atrId: selectedItem.id, user})
        }
        if(!this.licencesColumns){
            this.licencesColumns = getLicencesColumns({kodif, numurs : selectedItem.numurs, isDala, atrId: selectedItem.id, user})
        }
        const {numurs, id : atrId} = selectedItem

        let selectedPases = selectedItem.pases.filter(p => p.selected).map(p => p.id)
        let selectedLimiti = selectedItem.limiti.filter(p => p.selected).map(p => p.id)
        let selectedLicences = selectedItem.licences.filter(p => p.selected).map(p => p.id)
        /*if(selectedDok){
          const {dokType, id} = selectedDok
          if(dokType === 'pase') {
            selectedPases = selectedItem.pases.filter(p => p.id === id).map(p => p.id)
            selectedLimiti = selectedItem.limiti.filter(l => l.pase_id === id).map(l => l.id)
            selectedLicences = selectedItem.licences.filter(l => l.pase_id === id).map(l => l.id)
          } else if(dokType === 'limits') {
            const sl = selectedItem.limiti.filter(l => l.id === id)
            if(sl.length > 0){
              selectedLimiti = [sl[0].id]
              const paseId = sl[0].pase_id
              const licenceId = sl[0].licence_id
              if(paseId){
                selectedPases = selectedItem.pases.filter(p => p.id === paseId).map(p => p.id)
              }
              if(licenceId){
                selectedLicences = selectedItem.licences.filter(l => l.id === licenceId).map(l => l.id)
              }
            }
          } else if(dokType === 'licence'){
             const sl = selectedItem.licences.filter(l => l.id === id)
             if(sl.length > 0){
                const licId = sl[0].id
                selectedLicences = [licId]
                selectedLimiti = selectedItem.limiti.filter(l => l.licence_id === licId).map(l => l.id)
                const paseId = sl[0].pase_id
                if(paseId){
                  selectedPases = selectedItem.pases.filter(p => p.id === paseId).map(p => p.id)
                }
             }
          }
        }*/

        return <div className="grids-with-titles">
        <div className="grid-pane">
          <div className="title-bar">
            <h4>Pases</h4>
            { user.tiesibas.includes('LABOT_DOKUMENTACIJU') ? 
            <Link className="float-right"  to={getLinkParams({
                 loc : 'loc_atradnes_pase', 
                 locDala : 'loc_atradnes_dala_pase', 
                 numurs, isDala, atrId, extraPayload: {id: 'jauns'}})}>
              <Button color="primary"><i className="fa fa-plus-circle"></i>&nbsp;&nbsp;Jauna pase</Button>
            </Link> : null}
          </div>
          <BootstrapTable 
              bootstrap4
              striped
              hover
              condensed
              keyField="id"
              data={ selectedItem.pases }
              columns={ this.paseColumns }
              rowEvents={{onDoubleClick: this.getDoubleClickHandler({
                 loc : 'loc_atradnes_pase', 
                 locDala : 'loc_atradnes_dala_pase'})}}
              selectRow={{
                            ...this.selectRowDefaults,
                            onSelect: this.getHandleOnSelect('pase'),
                            selected: selectedPases
                        }}
              rowClasses={rowClasses}
          />
        </div>
        <div className="grid-pane">
        <div className="title-bar">
          <h4>Limiti</h4>
          { user.tiesibas.includes('LABOT_DOKUMENTACIJU') ? 
            <Link className="float-right"  to={getLinkParams({
                 loc : 'loc_atradnes_limits', 
                 locDala : 'loc_atradnes_dala_limits', 
                 numurs, isDala, atrId, extraPayload: {id: 'jauns'}})}>
            <Button color="primary"><i className="fa fa-plus-circle"></i>&nbsp;&nbsp;Jauns limits</Button>
          </Link> : null}
        </div>
        <BootstrapTable 
            bootstrap4
            striped
            hover
            condensed
            keyField="id"
            data={ selectedItem.limiti }
            columns={ this.limitiColumns }
            rowEvents={{onDoubleClick: this.getDoubleClickHandler({
                 loc : 'loc_atradnes_limits', 
                 locDala : 'loc_atradnes_dala_limits'})}}
            selectRow={{
                      ...this.selectRowDefaults,
                      onSelect: this.getHandleOnSelect('limits'),
                      selected: selectedLimiti
                  }}
            rowClasses={rowClasses}
        />
        </div>
        <div className="grid-pane">
        <div className="title-bar">
          <h4>Licences / atļaujas</h4>
          { user.tiesibas.includes('LABOT_DOKUMENTACIJU') ? 
            <Link className="float-right"  to={getLinkParams({
                 loc : 'loc_atradnes_licence', 
                 locDala : 'loc_atradnes_dala_licence', 
                 numurs, isDala, atrId, extraPayload: {id: 'jauns'}})}>
            <Button color="primary"><i className="fa fa-plus-circle"></i>&nbsp;&nbsp;Jauna licence</Button>
          </Link> : null}
        </div>
        <BootstrapTable 
            bootstrap4
            striped
            hover
            condensed
            keyField="id"
            data={ selectedItem.licences }
            columns={ this.licencesColumns }
            rowEvents={{onDoubleClick: this.getDoubleClickHandler({
                 loc : 'loc_atradnes_licence', 
                 locDala : 'loc_atradnes_dala_licence'})}}
            selectRow={{
                      ...this.selectRowDefaults,
                      onSelect: this.getHandleOnSelect('licence'),
                      selected: selectedLicences
                  }}
            rowClasses={rowClasses}
        />
        </div>
        </div>
        
    }
}

// connect Pamatinformacija with atradne state
const mapStateToProps = ({atradneDoks, kodif, user}) => ({atradneDoks, kodif, user})
const AtradnesDokumentacija =  connect(mapStateToProps, {openDok, atradneDokSelected})(AtradnesDokumentacijaComp)
export default AtradnesDokumentacija

const mapStateToPropsDala = ({atradnesDalaDoks, kodif, user}) => ({atradneDoks: atradnesDalaDoks, kodif, user, isDala: true})
export const AtradnesDokumentacijaDala = connect(mapStateToPropsDala, {openDok, atradneDokSelected})(AtradnesDokumentacijaComp)