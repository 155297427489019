import * as helpers from './helpers'

const defaultListReducerForAdresati = helpers.createListReducer({listViewName: 'adresats_edit', actionsName: 'adresats'})
const defaultEditReducerForAdresati = helpers.createEditReducer({editViewName: 'adresats_edit', actionsName: 'adresats'})
export const adresati = (state = helpers.listEditGetInitialState(), {type, payload, reqId}) => {
    //overwrite or add custom actions catches here

    const stateAfterList = defaultListReducerForAdresati({state, type, payload, reqId})
    const stateAfterEdit = defaultEditReducerForAdresati({state: stateAfterList, type, payload, reqId})
    if(type === 'adresats_aizstat_delete_success'){
        return {
            ...stateAfterEdit,
            selectedItem: null
        }
    }
    return stateAfterEdit
}
