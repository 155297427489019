import React, {Component} from 'react';
import {connect} from 'react-redux'
import Form from '../forms/Form'
import {udensParskatsActions} from "../../actions/udens2Actions";

const getFormFieldCfgs = ({kodif}) => {
  return [
    {
      name: 'gads',
      label: 'Gads',
    }, {
      name: 'operators',
      label: 'Operators',
    }, {
      name: 'tab_a',
      label: 'A tabula',
      multiFieldCfgs: [{
        name: 'code',
        label: 'Kods'
      }, {
        name: 'name',
        label: 'Nosaukums'
      }, {
        name: 'adrese',
        label: 'Vieta'
      }, {
        name: 'veids',
        label: 'Veids'
      }, {
        name: 'tips',
        label: 'Tips'
      }, {
        name: 'daudzums',
        label: 'Daudzums (tūkst.m3/gadā)'
      }, ]
    }, {
      name: 'tab_c',
      label: 'C tabula',
      multiFieldCfgs: [{
        name: 'npk',
        label: 'N.p.k.'
      }, {
        name: 'code',
        label: 'Kods'
      }, {
        name: 'name',
        label: 'Nosaukums'
      }, {
        name: 'adrese',
        label: 'Vieta'
      }, {
        name: 'veids',
        label: 'Attīrīšanas iekārtas NAI veids'
      }, {
        name: 'tips',
        label: 'NAI tips'
      }, {
        name: 'faktiska_pakape',
        label: 'Faktiskā attīrīšanas pakāpe'
      }, ]
    }, {
      name: 'tab_d',
      label: 'D tabula',
      multiFieldCfgs: [{
        name: 'piederiba',
        label: 'Piederība'
      }, {
        name: 'npk',
        label: 'N.p.k.'
      }, {
        name: 'daudzums',
        label: 'Daudzums'
      }],
      multiFieldColsToGroup: 1
    }, {
      name: 'tab_e',
      label: 'E tabula',
      multiFieldCfgs: [{
        name: 'piederiba',
        label: 'Piederība'
      }, {
        name: 'viela',
        label: 'Viela'
      }, {
        name: 'daudzums',
        label: 'Daudzums'
      }],
      multiFieldColsToGroup: 1
    }
  ]};

class Parskats extends Component {

  render() {
    const {udensParskats, kodif} = this.props

    if(!this.formFieldCfgs){
      this.formFieldCfgs = getFormFieldCfgs({
        kodif: kodif
      })
    }

    const {selectedItem} = udensParskats

    return (
      <div className="form-wrapper-pane">
        <Form
          dataObj={selectedItem}
          formFieldCfgs={this.formFieldCfgs}
          onStartEdit={null}
          sizeLabel={2}
          tiesibasLabot="UDENS2_VALIDACIJAS_LABOT"
        />
      </div>
    )
  }
}

const mapStateToProps = ({udensParskats, kodif}) => ({udensParskats, kodif})

export default connect(mapStateToProps, {...udensParskatsActions})(Parskats)
