import React from 'react'

import * as api from '../../api'
import {viewSingleFile} from "./SingleFileUploader";
import { v4 as uuidv4 } from 'uuid';
// import {estimateUploadTime} from "../../api";


class LargeFileUploader extends React.PureComponent {
    // generic file uplāderis

    constructor (props) {
        super(props)

        this.state = {
            error: null,
            is_uploading: false,
            smallLimit: props.smallLimit,
            largeLimit: props.largeLimit,
            progress: null,
            enable_resume: false,
            requestUUID: uuidv4(),
            file: null,
            uploadInstance: null
        }

        this.fileInput = React.createRef()

        ;([
            'renderError',
            'renderDropArea',

            'dropClicked',
            'fileInputChanged',

            'uploadOne',

            'dragEntered',
            'dragDropped',
        ]).forEach( f => {
            if ( ! this[f] ) {
                console.error(`Warning: ${f} missing`)
            } else {
                this[f] = this[f].bind(this)
            }
        } )
    }

    myThrottler = api.throttler(300)

    updateThrottled = (progress) => this.myThrottler(() => {
        if (this.state.is_uploading) {
            this.setState({progress: progress})
        }
    })

    handleLargeUpload = (file) => {
        this.setState({is_uploading: true})
        const chunkSize = 5*1024*1024
        // estimateUploadTime(file.size)
        const newUploadInstance = api.uploadResumable(file, chunkSize, this.state.requestUUID, this.updateThrottled)
        this.setState({uploadInstance : newUploadInstance})
        return newUploadInstance.startUpload()
            .then((response) => {
                console.log(response)
                this.setState({is_uploading: false, enable_resume: false, file: null})
                this.fileInput.current.value = null
                this.props.onFileUploaded(response)
            })
            .catch((error) => {
                this.setState({
                    error: `${error}`,
                    is_uploading: false,
                    enable_resume: true
                })
            })
    }

    handleSmallUpload = (file) => {
        this.setState({is_uploading: true})
        return api.uploadFile(file)
            .then((res) => {
                this.setState({is_uploading: false})
                this.fileInput.current.value = null
                this.props.onFileUploaded(res)
            })
            .catch( (error) => {
                this.setState({
                    error: `${error}`,
                    is_uploading: false,
                })
            })
    }

    uploadOne (file) {
        const isFileTooLarge = file.size > this.state.largeLimit
        const isFileLarge = file.size > this.state.smallLimit
        if (isFileTooLarge) {
            this.setState({error:"Fails ir pārāk liels!"})
        } else if (isFileLarge && !isFileTooLarge) {
            this.handleLargeUpload(file)
        } else {
            this.setState({file: null})
            this.handleSmallUpload(file)
        }
    }


    fileInputChanged (ev) {

        ev.preventDefault()

        if (this.state.is_uploading) {
            console.log("Still uploading, actions locked")
            return false
        }

        this.setState({ is_uploading: true, error: null, file: ev.target.files.item(0)})

        this.uploadOne(ev.target.files.item(0))
    }

    dragEntered (ev) {
        ev.preventDefault()
        ev.stopPropagation()
    }

    dragDropped (ev) {
        ev.preventDefault()
        ev.stopPropagation()

        if (ev.dataTransfer.files.length === 0) {
            alert('Nav saņemts neviens fails?')
        } else {
            this.setState({file: ev.target.files.item(0)})
            this.uploadOne(ev.dataTransfer.files.item(0))
        }
    }


    dropClicked () {
        if (this.state.is_uploading) return false
        this.fileInput.current && this.fileInput.current.click()
    }


    renderDropArea () {

        const c = "csfu-droparea " + (this.state.is_uploading ? ' is-uploading' : '')

        return (
            <button type="button" className={c}
                    onClick={this.dropClicked}
                    onDragEnter={this.dragEntered}
                    onDragOver={this.dragEntered}
                    onDrop={this.dragDropped}>
                <div className="csfu-droparea-inner">
                    <span className="fa fa-upload" />
                    {
                        this.props.filename ? this.props.filename : "Ielādē failu"
                    }
                    <div>
                        {
                            this.props.filename ? "" : "Vai uzvelc ar peli virsū"
                        }
                    </div>
                </div>
            </button>
        )
    }


    renderError () {
        if (this.state.error) {
            return (
                <p className="alert alert-danger">{ this.state.error }</p>
            )
        }
    }

    renderProgressBar () {
      return (
          <div>
            <div className="progress" style={{"height": "20px"}}>
                <div className="progress-bar progress-bar-striped" role="progressbar" style={{"width": this.state.progress}}
                     aria-valuenow={this.state.progress}
                     aria-valuemin="0" aria-valuemax="100">
                    {this.state.progress}
                </div>
            </div>
              <div>
                {
                    this.state.is_uploading && (
                        <button onClick={() => {
                            this.state.uploadInstance.stopUpload()
                        }} type="button" className="btn btn-small btn-outline-danger ml-2">Stop</button>
                    )
                }
                {
                    this.state.enable_resume && (
                        <button onClick={() => {
                            this.setState({is_uploading: true, enable_resume: false})
                            this.state.uploadInstance.resumeUpload()
                                .then((response) => {
                                    this.setState({is_uploading: false, enable_resume: false, file: null})
                                    this.fileInput.current.value = null
                                    this.props.onFileUploaded(response)
                                })
                                .catch((error) => {
                                    this.setState({
                                        error: `${error}`,
                                        is_uploading: false,
                                        enable_resume: true
                                    })
                                })
                        }} type="button" className="btn btn-small btn-outline-success ml-2">Turpināt</button>
                    )
                }
              </div>
          </div>
      )
    }

    render () {
        return (
            <div className="c-single-file-uploader">
                <input
                    type="file"
                    multiple={false}
                    ref={this.fileInput}
                    onChange={this.fileInputChanged}
                    className="d-none"
                />
                {
                    this.renderDropArea()
                }
                {
                    this.renderProgressBar()
                }
                {
                    this.renderError()
                }
            </div>
        )
    }
}

const LargeFileUploaderField = (props) => {

    const cu = props.currentValRow.file || {}
    console.log(props)
    return props.editable ? (

        cu.id ? (

            <div className="d-flex">
                { viewSingleFile(null, props.currentValRow) }
                <button onClick={() => {
                    props.setFieldValue(props.fieldName, null)
                    props.setFieldValue("file", {})
                }} className="btn btn-small btn-outline-danger ml-2"><span className="fa fa-trash" /></button>
            </div>

        ) : (
            <div>
                <LargeFileUploader
                    filename={ cu.filename }
                    smallLimit={props.uploadSize.smallFileLimit}
                    largeLimit={props.uploadSize.largeFileLimit}
                    onFileUploaded={fi => {
                        props.setFieldValue(props.fieldName, fi.id)
                        props.setFieldValue("file", fi)
                    }} />
            </div>

        )


    ) : viewSingleFile(null, props.currentValRow)
}


export { LargeFileUploader , LargeFileUploaderField}
export default LargeFileUploader