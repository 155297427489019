import React, {Component} from 'react';
import {connect} from 'react-redux'
import Loading from '../Loading'
import GridWithForm from '../grid/GridWithForm'
import { parisVknSelected, parisVknActions } from "../../actions/udens2Actions";
import {
  getFormatterForKodif,
  getTextFilterCfg,
} from '../grid/ColumnHelpers'
import {addUrlQuery} from '../../actions/uiActions'
import {getKlasifikatorsFieldCfg} from "../forms/FormHelpers";

const getColumns = ({ kodif }) => {
  return [
    {
      dataField: 'kods',
      text: 'Kods',
      filterCfg: getTextFilterCfg()
    }, {
      dataField: 'nosaukums',
      text: 'Nosaukums',
      filterCfg: getTextFilterCfg()
    }, {
      dataField: 'vkn_jura',
      text: 'Vides kvalitātes normatīvs JŪRĀ',
    }, {
      dataField: 'vkn_iekszeme',
      text: 'Vides kvalitātes normatīvs IEKŠZEMES',
    }, {
      dataField: 'krasa',
      text: 'Krāsa',
      formatter: getFormatterForKodif(kodif.parisVknKrasa)
    }
  ]};

const  getFormFieldCfgs = ({ kodif }) => {

  return [
    {
      name: 'kods',
      type: 'text',
      required: true,
    },
    {
      name: 'nosaukums',
      type: 'text',
      required: true,
    },
    {
      name: 'vkn_jura',
      type: 'number',
      required: true,
    },
    {
      name: 'vkn_iekszeme',
      type: 'number',
      required: true,
    },
    {
      ...getKlasifikatorsFieldCfg(kodif, 'parisVknKrasa'),
      name: 'krasa',
      renderer: getFormatterForKodif(kodif.parisVknKrasa)
    },
  ]};

class ParisVkn extends Component {
  render() {
    const { parisVkn, kodif } = this.props

    if(!parisVkn || parisVkn.loading) {
      return (<Loading/>)
    }

    if(!this.columns){
      this.columns = getColumns({ kodif })
    }

    if(!this.formFieldCfgs){
      this.formFieldCfgs = getFormFieldCfgs({ kodif })
    }

    return (
      <GridWithForm
        {...this.props}
        columns={this.columns}
        formFieldCfgs={this.formFieldCfgs}
        storeObj={this.props.parisVkn}
        formWidthGuiOption="paris_vkn_form_width"
        metadataFormCfg="paris_vkn_edit"
        tiesibasLabot="UDENS2_VKN_LABOT"
      />
    )

  }

}

const mapStateToProps = ({kodif, parisVkn, location}) => ({kodif, parisVkn, query: location.query})

export default connect(mapStateToProps, {
  selectedAct : parisVknSelected,
  addUrlQuery,
  ...parisVknActions
})(ParisVkn)