import React, { Component } from 'react';
import { connect } from 'react-redux'

import Form from '../forms/Form'
import SplitterLayout from "react-splitter-layout";
import IzraktenisMap from "../karte/IzraktenisMap";
import * as maputils from "../karte/maputils";
import {
  stacijaActions,
  stacijaGenerateAizsargjosla,
  atisObjektsActions,
  atisObjektsGeometrija,
  koordSelectAct,
  koordChangeAct,
  koordDeleteAct
} from "../../actions/stacijasActions";
import Loading from "../Loading";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import { getKlasifikatorsFieldCfg, getKodifRenderer } from "../forms/FormHelpers";
import KoordTable, { ShowKoordTableBtn } from '../karte/KoordTable'
import { showErrorMsg } from "../../actions/uiActions";

const showCoordsBtn = <ShowKoordTableBtn />

const getFormFieldCfgs = ({ kodif, fetchAct }) => {
  return [
    {
      name: 'monit_veids',
    },
    {
      name: 'tips',
    },
    {
      name: 'nosaukums',
      renderClassName: "bold",
    }, {
      name: 'piederiba',
    }, {
      name: 'darbibas_sakums',
    }, {
      name: 'darbibas_beigas',
    }, {
      name: 'augstums_uzdots',
    }, {
      name: 'augstums',
    }, {
      name: 'iekartas',
      label: 'Iekārtas',
      multiFieldCfgs: [{
        name: 'edit_icon',
        renderer: (val, row) => {
          return <>
            <Button color="success" outline size="sm" onClick={() => { fetchAct({ id: row.id }) }}><i className="fa fa-pencil"></i></Button> {' '}
            <DeleteButton rowId={row.id}></DeleteButton>
          </>
        }
      }, {
        name: 'atis_code',
      }, {
        name: 'descr',
      }, {
        name: 'infoscale',
      }]
    }, {
      name: 'aizsargjoslas',
      label: 'Aizsargjoslas',
      multiFieldCfgs: [{
        name: 'atis_code',
      }, {
        name: 'descr',
      }, {
        name: 'infoscale',
      }, {
        name: 'legal_base',
      }, {
        name: 'platums',
      }, {
        name: 'veids',
        renderer: getKodifRenderer(kodif.atisAizsargjoslaVeids)
      }]
    }
  ]
}

const getModalFieldCfg = ({ kodif, atisObjektsGeometrija, stacija }) => {
  return [{
    ...getKlasifikatorsFieldCfg(kodif, 'atisKodsObjekts'),
    name: 'atis_code',
    required: true,
  }, {
    name: 'descr',
    required: true,
  }, {
    ...getKlasifikatorsFieldCfg(kodif, 'atisKodsAizsargjosla'),
    name: 'aizsargjosla_atis_code',
  }, {
    name: 'aizsargjosla_platums',
    required: true,
  }, {
    ...getKlasifikatorsFieldCfg(kodif, 'atisAizsargjoslaVeids'),
    name: 'aizsargjosla_veids',
    required: true,
  }, {
    name: 'infoscale',
    required: true,
  }, {
    name: 'legal_base',
  }, {
    ...getKlasifikatorsFieldCfg(kodif, 'atisObjektsGeometry'),
    name: 'objekta_veids',
    label: 'Objekta ģeometrija',
    editable: true,
    required: true,
    overridePropsFunc: (props) => {
      const newProps = {
        ...props,
        setFieldValue: (fieldName, value, options) => {
          props.setFieldValue(fieldName, value, options)
          if (fieldName === 'objekta_veids' && value !== null) {
            atisObjektsGeometrija(value, stacija)
          }
        }
      }
      return newProps
    }
  }]
}

const getVectorLayerCfgs = ({ stacija, atisObjekts, koordsMapIdName }) => {
  let cfg = {}
  if (!!stacija.id) {
    cfg.stacija = {
      features: stacija,
      title: 'Stacija',
      styles: maputils.uoStyles.stacijas,
      opacity: 0.9,
      tooltipFunc: () => "Stacija",
      zoomTo: !atisObjekts?.editable || !atisObjekts.selectedItem?.id ? stacija.id : null,
      epsgCode: 3059,
      legend: maputils.defaultSinglePointLegend
    }

    if (stacija.iekartas && stacija.iekartas.length) {
      cfg.iekartas = {
        features: stacija.iekartas,
        title: 'Iekārtas',
        styles: maputils.uoStyles.iekartas,
        opacity: 0.9,
        tooltipFunc: (f) => {
          return `Iekārta - ${f.get('descr')}`
        },
        epsgCode: 3059,
        legend: maputils.defaultSinglePointLegend
      }
    }

    if (stacija.aizsargjoslas) {
      cfg.aizsargjoslas = {
        features: stacija.aizsargjoslas,
        title: 'Aizsargjoslas',
        styles: maputils.uoStyles.uoStyle,
        opacity: 0.7,
        tooltipFunc: (f) => {
          return `Aizsargjosla - ${f.get('descr')}`
        },
        legend: maputils.defaultSinglePolygonLegend,
        epsgCode: 3059
      }
    }

    if (atisObjekts.editable) {
      cfg.atisObjekts = {
        features: atisObjekts.geom || [],
        title: atisObjekts.selectedItem?.id ? 'ATIS objekts' : 'Jauns ATIS objekts',
        styles: maputils.uoStyles.iekartas,
        opacity: 1,
        epsgCode: 3059,
        editable: atisObjekts.editable,
        editAll: true,
        tooltipFunc: () => 'ATIS objekts',
        legend: maputils.defaultSinglePointLegend,
        zoomToLayer: !!atisObjekts.selectedItem?.id,
        zoomToObjectOnlyFirstTime: true,
      }

      const layerTitles = {
        koord: 'Sadalījuma robežpunkti',
        koord_labels: 'Robežpunktu numuri'
      }

      cfg[koordsMapIdName] = {
        title: layerTitles.koord,
        features: atisObjekts.koords,
        styles: maputils.borderPointStyles,
        tooltipFunc: (feat) => `Nr ${feat.get('id')} (${feat.get('x')};${feat.get('y')})`,
        selected: atisObjekts.selectedKoord,
        zIndex: 15,
        editable: atisObjekts.editable,
        legend: maputils.defaultSinglePointLegend
      }

      cfg.koord_labels = {
        title: layerTitles.koord_labels,
        features: atisObjekts.koords,
        styles: maputils.borderPointStylesLabels,
        selectable: false,
        zIndex: 20,
        declutter: true
      }
    }
  }

  return cfg
}

class DeleteButtonComp extends Component {

  toggleModal() {
    this.setState({ modal: !this.state.modal })
  }

  handleDelete() {
    this.props.deleteAct(this.props.rowId)
  }

  constructor(props) {
    super(props);

    this.state = { modal: false }
    this.toggleModal = this.toggleModal.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
  }

  render() {
    return <>
      <Button color="danger" outline size="sm" onClick={this.toggleModal}><i className="fa fa-trash-o"></i></Button>
      <Modal isOpen={this.state.modal}>
        <ModalBody>
          {"Tiešām dzēst ierakstu?"}
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={this.handleDelete} disabled={this.props.atisObjekts.submitting}>Dzēst</Button>{' '}
          <Button color="secondary" onClick={this.toggleModal}>Atcelt</Button>
        </ModalFooter>
      </Modal>
    </>
  }
}

class StacijaActionButtonsComp extends Component {
  generateAll = () => {
    const { selectedItem: stacija } = this.props.stacija
    this.props.stacijaGenerateAizsargjosla(stacija)
  }

  createAct = () => {
    const { selectedItem: stacija } = this.props.stacija
    this.props.createAct({
      vvis_stacija_id: stacija.id
    })
  }

  render() {
    const { selectedItem: stacija } = this.props.stacija

    return <>
      {stacija.atis_source_id ?
        (<>
          <Button className="float-right" color="secondary" onClick={this.generateAll}>Ģenerēt aizsargjoslas</Button>
          <Button className="float-right" color="secondary" onClick={this.createAct}>Pievienot iekārtu</Button>
        </>) :
        (<Button className="float-right" color="secondary" onClick={this.createAct}>Izveidot ATIS objektu</Button>)
      }
    </>
  }
}

class AtisObjectsForm extends Component {

  render() {
    const { atisObjekts, kodif, atisObjektsGeometrija, koordSelectAct, koordChangeAct, koordDeleteAct } = this.props
    const { selectedItem: stacija } = this.props.stacija

    if (atisObjekts.loading) {
      return <Loading></Loading>
    }

    if (!this.atisFieldCfgs) {
      this.atisFieldCfgs = getModalFieldCfg({ kodif, atisObjektsGeometrija, stacija })
    }

    return (<>
      <Form
        editable={true}
        dataObj={atisObjekts.selectedItem}
        onSubmit={this.props.saveAct}
        onReset={this.props.stopEdit}
        formFieldCfgs={this.atisFieldCfgs}
        metadataFormCfg="atis_objekts_view"
        onStartEdit={null}
        sizeLabel={3}
        tiesibasLabot={'NOV_STACIJAS_LABOT'}
        extraElements={showCoordsBtn}
      />
      <KoordTable
        editable={atisObjekts.editable}
        koords={atisObjekts.koords}
        selectedKoord={atisObjekts.selectedKoord}
        validation={atisObjekts.koordsFeat && atisObjekts.koordsFeat.validation}
        koordSelectAct={koordSelectAct}
        koordChangeAct={koordChangeAct}
        koordDeleteAct={koordDeleteAct}
        showErrorMsg={showErrorMsg}
        isPointGeometry
        showAdvancedFields={false}
        enableKoordRowDelete={true}
      />
    </>)
  }
}

class StacijaShow extends Component {

  render() {
    const { atisObjekts, fetchAct, deleteAtis } = this.props
    const { selectedItem: stacija, editable, submiting, generating } = this.props.stacija

    if (!stacija) {
      return (<Loading />)
    }

    // initial cfgs can come in as props, or we can create default ones
    const { getFormFieldCfgsFunc, kodif } = this.props

    // we cache form field cfgs
    if (!this.formFieldCfgs) {
      const f = getFormFieldCfgsFunc ? getFormFieldCfgsFunc : getFormFieldCfgs
      this.formFieldCfgs = f({ kodif, fetchAct, deleteAtis })
    }

    const koordsMapIdName = 'atis_objekts_koord';

    const vectorLayerCfgs = getVectorLayerCfgs({ stacija, atisObjekts, koordsMapIdName })

    const actionButtons = <StacijaActionButtons></StacijaActionButtons>

    return <>
      <SplitterLayout
        key="ver"
        customClassName="pane-paddings-hor"
        percentage
        primaryIndex={0}
        secondaryInitialSize={this.props.gui.stacija_show_karte_width}
        onSecondaryPaneSizeChange={this.mapPaneSizeChange}>

        <div className="leftside">
          {!atisObjekts.editable ?
            (<Form
              editable={editable}
              submiting={submiting}
              dataObj={stacija}
              formFieldCfgs={this.formFieldCfgs}
              metadataFormCfg="noverojumu_stacija_view"
              onSubmit={null}
              onReset={null}
              onStartEdit={null}
              onDelete={null}
              sizeLabel="2"
              sizeForm="10"
              extraElements={actionButtons}
            />) :
            (<AtisObjekts></AtisObjekts>)
          }
        </div>

        <div className="rightside">
          <div className="karte-wrapper">
            <IzraktenisMap mapId='stacija-view' vectorLayerCfgs={vectorLayerCfgs} />
          </div>
        </div>
      </SplitterLayout>
      <Modal
        isOpen={generating}
        className="modal-sm">
        <ModalHeader>Notiek aizsargjoslu ģenerēšana</ModalHeader>
        <ModalBody className="text-center">
          <Spinner size="lg" color="primary" />{' '}
        </ModalBody>
      </Modal>
    </>
  }
}

const StacijaActionButtons = connect(({ stacija }) => ({ stacija }), {
  stacijaGenerateAizsargjosla,
  createAct: atisObjektsActions.createAct
})(StacijaActionButtonsComp)

const AtisObjekts = connect(({
  stacija,
  atisObjekts,
  kodif,
}) => ({
  stacija,
  atisObjekts,
  kodif,
}), {
  stopEdit: atisObjektsActions.stopEditAct,
  saveAct: atisObjektsActions.saveAct,
  atisObjektsGeometrija,
  koordSelectAct,
  koordChangeAct,
  koordDeleteAct
})(AtisObjectsForm)

const DeleteButton = connect(({
  atisObjekts
}) => ({
  atisObjekts
}), {
  deleteAct: atisObjektsActions.deleteAct
})(DeleteButtonComp)

const mapStateToProps = ({
  stacija,
  atisObjekts,
  kodif,
  user,
  gui
}) => ({
  stacija,
  atisObjekts,
  kodif,
  user,
  gui
})
export default connect(mapStateToProps, {
  startEdit: stacijaActions.startEditAct,
  stopEdit: stacijaActions.stopEditAct,
  delete: stacijaActions.deleteAct,
  save: stacijaActions.saveAct,
  fetchAct: atisObjektsActions.fetchAct,
  deleteAtis: atisObjektsActions.deleteAct
})(StacijaShow)
