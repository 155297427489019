import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
    getTextFilterCfg,
    getSelectFilterCfg,
    getDateFilterCfg,
    dateTimeFormatter,
    getMultiBadgeFormatterForKodif,
    getEditIconColumn
} from '../grid/ColumnHelpers'
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,

} from 'reactstrap';

import IzraktenisMap from '../karte/IzraktenisMap';
import * as maputils from '../karte/maputils'

import SplitterLayout from 'react-splitter-layout';
import Link from 'redux-first-router-link';

import {guiSet} from '../../actions/uiActions'
import {atradneSelected, atradneDoubleSelected} from '../../actions/atradnesActions'

import Grid from '../grid/Grid'
import BBoxFilterButtonToUrl from '../grid/BBoxFilterButtonToUrl'
import ArchivedFilterButtonToUrl from '../grid/ArchivedFilterButtonToUrl'
import ManiObjektiButton from '../grid/ManiObjektiButton'

import _ from 'lodash'

const editIconColumn = getEditIconColumn((row) => (
    {
        type: 'loc_atradne',
        payload: {
            numurs: row.numurs
        }
    }
    )
)

const getColumns = ({kodif}) => {
  const veidsRenderer = getMultiBadgeFormatterForKodif(kodif.atradnesVeids)
  const ierakstaVeidsRenderer = getMultiBadgeFormatterForKodif(kodif.atradnesIerakstaVeids)
  return [editIconColumn,
    {
        dataField: 'ieraksta_veids',
        text: '',
        formatter: (cellContent) => {
            const veidsObj = cellContent.split('').reduce((veidi,veids) => {veidi[veids]=true;return veidi;}, {})
            return ierakstaVeidsRenderer(veidsObj)
        },
        width: 176,
        filterCfg: getSelectFilterCfg(_.omit(kodif.atradnesIerakstaVeids, ['D']))
    }, {
        dataField: 'numurs',
        text: 'Numurs',
        width: 85,
        filterCfg: getTextFilterCfg()
    }, {
        dataField: 'nosaukums',
        text: 'Nosaukums',
        filterCfg: getTextFilterCfg()
    }, {
        dataField: 'veids',
        text: 'Veids',
        formatter: (cellContent) => {
            const veidsObj = cellContent.split('').reduce((veidi,veids) => {veidi[veids]=true;return veidi;}, {})
            return veidsRenderer(veidsObj)
        },
        width: 120,
        filterCfg: getSelectFilterCfg(kodif.atradnesVeids)
    }, {
        dataField: 'q_adm_ters',
        text: 'Administratīvā teritorija',
        filterCfg: getTextFilterCfg()
    }, {
        dataField: 'izrakteni',
        text: 'Izrakteņi',
        width: 150,
        filterCfg: getSelectFilterCfg(kodif.DIZRAKT)
    }, {
        dataField: 'ped_izmainas',
        text: 'Labots',
        formatter: dateTimeFormatter,
        filterCfg: getDateFilterCfg(),
        width: 120,
    }
]};

class Saraksts extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modalSearch: false
        };
    }

    toggleModalSearch = () => {
        this.setState({
            modalSearch: !this.state.modalSearch
        });
    }

    mapPaneSizeChange = (h) => {
        this.props.guiSet('saraksts_karte_height', h);
    }

    rowEvents = {
        onDoubleClick: (e, row, rowIndex) => {
          this.props.atradneDoubleSelected(row.numurs)
        }
    }

    render() {

        const vectorLayerCfgs = {
            atradnes : {
                title: 'Atradnes',
                features: this.props.atradnes.list,
                styles: maputils.atradnesStyles,
                tooltipFunc: (feat) => `${feat.get('nosaukums')} (${feat.get('numurs')})`,
                selected: this.props.atradnes.selected,
                legend: {
                    features: [
                        {
                            label: 'Būvmateriālu atradne',
                            geomType: 'point',
                            selected: false,
                            ieraksta_veids: 'A',
                            veids: 'B'
                        },
                        {
                            label: 'Kūdras atradne',
                            geomType: 'point',
                            selected: false,
                            ieraksta_veids: 'A',
                            veids: 'K'
                        },
                        {
                            label: 'Sapropeļa atradne',
                            geomType: 'point',
                            selected: false,
                            ieraksta_veids: 'A',
                            veids: 'S'
                        },
                        {
                            label: 'Dziedniecības dūņu atradne',
                            geomType: 'point',
                            selected: false,
                            ieraksta_veids: 'A',
                            veids: 'D'
                        },
                        {
                            label: 'Cita veida atradne',
                            geomType: 'point',
                            selected: false,
                            ieraksta_veids: 'A',
                            veids: 'C'
                        },
                        {
                            label: 'Vairāku veidu atradne (krāsa atkarīga no veidiem)',
                            geomType: 'point',
                            selected: false,
                            ieraksta_veids: 'A',
                            veids: 'BKD'
                        },
                        {
                            label: 'Prognozēto resursu laukums (balta kontūra)',
                            geomType: 'point',
                            selected: false,
                            ieraksta_veids: 'P',
                            veids: 'B',
                            noFill: true
                        },
                        {
                            label: 'Atradne un Prognozēto resursu laukums (pelēka kontūra)',
                            geomType: 'point',
                            selected: false,
                            ieraksta_veids: 'AP',
                            veids: 'B',
                            noFill: true
                        },
                        {
                            label: 'Tabulā vai kartē izvēlēta atradne (sarkana kontūra)',
                            geomType: 'point',
                            selected: true,
                            ieraksta_veids: 'A',
                            veids: 'B',
                            noFill: true
                        }
                    ]
                }
            }
        }

        if(!this.columns){
            this.columns = getColumns({
                kodif: this.props.kodif
            })
        }

        return (
            <SplitterLayout
                vertical
                percentage
                primaryIndex={1}
                secondaryInitialSize={this.props.gui.saraksts_karte_height}
                onSecondaryPaneSizeChange={this.mapPaneSizeChange}>

                <IzraktenisMap mapId="atradnes_saraksts" vectorLayerCfgs={vectorLayerCfgs} />

                <Grid
                    onRowSelected={this.props.atradneSelected}
                    loading={this.props.atradnes.loading}
                    selectedId={this.props.atradnes.selected}
                    dataList={this.props.atradnes.list}
                    totalCount={this.props.atradnes.count}
                    columns={this.columns}
                    rowEvents={this.rowEvents}
                >

                { this.props.user.tiesibas.includes('LABOT_ATRADNES') ? (
                    <Link to={{ type: 'loc_atradne',
                        payload: {
                            numurs: 'jauns'
                        }
                    }}>
                        <Button color="primary"><i className="fa fa-plus-circle"></i>&nbsp;&nbsp;Jauns</Button>
                    </Link>
                ) : null }
                {/*<Button color="secondary" onClick={this.toggleModalSearch}>
                    <i className="fa fa-search"></i>&nbsp;&nbsp;Meklēt</Button> */}
                <Modal
                    isOpen={this.state.modalSearch}
                    toggle={this.toggleModalSearch}
                    className="modal-lg">
                    <ModalHeader toggle={this.toggleModalSearch}>Izvērsta atradņu meklēšana</ModalHeader>
                    <ModalBody>
                        Šeit būs lauki izvērstai atradņu meklēšana pēc visiem saistītajiem laukiem.
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.toggleModalSearch}>Meklēt</Button>
                        <Button color="secondary" onClick={this.toggleModalSearch}>Atcelt</Button>
                    </ModalFooter>
                </Modal>
                <BBoxFilterButtonToUrl/>
                <ArchivedFilterButtonToUrl/>
                <ManiObjektiButton allText="Rādīt visas" maniText="Rādīt tikai manas atradnes"/>
                </Grid>

            </SplitterLayout>
        )
    }
}

const mapStateToProps = ({kodif, atradnes, gui, user}) => {
    return {kodif, atradnes, gui, user}
}

export default connect(mapStateToProps, {atradneSelected, guiSet, atradneDoubleSelected})(Saraksts)