import React, {Component} from 'react'
import {connect} from 'react-redux'
import {komersantaIzmainaSelected} from '../../actions/izmainaActions'
import IzmainaSarakstsBase from './IzmainaSarakstsBase'
import IevadesStatusFilter from './IevadesStatusFilter'


const skipColumns = ['lic_izsniegts_datums', 'lic_derigs_lidz_datums', 'lim_adresats_nosaukums', 'lim_izsniegts_datums', 'lim_derigs_lidz_datums',
                     'limita_apjoms_korigetais', 'q2_izmantosanas', 'ir_parbauditi']

class IzmainaSarakstsKomersants extends Component {

    render() {

        const {komersantaIzmainas, komersantaIzmainaSelected} = this.props

        return (
          <IzmainaSarakstsBase
               mapId="komersanta_izmainu_saraksts"
               karte_height_gui="komersanta_izmainu_saraksts_karte_height"
               izmainas={komersantaIzmainas}
               izmainaSelected={komersantaIzmainaSelected}
               skipColumns={skipColumns}
          >
          <IevadesStatusFilter/>
          </IzmainaSarakstsBase>
        )
    }
}

const mapStateToProps = ({komersantaIzmainas}) => {
    return {komersantaIzmainas}
}
export default connect(mapStateToProps, {komersantaIzmainaSelected})(IzmainaSarakstsKomersants)
