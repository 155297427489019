import React, {Component} from 'react';

export default class YearGridFilter extends Component {

    constructor(props) {
        super(props)
        this.state = {
            dataObjVal: props.defaultValue || (new Date().getFullYear())
        }
        if(props.getFilterFunc){
            props.getFilterFunc(this.setFilterValue)
        }
    }

    componentDidMount() { 
        if(this.state.dataObjVal){
            this.props.onFilter(this.state.dataObjVal)
        }
    }

    // called from grid when clearing filters
    setFilterValue = (newVal) => {
        this.setState({
            dataObjVal: newVal 
        })
        this.props.onFilter(newVal)
    }

    prevYear = () => {
        return this.state.dataObjVal - 1
    }

    nextYear = () => {
        return this.state.dataObjVal + 1
    }

    render() {
        const { dataObjVal } = this.state
        return <div onClick={e => e.stopPropagation()} className="year-filter">
            <i className="fa fa-caret-left prev" title={this.prevYear()} aria-hidden="true" onClick={() => this.setFilterValue(this.prevYear())}></i>
            <span>{ dataObjVal }</span>
            <i className="fa fa-caret-right next" title={this.nextYear()} aria-hidden="true" onClick={() => this.setFilterValue(this.nextYear())}></i>
        </div>
    }

}
