import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
  getTextFilterCfg,
  getFormatterForKodif,
  getEditIconColumn, getmultiSelectFilterCfg
} from '../grid/ColumnHelpers'
import {
    Button,
} from 'reactstrap';

import Karte from '../karte/IzraktenisMap';
import * as maputils from '../karte/maputils'

import SplitterLayout from 'react-splitter-layout';
import Link from 'redux-first-router-link';

import {guiSet} from '../../actions/uiActions'
import {virszemesUdensObjektsSelected, virszemesUdensObjektsDoubleSelected} from '../../actions/virszemesUdensObjektiActions'

import Grid from '../grid/Grid'
import BBoxFilterButtonToUrl from '../grid/BBoxFilterButtonToUrl'
import ArchivedFilterButtonToUrl from '../grid/ArchivedFilterButtonToUrl'

const editIconColumn = getEditIconColumn((row) => (
    {
        type: 'loc_virszemes_udens_objekts',
        payload: {
            kods: row.kods
        }
    }
    )
)

const getColumns = ({kodif}) => {
  return [editIconColumn, {
    dataField: 'kods',
    text: 'Kods',
    width: 176,
    filterCfg: getTextFilterCfg()
  }, {
    dataField: 'nosaukums',
    text: 'Nosaukums',
    width: 235,
    filterCfg: getTextFilterCfg()
  }, {
    dataField: 'adm_teritorija',
    text: 'Adm. teritorija',
    filterCfg: getTextFilterCfg()
  }, {
    dataField: 'uo_tips',
    text: 'Tips',
    width: 220,
    filterCfg: getmultiSelectFilterCfg(kodif.UO_TIPS)
  }, {
    dataField: 'kategorija',
    text: 'Kategorija',
    formatter: getFormatterForKodif(kodif.virszemesUdensObjektaKategorija),
    width: 120,
    filterCfg: getmultiSelectFilterCfg(kodif.virszemesUdensObjektaKategorija)
  }, {
    dataField: 'izcelsme',
    text: 'Izcelsme',
    formatter: getFormatterForKodif(kodif.udensObjektaIzcelsme),
    width: 120,
    filterCfg: getmultiSelectFilterCfg(kodif.udensObjektaIzcelsme)
  }]
};

class VirszemesUdensObjekti extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modalSearch: false
        };
    }

    toggleModalSearch = () => {
        this.setState({
            modalSearch: !this.state.modalSearch
        });
    }

    mapPaneSizeChange = (h) => {
        this.props.guiSet('udeni_saraksts_karte_height', h);
    }

    rowEvents = {
        onDoubleClick: (e, row, rowIndex) => {
          this.props.udensObjektsDoubleSelected(row.kods)
        }
    }

    render() {
        const vectorLayerCfgs = {
            virszemesUdensObjektiLabels : {
                title: `Ūdensobjektu nosaukumi`,
                features: this.props.virszemesUdensObjekti.list,
                styles: maputils.uoStyles.uoLabelsStyle,
                selected: this.props.virszemesUdensObjekti.selected,
                zIndex: 6,
                declutter: true
            },
            virszemesUdensObjekti : {
                title: 'Ūdensobjekti',
                features: this.props.virszemesUdensObjekti.list,
                styles: maputils.uoStyles.uoStyle,
                tooltipFunc: (feat) => `${feat.get('nosaukums')} (${feat.get('kods')})`,
                selected: this.props.virszemesUdensObjekti.selected,
                legend: {
                    features: [
                        {
                            label: 'Ūdensobjekts',
                            geomType: 'polygon',
                            selected: false
                        },
                        {
                          label: 'Ūdensobjekts (izvēlēts)',
                          geomType: 'polygon',
                          selected: true
                      }
                    ]
                },
                zIndex: 5
            }
        }

        if(!this.columns){
            this.columns = getColumns({
                kodif: this.props.kodif
            })
        }

        return (
            <SplitterLayout
                vertical
                percentage
                primaryIndex={1}
                secondaryInitialSize={this.props.gui.udeni_saraksts_karte_height}
                onSecondaryPaneSizeChange={this.mapPaneSizeChange}>

                <Karte mapId="udens_objekti_saraksts" vectorLayerCfgs={vectorLayerCfgs} />

                <Grid
                    onRowSelected={this.props.udensObjektsSelected}
                    loading={this.props.virszemesUdensObjekti.loading}
                    selectedId={this.props.virszemesUdensObjekti.selected}
                    dataList={this.props.virszemesUdensObjekti.list}
                    totalCount={this.props.virszemesUdensObjekti.count}
                    columns={this.columns}
                    rowEvents={this.rowEvents}
                    enablePaging={false}
                >

                { this.props.user.tiesibas.includes('BAIS_VU_PAMAT_LABOT') ? (
                    <Link to={{ type: 'loc_virszemes_udens_objekts',
                        payload: {
                            kods: 'jauns'
                        }
                    }}>
                        <Button color="primary"><i className="fa fa-plus-circle"></i>&nbsp;&nbsp;Jauns</Button>
                    </Link>
                ) : null }
                <BBoxFilterButtonToUrl/>
                <ArchivedFilterButtonToUrl/>
                </Grid>

            </SplitterLayout>
        )
    }
}

const mapStateToProps = ({kodif, virszemesUdensObjekti, gui, user}) => {
    return {kodif, virszemesUdensObjekti, gui, user}
}

export default connect(mapStateToProps, {udensObjektsSelected: virszemesUdensObjektsSelected, guiSet, udensObjektsDoubleSelected: virszemesUdensObjektsDoubleSelected})(VirszemesUdensObjekti)
