import React, {Component} from 'react'
import {connect} from 'react-redux'
import Loading from '../Loading'

import Form from '../forms/Form'
import TypeaheadField from '../forms/TypeaheadField'
import Link from 'redux-first-router-link';
import {uzzinasActions, deleteAtradneUzzina, addAtradneUzzina, openUzzinaFromAtradne} from '../../actions/atradnesActions'
import {getColumns} from '../uzzinas/UzzinuSaraksts'
import {columnCfgToBootstrapTableColumn} from '../grid/Grid'
import {getEditIconColumn, dateFormatter} from '../grid/ColumnHelpers'
import BootstrapTable from 'react-bootstrap-table-next'
import {
    Button,
} from 'reactstrap'
import {showErrorMsg} from '../../actions/uiActions'

const formFields = []

class AtradnesUzzinas extends Component {

      rowEvents = {
            onDoubleClick: (e, row, rowIndex) => {
                // open uzzina edit page
                if(this.props.atradneUzzinas.editable){
                    this.props.showErrorMsg('Vispirms saglabājiet vai atceliet esošās izmaiņas!')
                } else {
                    this.props.openUzzinaFromAtradne({id:row.uzzina_id, numurs: this.props.atradne.selectedItem.numurs})
                }
            }
      }

      onDeleteAtradneUzzina = (row) => () => {
        this.props.deleteAtradneUzzina(row.uzzina_id)
      }

      deleteIconColumn = {
        dataField: 'df2',
        text: '',
        isDummyField: true,
        formatter: (cellContent, row) => {
            return (
                <Button color="danger" size="sm" title="Noņemt atradnei uzziņu" onClick={this.onDeleteAtradneUzzina(row)}>
                    <i className="fa fa-trash"></i>
                </Button>
            )
        },
        width: 40,
        sort: false,
      }

      editIconColumn = getEditIconColumn((row) => (
        {   
            type: 'loc_atradnes_uzzina',
            payload: {
                id: row.uzzina_id,
                numurs: this.props.atradne.selectedItem.numurs
            }
        }
        )
      )

      autocompleteCfg = {
          remoteSearchViewName: 'uzzina_search'
      }

      render() {
        
        const {selectedItem, editable, submiting, uzzinasInGrid} = this.props.atradneUzzinas

        if (!selectedItem) {
            return (<Loading/>)
        }

        // initial cfgs can come in as props, or we can create default ones
        const {kodif} = this.props

        const skipCols = ['atradnes', 'df1']
        const uzzinasCols = getColumns({
            kodif, user: this.props.user
        }).filter(col=> !skipCols.includes(col.dataField)).map(col=>columnCfgToBootstrapTableColumn(col))
        if(editable){
            uzzinasCols.unshift(columnCfgToBootstrapTableColumn(this.deleteIconColumn))
        }
        uzzinasCols.unshift(columnCfgToBootstrapTableColumn(this.editIconColumn))

        const addButtonsAndFields = editable ? <>
             <Link className="float-right"  to={{ type: 'loc_atradnes_uzzina',
                payload: {
                    id: 'jauns',
                    numurs: this.props.atradne.selectedItem.numurs
                }
              }}>
                <Button color="primary"><i className="fa fa-plus-circle"></i>&nbsp;&nbsp;Pievienot jaunu uzziņu</Button>
            </Link>
            
            <div className="field float-right" style={{width:300}}>
                <TypeaheadField 
                    fieldName="uzzinaSearch" 
                    autocompleteCfg={this.autocompleteCfg} 
                    extraFieldProps={{
                        placeholder: 'Pievienot esošu uzziņu',
                        renderMenuItemChildren: (opt, props, index) => <>
                            <b>{opt.dok_veids} &nbsp;
                            {opt.vgf_numurs ? 'VĢF: ' + opt.vgf_numurs : null} &nbsp;
                            {opt.protokola_nr ? 'Nr. ' + opt.protokola_nr : null} &nbsp;
                            {opt.protokola_datums ? dateFormatter(opt.protokola_datums) : null }</b>
                            {opt.nosaukums ? <><br/>{opt.nosaukums}</> : null}
                            {opt.veicejs ? <><br/>{opt.veicejs}</> : null}
                            {opt.gads ? <><br/>{opt.gads}</> : null}
                            {opt.autors ? <><br/>{opt.autors}</> : null}
                        </>
                    }} 
                    setFieldValue={this.props.addAtradneUzzina}
                />
            </div>
        </> : null

        return <div className="form-with-grid-container">
                    <Form 
                        editable={editable}
                        submiting={submiting}
                        dataObj={selectedItem}
                        formFieldCfgs={formFields}
                        onSubmit={this.props.saveAct}
                        onReset={this.props.stopEditAct}
                        onStartEdit={this.props.startEditAct}
                        extraElements={addButtonsAndFields}
                        tiesibasLabot="LABOT_ATRADNES"
                    />
                    <BootstrapTable 
                        bootstrap4
                        striped
                        hover
                        condensed
                        keyField="id"
                        data={ uzzinasInGrid }
                        columns={ uzzinasCols }
                        rowEvents={this.rowEvents}
                    />
                        
        </div>
    }
}

const mapStateToProps = ({atradneUzzinas, kodif, user, atradne}) => ({atradneUzzinas, kodif, user, atradne})
export default connect(mapStateToProps, {...uzzinasActions, openUzzinaFromAtradne, showErrorMsg, deleteAtradneUzzina, addAtradneUzzina})(AtradnesUzzinas)
