import React, {Component} from 'react';
import {connect} from 'react-redux'

import Form from '../forms/Form'
import {getDefaultRendererForMultiField, getKlasifikatorsFieldCfg} from '../forms/FormHelpers'
import {vnkIesniegumsActions} from '../../actions/vnkActions'
import {
    dateTimeFormatter,
    getBooleanFormatter,
    getBooleanSelectFilterCfg,
    getDateFilterCfg
} from "../grid/ColumnHelpers";
import KoordTable from '../karte/KoordTable'
import FormCard from "../forms/FormCard";

const getFormFieldWrapperCfgs = ({user}) => () => {
    return [
        {
            id: 'id_wrap',
            comp: FormCard,
            compProps: {
                title: '',
                pinnable: false,
                collapse: false,
                size: 12
            }
        },
        {
            id: 'iesniedzejs_wrap',
            comp: FormCard,
            compProps: {
                title: 'Dati par iesniedzēju',
                pinnable: false,
                collapse: true,
                size: 12
            }
        },
        {
            id: 'gadijuma_info_wrap',
            comp: FormCard,
            compProps: {
                title: 'Informācija par gadījumu',
                pinnable: false,
                collapse: true,
                size: 12
            }
        },
        {
            id: 'tiesais_kaitejums_wrap',
            comp: FormCard,
            compProps: {
                title: 'Ziņas par tieša kaitējuma draudiem',
                pinnable: false,
                collapse: true,
                size: 12
            }
        },
        {
            id: 'draudu_apjoms_nozimiba_wrap',
            comp: FormCard,
            compProps: {
                title: 'Draudu apjoms un nozīmība',
                pinnable: false,
                collapse: true,
                hiddenFunc: ({currentValues}) => !currentValues?.kait_veidi?.length,
                size: 12
            }
        },
        {
            id: 'noverojumi_merijumi_prognozes_wrap',
            comp: FormCard,
            compProps: {
                title: 'Veiktie novērojumi, mērījumi vai prognozes',
                pinnable: false,
                collapse: true,
                size: 12
            }
        },
        {
            id: 'operators_wrap',
            comp: FormCard,
            compProps: {
                title: 'Informācija par operatoru',
                pinnable: false,
                collapse: true,
                size: 12
            }
        },
        {
            id: 'pasakumi_wrap',
            comp: FormCard,
            compProps: {
                title: 'Informācija par pasākumiem',
                pinnable: false,
                collapse: true,
                size: 12
            }
        },
        {
            id: 'registracijas_info_wrap',
            comp: FormCard,
            compProps: {
                title: 'Reģistrācijas informācija',
                pinnable: false,
                collapse: true,
                size: 12
            }
        }
    ]
}

const KoordTableCustomField = (props) => {
    return (
            <KoordTable
                editable={props.vnkIesniegums.editable}
                koords={props.vnkIesniegums.koords}
                // selectedKoord={props.vnkIesniegums.selectedKoord}
                // validation={props.vnkIesniegums.koords.x !== 0 && props.vnkIesniegums.koords.y !== 0}
                // koordSelectAct={props.koordSelectAct}
                koordChangeAct={props.koordChangeAct}
                showErrorMsg={props.showErrorMsg}
                // geomTitle={'Vietas (epicentra) koordinātas LKS 92 koordinātu sistēmā'}
                isPointGeometry
                simpleColumns
                showAdvancedFields={false}
                alwaysShow
            />
    )
}

const getFormFieldCfgs = ({kodif, user, KoordTable}) => () => {
    const iesniedzejs_wrap = [
        {
            name: 'sniedzejs',
            fieldWrapper: 'iesniedzejs_wrap',
            type: 'text',
            required: true
        },
        {
            name: 'sniedzeja_amats',
            fieldWrapper: 'iesniedzejs_wrap',
            type: 'text',
            required: true
        },
        {
            name: 'iesniegsanas_datums',
            formatter: dateTimeFormatter,
            filterCfg: getDateFilterCfg(),
            fieldWrapper: 'iesniedzejs_wrap',
            required: true
        }
    ]

    const gadijuma_info_wrap = [
        {
            name: 'vai_sniegta_pirmo_reizi',
            type: 'checkbox',
            renderer: getBooleanFormatter(),
            filterCfg: getBooleanSelectFilterCfg(),
            fieldWrapper: 'gadijuma_info_wrap'
        },
        {
            name: 'iepr_vnk_iesniegums_id',
            fieldWrapper: 'gadijuma_info_wrap',
            editable: false,
        }
    ]

    const tiesais_kaitejums_wrap = [
        {
            name: 'draudu_konst_datums',
            type: 'date',
            fieldWrapper: 'tiesais_kaitejums_wrap'
        },
        {
            name: 'vai_ietekme_cilveku',
            formatter: getBooleanFormatter,
            fieldWrapper: 'tiesais_kaitejums_wrap'
        },
        // {
        //     name: 'draudu_iemesls_id',
        //     formatter: getBooleanFormatter,
        //     fieldWrapper: 'tiesais_kaitejums_wrap'
        // },
        {
            name: 'kait_veidi',
            label: 'Kaitējuma veidi',
            type: 'autocomplete',
            fieldWrapper: 'tiesais_kaitejums_wrap',
            autocompleteCfg: {
                multiple: true,
                keyField: 'tah_id',
                initialOptionsViewName: 'vnk_kaitejuma_veids_options',
                extraFilterParams : {},
                isValueObjStructure: true
            },
            renderer: getDefaultRendererForMultiField('tah_label')
        },
        {
            name: 'op_darbibas_veidi',
            label: 'Operatora profesionālā darbība, kas rada tieša kaitējuma draudus',
            type: 'autocomplete',
            fieldWrapper: 'tiesais_kaitejums_wrap',
            autocompleteCfg: {
                multiple: true,
                keyField: 'tah_id',
                initialOptionsViewName: 'vnk_operatoru_darb_veids_options',
                extraFilterParams : {},
                isValueObjStructure: true
            },
            renderer: getDefaultRendererForMultiField('tah_label')
        },
        // obligāts ja op_darbibas_veidi ir cita darbība
        {
            name: 'cita_darbiba_text',
            type: 'textarea',
            fieldWrapper: 'tiesais_kaitejums_wrap'
        },
        {
            name: 'adm_teritorijas',
            label: 'Administratīvās vai teritoriālās vienības kods un nosaukums',
            type: 'autocomplete',
            fieldWrapper: 'tiesais_kaitejums_wrap',
            autocompleteCfg: {
                multiple: true,
                keyField: 'tah_id',
                remoteSearchViewName: 'vnk_adm_teritorijas_search'
            },
            renderer: getDefaultRendererForMultiField('tah_label')
        },
        {
            name: 'usik',
            label: 'Ūdens saimnieciskā iecirkņa kods un nosaukums',
            type: 'autocomplete',
            fieldWrapper: 'tiesais_kaitejums_wrap',
            autocompleteCfg: {
                multiple: true,
                keyField: 'tah_id',
                remoteSearchViewName: 'vnk_usik_search'
            },
            renderer: getDefaultRendererForMultiField('tah_label')
        },
        {
            name: 'udenstilpes',
            label: 'Ūdenstilpes kods un nosaukums',
            type: 'autocomplete',
            fieldWrapper: 'tiesais_kaitejums_wrap',
            autocompleteCfg: {
                multiple: true,
                keyField: 'tah_id',
                remoteSearchViewName: 'vnk_udens_objekts_search'
            },
            renderer: getDefaultRendererForMultiField('tah_label')
        },
        {
            name: 'iadt',
            label: 'Īpaši aizsargājamās dabas teritorijas kods un nosaukums',
            type: 'autocomplete',
            fieldWrapper: 'tiesais_kaitejums_wrap',
            autocompleteCfg: {
                multiple: true,
                keyField: 'tah_id',
                remoteSearchViewName: 'vnk_iadt_search'
            },
            renderer: getDefaultRendererForMultiField('tah_label')
        },
        {
            name: 'koords',
            fieldWrapper: 'tiesais_kaitejums_wrap',
            label: 'Vietas (epicentra) koordinātas LKS 92 koordinātu sistēmā',
            sizeForm: 12,
            sizeLabel: 12,
            customField: () => KoordTable,
            renderer: () => KoordTable,
        },
        {
            name: 'vietas_adrese',
            fieldWrapper: 'tiesais_kaitejums_wrap'
        }
    ]

    const draudu_apjoms_nozimiba_wrap = [
        // Īpaši aizsargājamai sugai
        {
            label: 'Īpaši aizsargājamai sugai',
            sizeLabel: 0,
            sizeForm: 12,
            name: 'aizsargajamas_sugas',
            fieldWrapper: 'draudu_apjoms_nozimiba_wrap',
            hiddenFunc: ({currentValues}) => !currentValues.kait_veidi.map(e => e.tah_label).includes('Kaitējums īpaši aizsargājamai sugai'),
            multiFieldCfgs: [{
                name: 'tah_id',
                label: 'Sugas nosaukums',
                type: 'autocomplete',
                required: true,
                autocompleteCfg: {
                    labelFieldName: 'tah_label',
                    remoteSearchViewName: 'vnk_aizsargajamas_sugas_search'
                },
                renderer: (fieldValue, dataObj) => dataObj.tah_label,
            }, {
                label: 'Apdraudēto skaits',
                name: 'apdraudeto_skaits_text',
                type: 'textarea'
            }]
        },
        {
            label: 'Īpaši aizsargājamam biotopam',
            sizeLabel: 0,
            sizeForm: 12,
            name: 'aizsargajamie_biotopi',
            fieldWrapper: 'draudu_apjoms_nozimiba_wrap',
            hiddenFunc: ({currentValues}) => !currentValues.kait_veidi.map(e => e.tah_label).includes('Kaitējums īpaši aizsargājamam biotopam'),
            multiFieldCfgs: [{
                name: 'tah_id',
                label: 'Biotopa nosaukums latviski',
                type: 'autocomplete',
                required: true,
                autocompleteCfg: {
                    labelFieldName: 'tah_label',
                    remoteSearchViewName: 'vnk_aizsargajamie_biotopi_search'
                },
                renderer: (fieldValue, dataObj) => dataObj.tah_label,
            }, {
                label: 'Apdraudētā platība (ha)',
                name: 'apdraudeta_platiba_ha',
                type: 'textarea',
            }]
        },
        {
            label: 'Ūdeņiem',
            sizeLabel: 0,
            sizeForm: 12,
            name: 'udeni',
            fieldWrapper: 'draudu_apjoms_nozimiba_wrap',
            hiddenFunc: ({currentValues}) => !currentValues.kait_veidi.map(e => e.tah_label).includes('Kaitējums ūdeņiem'),
            multiFieldCfgs: [{
                name: 'tah_id',
                label: 'Ūdensobjekts',
                type: 'autocomplete',
                required: true,
                autocompleteCfg: {
                    labelFieldName: 'tah_label',
                    remoteSearchViewName: 'vnk_udens_objekts_search'
                },
                renderer: (fieldValue, dataObj) => dataObj.tah_label,
            }, {
                label: 'Kaitējuma draudu izplatības platība (m2)',
                name: 'kaitejuma_draudu_platiba_m2',
                type: 'textarea',
            }, {
                label: 'Piezīmes par kaitējuma draudu apjomu',
                name: 'piezimes',
                type: 'textarea',
            }]
        },
        {
            label: 'Augsnei',
            sizeLabel: 0,
            sizeForm: 12,
            name: 'augsnes',
            fieldWrapper: 'draudu_apjoms_nozimiba_wrap',
            hiddenFunc: ({currentValues}) => !currentValues.kait_veidi.map(e => e.tah_label).includes('Kaitējums augsnei'),
            multiFieldCfgs: [{
                label: 'Augsnes tips',
                name: 'augsnes_tips'
            }, {
                label: 'Kaitējuma apjoms (m3)',
                name: 'kaitejuma_draudu_apjoms_m3'
            }, {
                label: 'Kaitējuma draudu platiba (m2)',
                name: 'kaitejuma_draudu_platiba_m2'
            }, {
                label: 'Piezīmes',
                name: 'piezimes',
                type: 'textarea'
            }]
        },
        {
            label: 'Zemes dzīlēm',
            sizeLabel: 0,
            sizeForm: 12,
            name: 'zemes_dziles',
            fieldWrapper: 'draudu_apjoms_nozimiba_wrap',
            hiddenFunc: ({currentValues}) => !currentValues.kait_veidi.map(e => e.tah_label).includes('Kaitējums zemes dzīlēm'),
            multiFieldCfgs: [{
                label: 'Ģeoloģiskais raksturojums',
                name: 'geo_raksturojums'
            }, {
                label: 'Kaitējuma apjoms (m3)',
                name: 'kaitejuma_draudu_apjoms_m3'
            }, {
                label: 'Kaitējuma maksimālais dziļums (m)',
                name: 'draudu_maks_dzilums_m'
            }, {
                label: 'Piezīmes',
                name: 'piezimes',
                type: 'textarea'
            }]
        },
        {
            name: 'cita_info',
            label: 'Cita informācija',
            type: 'textarea',
            fieldWrapper: 'draudu_apjoms_nozimiba_wrap',
        }
    ]

    const noverojumi_merijumi_prognozes_wrap = [
        {
            label: '',
            sizeLabel: 0,
            sizeForm: 12,
            name: 'noverojumi',
            fieldWrapper: 'noverojumi_merijumi_prognozes_wrap',
            multiFieldCfgs: [{
                name: 'piesarnojuma_tips',
                label: 'Parametra tips',
                required: true,
                ...getKlasifikatorsFieldCfg(kodif, 'vnkPiesarnojosasVielasTips')
            }, {

                name: 'tah_id',
                label: 'Novērotais vai mērītais parametrs',
                type: 'autocomplete',
                required: true,
                autocompleteCfg: {
                    labelFieldName: 'tah_label',
                    initialOptionsViewName: 'vnk_piesarnojosas_vielas_search',
                    extraFilterParams : {},
                    ValueObjStructure: true
                },
                overridePropsFunc: (props) => {
                    const changedProps = {...props}
                    const {currentValRow} = changedProps
                    const placeholder = 'Nepieciešams izvēlēties parametra tipu'
                    if(currentValRow.piesarnojuma_tips){
                        changedProps.extraFieldProps = {disabled : false}
                        const extraFilterParams = {tips: currentValRow.piesarnojuma_tips}
                        changedProps.autocompleteCfg = {...changedProps.autocompleteCfg, extraFilterParams}
                    } else {
                        changedProps.extraFieldProps = {disabled : true, placeholder}
                        changedProps.autocompleteCfg = {...changedProps.autocompleteCfg, extraFilterParams : null}
                    }
                    return changedProps
                },
                renderer: (fieldValue, dataObj) => dataObj.tah_label,
            }, {
                label: 'Novērojuma vai mērījuma metode',
                name: 'metode',
                type: 'textarea',
                required: true
            }, {
                label: 'Parametra vērtība',
                name: 'vertiba',
                type: 'textarea',
                required: true
            }, {
                label: 'Vērtības mērvienība',
                name: 'mervieniba',
                type: 'textarea',
                required: true
            }, {
                label: 'Piezimes',
                name: 'piezimes',
                type: 'textarea',
            }]
        }
    ]

    const operators_wrap = [
        {
            name: 'ir_operators',
            fieldWrapper: 'operators_wrap'
        },
        {
            name: 'op_reg_num',
            fieldWrapper: 'operators_wrap',
            hiddenFunc: ({currentValues}) => !currentValues.ir_operators
        },
        {
            name: 'op_nosaukums',
            fieldWrapper: 'operators_wrap',
            hiddenFunc: ({currentValues}) => !currentValues.ir_operators
        },
        {
            name: 'op_adrese',
            fieldWrapper: 'operators_wrap',
            hiddenFunc: ({currentValues}) => !currentValues.ir_operators
        }
    ]

    const pasakumi_wrap = [
        {
            label: 'Vai preventīvie pasākumi ir uzsākti',
            name: 'vai_neatl_pas_uzsakti',
            formatter: getBooleanFormatter,
            fieldWrapper: 'pasakumi_wrap'
        },
        {
            label: 'Vai preventīvie pasākumi ir pabeigti',
            name: 'vai_neatl_pas_pabeigti',
            formatter: getBooleanFormatter,
            fieldWrapper: 'pasakumi_wrap'
        },
        {
            label: 'Veiktie pasākumi',
            name: 'veiktie_pas',
            type: 'textarea',
            fieldWrapper: 'pasakumi_wrap'
        },
        {
            label: 'Vai tieša kaitējuma draudi ir pilnībā novērsti',
            name: 'vai_darbi_pabeigti',
            formatter: getBooleanFormatter,
            fieldWrapper: 'pasakumi_wrap'
        },
        {
            label: 'Informācija par VVD pieņemtajiem lēmumiem',
            name: 'vvd_pienemt_lemumi_text',
            type: 'textarea',
            fieldWrapper: 'pasakumi_wrap'
        }
    ]

    const registracijas_info_wrap = [
        {
            name: 'reg_num',
            fieldWrapper: 'registracijas_info_wrap',
            editable: false
        },
        {
            name: 'ieraksta_reg_dat',
            type: 'date',
            fieldWrapper: 'registracijas_info_wrap',
            required: true
        },
        {
            name: 'informaciju_pienema',
            fieldWrapper: 'registracijas_info_wrap',
            required: true
        },
        {
            name: 'informaciju_pienema_amats',
            fieldWrapper: 'registracijas_info_wrap'
        }
    ]

    return [
        {
            name: 'id',
            renderClassName: "bold",
            fieldWrapper: "id_wrap",
            editable: false
        },
        ...iesniedzejs_wrap,
        ...gadijuma_info_wrap,
        ...tiesais_kaitejums_wrap,
        ...draudu_apjoms_nozimiba_wrap,
        ...noverojumi_merijumi_prognozes_wrap,
        ...operators_wrap,
        ...pasakumi_wrap,
        ...registracijas_info_wrap
    ]}

class PamatinformacijaComp extends Component {

    render() {
        const {selectedItem:vnkIesniegums, editable, submiting} = this.props.vnkIesniegums

        // initial cfgs can come in as props, or we can create default ones
        const {user, getFormFieldCfgsFunc, getFormFieldWrapperCfgsFunc, kodif, metadataFormCfg, saveAct, stopEditAct, startEditAct, deleteAct} = this.props

        // we cache form field cfgs
        if(!this.formFieldCfgs || this.props.vnkIesniegums.editable){
            const KoordTable = <KoordTableCustomField
                {...this.props}
            />
            const f = getFormFieldCfgsFunc ? getFormFieldCfgsFunc : getFormFieldCfgs({kodif, user, KoordTable})
            this.formFieldCfgs = f()
        }

        if(!this.formFieldWrapperCfgs){
            const f = getFormFieldWrapperCfgsFunc ? getFormFieldWrapperCfgsFunc : getFormFieldWrapperCfgs({user})
            this.formFieldWrapperCfgs = f()
        }

        return <div className="form-container">
                    <Form
                        editable={editable}
                        submiting={submiting}
                        dataObj={vnkIesniegums}
                        formFieldCfgs={this.formFieldCfgs}
                        formFieldWrapperCfgs={this.formFieldWrapperCfgs}
                        metadataFormCfg={metadataFormCfg}
                        onSubmit={saveAct}
                        onReset={stopEditAct}
                        onStartEdit={startEditAct}
                        onDelete={user.tiesibas.includes('VNK_DZEST') ? deleteAct : null}
                        tiesibasLabot="VNK_LABOT"
                    />
            </div>
    }
}

// connect Pamatinformacija with vnkIesniegums state
const mapStateToProps = ({vnkIesniegums, kodif, user}) => ({vnkIesniegums, kodif, user, metadataFormCfg: "videi_nodaritie_kaitejumi_view"})
const Pamatinformacija =  connect(mapStateToProps, {...vnkIesniegumsActions})(PamatinformacijaComp)
export default Pamatinformacija
