import React, { Component } from 'react'
import { default as OLMap } from 'ol/Map.js'
import View from 'ol/View.js'
import {
    defaults as defaultControls,
    FullScreen,
    ScaleLine,
    Attribution
} from 'ol/control.js'
import {
    defaults as defaultInteractions
} from 'ol/interaction'
import { Modify } from 'ol/interaction.js'
import * as maputils from './maputils'
import Group from 'ol/layer/Group.js'
import VectorLayer from 'ol/layer/Vector.js'
import VectorSource from 'ol/source/Vector.js'
import _ from 'lodash'
import Feature from 'ol/Feature'
import Overlay from 'ol/Overlay'
import { bbox as bboxStrategy } from 'ol/loadingstrategy'
import ReactResizeDetector from 'react-resize-detector'
import Draw from 'ol/interaction/Draw'

/*
Renders OL (OpenLayers) map component

Available props:

mapId - mandatory map identificator
center - object with x, y coordinates (GPS) for map center and z - zoom level
backgroundLayers - tilelayers as background maps
vectorLayerCfgs - vector layer configs
onChangeMapBBox - function, will be called when map bbox changes, e.g. center or zoom level
onClickFeature - called when clicked feature (single feature is returned, if there are multiple under cursor, next time the next one is returned)
onClickFeatures - called when clicked feature or features
onDoubleClickFeature - function, will be called when vector feature is double clicked
onClickOutsideFeature - called when clicked outside any feature
onMapInit - called after openlayers map created (with map refernce)
onMapContainerResize - called when map div resized
onModifyFeature - called when feature has been modified
onBackgroundLayerError - when background layer tile fails to load
onGetFeatureInfo - called when recieved response from WMS getFeatureInfo service (works on layers with config wmsFeatureInfoLayer and clickableRasterLayer)
onChangeLayerOpacity - called when layer opacity has been changed
onChangeLayerVisibility - called when layer visiblity has been changed
*/
export default class Map extends Component {

    constructor(props) {
        super(props)
        this.state = {
            overFeatures: [],
            overClickableRasterLayers: [],
            tooltip: null,
            loading: 0,
            loaded: 0
        }
        this.vectorLayers = []
        this.vectorLayerGroups = []
        this.zoomedLayersKeys = {}
        this.zoomedObjects = {}

        this.is_mounted = false
    }

    componentDidMount() {

        this.is_mounted = true
        const { center, vectorLayerCfgs: vectorLayerCfgsProp, layerGroups: layerGroupsProp, backgroundLayers } = this.props

        this.tileLayers = backgroundLayers

        // get view config from props or create default
        const centerArr = center && center.x && center.y ? maputils.fromGPSToWEB([Number(center.x), Number(center.y)]) : maputils.fromGPSToWEB([24.1, 56.95])
        const zoom = center && center.z ? Number(center.z) : 7
        const viewCfg = {
            center: centerArr,
            zoom,
            projection: maputils.PROJ_WEB,
            constrainResolution: true,
        }
        const view = new View(viewCfg)

        //create map
        const map = new OLMap({
            controls: defaultControls({
                zoomOptions: {
                    zoomInTipLabel: 'Tuvināt karti',
                    zoomOutTipLabel: 'Attālināt karti'
                },
                attribution: false
            }).extend([
                new FullScreen({ tipLabel: 'Pilnekrāna režīms' }),
                new ScaleLine(),
                new Attribution({
                    collapsible: true,
                    tipLabel: 'Karšu autortiesības',
                    label: 'Autortiesības'
                })
            ]),
            layers: [
                new Group({
                    'title': 'Fona kartes',
                    layers: this.tileLayers,
                    'fold': 'close'
                })
            ],
            interactions: defaultInteractions({
                keyboard: false
            }),
            target: this.mapContainer,
            view
        })

        // map.addControl(new LayerSwitcher({tipLabel: 'Slāņi'}))

        this.tileLayers.forEach(t => {
            const src = t.getSource()
            src.on('tileloadstart', () => { this.addLoading() })
            src.on('tileloadend', () => { this.addLoaded(); this.loadingOK(t); })
            src.on('tileloaderror', () => { this.addLoaded(); this.loadingError(t); })
            t.on('change:visible', this.handleChangeLayerVisible)
            t.on('change:opacity', this.handleChangeLayerOpacity)
        })

        // add openlayers events
        // on change bbox event
        const debouncedMapBBoxChangeEvent = _.debounce(this.handleChangeMapBBoxChanged, 400)
        view.on('change:resolution', debouncedMapBBoxChangeEvent)
        view.on('change:center', debouncedMapBBoxChangeEvent)

        const debouncedMapZoomChangeEvent = _.debounce(this.handleChangeMapZoom, 400)
        view.on('change:resolution', debouncedMapZoomChangeEvent)

        map.on('click', this.handleOnClick)
        map.on('dblclick', this.handleOnDoubleClick)
        const debouncedPointerMove = _.throttle(this.handleOnPointerMove, 50)
        map.on('pointermove', debouncedPointerMove)


        //add maptooltips
        this.tooltipOverlay = new Overlay({
            element: this.mapTooltips,
            offset: [10, 0],
            positioning: 'bottom-left'
        });
        map.addOverlay(this.tooltipOverlay)

        //add popup overlay
        this.popupOverlay = new Overlay({
            element: this.mapPopups,
            autoPan: true,
            autoPanAnimation: {
                duration: 250
            }
        })
        map.addOverlay(this.popupOverlay);

        //add modify interaction
        this.modifyInteractionSource = new VectorSource()
        this.modifyInteraction = new Modify({
            source: this.modifyInteractionSource
        })
        this.modifyInteraction.on('modifyend', (e) => {
            e.features.getArray().forEach(f => {
                if (this.props.onModifyFeature) {
                    const featureProps = f.getProperties()
                    const featureGeom = f.getGeometry().clone().transform(maputils.PROJ_WEB, maputils.PROJ_LKS)
                    this.props.onModifyFeature({
                        featureGeom,
                        featureProps,
                        layerId: featureProps['mapLayerId'],
                        mapId: this.props.mapId
                    })
                }
            })
        })
        map.addInteraction(this.modifyInteraction)

        // save map and layer references to this object
        this.map = map;
        this.view = view;

        // update vector layers from initial
        const vectorLayerCfgs = vectorLayerCfgsProp ? vectorLayerCfgsProp : {}
        const layerGroups = layerGroupsProp ? layerGroupsProp : {}
        this.updateVectorLayersFromCfgIfNeeded(vectorLayerCfgs, layerGroups)

        //add initial features and selected, if we have
        const zoomGeoms = Object.keys(vectorLayerCfgs).map(k => this.updateFeatures(k)).flat()
        maputils.zoomGeometries(this.view, zoomGeoms)
        //add editable features
        this.updateEditable()

        if (this.props.onMapInit) {
            this.props.onMapInit(map)
        }
    }

    componentWillUnmount() {
        this.is_mounted = false
    }

    addLoading = (weight) => {
        this.is_mounted && this.setState({
            loading: this.state.loading + (weight ? weight : 1)
        })
    }

    addLoaded = (w) => {
        const weight = w ? w : 1
        if (this.state.loaded + weight === this.state.loading) {
            this.is_mounted && this.setState({
                loaded: 0,
                loading: 0
            })
        } else {
            this.is_mounted && this.setState({
                loaded: this.state.loaded + weight
            })
        }
    }

    loadingOK = (layer) => {
        layer.loadErrors = 0
    }

    loadingError = (layer) => {
        layer.loadErrors = layer.loadErrors ? layer.loadErrors + 1 : 1
        // fallback to backup layer if 5 errors and this is background tileLayer
        if (layer.loadErrors > 4 && !layer.get("backupLayerOnError") && this.tileLayers.find(l => l === layer)) {
            const backupLayer = this.tileLayers.find(l => l.get("backupLayerOnError") === true)
            if (backupLayer) {
                layer.setVisible(false)
                backupLayer.setVisible(true)
            }
        }
        if (this.props.onBackgroundLayerError) {
            this.props.onBackgroundLayerError(layer.getProperties())
        }
    }

    // from atribute "vectorLayerCfgs" adds (when missing) or removes (when no more there) vector layers to OL Map
    updateVectorLayersFromCfgIfNeeded = (vectorLayerCfgs, layerGroups = {}) => {
        const addedLayers = []
        //add when missing
        Object.keys(vectorLayerCfgs).forEach(k => {
            const currentLayer = this.findLayerById(k)
            const cfg = vectorLayerCfgs[k]
            if (currentLayer) {
                if (currentLayer.get('urlParams') !== cfg.urlParams) {
                    if (cfg.type === 'bbox') {
                        currentLayer.getSource().setLoader(maputils.getBBoxLoaderFunction(k, cfg.bboxUrl, cfg.urlParams, currentLayer.getSource(), cfg.format, cfg.epsgCode, this.addLoading, this.addLoaded, cfg.reverseAxis))
                        currentLayer.getSource().refresh()
                    }
                    if (cfg.type === 'GeoServerWMS') {
                        currentLayer.getSource().setUrl(maputils.getGeoServerWMSUrl(cfg.url, cfg.urlParams, cfg.workspace))
                    }
                    currentLayer.set('urlParams', cfg.urlParams)
                }
            } else {
                if (cfg.type === 'bbox' || cfg.type === 'vector') {
                    let source
                    if (cfg.bboxUrl) {
                        source = new VectorSource({
                            format: cfg.format === 'json' ? maputils.GeoJSONFormat : maputils.GML3Format,
                            strategy: bboxStrategy,
                            crossOrigin: 'anonymous',
                            attributions: cfg.attributions
                        })
                        source.setLoader(maputils.getBBoxLoaderFunction(k, cfg.bboxUrl, cfg.urlParams, source, cfg.format, cfg.epsgCode, this.addLoading, this.addLoaded, cfg.reverseAxis))
                    } else {
                        source = new VectorSource({
                            attributions: cfg.attributions
                        })
                    }
                    const style = cfg.styles && cfg.styles.default ? cfg.styles.default : maputils.defaultStyles.default
                    const title = cfg.title ? cfg.title : 'Objekti'
                    const vector = new VectorLayer({
                        id: k,
                        className: `ol-layer-${k}`,
                        urlParams: cfg.urlParams,
                        group: cfg.group,
                        source,
                        title,
                        opacity: cfg.opacity ?? 1.0,
                        declutter: !!cfg.declutter,
                        style,
                        legend: cfg.legend ? maputils.getVectorLegend(cfg.legend, style, title) : undefined,
                        zIndex: cfg.zIndex ? cfg.zIndex : 10,
                        ...cfg.layerProps
                    });
                    addedLayers.push(vector)
                    if (cfg.bboxUrl) {
                        source.on('change', (event) => {
                            const source = event.target
                            const layerKey = this.findLayerKeyBySource(source)
                            source.getFeatures().forEach(f => f.set('mapLayerId', layerKey))
                        })
                    }
                } else if (cfg.type === 'GeoServerWMS') {
                    const layer = maputils.createGeoServerWMSLayer(cfg.title, k,
                        { ...cfg.layerProps, group: cfg.group, urlParams: cfg.urlParams, className: `ol-layer-${k}` },
                        maputils.getGeoServerWMSUrl(cfg.url, cfg.urlParams, cfg.workspace), cfg.epsgCode, cfg.attributions, cfg.legend)
                    const src = layer.getSource()
                    src.on('imageloadstart', () => { this.addLoading(8) })
                    src.on('imageloadend', () => { this.addLoaded(8) })
                    src.on('imageloaderror', () => { this.addLoaded(8) })
                    addedLayers.push(layer)
                } else if (cfg.type === 'arcgis') {
                    const layer = maputils.createArcGISLayer(cfg.title, k,
                        { ...cfg.layerProps, group: cfg.group, className: `ol-layer-${k}` }, cfg.url)
                    const src = layer.getSource()
                    src.on('tileloadstart', () => { this.addLoading() })
                    src.on('tileloadend', () => { this.addLoaded(); this.loadingOK(layer); })
                    src.on('tileloaderror', () => { this.addLoaded(); this.loadingError(layer); })
                    addedLayers.push(layer)
                } else if (cfg.type === 'wms') {
                    const layer = maputils.createWMSLayer(cfg.title, k,
                        { ...cfg.layerProps, group: cfg.group, className: `ol-layer-${k}` },
                        cfg.wmsSourceProps, cfg.epsgCode)
                    const src = layer.getSource()
                    src.on('imageloadstart', () => { this.addLoading(8) })
                    src.on('imageloadend', () => { this.addLoaded(8) })
                    src.on('imageloaderror', () => { this.addLoaded(8) })
                    addedLayers.push(layer)
                } else {
                    throw new Error(`vectorLayerCfgs contains config with unsupported type = ${cfg.type}`)
                }
            }
        })

        const missingLayers = this.vectorLayers.filter(vl => !vectorLayerCfgs[vl.get('id')])

        //groups not yet added
        const newGroupsIds = Object.keys(layerGroups).filter(k => !this.vectorLayerGroups.find(vl => vl.get('id') === k))
        if (addedLayers.length > 0 || missingLayers.length > 0 ||
            newGroupsIds.length > 0 || this.vectorLayerGroups.length !== Object.keys(layerGroups).length) {

            this.vectorLayers = this.vectorLayers.filter(vl => !!vectorLayerCfgs[vl.get('id')]).concat(addedLayers)

            //add or remove all layer groups only in case of changes with layers who have the group
            if (missingLayers.filter(vl => vl.get('group')).length > 0 ||
                addedLayers.filter(vl => vl.get('group')).length > 0) {

                //remove all layer groups from map
                const prevGroupVisibilities = this.vectorLayerGroups.reduce((o, vg) => ({ ...o, [vg.get('id')]: { visible: vg.getVisible() } }), {})

                this.vectorLayerGroups.forEach(g => this.map.removeLayer(g))
                this.vectorLayerGroups = []

                //order keys in such a way that deepest children (group) comes first
                const groupKeysSorted = Object.keys(layerGroups).sort((k1, k2) => {
                    const g1 = layerGroups[k1].group
                    const g2 = layerGroups[k2].group
                    if (!g1 && g2) { return 1 }
                    if (g1 && !g2) { return -1 }
                    if (!g1 && !g2) { return 0 }
                    if (g1 === k2) { return -1 }
                    if (g2 === k1) { return 1 }
                    return 0
                })

                //create layer groups
                groupKeysSorted.forEach(k => {
                    const layers = this.vectorLayers.filter(vl => vl.get('group') === k)
                    const childrenGroups = this.vectorLayerGroups.filter(vlg => vlg.get('group') === k)
                    const groupCfg = {
                        fold: 'close',
                        ...layerGroups[k],
                        id: k,
                        layers: [...childrenGroups, ...layers]
                    }
                    if (prevGroupVisibilities[k]) {
                        groupCfg.visible = prevGroupVisibilities[k].visible
                    }
                    const group = new Group(groupCfg)
                    layers.forEach(x => {
                        x.on('change:visible', this.handleChangeLayerVisible)
                        x.on('change:opacity', this.handleChangeLayerOpacity)
                    })
                    this.vectorLayerGroups.push(group)
                    if (!layerGroups[k].group) {
                        this.map.addLayer(group)
                    }
                })

            }

            //remove missing layers without group
            missingLayers.filter(vl => !vl.get('group')).forEach(vl => {
                this.map.removeLayer(vl)
            })
            // add layers without group
            addedLayers.filter(vl => !vl.get('group')).forEach(vl => {
                this.map.addLayer(vl)
                vl.on('change:visible', this.handleChangeLayerVisible)
                vl.on('change:opacity', this.handleChangeLayerOpacity)
            })
        }
    }

    handleChangeMapBBoxChanged = () => {
        const view = this.view;
        const map = this.map;
        const center = maputils.fromWEBToGPS(view.getCenter())
        const x = center[0].toFixed(6)
        const y = center[1].toFixed(6)
        const z = view.getZoom()
        const bbox = view.calculateExtent(map.getSize()).map(c => c.toFixed(0));
        if (this.props.onChangeMapBBox) {
            this.props.onChangeMapBBox({
                mapBbox: { x, y, z, bbox },
                mapId: this.props.mapId
            })
        }
    }

    handleChangeMapZoom = () => {
        if (this.props.onMapZoomChange) {
            this.props.onMapZoomChange(this.view.getZoom())
        }
    }

    handleChangeLayerOpacity = (e) => {
        if (this.props.onChangeLayerOpacity) {
            this.props.onChangeLayerOpacity({ id: e.target.get("id"), opacity: e.target.getOpacity() })
        }
    }

    handleChangeLayerVisible = (e) => {
        if (this.props.onChangeLayerVisibility) {
            this.props.onChangeLayerVisibility({ id: e.target.get("id"), visible: e.target.getVisible() })
        }
    }

    findLayerById = (id) => {
        return this.vectorLayers.find(l => l.get('id') === id)
    }

    getLayerSourceByLayerId = (id) => {
        return this.findLayerById(id).getSource()
    }

    findLayerKeyBySource = (source) => this.vectorLayers.find(l => l.getSource() === source).get('id')

    // updates layer features from props
    updateFeatures = (layerKey) => {
        const layerCfg = this.props.vectorLayerCfgs[layerKey]
        const zoomGeoms = []
        // layers with bbox strategy will update automaticaly
        if (layerCfg.type === 'vector') {
            const src = this.getLayerSourceByLayerId(layerKey)
            const firstTime = !this.zoomedLayersKeys[layerKey]
            src.clear()
            const {
                zoomTo,
                features,
                zoomToOnlyFirstTime,
                zoomToObjectOnlyFirstTime,
                zoomToLayer
            } = layerCfg
            const feats = Array.isArray(features) ? features : [features]
            const newFeatures = feats.map(f => {
                let mapF
                if (f instanceof Feature) {
                    mapF = f
                    mapF.set('mapLayerId', layerKey)
                } else {
                    const ff = f.koordsFeat || f
                    mapF = new Feature({
                        ...ff,
                        mapLayerId: layerKey,
                        geometry: maputils.getGeometryFromFeatureObj(ff, layerCfg.epsgCode),
                        selected: layerCfg.selected === ff.id
                    })
                    //allow new features to have no ID field
                    const id = !ff.id ? maputils.guidGenerator() : ff.id
                    mapF.setId(id);
                }
                const firstTimeObject = !this.zoomedObjects[`${layerKey}_${mapF.getId()}`]
                if (mapF.getGeometry() && (mapF.get('zoomTo') === true || (((zoomTo && mapF.getId() === zoomTo) || zoomToLayer) && (zoomToOnlyFirstTime !== true || firstTime) && (zoomToObjectOnlyFirstTime !== true || firstTimeObject)))) {
                    zoomGeoms.push(mapF.getGeometry())
                    this.zoomedObjects[`${layerKey}_${mapF.getId()}`] = true
                }
                return mapF
            })
            src.addFeatures(newFeatures)
        }
        if (zoomGeoms.length > 0) {
            this.zoomedLayersKeys[layerKey] = true
        }
        return zoomGeoms
    }

    updateSelected = (layerKey) => {
        const layerCfg = this.props.vectorLayerCfgs[layerKey]
        const src = this.getLayerSourceByLayerId(layerKey)
        //clear selected
        // TileArcGISRest does not have this?
        if (src.forEachFeature) {
            src.forEachFeature(f => {
                if (f.get('selected') === true) f.set('selected', false)
            })
        }
        //set selected prop
        if (layerCfg.selected) {
            const f = src.getFeatureById(layerCfg.selected)
            if (f) {
                f.set('selected', true)
            }
        }
    }

    updateEditable = () => {
        const { vectorLayerCfgs } = this.props
        this.modifyInteractionSource.clear()
        Object.keys(vectorLayerCfgs).forEach(k => {
            const cfg = vectorLayerCfgs[k]
            if (cfg.editable) {
                const src = this.getLayerSourceByLayerId(k)
                if (cfg.selected) {
                    const f = src.getFeatureById(cfg.selected)
                    if (f) {
                        this.modifyInteractionSource.addFeatures([f])
                    }
                } else if (cfg.editAll) {
                    this.modifyInteractionSource.addFeatures(src.getFeatures())
                }
            }
        })
    }

    isVectorLayerLoading = () => {
        const { vectorLayerCfgs } = this.props
        return Object.keys(vectorLayerCfgs).filter(k => vectorLayerCfgs[k].loading === true).length > 0
    }

    // pass new features from props into the OpenLayers layer object
    componentDidUpdate(prevProps) {
        const { vectorLayerCfgs, popup, layerGroups, draw } = this.props
        let shouldUpdateEditable = false
        if (vectorLayerCfgs) {
            if (!prevProps.vectorLayerCfgs || !prevProps.layerGroups || prevProps.vectorLayerCfgs !== vectorLayerCfgs
                || prevProps.layerGroups !== layerGroups) {
                this.updateVectorLayersFromCfgIfNeeded(vectorLayerCfgs, layerGroups)
            }
            let zoomGeoms = []
            Object.keys(vectorLayerCfgs).forEach(k => {
                const cfg = vectorLayerCfgs[k]
                const prevCfg = prevProps.vectorLayerCfgs[k]
                if (!prevCfg || cfg.features !== prevCfg.features) {
                    zoomGeoms = zoomGeoms.concat(this.updateFeatures(k))
                    shouldUpdateEditable = true
                }
                if (!prevCfg || cfg.selected !== prevCfg.selected) {
                    this.updateSelected(k)
                    shouldUpdateEditable = true
                }
                if (!prevCfg || cfg.editable !== prevCfg.editable) {
                    shouldUpdateEditable = true
                }
            })
            maputils.zoomGeometries(this.view, zoomGeoms)
            if (shouldUpdateEditable) {
                this.updateEditable()
            }
        }
        if (popup && (!prevProps.popup || prevProps.popup.coordinate !== popup.coordinate)) {
            this.popupOverlay.setPosition(popup.coordinate)
        }
        if (prevProps.draw !== draw) {
            if (this.drawInteraction) {
                if (draw === "finish") {
                    this.drawInteraction.finishDrawing()
                }
                this.map.removeInteraction(this.drawInteraction)
            }
            if (draw) {
                const drawInteractionSource = new VectorSource()
                this.drawInteraction = new Draw({
                    source: drawInteractionSource,
                    type: draw,
                })
                this.drawInteraction.on('drawend', (e) => {
                    if (this.props.onDrawFeature) {
                        this.props.onDrawFeature({
                            feature: e.feature
                        })
                    }
                })
                this.drawInteraction.on('drawabort', (e) => {
                    if (this.props.onDrawFeatureAbort) {
                        this.props.onDrawFeatureAbort()
                    }
                })
                this.map.addInteraction(this.drawInteraction)
            }
        }
    }

    //if the mouse cursor is over multiple features, each time take next feature
    getNextOverFeature = (lastClicked) => {
        const { overFeatures } = this.state
        if (overFeatures.length === 1) {
            return overFeatures[0]
        } else if (overFeatures.length > 1) {
            if (!lastClicked) {
                return overFeatures[0]
            } else {
                const lastClickedId = lastClicked.getId()
                if (lastClickedId) {
                    //try next id
                    const sorted = [...overFeatures].sort((f1, f2) => (f1.getId() > f2.getId()) ? 1 : ((f2.getId() > f1.getId()) ? -1 : 0))
                    const currentInd = sorted.findIndex(e => e.getId() === lastClickedId)
                    const newInd = currentInd + 1 < overFeatures.length ? currentInd + 1 : 0
                    return sorted[newInd]
                } else {
                    return overFeatures[0]
                }
            }
        }
    }

    handleOnDoubleClick = (event) => {
        const { overFeatures } = this.state
        if (overFeatures.length > 0) {
            const overFeature = this.getNextOverFeature(this.lastDblClickedFeature)
            this.lastDblClickedFeature = overFeature
            const featureProps = overFeature.getProperties()
            if (this.props.onDoubleClickFeature) {
                this.props.onDoubleClickFeature({
                    event,
                    featureProps,
                    featureId: overFeature.getId(),
                    layerId: featureProps['mapLayerId'],
                    mapId: this.props.mapId
                })
            }
            event.stopPropagation()
        } else if (this.props.onClickOutsideFeature) {
            this.props.onClickOutsideFeature({
                mapId: this.props.mapId,
                event
            })
        }
    }

    handleOnClick = (event) => {
        const { vectorLayerCfgs } = this.props
        const { overFeatures, overClickableRasterLayers } = this.state
        let clickedSomething = false

        if (overFeatures.length > 0) {
            if (this.props.onClickFeature) {
                const overFeature = this.getNextOverFeature(this.lastClickedFeature)
                this.lastClickedFeature = overFeature
                const featureProps = overFeature.getProperties()
                this.props.onClickFeature({
                    event,
                    featureProps,
                    featureId: overFeature.getId(),
                    layerId: featureProps['mapLayerId'],
                    mapId: this.props.mapId
                })
                clickedSomething = true
            }
            if (this.props.onClickFeatures) {
                this.props.onClickFeatures({
                    event,
                    featuresProps: overFeatures.map(f => ({ ...f.getProperties(), fid: f.getId() })),
                    mapId: this.props.mapId
                })
                clickedSomething = true
            }
        }

        if (overClickableRasterLayers.length > 0) {
            if (this.props.onClickRasterLayer) {
                this.props.onClickRasterLayer({
                    mapId: this.props.mapId,
                    layers: overClickableRasterLayers,
                    event
                })
                clickedSomething = true
            }
            if (this.props.onGetFeatureInfo) {
                // atfiltrējam slāņus ar wmsFeatureInfoLayer
                // katram pasaucam maputils getfeatureinfo servisu
                const responses = overClickableRasterLayers
                    .filter(layerId => vectorLayerCfgs[layerId] && vectorLayerCfgs[layerId].wmsFeatureInfoLayer)
                    .map(layerId =>
                        maputils.getFeatureInfo(this.getLayerSourceByLayerId(layerId), event.coordinate, this.view.getResolution(), this.addLoading, this.addLoaded)
                            .then((features) => features.map(f => ({
                                ...f.getProperties(),
                                layer: layerId
                            })))
                    )
                if (responses.length > 0) {
                    Promise.all(responses).then((values) => {
                        this.props.onGetFeatureInfo({ event, features: values.flat() })
                    })
                }
                clickedSomething = true
            }
        }

        if (!clickedSomething && this.props.onClickOutsideFeature) {
            this.props.onClickOutsideFeature({
                mapId: this.props.mapId,
                event
            })
        }
    }

    handleOnPointerMove = (event) => {
        let overFeatures = []
        let overClickableRasterLayers = []
        let tooltip = null
        const map = this.map
        const { vectorLayerCfgs } = this.props
        const allFeatures = map.getFeaturesAtPixel(event.pixel)
        if (allFeatures) {
            const features = allFeatures.filter(f => !!f.get('mapLayerId'))
            if (features.length > 0) {
                overFeatures = features.filter(f => vectorLayerCfgs[f.get('mapLayerId')]['selectable'] !== false)
                const toolstips = features.map(f => {
                    const layerId = f.get('mapLayerId')
                    const tooltipFunc = vectorLayerCfgs[layerId]['tooltipFunc']
                    if (tooltipFunc) {
                        return tooltipFunc(f)
                    } else {
                        return null
                    }
                }).filter(t => t)
                if (toolstips.length > 0) {
                    tooltip = toolstips.join('<br>');
                    this.tooltipOverlay.setPosition(event.coordinate)
                }
            }
        }
        map.forEachLayerAtPixel(event.pixel, layer => {
            const layerId = layer.get('id')
            const cfg = vectorLayerCfgs[layerId]
            if (cfg && cfg.clickableRasterLayer === true) {
                overClickableRasterLayers.push(layerId)
            }
        })
        this.setState({
            overFeatures,
            overClickableRasterLayers,
            tooltip
        })
    }

    onMapContainerResize = (w, h) => {
        if (this.map) {
            this.map.updateSize();
        }
        if (this.props.onMapContainerResize) {
            this.props.onMapContainerResize(this.props.mapId, [w, h])
        }
    }

    render() {
        const { loading, loaded } = this.state
        let precLoaded = loaded / loading * 100
        if (precLoaded < 5) {
            precLoaded = 5
        }
        return (
            <>
                <div style={{ cursor: this.state.overFeatures.length > 0 || this.state.overClickableRasterLayers.length > 0 ? 'pointer' : '' }}
                    ref={node => this.mapContainer = node}
                    className="map">

                    {this.props.children}

                    <div className="map-tooltips"
                        ref={node => this.mapTooltips = node}
                        style={{ display: this.state.tooltip ? '' : 'none' }}
                        dangerouslySetInnerHTML={{ __html: this.state.tooltip }} />

                    <div className="map-popups"
                        ref={node => this.mapPopups = node}
                        style={{ display: this.props.popup ? '' : 'none' }}>
                        <div className="map-popup-close-btn"><i onClick={this.props.onPopupClose} className="fa fa-close"></i></div>
                        {this.props.popup ? this.props.popup.content : null}
                    </div>

                    <ReactResizeDetector
                        handleWidth
                        handleHeight
                        onResize={this.onMapContainerResize}
                        refreshRate={100}
                        refreshMode="throttle" />

                    {loading > loaded || this.isVectorLayerLoading() ?
                        <div className="spinner"> <i className="fa fa-spinner fa-spin fa-2x fa-fw"></i></div>
                        : null}
                </div>
            </>
        );
    }

}
