import * as helpers from './helpers'

const defaultListReducerForPasakumi = helpers.createListReducerWithMapClicking({
  listViewName: 'pasakumi_list',
  actionsName: 'pasakumi'
})
export const pasakumi = (state = helpers.listGetInitialState(), {type, payload, reqId}) => {
  return defaultListReducerForPasakumi({state, type, payload, reqId})
}

const defaultEditReducerForPasakums = helpers.createEditReducer({
  editViewName: 'pasakums_view',
  actionsName: 'pasakums'
})
export const pasakums = (state = helpers.editGetInitialState(), {type, payload}) => {
  return defaultEditReducerForPasakums({state, type, payload})
}

const defaultListReducerForPasKlasif = helpers.createListReducer({listViewName: 'pasakumu_klasifikators_edit', actionsName: 'pasakumuKlasif'})
const defaultEditReducerForPasKlasif = helpers.createEditReducer({editViewName: 'pasakumu_klasifikators_edit', actionsName: 'pasakumuKlasif'})
export const pasakumuKlasif = (state = helpers.listEditGetInitialState(), {type, payload, reqId}) => {
  //overwrite or add custom actions catches here

  const stateAfterList = defaultListReducerForPasKlasif({state, type, payload, reqId})
  return defaultEditReducerForPasKlasif({state: stateAfterList, type, payload, reqId})
}
